import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AnyScalar: any;
  DateISOString: any;
  DateTime: any;
  DateTimeISO: any;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  Money: any;
};

export type AccessoriesReturnStep = BasicReturnStep & {
  __typename?: 'AccessoriesReturnStep';
  accessories: Array<Scalars['String']>;
  description: Scalars['String'];
  headline: Scalars['String'];
  name: Scalars['String'];
  warning?: Maybe<Scalars['String']>;
};

export type AccountBasicModel = {
  __typename?: 'AccountBasicModel';
  accountUuid: Scalars['String'];
  amount: Scalars['Money'];
  currency: Scalars['String'];
  uuid: Scalars['String'];
};

export type AccountDcPaymentModel = {
  __typename?: 'AccountDcPaymentModel';
  currency: Scalars['String'];
  method: Scalars['String'];
  referenceId: Scalars['String'];
  transactionId: Scalars['String'];
};

export type AccountManualBookingModel = {
  __typename?: 'AccountManualBookingModel';
  orderNumber: Scalars['String'];
  otpLink: Scalars['String'];
};

export type AccountMenuHint = {
  __typename?: 'AccountMenuHint';
  text?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  urlText: Scalars['String'];
};

export type AccountMenuLink = {
  __typename?: 'AccountMenuLink';
  description: Scalars['String'];
  external: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  isHidden: Scalars['Boolean'];
  key: Scalars['String'];
  targetBlank: Scalars['Boolean'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type AccountModel = {
  __typename?: 'AccountModel';
  deleted: Scalars['Boolean'];
  type: Scalars['String'];
  uuid: Scalars['ID'];
  walletUuid: Scalars['String'];
};

export type AccountPayByInvoiceModel = {
  __typename?: 'AccountPayByInvoiceModel';
  viban: Scalars['String'];
};

export type AccountPaymentServiceModel = {
  __typename?: 'AccountPaymentServiceModel';
  gateway: Scalars['String'];
  referenceId: Scalars['String'];
  transactionId: Scalars['String'];
  transactionToken?: Maybe<Scalars['String']>;
};

export type AccountType =
  | AccountBasicModel
  | AccountDcPaymentModel
  | AccountManualBookingModel
  | AccountPayByInvoiceModel
  | AccountPaymentServiceModel
  | AccountWalletServiceModel;

export type AccountWalletServiceModel = {
  __typename?: 'AccountWalletServiceModel';
  paymentMethodId: Scalars['String'];
};

export type Action = {
  __typename?: 'Action';
  defaultMessage?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: Scalars['String'];
};

export type ActionInputType = {
  campaignUuid: Scalars['String'];
  content: Scalars['AnyScalar'];
  type: ActionTypes;
};

export type ActionMetadata = {
  __typename?: 'ActionMetadata';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  /** @deprecated use performedByEmail instead */
  performedBy?: Maybe<Scalars['Int']>;
  performedByEmail?: Maybe<Scalars['String']>;
  reason?: Maybe<ManualGrantReason>;
};

/** Transaction Action Status History Enums */
export enum ActionStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  SCHEDULED = 'SCHEDULED',
}

/** Transaction Action Type History Enums */
export enum ActionType {
  CANCEL = 'CANCEL',
  PUT_ON_HOLD = 'PUT_ON_HOLD',
  REFUND = 'REFUND',
  RETRY = 'RETRY',
}

export enum ActionTypes {
  copyToClipboardAction = 'copyToClipboardAction',
  launchFlowActionModel = 'launchFlowActionModel',
  linkAction = 'linkAction',
}

export type ActionUnionModel = CopyToClipboardActionModel | LaunchFlowActionModel | LinkActionModel;

export type Actor = EmployeeOfGrover | User;

/** Input type for adding an account to the Billing system */
export type AddAccountInput = {
  currency: Scalars['String'];
  externalCustomer: Scalars['String'];
  gateway: Gateway;
  referenceId: Scalars['String'];
  transactionId: Scalars['String'];
  transactionToken?: InputMaybe<Scalars['String']>;
};

export type AddLocaleInput = {
  content: Scalars['String'];
  locale: Scalars['String'];
  summary: Scalars['String'];
  version: Scalars['ID'];
};

/** Input type for adding a pay-by-invoice account to the Billing system */
export type AddPayByInvoiceAccountInput = {
  currency: Scalars['String'];
  externalCustomer: Scalars['String'];
  viban: Scalars['String'];
};

/** Input for the addPaymentMethod mutation. */
export type AddPaymentMethodInput = {
  /** Where to redirect the customer if authorization is cancelled. The occurrences of substring '{{payment-method-id}}' will be replaced with the method ID. */
  cancelRedirectUrl: Scalars['String'];
  /** Applicable for cards: a token generated by the payment provider from card data. */
  cardToken?: InputMaybe<Scalars['String']>;
  /** Used for invoice methods: a text description explaining the reason for the request. */
  comment?: InputMaybe<Scalars['String']>;
  /** Where to redirect the customer if authorization fails. The occurrences of substring '{{payment-method-id}}' will be replaced with the method ID. */
  errorRedirectUrl: Scalars['String'];
  option: PaymentOptionType;
  /** The order for which the method is being added. Must be provided if the addition happens during checkout. */
  orderNumber?: InputMaybe<Scalars['ID']>;
  /** Where to redirect the customer if authorization succeeds. The occurrences of substring '{{payment-method-id}}' will be replaced with the method ID. */
  successRedirectUrl: Scalars['String'];
  /** 3-D Secure-related data, applicable to bank cards. */
  threeDSData?: InputMaybe<PaymentMethod3dsData>;
};

/** Result of the addPaymentMethod mutation. */
export type AddPaymentMethodResult = {
  __typename?: 'AddPaymentMethodResult';
  /** ID of the new payment method. */
  id: Scalars['ID'];
  /** URL to redirect the user to if authorization is required by the payment provider. */
  redirectUrl?: Maybe<Scalars['String']>;
};

export type AddProductsToCountdownTimerInput = {
  countdownTimerId: Scalars['ID'];
  skus: Array<Scalars['String']>;
};

export type AddProductsToCountdownTimerOutput = {
  __typename?: 'AddProductsToCountdownTimerOutput';
  numberOfCreatedRecords: Scalars['Int'];
};

/** Autogenerated input type of AddToFavorites */
export type AddToFavoritesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  skus: Array<Scalars['String']>;
};

/** Autogenerated return type of AddToFavorites. */
export type AddToFavoritesPayload = {
  __typename?: 'AddToFavoritesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  favorites: Array<Product>;
};

/** Input type for adding a Wallet service account (payment method) */
export type AddWalletAccountInput = {
  currency: Scalars['String'];
  externalCustomer: Scalars['String'];
  paymentMethodId: Scalars['String'];
};

export type Addon = DigitalOtpVariant | ExternalPhysicalOtpProduct;

export type AddonAssociation = {
  __typename?: 'AddonAssociation';
  addons: Array<AddonsAddon>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  product?: Maybe<Product>;
  productSku: Scalars['String'];
  storeCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AddonAssociationInput = {
  productSku: Scalars['String'];
  storeCode?: InputMaybe<Scalars['String']>;
};

export type AddonAssociationsInput = {
  addonSku?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  mainProductSku?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
};

export type AddonAssociationsResult = {
  __typename?: 'AddonAssociationsResult';
  associations: Array<AddonAssociation>;
  nextCursor: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type AddonsAddon = {
  id: Scalars['ID'];
};

export type AddonsInternalAddon = AddonsAddon & {
  __typename?: 'AddonsInternalAddon';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  product?: Maybe<Product>;
  productSku: Scalars['String'];
  rentalPlan: AddonsRentalPlan;
  updatedAt: Scalars['DateTime'];
};

export type AddonsMainProduct = {
  __typename?: 'AddonsMainProduct';
  addons?: Maybe<Array<AddonsAddon>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  product?: Maybe<Product>;
  productSku: Scalars['String'];
  rentalPlan: AddonsRentalPlan;
  storeCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AddonsRentalPlan = {
  __typename?: 'AddonsRentalPlan';
  id: Scalars['ID'];
  length: Scalars['Int'];
  oldPrice?: Maybe<Price>;
  price: Price;
};

export type Address = {
  __typename?: 'Address';
  additionalInfo?: Maybe<Scalars['String']>;
  billingAddress: Scalars['Boolean'];
  city: Scalars['String'];
  country: Scalars['String'];
  countryIso: Scalars['String'];
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  shippingAddress: Scalars['Boolean'];
  stateAbbr?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type AddressInputType = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type AddressInvoice = {
  __typename?: 'AddressInvoice';
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  stateAbbr?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AdjustmentInput = {
  code?: InputMaybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AllConnectionsFilter = {
  hostId: Scalars['String'];
};

export type AllocationResult = {
  __typename?: 'AllocationResult';
  allocation?: Maybe<AssetAllocation>;
  success: Scalars['Boolean'];
};

export type AnalyticsTermV2 = {
  __typename?: 'AnalyticsTermV2';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ApplicableCarrierGraphql = {
  __typename?: 'ApplicableCarrierGraphql';
  description?: Maybe<Scalars['String']>;
  name: CarrierName;
  service: Array<CarrierServiceGraphql>;
};

export type ApplicableCarrierResultGraphql = {
  __typename?: 'ApplicableCarrierResultGraphql';
  carriers?: Maybe<Array<ApplicableCarrierGraphql>>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ApplyDiscount */
export type ApplyDiscountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  discountAmountInCents: Scalars['Int'];
  discountReason: DiscountReason;
};

/** Autogenerated return type of ApplyDiscount. */
export type ApplyDiscountPayload = {
  __typename?: 'ApplyDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

/** Autogenerated input type of ApplyFreeYearOffer */
export type ApplyFreeYearOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of ApplyFreeYearOffer. */
export type ApplyFreeYearOfferPayload = {
  __typename?: 'ApplyFreeYearOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptionDetails: SubscriptionDetails;
};

/** Autogenerated input type of ApplyLoyaltyDiscount */
export type ApplyLoyaltyDiscountInput = {
  amountInCents: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentId: Scalars['ID'];
};

/** Autogenerated return type of ApplyLoyaltyDiscount. */
export type ApplyLoyaltyDiscountPayload = {
  __typename?: 'ApplyLoyaltyDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payment: PaymentsUnion;
};

/** Autogenerated input type of ApplySurpriseDiscount */
export type ApplySurpriseDiscountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of ApplySurpriseDiscount. */
export type ApplySurpriseDiscountPayload = {
  __typename?: 'ApplySurpriseDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  subscriptionDetails: SubscriptionDetails;
};

export type Asset = {
  __typename?: 'Asset';
  isDeviceLocked?: Maybe<Scalars['Boolean']>;
  productNumber: Scalars['String'];
  serialNumber?: Maybe<Scalars['String']>;
};

export type AssetAllocation = {
  __typename?: 'AssetAllocation';
  asset: AssetModel;
  assetUid?: Maybe<Scalars['String']>;
  condition: AssetCondition;
  contract?: Maybe<Contract>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  orderMode?: Maybe<JunoOrderMode>;
  orderNumber?: Maybe<Scalars['String']>;
  reallocationAvailability: ReallocationAvailability;
  replacedBy: ReplacementModel;
  replacement: ReplacementModel;
  replacementAvailability: ReplacementAvailability;
  replacements?: Maybe<Array<ReplacementModel>>;
  reservation?: Maybe<Reservation>;
  reservationUid: Scalars['String'];
  salesforceAllocationId?: Maybe<Scalars['String']>;
  salesforceAssetId?: Maybe<Scalars['String']>;
  salesforceSubscriptionId?: Maybe<Scalars['String']>;
  /** @deprecated Use serialNumber from Asset entity */
  serialNumber?: Maybe<Scalars['String']>;
  shipments: Array<Shipment>;
  skuVariantCode?: Maybe<Scalars['String']>;
  status: AssetAllocationStatus;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['Int']>;
  subscription?: Maybe<SubscriptionDetails>;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
};

export type AssetAllocationCollection = {
  __typename?: 'AssetAllocationCollection';
  count: Scalars['Float'];
  rows: Array<AssetAllocation>;
};

export type AssetAllocationFilterInput = {
  assetUid?: InputMaybe<Scalars['String']>;
  orderMode?: InputMaybe<JunoOrderMode>;
  orderNumber?: InputMaybe<Scalars['String']>;
  reservationUid?: InputMaybe<Scalars['String']>;
  salesforceAllocationId?: InputMaybe<Scalars['String']>;
  salesforceAssetId?: InputMaybe<Scalars['String']>;
  salesforceSubscriptionId?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  skuVariantCode?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['Float']>;
  uid?: InputMaybe<Scalars['String']>;
};

export enum AssetAllocationStatus {
  Cancelled = 'Cancelled',
  Created = 'Created',
}

export type AssetBulkConditionUpdate = BulkUpdateParams & {
  __typename?: 'AssetBulkConditionUpdate';
  assetIds?: Maybe<Array<Scalars['String']>>;
  condition: AssetCondition;
  serialNumbers?: Maybe<Array<Scalars['String']>>;
};

export type AssetBulkConditionUpdateInput = {
  assetIds?: InputMaybe<Array<Scalars['String']>>;
  condition: AssetCondition;
  serialNumbers?: InputMaybe<Array<Scalars['String']>>;
};

export type AssetBulkConditionUpdateResult = BulkUpdateError & {
  __typename?: 'AssetBulkConditionUpdateResult';
  bulkError?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<AssetUpdateError>>;
  success?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AssetBulkConditionUpdate>;
};

export type AssetBulkSerialNumberUpdate = BulkUpdateParams & {
  __typename?: 'AssetBulkSerialNumberUpdate';
  assetIds?: Maybe<Array<Scalars['String']>>;
  currentSerialNumber: Scalars['String'];
  newSerialNumber: Scalars['String'];
  serialNumbers?: Maybe<Array<Scalars['String']>>;
};

export type AssetBulkSerialNumberUpdateInput = {
  serialNumbers: Array<SerialNumberUpdate>;
};

export type AssetBulkSerialNumberUpdateResult = BulkUpdateError & {
  __typename?: 'AssetBulkSerialNumberUpdateResult';
  bulkError?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<AssetUpdateError>>;
  success?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Array<AssetBulkSerialNumberUpdate>>;
};

export type AssetBulkStatusUpdate = BulkUpdateParams & {
  __typename?: 'AssetBulkStatusUpdate';
  assetIds?: Maybe<Array<Scalars['String']>>;
  serialNumbers?: Maybe<Array<Scalars['String']>>;
  status: AssetStatus;
};

export type AssetBulkStatusUpdateResult = BulkUpdateError & {
  __typename?: 'AssetBulkStatusUpdateResult';
  bulkError?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<AssetUpdateError>>;
  success?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AssetBulkStatusUpdate>;
};

export type AssetBulkUpdateInput = {
  assetIds?: InputMaybe<Array<Scalars['String']>>;
  lostReason?: InputMaybe<Scalars['String']>;
  lostReasonNote?: InputMaybe<Scalars['String']>;
  serialNumbers?: InputMaybe<Array<Scalars['String']>>;
  status: AssetStatus;
};

export type AssetBulkWarehouseCodeUpdate = BulkUpdateParams & {
  __typename?: 'AssetBulkWarehouseCodeUpdate';
  assetIds?: Maybe<Array<Scalars['String']>>;
  serialNumbers?: Maybe<Array<Scalars['String']>>;
  warehouseCode: WarehouseCode;
};

export type AssetBulkWarehouseCodeUpdateInput = {
  assetIds?: InputMaybe<Array<Scalars['String']>>;
  serialNumbers?: InputMaybe<Array<Scalars['String']>>;
  warehouseCode: WarehouseCode;
};

export type AssetBulkWarehouseCodeUpdateResult = BulkUpdateError & {
  __typename?: 'AssetBulkWarehouseCodeUpdateResult';
  bulkError?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<AssetUpdateError>>;
  success?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AssetBulkWarehouseCodeUpdate>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  count: Scalars['Float'];
  rows: Array<AssetModel>;
};

export enum AssetCondition {
  Agan = 'Agan',
  Damaged = 'Damaged',
  Incomplete = 'Incomplete',
  Irreparable = 'Irreparable',
  Locked = 'Locked',
  New = 'New',
  Used = 'Used',
  Warranty = 'Warranty',
}

export type AssetEventModel = {
  __typename?: 'AssetEventModel';
  assetUid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  description: Scalars['String'];
  serialNumber: Scalars['String'];
  skuVariantCode: Scalars['String'];
  uid: Scalars['ID'];
  warehouseCode: WarehouseCode;
};

export type AssetFilterInput = {
  capitalSourceName?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<AssetCondition>;
  orderNumber?: InputMaybe<Scalars['String']>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']>;
  purchaseRequestItemName?: InputMaybe<Scalars['String']>;
  purchaseRequestName?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceStatus?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  skuVariantCode?: InputMaybe<Scalars['String']>;
  skuVariantCodes?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<AssetStatus>;
  stockUid?: InputMaybe<Scalars['String']>;
  storeUid?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  warehouseCode?: InputMaybe<Scalars['String']>;
  warehouseCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type AssetHistory = {
  __typename?: 'AssetHistory';
  changeset: Array<AssetHistorySingleChange>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['ID'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export type AssetHistorySingleChange = {
  __typename?: 'AssetHistorySingleChange';
  newStock?: Maybe<Stock>;
  newValue?: Maybe<Scalars['String']>;
  oldStock?: Maybe<Stock>;
  oldValue?: Maybe<Scalars['String']>;
  property: AssetHistorySingleChangeProperty;
};

export enum AssetHistorySingleChangeProperty {
  capitalSourceName = 'capitalSourceName',
  condition = 'condition',
  lostReason = 'lostReason',
  serialNumber = 'serialNumber',
  skuVariantCode = 'skuVariantCode',
  status = 'status',
  stockUid = 'stockUid',
  supplierName = 'supplierName',
  warehouseCode = 'warehouseCode',
}

export type AssetModel = {
  __typename?: 'AssetModel';
  /** [Flex, Mix] contracts tuple from Loop service */
  activeContract?: Maybe<Contract>;
  allocations: Array<AssetAllocation>;
  capitalSourceName?: Maybe<Scalars['String']>;
  condition: AssetCondition;
  createdAt: Scalars['DateTimeISO'];
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  events: Array<AssetEventModel>;
  history: Array<AssetHistory>;
  isDeviceLocked?: Maybe<Scalars['Boolean']>;
  lostReason?: Maybe<Scalars['String']>;
  lostReasonNote?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  purchaseRequest?: Maybe<PurchaseRequest>;
  purchaseRequestItem?: Maybe<PurchaseRequestItem>;
  purchasedAt?: Maybe<Scalars['DateTimeISO']>;
  salesforceCapitalSourceId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceLastModifiedDate?: Maybe<Scalars['DateTimeISO']>;
  salesforcePurchaseRequestId?: Maybe<Scalars['String']>;
  salesforcePurchaseRequestItemId?: Maybe<Scalars['String']>;
  salesforceStatus?: Maybe<Scalars['String']>;
  salesforceStockUid?: Maybe<Scalars['String']>;
  salesforceSupplierId?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  shipments: Array<Shipment>;
  skuUid: Scalars['String'];
  skuVariantCode: Scalars['String'];
  status: AssetStatus;
  statusUpdatedAt?: Maybe<Scalars['DateTimeISO']>;
  stock?: Maybe<Stock>;
  stockUid: Scalars['String'];
  supplierName?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTimeISO'];
  variant?: Maybe<Variant>;
  warehouseCode: WarehouseCode;
};

export enum AssetStatus {
  CrossSale = 'CrossSale',
  Deleted = 'Deleted',
  Donated = 'Donated',
  InDebtCollection = 'InDebtCollection',
  InRepair = 'InRepair',
  InStock = 'InStock',
  InboundDamaged = 'InboundDamaged',
  InboundQuarantine = 'InboundQuarantine',
  InboundUnallocable = 'InboundUnallocable',
  Incomplete = 'Incomplete',
  Insolvency = 'Insolvency',
  InvestigationCarrier = 'InvestigationCarrier',
  InvestigationWarehouse = 'InvestigationWarehouse',
  Irreparable = 'Irreparable',
  LockedDevice = 'LockedDevice',
  Lost = 'Lost',
  LostSolved = 'LostSolved',
  Office = 'Office',
  OnLoan = 'OnLoan',
  Purchased = 'Purchased',
  Recovered = 'Recovered',
  Refurbished = 'Refurbished',
  Refurbishing = 'Refurbishing',
  ReportedAsStolen = 'ReportedAsStolen',
  Reserved = 'Reserved',
  Returned = 'Returned',
  ReturnedToSupplier = 'ReturnedToSupplier',
  Selling = 'Selling',
  SentForRefurbishment = 'SentForRefurbishment',
  Sold = 'Sold',
  TransferredToWarehouse = 'TransferredToWarehouse',
  WaitingForRefurbishment = 'WaitingForRefurbishment',
  Warranty = 'Warranty',
  WrittenOffDc = 'WrittenOffDc',
  WrittenOffOps = 'WrittenOffOps',
}

export type AssetStatusTransition = {
  __typename?: 'AssetStatusTransition';
  allowedTransitions: Array<AssetStatus>;
  status: AssetStatus;
};

export type AssetUpdateError = {
  __typename?: 'AssetUpdateError';
  condition?: Maybe<AssetCondition>;
  errors?: Maybe<Array<Scalars['String']>>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<AssetStatus>;
  success?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['ID']>;
};

export type AssociateProductInput = {
  sku: Scalars['String'];
};

export type AttentionNote = TextNoteInterface & {
  __typename?: 'AttentionNote';
  text?: Maybe<Scalars['String']>;
};

export type AuthDevice = {
  __typename?: 'AuthDevice';
  createdAt: Scalars['ISO8601DateTime'];
  deviceType: AuthDeviceType;
  history: Array<AuthDeviceHistoryItem>;
  id: Scalars['ID'];
  lastLocation?: Maybe<AuthDeviceHistoryItem>;
  os: Os;
  platform: Platform;
  stale: Scalars['Boolean'];
  userAgent: Scalars['String'];
};

/** The connection type for AuthDevice. */
export type AuthDeviceConnection = {
  __typename?: 'AuthDeviceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuthDeviceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AuthDevice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AuthDeviceEdge = {
  __typename?: 'AuthDeviceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AuthDevice>;
};

export type AuthDeviceHistoryItem = {
  __typename?: 'AuthDeviceHistoryItem';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryIso?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  ipAddress: Scalars['String'];
  lastSeenAt: Scalars['ISO8601DateTime'];
};

export enum AuthDeviceType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  OTHER = 'OTHER',
  TABLET = 'TABLET',
}

export type AutoRegenerateFields = {
  billingAddress?: InputMaybe<Scalars['Boolean']>;
  companyName?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  shippingAddress?: InputMaybe<Scalars['Boolean']>;
  ust?: InputMaybe<Scalars['Boolean']>;
};

export type AvailabilityCampaign = {
  __typename?: 'AvailabilityCampaign';
  allocationDate?: Maybe<Scalars['DateTimeISO']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  createdBy?: Maybe<Scalars['String']>;
  defaultThreshold?: Maybe<Scalars['Float']>;
  deliveryRange?: Maybe<AvailabilityCampaignDeliveryRange>;
  description?: Maybe<Scalars['String']>;
  finalAvailability?: Maybe<AvailabilityMode>;
  finishDate?: Maybe<Scalars['DateTimeISO']>;
  name?: Maybe<Scalars['String']>;
  skuThresholds?: Maybe<Array<AvailabilityCampaignSkuThreshold>>;
  startAvailability?: Maybe<AvailabilityMode>;
  startDate?: Maybe<Scalars['DateTimeISO']>;
  status?: Maybe<AvailabilityCampaignStatus>;
  storeIds?: Maybe<Array<Scalars['Int']>>;
  supplierNames?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<AvailabilityCampaignType>;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
};

export type AvailabilityCampaignCollection = {
  __typename?: 'AvailabilityCampaignCollection';
  count: Scalars['Float'];
  rows: Array<AvailabilityCampaign>;
};

export type AvailabilityCampaignDeliveryRange = {
  __typename?: 'AvailabilityCampaignDeliveryRange';
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

export type AvailabilityCampaignDeliveryRangeInput = {
  from?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['Float']>;
};

export type AvailabilityCampaignFilterInput = {
  skuVariantCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AvailabilityCampaignStatus>;
  storeId?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<AvailabilityCampaignType>;
  uid?: InputMaybe<Scalars['ID']>;
};

export type AvailabilityCampaignSkuThreshold = {
  __typename?: 'AvailabilityCampaignSkuThreshold';
  skuVariantCode: Scalars['String'];
  threshold?: Maybe<Scalars['Float']>;
};

export type AvailabilityCampaignSkuThresholdInput = {
  skuVariantCode: Scalars['String'];
  threshold?: InputMaybe<Scalars['Float']>;
};

export enum AvailabilityCampaignStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Created = 'Created',
}

export enum AvailabilityCampaignType {
  Live = 'Live',
  PreOrder = 'PreOrder',
}

export type AvailabilityCampaignUpsertInput = {
  allocationDate?: InputMaybe<Scalars['DateTimeISO']>;
  defaultThreshold?: InputMaybe<Scalars['Float']>;
  deliveryRange?: InputMaybe<AvailabilityCampaignDeliveryRangeInput>;
  description?: InputMaybe<Scalars['String']>;
  finalAvailability?: InputMaybe<AvailabilityMode>;
  finishDate?: InputMaybe<Scalars['DateTimeISO']>;
  name?: InputMaybe<Scalars['String']>;
  skuThresholds?: InputMaybe<Array<AvailabilityCampaignSkuThresholdInput>>;
  startAvailability?: InputMaybe<AvailabilityMode>;
  startDate?: InputMaybe<Scalars['DateTimeISO']>;
  storeIds?: InputMaybe<Array<Scalars['Int']>>;
  supplierNames?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<AvailabilityCampaignType>;
  uid?: InputMaybe<Scalars['ID']>;
};

export enum AvailabilityMode {
  Automatic = 'Automatic',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  WaitingList = 'WaitingList',
}

export enum B2BDashboardAccessRightStateGql {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
}

/** Mimics behavior from grover_api, to ease out migration */
export type B2BIntegerComparisonInput = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
};

/** Mimics behavior from grover_api, to ease out migration */
export type B2BStringComparisonInput = {
  _eq?: InputMaybe<Scalars['String']>;
  _ew?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _substr?: InputMaybe<Scalars['String']>;
  _sw?: InputMaybe<Scalars['String']>;
};

export type BankAccountSnapshotDataKontomatikInput = {
  sessionId: Scalars['ID'];
  sessionIdSignature: Scalars['String'];
};

export type BankAccountSnapshotDataPlaidInput = {
  publicToken: Scalars['String'];
};

export type BasicContract = {
  actions?: Maybe<Array<ContractAction>>;
  activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  billingAccountId: Scalars['ID'];
  billingTerms: BillingTerms;
  cancelPurchaseTerm: CancelPurchaseTerm;
  cancelReturnTerm: CancelReturnTerm;
  createdAt: Scalars['ISO8601DateTime'];
  currentFlow?: Maybe<Flow>;
  customerWishCancelTerm?: Maybe<CustomerWishCancelTerm>;
  customerWishKeepChargingTerm?: Maybe<CustomerWishKeepChargingTerm>;
  damageClaimTerm?: Maybe<DamageClaimTerm>;
  durationTerms: DurationTerms;
  earlyOrderCancellationTerm: EarlyOrderCancellationTerm;
  endingTerm: EndingTerm;
  flow?: Maybe<Flows>;
  good?: Maybe<Goods>;
  goods?: Maybe<Array<Goods>>;
  id: Scalars['ID'];
  internalDebtCollectionTerm?: Maybe<InternalDebtCollectionTerm>;
  manualPauseTerm: ManualPauseTerm;
  order: Order;
  orderNumber: Scalars['ID'];
  pausedAt?: Maybe<Scalars['ISO8601DateTime']>;
  replacementTerm: ReplacementTerm;
  revivalTerm: RevivalTerm;
  revocationTerms?: Maybe<RevocationTerms>;
  state: ContractState;
  surpriseDiscountTerm: SurpriseDiscountTerm;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  terminationComment?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<ContractTerminationReason>;
  tosUpdates: Array<TosUpdate>;
  tosVersion?: Maybe<Scalars['ID']>;
  upsellTerm?: Maybe<UpsellTerm>;
  userId: Scalars['ID'];
};

export type BasicContractgoodArgs = {
  id: Scalars['ID'];
};

export type BasicMarketingCardContentModel = {
  __typename?: 'BasicMarketingCardContentModel';
  actions?: Maybe<Array<ActionUnionModel>>;
  /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
  bodyTemplate: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  largeImage?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['ID'];
};

export type BasicMarketingCardModel = {
  __typename?: 'BasicMarketingCardModel';
  actions?: Maybe<Array<ActionUnionModel>>;
  /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
  bodyTemplate: Scalars['String'];
  colorFunction?: Maybe<ColorFunctions>;
  imageUrl?: Maybe<Scalars['String']>;
  largeImage?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['ID'];
};

export type BasicReturnStep = {
  description: Scalars['String'];
  headline: Scalars['String'];
  name: Scalars['String'];
  warning?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of BatchCancelContracts */
export type BatchCancelContractsInput = {
  cancellationReason: ContractCancellationReason;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractIds: Array<Scalars['ID']>;
  debtCollectionAgency?: InputMaybe<DebtCollectionAgency>;
  productVariantState: ContractGoodState;
};

/** Autogenerated return type of BatchCancelContracts. */
export type BatchCancelContractsPayload = {
  __typename?: 'BatchCancelContractsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Contract>>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of BatchPauseContracts */
export type BatchPauseContractsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  days: Scalars['Int'];
  orderNumbers?: InputMaybe<Array<Scalars['ID']>>;
  reason: PauseReason;
};

/** Autogenerated return type of BatchPauseContracts. */
export type BatchPauseContractsPayload = {
  __typename?: 'BatchPauseContractsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Contract>>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of BatchReviveContracts */
export type BatchReviveContractsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of BatchReviveContracts. */
export type BatchReviveContractsPayload = {
  __typename?: 'BatchReviveContractsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Contract>>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type BigMigrationCreateRecurrentPaymentsContractInput = {
  id: Scalars['String'];
  nextPeriodNumber: Scalars['Int'];
  subscriptionPayments: Array<BigMigrationCreateRecurrentPaymentsContractPaymentInput>;
};

export type BigMigrationCreateRecurrentPaymentsContractPaymentInput = {
  amount: Scalars['Float'];
  createdAt: Scalars['String'];
  dueDate: Scalars['String'];
  paid: Scalars['Float'];
  period: Scalars['Int'];
  referenceId?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type BigMigrationCreateRecurrentPaymentsInput = {
  billingAccountId: Scalars['String'];
  orders: Array<BigMigrationCreateRecurrentPaymentsOrderInput>;
  useConsolidatedBilling: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type BigMigrationCreateRecurrentPaymentsOrderInput = {
  additionalData: Scalars['JSON'];
  contracts: Array<BigMigrationCreateRecurrentPaymentsContractInput>;
  currency: Scalars['String'];
  lineItems: Array<Scalars['JSON']>;
  orderNumber: Scalars['String'];
  orderPaymentCreatedAt: Scalars['String'];
  orderPaymentUpdatedAt: Scalars['String'];
  totalInCents: Scalars['Int'];
};

export type BigMigrationCreateRecurrentPaymentsPayload = {
  __typename?: 'BigMigrationCreateRecurrentPaymentsPayload';
  result: Scalars['JSON'];
  success: Scalars['Boolean'];
};

export type BigMigrationRevertRecurrentPaymentsContractInput = {
  id: Scalars['String'];
};

export type BigMigrationRevertRecurrentPaymentsInput = {
  billingAccountId: Scalars['String'];
  ignoreShippingPayment: Scalars['Boolean'];
  orders: Array<BigMigrationRevertRecurrentPaymentsOrderInput>;
  userId: Scalars['String'];
};

export type BigMigrationRevertRecurrentPaymentsOrderInput = {
  contracts: Array<BigMigrationRevertRecurrentPaymentsContractInput>;
  orderNumber: Scalars['String'];
};

/** A summary of the billing status of the user: how much they owe, what was paid already, etc. */
export type BillingBreakdown = {
  __typename?: 'BillingBreakdown';
  /** Sum of failed recurrent payments, partially paid payments, and pending payments older than 14 days. */
  outstandingAmount: Price;
  /** Sum of fully paid payments. */
  paidAmount: Price;
  /** Sum of pending payments newer than 14 days. */
  pendingAmount: Price;
  /** Sum of payments not charged yet, but scheduled to. */
  plannedAmount: Price;
};

/** Keeps of reference on common metadata of initial creation of schedules */
export type BillingChargeRequest = {
  __typename?: 'BillingChargeRequest';
  correlationId: Scalars['String'];
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  externalId: Scalars['ID'];
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  schedules: Array<BillingSchedule>;
  source: BillingChargeRequestSource;
  storeCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export enum BillingChargeRequestSource {
  one_time = 'one_time',
  order = 'order',
  recurrent = 'recurrent',
}

/** Keeps information about how we plan to charge customers. Which Items will be charged, when and why. One Group = One Charge. */
export type BillingGroup = {
  __typename?: 'BillingGroup';
  createdAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  groupingStrategy: BillingGroupingStrategyObject;
  id: Scalars['ID'];
  paymentMethod: BillingPaymentMethod;
  schedules: Array<BillingSchedule>;
  schedulesLog: Array<BillingSchedule>;
  status: BillingGroupStatus;
  statusesHistory: Array<BillingGroupStatus>;
  strategyReason: Scalars['String'];
  type: GroupType;
  updatedAt: Scalars['DateTime'];
};

/** Keeps information about how we plan to charge customers. Which Items will be charged, when and why. One Group = One Charge. */
export type BillingGroupschedulesLogArgs = {
  filters: BillingSchedulesLogInput;
};

export type BillingGroupStatus = {
  __typename?: 'BillingGroupStatus';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reason: BillingGroupStatusReason;
  status: BillingGroupStatusOption;
  updatedAt: Scalars['DateTime'];
};

/**
 * 'NEW' =  group created but not yet placed into a charge job
 * 'SCHEDULED' = group pushed into charge job
 * 'PROCESSING' = Group undergoing operation on ledger
 * 'COMPLETED' = Schedule fulfilled
 * 'FAILED' = It was not charged/booked on time
 * 'CANCELLED' = Schedule canceled due to some action taken by the user or automated process
 */
export enum BillingGroupStatusOption {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  NEW = 'NEW',
  ON_HOLD = 'ON_HOLD',
  PROCESSING = 'PROCESSING',
  SCHEDULED = 'SCHEDULED',
}

export enum BillingGroupStatusReason {
  automatic__charge_job_failed = 'automatic__charge_job_failed',
  automatic__charge_job_initialized = 'automatic__charge_job_initialized',
  automatic__charge_job_retries_exceeded = 'automatic__charge_job_retries_exceeded',
  automatic__charge_job_retries_failed = 'automatic__charge_job_retries_failed',
  automatic__charge_job_retry_attempted = 'automatic__charge_job_retry_attempted',
  automatic__charge_job_scheduled = 'automatic__charge_job_scheduled',
  automatic__contract_event_activated = 'automatic__contract_event_activated',
  automatic__contract_event_cancelled = 'automatic__contract_event_cancelled',
  automatic__contract_event_ended = 'automatic__contract_event_ended',
  automatic__contract_event_paused = 'automatic__contract_event_paused',
  automatic__contract_event_purchase = 'automatic__contract_event_purchase',
  automatic__contract_event_reactivated = 'automatic__contract_event_reactivated',
  automatic__contract_event_revived = 'automatic__contract_event_revived',
  automatic__contract_event_revocation = 'automatic__contract_event_revocation',
  automatic__contract_extended = 'automatic__contract_extended',
  automatic__job_push_failed = 'automatic__job_push_failed',
  automatic__movement_event_failed = 'automatic__movement_event_failed',
  automatic__movement_event_succeeded = 'automatic__movement_event_succeeded',
  automatic__no_active_schedules = 'automatic__no_active_schedules',
  automatic__no_gaps_outstanding_attempt = 'automatic__no_gaps_outstanding_attempt',
  automatic__no_gaps_outstanding_err_linked_to_terminated = 'automatic__no_gaps_outstanding_err_linked_to_terminated',
  automatic__no_gaps_outstanding_failed = 'automatic__no_gaps_outstanding_failed',
  automatic__no_gaps_outstanding_succeeded = 'automatic__no_gaps_outstanding_succeeded',
  automatic__no_psp_charge_failed_job_failed = 'automatic__no_psp_charge_failed_job_failed',
  automatic__no_psp_charge_failed_job_initialized = 'automatic__no_psp_charge_failed_job_initialized',
  automatic__no_psp_charge_failed_job_scheduled = 'automatic__no_psp_charge_failed_job_scheduled',
  automatic__order_payment = 'automatic__order_payment',
  automatic__payment_method_change = 'automatic__payment_method_change',
  automatic__refund_job_failed = 'automatic__refund_job_failed',
  automatic__refund_job_initialized = 'automatic__refund_job_initialized',
  automatic__refund_job_retries_exceeded = 'automatic__refund_job_retries_exceeded',
  automatic__refund_job_retries_failed = 'automatic__refund_job_retries_failed',
  automatic__refund_job_retry_attempted = 'automatic__refund_job_retry_attempted',
  automatic__refund_job_scheduled = 'automatic__refund_job_scheduled',
  automatic__req_charge_one_time = 'automatic__req_charge_one_time',
  internal__retry_attempt = 'internal__retry_attempt',
  internal__technical_error = 'internal__technical_error',
  internal__technical_error_another_attempt = 'internal__technical_error_another_attempt',
}

export enum BillingGroupStrategyType {
  DUE_DATE = 'DUE_DATE',
  HOURLY = 'HOURLY',
  NONE = 'NONE',
  ORDER_NUMBER = 'ORDER_NUMBER',
  REFUND = 'REFUND',
}

/** Defines how we will group upcoming payments. */
export type BillingGroupingStrategyObject = {
  __typename?: 'BillingGroupingStrategyObject';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: BillingGroupStrategyType;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BillingGroupsLogInput = {
  type: BillingScheduleSchedulesLogType;
};

/** A legacy schedule is a Billing Service's payment. */
export type BillingLegacySchedule = {
  __typename?: 'BillingLegacySchedule';
  chargingAmount: Price;
  consolidated: Scalars['Boolean'];
  /** Only present for consolidated payments */
  consolidatedAmount?: Maybe<Price>;
  contractId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  failedReason?: Maybe<Scalars['String']>;
  group: Scalars['ID'];
  id: Scalars['ID'];
  /** URL for the related invoice PDF, if any. */
  invoiceUrl?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  /** Wallet payment method. */
  paymentMethod: PaymentMethod2;
  period?: Maybe<Scalars['Int']>;
  /** Payment statuses from Billing Services: 'new', 'failed', 'partial paid', 'paid', 'partial refund', 'refund', 'pending', or 'cancelled' */
  status: Scalars['String'];
  totalAmount: Price;
  type: BillingScheduleType;
  updatedAt: Scalars['DateTime'];
};

export enum BillingMode {
  PREPAID = 'PREPAID',
}

export type BillingPaymentMethod = {
  __typename?: 'BillingPaymentMethod';
  createdAt: Scalars['DateTime'];
  /** Wallet payment method ID. */
  externalId: Scalars['ID'];
  id: Scalars['ID'];
  /** Wallet payment method. */
  paymentMethod: PaymentMethod2;
  type: BillingPaymentMethodType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export enum BillingPaymentMethodType {
  CHARGEABLE = 'CHARGEABLE',
  MANUAL = 'MANUAL',
}

export type BillingPeriod = Period & {
  __typename?: 'BillingPeriod';
  amountDue: Price;
  amounts: Array<PeriodAmount>;
  contractId: Scalars['ID'];
  discounts?: Maybe<Array<ContractDiscounts>>;
  ends: Scalars['ISO8601DateTime'];
  number: Scalars['Int'];
  payment?: Maybe<ContractPaymentGroupModel>;
  starts: Scalars['ISO8601DateTime'];
};

/** Keeps information about a schedule's price, with discriminated amounts. */
export type BillingPriceSnapshot = {
  __typename?: 'BillingPriceSnapshot';
  amountPaid: Price;
  /** Amount that is going to be charged, after discount. */
  chargingAmount: Price;
  createdAt: Scalars['DateTime'];
  discount: Price;
  discountPercentage: Scalars['Float'];
  groverCash: Price;
  id: Scalars['ID'];
  taxAmount: Price;
  taxRate: Scalars['Float'];
  totalAmount: Price;
  updatedAt: Scalars['DateTime'];
};

/** Keeps information about a retry request and its status. */
export type BillingRetryOperation = {
  __typename?: 'BillingRetryOperation';
  contractId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status: BillingRetryOperationStatus;
  updatedAt: Scalars['DateTime'];
};

export enum BillingRetryOperationStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
}

/** Keeps information about all submitted items that we currently run billing schedule on. Detail description can be found on Scheduler Confluence page. */
export type BillingSchedule = {
  __typename?: 'BillingSchedule';
  /** ID of related Billing Service's payment. */
  billingId?: Maybe<Scalars['ID']>;
  /** Related charge request. */
  chargeRequest: BillingChargeRequest;
  contractId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  externalId: Scalars['ID'];
  /** Currently active billing schedule group. */
  group?: Maybe<BillingGroup>;
  /** History of all billing schedule group changes. */
  groupsLog: Array<BillingGroup>;
  id: Scalars['ID'];
  /** URL for the related invoice PDF, if any. */
  invoiceUrl?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  orderNumber: Scalars['String'];
  /** Currently assigned payment method. */
  paymentMethod: BillingPaymentMethod;
  /** History of all payment method changes. */
  paymentMethodsHistory: Array<BillingPaymentMethod>;
  period?: Maybe<Scalars['Int']>;
  /** Price of the schedule. Should be empty for future payments. */
  priceSnapshot?: Maybe<BillingPriceSnapshot>;
  slug: Scalars['String'];
  status: BillingScheduleStatus;
  /** History of all status changes. */
  statusesHistory: Array<BillingScheduleStatus>;
  type: BillingScheduleType;
  updatedAt: Scalars['DateTime'];
};

/** Keeps information about all submitted items that we currently run billing schedule on. Detail description can be found on Scheduler Confluence page. */
export type BillingSchedulegroupsLogArgs = {
  filters: BillingGroupsLogInput;
};

export enum BillingScheduleSchedulesLogType {
  ALL = 'ALL',
  PAST = 'PAST',
}

export type BillingScheduleStatus = {
  __typename?: 'BillingScheduleStatus';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  reason: BillingScheduleStatusReason;
  status: BillingScheduleStatusOptions;
  updatedAt: Scalars['DateTime'];
};

/**
 * 'NEW' = created but not yet part of the group;
 * 'SCHEDULED' = is part of the Billing Schedule Group;
 * 'COMPLETED' = fulfilled, no more action needed;
 * 'CANCELLED' = canceled due to some action taken by the user or automated process;
 */
export enum BillingScheduleStatusOptions {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  PAUSED = 'PAUSED',
  SCHEDULED = 'SCHEDULED',
}

export enum BillingScheduleStatusReason {
  automatic__charge_job_completed = 'automatic__charge_job_completed',
  automatic__charge_job_created = 'automatic__charge_job_created',
  automatic__contract_event_activated = 'automatic__contract_event_activated',
  automatic__contract_event_cancelled = 'automatic__contract_event_cancelled',
  automatic__contract_event_cancelled_refund = 'automatic__contract_event_cancelled_refund',
  automatic__contract_event_ended = 'automatic__contract_event_ended',
  automatic__contract_event_paused = 'automatic__contract_event_paused',
  automatic__contract_event_purchase_started = 'automatic__contract_event_purchase_started',
  automatic__contract_event_reactivated = 'automatic__contract_event_reactivated',
  automatic__contract_event_revived = 'automatic__contract_event_revived',
  automatic__contract_event_revocation = 'automatic__contract_event_revocation',
  automatic__contract_extended = 'automatic__contract_extended',
  automatic__movement_event_dc_booking = 'automatic__movement_event_dc_booking',
  automatic__movement_event_manual_booking = 'automatic__movement_event_manual_booking',
  automatic__movement_event_manual_booking_bs = 'automatic__movement_event_manual_booking_bs',
  automatic__movement_event_status_failed = 'automatic__movement_event_status_failed',
  automatic__movement_event_status_succeeded = 'automatic__movement_event_status_succeeded',
  automatic__order_payment = 'automatic__order_payment',
  automatic__payment_method_change = 'automatic__payment_method_change',
  automatic__refund_job_completed = 'automatic__refund_job_completed',
  automatic__req_charge_one_time = 'automatic__req_charge_one_time',
  internal__technical_error = 'internal__technical_error',
}

export enum BillingScheduleType {
  CONTRACT = 'CONTRACT',
  DIGITAL_OTP = 'DIGITAL_OTP',
  EXTERNAL_PHYSICAL_OTP = 'EXTERNAL_PHYSICAL_OTP',
  GROVER_CARE = 'GROVER_CARE',
  GROVER_CARE_LEGACY = 'GROVER_CARE_LEGACY',
  PURCHASE = 'PURCHASE',
  SHIPMENT = 'SHIPMENT',
}

export type BillingScheduleUnion = BillingLegacySchedule | BillingSchedule;

export type BillingSchedulesLogInput = {
  type: BillingScheduleSchedulesLogType;
};

export enum BillingServicePaymentProcess {
  Basic = 'Basic',
  ManualBooking = 'ManualBooking',
  PayByInvoice = 'PayByInvoice',
  PaymentServiceProvider = 'PaymentServiceProvider',
}

export type BillingTerms = {
  __typename?: 'BillingTerms';
  basePrice: Price;
  currentPeriod?: Maybe<BillingPeriod>;
  discounts?: Maybe<Array<ContractDiscounts>>;
  nextPeriod?: Maybe<BillingPeriod>;
  period?: Maybe<BillingPeriod>;
  periodLength: Duration;
  price: Price;
  type: BillingMode;
};

export type BillingTermsperiodArgs = {
  number: Scalars['Int'];
};

export type BookPBIInput = {
  allocate: Scalars['Float'];
  effectiveDate: Scalars['ISO8601Date'];
  movementID?: InputMaybe<Scalars['ID']>;
  paymentReference: Scalars['String'];
  paymentSource: Scalars['String'];
  reason: Scalars['String'];
  scheduleID?: InputMaybe<Scalars['ID']>;
};

export type BookPBIPayload = {
  __typename?: 'BookPBIPayload';
  movement?: Maybe<Movement>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BooleanComparisonInput = {
  _eq?: InputMaybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  image?: Maybe<HeaderImage>;
  linkV2?: Maybe<HeaderLink>;
  title?: Maybe<Scalars['String']>;
};

export type BulkAssociationAddonsInput = {
  associations: Array<UpdateAllAssociationInput>;
  overwriteExistingAssociations?: InputMaybe<Scalars['Boolean']>;
};

export type BulkAssociationAddonsResult = {
  __typename?: 'BulkAssociationAddonsResult';
  errorRows: Array<BulkAssociationErrorRow>;
  successfulCount: Scalars['Int'];
};

export type BulkAssociationErrorRow = {
  __typename?: 'BulkAssociationErrorRow';
  digitalOtpProductSkus: Array<Scalars['String']>;
  mainProductSku: Scalars['String'];
  storeCode: Scalars['String'];
};

export enum BulkChargeDamageLevel {
  CRITICAL = 'CRITICAL',
  MAJOR = 'MAJOR',
  NOTICEABLE = 'NOTICEABLE',
}

export type BulkChargeForDamagedAssetsError = {
  __typename?: 'BulkChargeForDamagedAssetsError';
  allocationId: Scalars['String'];
  error: Scalars['String'];
};

export type BulkChargeForDamagedAssetsInput = {
  items: Array<BulkChargeForDamagedAssetsItemInput>;
};

export type BulkChargeForDamagedAssetsItemInput = {
  allocationId: Scalars['String'];
  amountToChargeInCents: Scalars['Float'];
  assetId: Scalars['String'];
  chargeType: BulkChargeType;
  damageLevel: BulkChargeDamageLevel;
};

export type BulkChargeForDamagedAssetsResult = {
  __typename?: 'BulkChargeForDamagedAssetsResult';
  failedAllocations: Array<BulkChargeForDamagedAssetsError>;
  message: Scalars['String'];
};

export enum BulkChargeType {
  DAMAGED = 'DAMAGED',
  LOCKED = 'LOCKED',
  RECOMMERCE = 'RECOMMERCE',
}

export type BulkDashboardAccessInput = {
  /** CompanyIds that the access should be toggled for. */
  companyIds: Array<Scalars['Int']>;
};

export type BulkDeliveryProfileRuleMatchInput = {
  country?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<CustomerType>;
  force?: InputMaybe<Scalars['Boolean']>;
  orderMode?: InputMaybe<DeliveryOrderMode>;
  orderNumber?: InputMaybe<Scalars['String']>;
  preferredCarrier?: InputMaybe<CarrierName>;
  priority?: InputMaybe<Scalars['Int']>;
  requestId: Scalars['String'];
  skuProducts?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<DeliveryType>;
  userId?: InputMaybe<Scalars['String']>;
  warehouseCode?: InputMaybe<DeliveryWarehouseCode>;
};

export type BulkDeliveryProfileRuleMatchResult = {
  __typename?: 'BulkDeliveryProfileRuleMatchResult';
  error?: Maybe<Scalars['String']>;
  matchedRule?: Maybe<DeliveryProfileRuleMatch>;
  profile?: Maybe<DeliveryProfile>;
  requestId: Scalars['String'];
  riskLevel?: Maybe<Scalars['String']>;
};

export type BulkUpdateError = {
  bulkError?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<AssetUpdateError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type BulkUpdateParams = {
  assetIds?: Maybe<Array<Scalars['String']>>;
  serialNumbers?: Maybe<Array<Scalars['String']>>;
};

export type Bundle = {
  __typename?: 'Bundle';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mainProduct?: Maybe<Product>;
  mainProductSku: Scalars['String'];
  secondaryProducts?: Maybe<Array<Maybe<Product>>>;
  secondarySkus: Array<Scalars['String']>;
  store: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
};

export type BundleBulkCreateInput = {
  bundles?: InputMaybe<Array<BundleCreateInput>>;
};

export type BundleCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  mainProductSku: Scalars['String'];
  secondarySkus: Array<Scalars['String']>;
  store: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type BundleError = {
  __typename?: 'BundleError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type BundleOrError = Bundle | BundleError;

export type BundleOrderByInput = {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainProductSku?: InputMaybe<SortOrder>;
  secondarySkus?: InputMaybe<SortOrder>;
  store?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type BundlePaginationOutput = {
  __typename?: 'BundlePaginationOutput';
  rows: Array<Bundle>;
  totalCount: Scalars['Int'];
};

export type BundleUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  mainProductSku?: InputMaybe<Scalars['String']>;
  secondarySkus?: InputMaybe<Array<Scalars['String']>>;
  store?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BundleWhereInput = {
  AND?: InputMaybe<Array<BundleWhereInput>>;
  NOT?: InputMaybe<Array<BundleWhereInput>>;
  OR?: InputMaybe<Array<BundleWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  mainProductSku?: InputMaybe<StringFilter>;
  secondarySkus?: InputMaybe<StringNullableListFilter>;
  store?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type BundleWhereUniqueInput = {
  id: Scalars['Int'];
};

export type BundlesExportTask = Task & {
  __typename?: 'BundlesExportTask';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['Int'];
  fileName: Scalars['String'];
  fileUri?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: TaskStatus;
};

export type BusinessAssetReturnMetadata = {
  __typename?: 'BusinessAssetReturnMetadata';
  /** Juno Asset ID */
  assetId: Scalars['ID'];
  /** Work-Service Asset ID */
  assetUuid: Scalars['ID'];
  /** Loop Contract ID */
  contractId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  email: Scalars['String'];
  employee?: Maybe<GroverEmployee>;
  employeeUuid?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type BusinessDashboard = {
  __typename?: 'BusinessDashboard';
  /** User from GroverAPI through 'userId' */
  activatedAt?: Maybe<Scalars['DateTime']>;
  activatedBy?: Maybe<User>;
  /** User from GroverAPI through 'userId' */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  deactivatedBy?: Maybe<User>;
  enabled: Scalars['Boolean'];
};

export type Buyout = {
  __typename?: 'Buyout';
  /** @deprecated Use graphql __typename instead */
  action: Scalars['String'];
  available?: Maybe<Scalars['Boolean']>;
  availableAt?: Maybe<Scalars['ISO8601DateTime']>;
  deactivated?: Maybe<Scalars['Boolean']>;
  discountOfferPercentage?: Maybe<Scalars['Int']>;
  discountOfferPrice?: Maybe<Price>;
  initialPrice?: Maybe<Price>;
  marketPrice?: Maybe<Price>;
  markup?: Maybe<Scalars['Float']>;
  minimalCartPrice?: Maybe<Price>;
  monthsRequired?: Maybe<Scalars['Int']>;
  purchasePrice?: Maybe<Price>;
  remainingMonths?: Maybe<Scalars['String']>;
  selfServiceAvailable: Scalars['Boolean'];
  type?: Maybe<Scalars['String']>;
  unavailableReason?: Maybe<Scalars['String']>;
  unavailableSince?: Maybe<Scalars['ISO8601DateTime']>;
};

export type BuyoutCalculation = {
  __typename?: 'BuyoutCalculation';
  marketPriceWithMarkupInCents: Scalars['Int'];
  markup: Scalars['Float'];
  requiredMonths?: Maybe<Scalars['Int']>;
};

export type ByInvoice = PaymentMethodInterface & {
  __typename?: 'ByInvoice';
  billingAccountId?: Maybe<Scalars['String']>;
  contracts: Array<BasicContract>;
  id: Scalars['ID'];
  viban?: Maybe<Scalars['String']>;
};

/** Invoice payment method from Wallet service. Eventually will deprecate the ByInvoice type. */
export type ByInvoicePaymentMethod = PaymentMethod2 & {
  __typename?: 'ByInvoicePaymentMethod';
  /** ID of the corresponding account in Billing Service. */
  billingAccountId?: Maybe<Scalars['String']>;
  /** Date/time of the method creation. Empty in case of a legacy method. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Present if the method deletion is currently forbidden, contains details about the reason. Warning: currently does not return "has linked contracts", which is one of the possible reasons for deletion lock. Check the linkedContracts field instead. */
  deletionLock?: Maybe<PaymentMethodDeletionLock>;
  /** ID of the corresponding method in Grover API. */
  groverApiId?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Method's external ID in IXOPAY. */
  ixopayReferenceId?: Maybe<Scalars['String']>;
  /** Contracts and subscriptions that are using this method. */
  linkedContracts: Array<FlexDetails>;
  /** URL to redirect the user to if authorization is required by the payment provider. */
  redirectUrl?: Maybe<Scalars['String']>;
  /** Error code classifying the method registration failure, if any. */
  registrationErrorCode?: Maybe<TransactionErrorCode>;
  status: PaymentMethodStatus;
  /** Get status of this method in relation to the given order. */
  statusForOrder: PaymentMethodStatusForOrderResult;
  viban?: Maybe<Scalars['String']>;
};

/** Invoice payment method from Wallet service. Eventually will deprecate the ByInvoice type. */
export type ByInvoicePaymentMethodstatusForOrderArgs = {
  orderNumber: Scalars['String'];
};

export type CampaignFilterInputType = {
  level?: InputMaybe<LevelTypes>;
  status?: InputMaybe<StatusTypes>;
};

export type CampaignInputType = {
  conditionUuid?: InputMaybe<Scalars['String']>;
  content: Scalars['AnyScalar'];
  level: LevelTypes;
  name: Scalars['String'];
  pages?: InputMaybe<Array<Scalars['String']>>;
  platforms?: InputMaybe<Array<PlatformTypes>>;
  status?: InputMaybe<StatusTypes>;
  stores?: InputMaybe<Array<Scalars['String']>>;
  type: CampaignTypes;
};

export type CampaignModel = {
  __typename?: 'CampaignModel';
  condition?: Maybe<ConditionsContentModel>;
  conditionUuid?: Maybe<Scalars['String']>;
  content?: Maybe<ContentUnionModel>;
  createdAt: Scalars['DateTime'];
  /** Returns goal entity */
  goal?: Maybe<GoalModel>;
  level: Scalars['String'];
  /** Calculates dismissed, finished and usersInCampaign metrics */
  metrics: MetricsModel;
  name: Scalars['String'];
  pages?: Maybe<Array<Scalars['String']>>;
  platforms?: Maybe<Array<PlatformTypes>>;
  status: StatusTypes;
  stores?: Maybe<Array<Scalars['String']>>;
  translate: Scalars['JSON'];
  type: CampaignTypes;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export enum CampaignTypes {
  basicMarketingCard = 'basicMarketingCard',
  confirmationPollCard = 'confirmationPollCard',
  hint = 'hint',
  pollCard = 'pollCard',
  subscriptionBadge = 'subscriptionBadge',
}

export type CampaignUserStateModel = {
  __typename?: 'CampaignUserStateModel';
  campaignId: Scalars['String'];
  campaignName: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of CancelContract */
export type CancelContractInput = {
  cancellationComment?: InputMaybe<Scalars['String']>;
  cancellationReason: ContractCancellationReason;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  productVariantState: ContractGoodState;
};

/** Autogenerated return type of CancelContract. */
export type CancelContractPayload = {
  __typename?: 'CancelContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of CancelContractReturn */
export type CancelContractReturnInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of CancelContractReturn. */
export type CancelContractReturnPayload = {
  __typename?: 'CancelContractReturnPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

export type CancelGoodsOrderResponseType = {
  __typename?: 'CancelGoodsOrderResponseType';
  msg?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CancelOrderContracts */
export type CancelOrderContractsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderCancellationReason: Scalars['String'];
  orderNumber: Scalars['ID'];
  requestedBy: Scalars['String'];
};

/** Autogenerated return type of CancelOrderContracts. */
export type CancelOrderContractsPayload = {
  __typename?: 'CancelOrderContractsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contracts: Array<Contract>;
};

export type CancelOrderEarly = {
  __typename?: 'CancelOrderEarly';
  /** @deprecated Use graphql __typename instead */
  action: Scalars['String'];
  available: Scalars['Boolean'];
};

/** Autogenerated input type of CancelOrderEarly */
export type CancelOrderEarlyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderNumber: Scalars['ID'];
};

/** Autogenerated return type of CancelOrderEarly. */
export type CancelOrderEarlyPayload = {
  __typename?: 'CancelOrderEarlyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contracts: Array<Contract>;
};

/** Autogenerated input type of CancelPurchase */
export type CancelPurchaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of CancelPurchase. */
export type CancelPurchasePayload = {
  __typename?: 'CancelPurchasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type CancelPurchaseTerm = {
  __typename?: 'CancelPurchaseTerm';
  available: Scalars['Boolean'];
  unavailabilityReason?: Maybe<CancelPurchaseUnavailabilityReason>;
};

export enum CancelPurchaseUnavailabilityReason {
  CONTRACT_NOT_PAUSED = 'CONTRACT_NOT_PAUSED',
  NO_PURCHASE_IN_PROGRESS = 'NO_PURCHASE_IN_PROGRESS',
  PAYMENT_NOT_BY_INVOICE = 'PAYMENT_NOT_BY_INVOICE',
  PURCHASE_ALREADY_COMPLETED = 'PURCHASE_ALREADY_COMPLETED',
}

export type CancelReturnTerm = {
  __typename?: 'CancelReturnTerm';
  available: Scalars['Boolean'];
  unavailabilityReason?: Maybe<CancelReturnUnavailabilityReason>;
};

export enum CancelReturnUnavailabilityReason {
  ASSETS_ALREADY_ALLOCATED = 'ASSETS_ALREADY_ALLOCATED',
  ASSETS_ALREADY_SHIPPED = 'ASSETS_ALREADY_SHIPPED',
  NEW_ASSETS_ALREADY_SHIPPED = 'NEW_ASSETS_ALREADY_SHIPPED',
  NON_RETURN_FLOW_IN_PROGRESS = 'NON_RETURN_FLOW_IN_PROGRESS',
  NO_RETURN_IN_PROGRESS = 'NO_RETURN_IN_PROGRESS',
}

export type CancelShipmentsResult = {
  __typename?: 'CancelShipmentsResult';
  uids: Array<Scalars['String']>;
};

export type CancelSubscription = {
  __typename?: 'CancelSubscription';
  available: Scalars['Boolean'];
};

/** Autogenerated input type of CancelSubscription */
export type CancelSubscriptionInput = {
  cancellationReason: SubscriptionCancellationReason;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of CancelSubscription. */
export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptionDetails: SubscriptionDetails;
};

/** Autogenerated input type of CancelSwap */
export type CancelSwapInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderNumber: Scalars['ID'];
};

/** Autogenerated return type of CancelSwap. */
export type CancelSwapPayload = {
  __typename?: 'CancelSwapPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<SwapCancellationError>;
  swap?: Maybe<Swap>;
};

export type Card = PaymentMethodInterface & {
  __typename?: 'Card';
  adyenContractNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  contracts: Array<BasicContract>;
  countryIso?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  firstDigits?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastDigits?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  merchantTransactionId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  threedSecure?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** Credit card payment method from Wallet service. Eventually will deprecate the Card type. */
export type CardPaymentMethod = PaymentMethod2 & {
  __typename?: 'CardPaymentMethod';
  bankName?: Maybe<Scalars['String']>;
  /** ID of the corresponding account in Billing Service. */
  billingAccountId?: Maybe<Scalars['String']>;
  /** Credit card's brand, e.g. 'mastercard', 'visa', etc. */
  brand?: Maybe<Scalars['String']>;
  /** Credit card's country code, e.g. 'DE', 'US', etc. */
  countryCode?: Maybe<Scalars['String']>;
  /** Date/time of the method creation. Empty in case of a legacy method. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Present if the method deletion is currently forbidden, contains details about the reason. Warning: currently does not return "has linked contracts", which is one of the possible reasons for deletion lock. Check the linkedContracts field instead. */
  deletionLock?: Maybe<PaymentMethodDeletionLock>;
  expiryMonth?: Maybe<Scalars['String']>;
  expiryYear?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  /** First six digits of the credit card's number. */
  firstSixDigits?: Maybe<Scalars['String']>;
  /** ID of the corresponding method in Grover API. */
  groverApiId?: Maybe<Scalars['Float']>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Method's external ID in IXOPAY. */
  ixopayReferenceId?: Maybe<Scalars['String']>;
  /** Last four digits of the credit card's number. */
  lastFourDigits?: Maybe<Scalars['String']>;
  /** Credit card's level, e.g. 'business', 'standard', 'gold', etc. */
  level?: Maybe<Scalars['String']>;
  /** Contracts and subscriptions that are using this method. */
  linkedContracts: Array<FlexDetails>;
  /** URL to redirect the user to if authorization is required by the payment provider. */
  redirectUrl?: Maybe<Scalars['String']>;
  /** Error code classifying the method registration failure, if any. */
  registrationErrorCode?: Maybe<TransactionErrorCode>;
  status: PaymentMethodStatus;
  /** Get status of this method in relation to the given order. */
  statusForOrder: PaymentMethodStatusForOrderResult;
  /** Credit card's type, e.g. 'credit', etc. */
  type?: Maybe<Scalars['String']>;
};

/** Credit card payment method from Wallet service. Eventually will deprecate the Card type. */
export type CardPaymentMethodstatusForOrderArgs = {
  orderNumber: Scalars['String'];
};

export type CardTypeUnionModel =
  | BasicMarketingCardModel
  | ConfirmationPollCardModel
  | PollCardModel
  | SubscriptionBadgeModel;

export enum CarrierName {
  CargoInternational = 'CargoInternational',
  DPD = 'DPD',
  Dhl = 'Dhl',
  DhlExpress = 'DhlExpress',
  Gel = 'Gel',
  Hermes2MH = 'Hermes2MH',
  Omni = 'Omni',
  Ups = 'Ups',
}

export enum CarrierServiceAdditionalNameType {
  AccessPointNotification = 'AccessPointNotification',
  AdultSignature = 'AdultSignature',
  AdvanceNotice = 'AdvanceNotice',
  DirectDeliveryOnly = 'DirectDeliveryOnly',
  IdentCheck = 'IdentCheck',
  NamedPersonOnly = 'NamedPersonOnly',
  PremiumInternational = 'PremiumInternational',
  SaturdayDelivery = 'SaturdayDelivery',
  SignatureRequired = 'SignatureRequired',
  VisualAgeCheck = 'VisualAgeCheck',
}

export type CarrierServiceGraphql = {
  __typename?: 'CarrierServiceGraphql';
  additionalServices?: Maybe<Array<CarrierServiceAdditionalNameType>>;
  name: CarrierServiceNameType;
};

export type CarrierServiceInputGraphql = {
  additionalServices?: InputMaybe<Array<CarrierServiceAdditionalNameType>>;
  name: CarrierServiceNameType;
};

export enum CarrierServiceNameType {
  EuroPacket = 'EuroPacket',
  Expedited = 'Expedited',
  Express = 'Express',
  ExpressNoon = 'ExpressNoon',
  Ground = 'Ground',
  OneDay = 'OneDay',
  OneDayEarly = 'OneDayEarly',
  Prio = 'Prio',
  Returns = 'Returns',
  Standard = 'Standard',
  Warenpost = 'Warenpost',
}

export type CartAddon = {
  __typename?: 'CartAddon';
  id: Scalars['ID'];
  selectedAddonVariantId?: Maybe<Scalars['String']>;
};

export type CartAddonV2 = {
  __typename?: 'CartAddonV2';
  product: Addon;
};

export type CartAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  countryIso: Scalars['String'];
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type CartAddressSetInput = {
  addressId: Scalars['String'];
  type: CartAddressType;
};

export enum CartAddressType {
  B2B_EMPLOYEE = 'B2B_EMPLOYEE',
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING',
  SHIPPING_BILLING = 'SHIPPING_BILLING',
}

export type CartItem = {
  id: Scalars['ID'];
  price: Price;
  quantity: Scalars['Int'];
};

export type CartOrder = {
  __typename?: 'CartOrder';
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  dashboardSubscriptions?: Maybe<SubscriptionCardConnection>;
  guestToken?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Address>;
  id: Scalars['ID'];
  lineItems: Array<FlexLineItem>;
  orderNumber: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  price: Price;
  pricingDetails?: Maybe<PricingDetails>;
  shippingAddress?: Maybe<Address>;
  step?: Maybe<Scalars['String']>;
  store: Store;
};

export type CartOrderdashboardSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for CartOrder. */
export type CartOrderConnection = {
  __typename?: 'CartOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CartOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CartOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CartOrderCreateInput = {
  adjustment?: InputMaybe<AdjustmentInput>;
  flowType?: InputMaybe<FlowType>;
  kitUuid?: InputMaybe<Scalars['String']>;
  lineItems: Array<InputMaybe<MixLineItemInput>>;
  orderMode?: InputMaybe<OrderMode>;
};

/** An edge in a connection. */
export type CartOrderEdge = {
  __typename?: 'CartOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CartOrder>;
};

export type CartOrderSubmitInput = {
  attemptReference?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<CartAddressInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  creditCheckAcceptedAt?: InputMaybe<Scalars['String']>;
  employeeUuid?: InputMaybe<Scalars['String']>;
  experianCollectorData?: InputMaybe<ExperianCollectorDataInput>;
  fraudCheckConsent?: InputMaybe<Scalars['Boolean']>;
  initiateFraudCheck?: InputMaybe<Scalars['Boolean']>;
  metaData?: InputMaybe<MetaDataInput>;
  orderMode?: InputMaybe<OrderMode>;
  paymentMethod?: InputMaybe<CartPaymentMethodInput>;
  profileReference?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<CartAddressInput>;
  shippingPreferences?: InputMaybe<ShippingPreferences>;
  store?: InputMaybe<CartStoreInput>;
  user?: InputMaybe<CartUserInput>;
};

export type CartOrderUpdateInput = {
  address?: InputMaybe<CartAddressInput>;
  orderMode?: InputMaybe<OrderMode>;
  paymentMethod?: InputMaybe<CartPaymentMethodInput>;
  step?: InputMaybe<Scalars['String']>;
  workServiceAddressUuid?: InputMaybe<Scalars['ID']>;
};

export type CartPaymentMethodInput = {
  billingAccountId: Scalars['String'];
  id: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
};

export type CartPaymentMethodSetInput = {
  billingAccountId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};

export enum CartRequirement {
  AUTH = 'AUTH',
  BILLING_ADDRESS = 'BILLING_ADDRESS',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PHONE = 'PHONE',
  REVIEW = 'REVIEW',
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
  SPAIN_ID = 'SPAIN_ID',
}

export type CartStoreInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['ID']>;
  storeType?: InputMaybe<Scalars['String']>;
};

export type CartUserInput = {
  birthdate?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
  userId: Scalars['Int'];
  userType?: InputMaybe<Scalars['String']>;
};

export type CartVoucher = {
  __typename?: 'CartVoucher';
  code: Scalars['String'];
  discount: Price;
  recurrent: Scalars['Boolean'];
};

export type CartWithKit = {
  __typename?: 'CartWithKit';
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  kit?: Maybe<Kit>;
  kitUuid: Scalars['String'];
  orderId: Scalars['String'];
  storeCode: Scalars['String'];
  uuid: Scalars['ID'];
};

export type CatalogAffectedRowsOutput = {
  __typename?: 'CatalogAffectedRowsOutput';
  count: Scalars['Int'];
};

export type CatalogGrouping = {
  __typename?: 'CatalogGrouping';
  id: Scalars['ID'];
  name: Scalars['String'];
  skuList: Array<Scalars['String']>;
};

export type CatalogGroupingUpsertError = GenericError & {
  __typename?: 'CatalogGroupingUpsertError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type CatalogGroupingUpsertInput = {
  name: Scalars['String'];
  skuList: Array<Scalars['String']>;
};

export type CatalogGroupingUpsertSuccess = {
  __typename?: 'CatalogGroupingUpsertSuccess';
  success: Scalars['Boolean'];
};

export type CatalogGroupingUpsertSuccessOrError =
  | CatalogGroupingUpsertError
  | CatalogGroupingUpsertSuccess;

export type CatalogItem = {
  categoryId: Scalars['ID'];
  product: Product;
  productSku: Scalars['ID'];
  serialNumber?: Maybe<Scalars['ID']>;
  subCategoryId: Scalars['ID'];
};

export type CatalogItemVariant = {
  assetId?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  product: Product;
  productSku: Scalars['ID'];
  serialNumber?: Maybe<Scalars['ID']>;
  subCategoryId: Scalars['ID'];
  variant: Variant;
  variantSku: Scalars['ID'];
};

export type Category = {
  __typename?: 'Category';
  categoryStores: Array<CategoryStore>;
  children?: Maybe<Array<Category>>;
  /** This is a the preferred field for the cover image. The one from images.coverImageUrl will return the generic one, the ones on the store have this information too nested. */
  coverImageUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** @deprecated Use Category > CategoryStore > defaultRentalPeriod instead. */
  defaultRentalPlanDuration?: Maybe<Scalars['Int']>;
  groverCareDeductibleFees: Array<GroverCareDeductibleFee>;
  /** @deprecated Use images.iconSvgPath instead. */
  iconSvgPath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Use Category.images instead. */
  imageAssets?: Maybe<Scalars['JSON']>;
  images: CategoryImages;
  name: Scalars['String'];
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['ID']>;
  permalink?: Maybe<Scalars['String']>;
  productFilters?: Maybe<Array<ProductFilter>>;
  /** @deprecated Use Category.translatedTitle instead. */
  title?: Maybe<Scalars['String']>;
  titles: Scalars['JSON'];
  translatedTitle?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CategoryCreateInput = {
  categoryStores: Array<CategoryStoreInput>;
  imageAssets?: InputMaybe<ImageAssets>;
  images?: InputMaybe<CategoryImagesInput>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
  permalink: Scalars['String'];
  title: Languages;
};

export type CategoryError = GenericError & {
  __typename?: 'CategoryError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type CategoryImages = {
  __typename?: 'CategoryImages';
  coverImageUrl?: Maybe<Scalars['String']>;
  iconSvgPath?: Maybe<Scalars['String']>;
};

export type CategoryImagesInput = {
  coverImageUrl?: InputMaybe<Scalars['String']>;
  iconSvgPath?: InputMaybe<Scalars['String']>;
};

export type CategoryMenu = {
  __typename?: 'CategoryMenu';
  categories: Array<HeaderCategory>;
  localeOptions: Array<HeaderOption>;
  mobileLinks: Array<HeaderLink>;
  mobileOptionLinks: Array<HeaderLink>;
  storeOptions: Array<HeaderOption>;
};

export type CategoryOrError = Category | CategoryError;

export type CategoryPositionUpdateInput = {
  categoryId: Scalars['ID'];
  position: Scalars['Int'];
  storeCode: Scalars['String'];
};

export type CategoryStore = {
  __typename?: 'CategoryStore';
  active: Scalars['Boolean'];
  categoryId: Scalars['ID'];
  contentfulId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultRentalPeriod?: Maybe<Scalars['Int']>;
  /** @deprecated Use images instead. */
  imageAssets?: Maybe<Scalars['JSON']>;
  images: CategoryStoreImages;
  position: Scalars['Int'];
  shippingPrice?: Maybe<Scalars['Int']>;
  storeCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CategoryStoreImages = {
  __typename?: 'CategoryStoreImages';
  coverImageUrl?: Maybe<Scalars['String']>;
};

export type CategoryStoreImagesInput = {
  coverImageUrl?: InputMaybe<Scalars['String']>;
};

export type CategoryStoreInput = {
  active: Scalars['Boolean'];
  contentfulId?: InputMaybe<Scalars['String']>;
  defaultRentalPeriod?: InputMaybe<Scalars['Int']>;
  imageAssets?: InputMaybe<CoverImageAsset>;
  images?: InputMaybe<CategoryStoreImagesInput>;
  position: Scalars['Int'];
  shippingPrice?: InputMaybe<Scalars['Int']>;
  storeCode: Scalars['String'];
};

export type CategoryUpdateInput = {
  categoryStores?: InputMaybe<Array<CategoryStoreInput>>;
  imageAssets?: InputMaybe<ImageAssets>;
  images?: InputMaybe<CategoryImagesInput>;
  parentId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Languages>;
};

export type ChangePaymentMethod = {
  __typename?: 'ChangePaymentMethod';
  /** @deprecated Use subscription payment method instead */
  email?: Maybe<Scalars['String']>;
  /** @deprecated Use subscription payment method instead */
  ibanFirstDigits?: Maybe<Scalars['String']>;
  /** @deprecated Use subscription payment method instead */
  ibanLastDigits?: Maybe<Scalars['String']>;
  /** @deprecated Use subscription payment method instead */
  last4Digits?: Maybe<Scalars['String']>;
  /** @deprecated Use subscription payment method instead */
  type?: Maybe<Scalars['String']>;
};

export type CheckEmployeeApprovalInput = {
  orderNumber: Scalars['String'];
  userId: Scalars['Int'];
};

export type CheckProviderReport = {
  reportUrl: Scalars['String'];
  result?: Maybe<ProviderCheckResult>;
};

export type CheckoutConfig = {
  __typename?: 'CheckoutConfig';
  canEditPlan: Scalars['Boolean'];
  canEditQuantity: Scalars['Boolean'];
  canEditVariant: Scalars['Boolean'];
  canEditVoucher: Scalars['Boolean'];
  canRemoveItem: Scalars['Boolean'];
  requiresTermsOptIn: Scalars['Boolean'];
  showVoucher: Scalars['Boolean'];
};

/** Autogenerated input type of ClearExpiredFlow */
export type ClearExpiredFlowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of ClearExpiredFlow. */
export type ClearExpiredFlowPayload = {
  __typename?: 'ClearExpiredFlowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
};

export enum ColorFunctions {
  CAUTION = 'CAUTION',
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type ColumnConflict = {
  __typename?: 'ColumnConflict';
  columnName: Scalars['String'];
  errorMessage: Scalars['String'];
};

export type ColumnOrderType = {
  column: Scalars['String'];
  order: ShippingOrderEnum;
};

export type ColumnSortType = {
  column: Scalars['String'];
  direction: OrderFulfillmentSortDirection;
};

export type CompaniesWhereInput = {
  and?: InputMaybe<Array<CompaniesWhereInput>>;
  country?: InputMaybe<B2BStringComparisonInput>;
  hrv?: InputMaybe<B2BStringComparisonInput>;
  id?: InputMaybe<B2BIntegerComparisonInput>;
  legalRepresentativeEmail?: InputMaybe<B2BStringComparisonInput>;
  name?: InputMaybe<B2BStringComparisonInput>;
  size?: InputMaybe<CompanySizeComparisonInput>;
  status?: InputMaybe<B2BStringComparisonInput>;
  ust?: InputMaybe<B2BStringComparisonInput>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<CompanyAddress>;
  /** @deprecated Please dont use directly, only needed for federation */
  addressId?: Maybe<Scalars['Int']>;
  authorizedByLegalRepresentative?: Maybe<Scalars['Boolean']>;
  billingEmail?: Maybe<Scalars['String']>;
  businessDashboard?: Maybe<BusinessDashboard>;
  canPayByInvoice: Scalars['Boolean'];
  /** @deprecated Please use the type object instead. type { id } */
  companyTypeId: Scalars['Int'];
  forceApprovedUser?: Maybe<User>;
  /** @deprecated Please dont use directly, only needed for federation */
  forceApprovedUserId?: Maybe<Scalars['Int']>;
  /** @deprecated Use businessDashboard.enabled field instead */
  groverWorkEnabled?: Maybe<Scalars['Boolean']>;
  hrv?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legalRepresentativeEmail?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size?: Maybe<CompanySize>;
  status: Scalars['String'];
  type: CompanyType;
  user: User;
  ust?: Maybe<Scalars['String']>;
  verificationCheck?: Maybe<CompanyVerificationCheck>;
  viban?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  additionalInfo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  countryIso: Scalars['String'];
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  stateAbbr?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<CompanyEdge>;
  nodes: Array<Company>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  node: Company;
};

export enum CompanySize {
  /** 1-10 */
  _1 = '_1',
  /** 11-50 */
  _11 = '_11',
  /** 51-200 */
  _51 = '_51',
  /** 201-500 */
  _201 = '_201',
  /** 501-1000 */
  _501 = '_501',
  /** 1000+ */
  _1001 = '_1001',
}

export type CompanySizeComparisonInput = {
  _eq?: InputMaybe<CompanySize>;
  _neq?: InputMaybe<CompanySize>;
};

export enum CompanyStatus {
  approved = 'approved',
  incomplete = 'incomplete',
  pending = 'pending',
  pending_documents = 'pending_documents',
  pending_documents_verification = 'pending_documents_verification',
  rejected = 'rejected',
}

export type CompanyType = {
  __typename?: 'CompanyType';
  id: Scalars['ID'];
  individual: Scalars['Boolean'];
  name: Scalars['String'];
  ustIdRequired: Scalars['Boolean'];
};

export type CompanyVerificationCheck = CheckProviderReport & {
  __typename?: 'CompanyVerificationCheck';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  reportUrl: Scalars['String'];
  result?: Maybe<ProviderCheckResult>;
  verificationType?: Maybe<CompanyVerificationType>;
};

export enum CompanyVerificationType {
  BANK_ACCOUNT_SNAPSHOT = 'BANK_ACCOUNT_SNAPSHOT',
  ID_VERIFICATION = 'ID_VERIFICATION',
}

export enum ComparisonType {
  contains = 'contains',
  endsWith = 'endsWith',
  equals = 'equals',
  gt = 'gt',
  gte = 'gte',
  in = 'in',
  lt = 'lt',
  lte = 'lte',
  not = 'not',
  notContains = 'notContains',
  notEndsWith = 'notEndsWith',
  notIn = 'notIn',
  notStartsWith = 'notStartsWith',
  startsWith = 'startsWith',
}

/** Autogenerated input type of CompleteContract */
export type CompleteContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of CompleteContract. */
export type CompleteContractPayload = {
  __typename?: 'CompleteContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
};

export type Completion = Operation & {
  __typename?: 'Completion';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: InboundShipmentStates;
  updates: Array<FlowUpdate>;
};

export type ConditionInputType = {
  groverApi?: InputMaybe<GroverApiInputType>;
};

export type ConditionsContentModel = {
  __typename?: 'ConditionsContentModel';
  contractService?: Maybe<ContractServiceConditionModel>;
  groverApi?: Maybe<GroverApiConditionModel>;
};

export type ConfirmAutomaticChargeInput = {
  movementID: Scalars['ID'];
  paymentMethodType: Scalars['String'];
  paymentReference: Scalars['String'];
  paymentSource: Scalars['String'];
  providerResponse: Scalars['JSON'];
  status: Scalars['String'];
};

export type ConfirmAutomaticChargePayload = {
  __typename?: 'ConfirmAutomaticChargePayload';
  movement?: Maybe<Movement>;
};

export type ConfirmRefundInput = {
  movementID: Scalars['ID'];
  paymentMethodType: Scalars['String'];
  paymentReference: Scalars['String'];
  paymentSource: Scalars['String'];
  providerResponse: Scalars['JSON'];
  status: Scalars['String'];
};

export type ConfirmRefundPayload = {
  __typename?: 'ConfirmRefundPayload';
  movement?: Maybe<Movement>;
};

export type ConfirmableReturnStep = BasicReturnStep & {
  __typename?: 'ConfirmableReturnStep';
  checkbox?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  headline: Scalars['String'];
  name: Scalars['String'];
  warning?: Maybe<Scalars['String']>;
};

export type ConfirmationPollCardConfirmationStepModel = {
  __typename?: 'ConfirmationPollCardConfirmationStepModel';
  body: Scalars['String'];
  buttonLabel: Scalars['String'];
  title: Scalars['String'];
};

export type ConfirmationPollCardContentModel = {
  __typename?: 'ConfirmationPollCardContentModel';
  actions?: Maybe<Array<ActionUnionModel>>;
  copy: ConfirmationPollCardCopyModel;
  hasCircleDismissButton?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  pollSlug: Scalars['String'];
  uuid: Scalars['ID'];
};

export type ConfirmationPollCardCopyModel = {
  __typename?: 'ConfirmationPollCardCopyModel';
  confirmationStep: ConfirmationPollCardConfirmationStepModel;
  outcome: ConfirmationPollCardItemModel;
};

export type ConfirmationPollCardItemModel = {
  __typename?: 'ConfirmationPollCardItemModel';
  body: Scalars['String'];
  title: Scalars['String'];
};

export type ConfirmationPollCardModel = {
  __typename?: 'ConfirmationPollCardModel';
  actions?: Maybe<Array<ActionUnionModel>>;
  copy: ConfirmationPollCardCopyModel;
  hasCircleDismissButton?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  pollSlug: Scalars['String'];
  uuid: Scalars['ID'];
};

export type ConnectionDetails = {
  __typename?: 'ConnectionDetails';
  createdAt: Scalars['DateTime'];
  defaultStoreCode?: Maybe<Scalars['String']>;
  guestId: Scalars['String'];
  guestRewardValue?: Maybe<Scalars['String']>;
  hostId: Scalars['String'];
  hostRewardValue?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ConnectionModel = {
  __typename?: 'ConnectionModel';
  accountCreatedAt: Scalars['DateTime'];
  assetReceivedDate?: Maybe<Scalars['DateTime']>;
  campaign: ReferralCampaign;
  contractActivationDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guestId: Scalars['String'];
  hostEventDispatchedAt?: Maybe<Scalars['DateTime']>;
  hostId: Scalars['String'];
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  paymentDate?: Maybe<Scalars['DateTime']>;
  report: Scalars['String'];
  status: ConnectionStatus;
  step: ReferralConnectionStep;
  updatedAt: Scalars['DateTime'];
};

/** ConnectionStatus: active, revoked, deleted */
export enum ConnectionStatus {
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
}

export type ConsentAttributeInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  value: UserConsentValue;
};

export type ContentUnionModel =
  | BasicMarketingCardContentModel
  | ConfirmationPollCardContentModel
  | HintContentModel
  | PollCardContentModel
  | SubscriptionBadgeContentModel;

export type Contract = Flex | Mix;

export type ContractAction =
  | Buyout
  | CancelOrderEarly
  | PrintReturnLabel
  | ReportDamage
  | SupportRequest;

export enum ContractCancellationReason {
  ASSET_LOST_DURING_TRANSIT = 'ASSET_LOST_DURING_TRANSIT',
  ASSET_NOT_RECEIVED_BY_CUSTOMER = 'ASSET_NOT_RECEIVED_BY_CUSTOMER',
  B2B_UPSELL = 'B2B_UPSELL',
  BILLING_ISSUES = 'BILLING_ISSUES',
  CANCELLATION_BEFORE_ALLOCATION = 'CANCELLATION_BEFORE_ALLOCATION',
  CANCELLED_ACCOUNT_BANKRUPTCY = 'CANCELLED_ACCOUNT_BANKRUPTCY',
  EARLY_ORDER_CANCELLATION = 'EARLY_ORDER_CANCELLATION',
  END_OF_CYCLE_RETURN = 'END_OF_CYCLE_RETURN',
  FAILED_DELIVERY = 'FAILED_DELIVERY',
  FRAUD = 'FRAUD',
  HANDED_OVER_TO_DCA = 'HANDED_OVER_TO_DCA',
  PURCHASED = 'PURCHASED',
  UNABLE_TO_REPAIR = 'UNABLE_TO_REPAIR',
  WAREHOUSE_CANCELLATION = 'WAREHOUSE_CANCELLATION',
}

/** The connection type for Contract. */
export type ContractConnection = {
  __typename?: 'ContractConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContractEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Contract>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ContractDiscounts = InternalDiscount;

/** An edge in a connection. */
export type ContractEdge = {
  __typename?: 'ContractEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Contract>;
};

export type ContractExtensionTerms = {
  __typename?: 'ContractExtensionTerms';
  available: Scalars['Boolean'];
  savingsAmount: Price;
  unavailabilityReason?: Maybe<ExtensionUnavailabilityReason>;
};

export type ContractFilterInput = {
  countryIso?: InputMaybe<CountryIso>;
  flow?: InputMaybe<Array<Flow>>;
  flowState?: InputMaybe<Array<FlowState>>;
  id?: InputMaybe<Array<Scalars['ID']>>;
  maxBillingPrice?: InputMaybe<Scalars['Int']>;
  minBillingPrice?: InputMaybe<Scalars['Int']>;
  minDaysInCurrentProductVariantState?: InputMaybe<Scalars['Int']>;
  minDaysInCurrentState?: InputMaybe<Scalars['Int']>;
  minDaysPausedInTransit?: InputMaybe<Scalars['Int']>;
  orderNumber?: InputMaybe<Scalars['ID']>;
  productVariantState?: InputMaybe<Array<ContractGoodState>>;
  serialNumber?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Array<ContractState>>;
  storeCode?: InputMaybe<StoreCode>;
  terminationReason?: InputMaybe<ContractTerminationReason>;
  type?: InputMaybe<ContractType>;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum ContractFlow {
  CANCELLATION = 'CANCELLATION',
  COMPLETION = 'COMPLETION',
  CUSTOMER_WISH_CANCEL = 'CUSTOMER_WISH_CANCEL',
  CUSTOMER_WISH_KEEP_CHARGING = 'CUSTOMER_WISH_KEEP_CHARGING',
  DAMAGE_CLAIM = 'DAMAGE_CLAIM',
  INTERNAL_DEBT_COLLECTION = 'INTERNAL_DEBT_COLLECTION',
  MANUAL_PAUSE = 'MANUAL_PAUSE',
  PURCHASE = 'PURCHASE',
  REPLACEMENT = 'REPLACEMENT',
  REVOCATION = 'REVOCATION',
  SWAPPING = 'SWAPPING',
  UPSELL = 'UPSELL',
}

export enum ContractGoodState {
  AWAITING_RETURN = 'AWAITING_RETURN',
  NEVER_SENT = 'NEVER_SENT',
  PROCESSING_RETURN = 'PROCESSING_RETURN',
  RESERVED = 'RESERVED',
  RETURN_REJECTED = 'RETURN_REJECTED',
  RETURN_SUCCEEDED = 'RETURN_SUCCEEDED',
  SHIPPING = 'SHIPPING',
  SHIPPING_FAILED = 'SHIPPING_FAILED',
  SHIPPING_RETURN = 'SHIPPING_RETURN',
  SHIPPING_RETURN_FAILED = 'SHIPPING_RETURN_FAILED',
  SOLD = 'SOLD',
  STOLEN = 'STOLEN',
  WITH_CUSTOMER = 'WITH_CUSTOMER',
}

export type ContractGoods = {
  contractId: Scalars['ID'];
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: ContractGoodState;
};

/** Contract Payment Group Filter */
export type ContractPaymentGroupFilterInput = {
  externalContract?: InputMaybe<Scalars['String']>;
  externalCustomer?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentGroupStatusTypes>;
};

export type ContractPaymentGroupModel = {
  __typename?: 'ContractPaymentGroupModel';
  amount: InCentsModel;
  billingDate: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  externalContract: Scalars['String'];
  externalCustomer: Scalars['String'];
  period: Scalars['Float'];
  status: PaymentGroupStatusTypes;
  uuid: Scalars['ID'];
};

export type ContractServiceConditionModel = {
  __typename?: 'ContractServiceConditionModel';
  numberOfActiveMixContracts?: Maybe<Scalars['AnyScalar']>;
};

export type ContractServiceRecurrentModel = {
  __typename?: 'ContractServiceRecurrentModel';
  currentPeriod: Scalars['Float'];
  dueDate: Scalars['DateTime'];
  externalContract: Scalars['String'];
  externalCustomer: Scalars['String'];
  paymentGroups: Array<PaymentGroupModel>;
  recurrentPaymentUuid: Scalars['String'];
  scopeUuid?: Maybe<Scalars['String']>;
  status: RecurrentPaymentStatusTypes;
  type: Scalars['String'];
  uuid: Scalars['ID'];
};

export enum ContractState {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  FULFILLING = 'FULFILLING',
  PAUSED = 'PAUSED',
  REFUSED = 'REFUSED',
}

export enum ContractTerminationReason {
  ASSET_LOST_DURING_TRANSIT = 'ASSET_LOST_DURING_TRANSIT',
  ASSET_NOT_RECEIVED_BY_CUSTOMER = 'ASSET_NOT_RECEIVED_BY_CUSTOMER',
  B2B_UPSELL = 'B2B_UPSELL',
  BILLING_ISSUES = 'BILLING_ISSUES',
  CANCELLATION_BEFORE_ALLOCATION = 'CANCELLATION_BEFORE_ALLOCATION',
  CANCELLED_ACCOUNT_BANKRUPTCY = 'CANCELLED_ACCOUNT_BANKRUPTCY',
  COMPLETED_BY_CUSTOMER = 'COMPLETED_BY_CUSTOMER',
  CUSTOMER_WISH = 'CUSTOMER_WISH',
  DAMAGE_CLAIM = 'DAMAGE_CLAIM',
  EARLY_ORDER_CANCELLATION = 'EARLY_ORDER_CANCELLATION',
  END_OF_CYCLE_RETURN = 'END_OF_CYCLE_RETURN',
  FAILED_DELIVERY = 'FAILED_DELIVERY',
  FRAUD = 'FRAUD',
  HANDED_OVER_TO_DCA = 'HANDED_OVER_TO_DCA',
  INTERNAL_DEBT_COLLECTION = 'INTERNAL_DEBT_COLLECTION',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PURCHASED = 'PURCHASED',
  REVOCATION = 'REVOCATION',
  RISK_DECLINED = 'RISK_DECLINED',
  UNABLE_TO_REPAIR = 'UNABLE_TO_REPAIR',
  UPSELL = 'UPSELL',
  WAREHOUSE_CANCELLATION = 'WAREHOUSE_CANCELLATION',
}

export enum ContractType {
  FLEX = 'FLEX',
  MIX = 'MIX',
}

export type ContractValidationResult = ValidationResult & {
  __typename?: 'ContractValidationResult';
  valid: Scalars['Boolean'];
};

export type CopyToClipboardActionModel = {
  __typename?: 'CopyToClipboardActionModel';
  text: Scalars['String'];
  value: Scalars['String'];
};

export type CountdownTimer = {
  __typename?: 'CountdownTimer';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  productSkus?: Maybe<Array<Scalars['String']>>;
  /** @deprecated use productSkus instead */
  products?: Maybe<Array<Product>>;
  startAt: Scalars['DateTime'];
  storeCodes: Array<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CountdownTimerCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  endAt: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  products?: InputMaybe<Array<AssociateProductInput>>;
  startAt: Scalars['DateTime'];
  storeCodes: Array<Scalars['String']>;
};

export type CountdownTimerOrderByInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type CountdownTimerPaginationOutput = {
  __typename?: 'CountdownTimerPaginationOutput';
  rows: Array<CountdownTimer>;
  totalCount: Scalars['Int'];
};

export type CountdownTimerUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  storeCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type CountdownTimerWhereInput = {
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  stores?: InputMaybe<Array<Scalars['String']>>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  iso: Scalars['String'];
  iso3: Scalars['String'];
  isoName: Scalars['String'];
  name: Scalars['String'];
  numcode: Scalars['Int'];
  statesRequired: Scalars['Boolean'];
};

export enum CountryIso {
  AT = 'AT',
  DE = 'DE',
  ES = 'ES',
  NL = 'NL',
  US = 'US',
}

export type CoverImageAsset = {
  coverImageUrl?: InputMaybe<Scalars['String']>;
};

export type CreateAddonAssociationInput = {
  addonsSku?: InputMaybe<Array<Scalars['String']>>;
  mainProductSku: Scalars['String'];
  storeCode: Scalars['String'];
};

/** Autogenerated input type of CreateAddress */
export type CreateAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  billingAddress: Scalars['Boolean'];
  city: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryIso: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  shippingAddress: Scalars['Boolean'];
  stateAbbr?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  zip: Scalars['String'];
};

export type CreateAddressInputV2 = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  countryIso: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['String']>;
  stateAbbr?: InputMaybe<Scalars['String']>;
  twoFACode?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

/** Autogenerated return type of CreateAddress. */
export type CreateAddressPayload = {
  __typename?: 'CreateAddressPayload';
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCheckoutAttempt = {
  __typename?: 'CreateCheckoutAttempt';
  reference?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCompanyAddress */
export type CreateCompanyAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['ID'];
  countryIso: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  stateAbbr?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

/** Autogenerated return type of CreateCompanyAddress. */
export type CreateCompanyAddressPayload = {
  __typename?: 'CreateCompanyAddressPayload';
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateConnectionResponse = {
  __typename?: 'CreateConnectionResponse';
  message: ReferralCreateConnectionResponse;
  response: Scalars['Boolean'];
};

export type CreateEmployeeInput = {
  address?: InputMaybe<WorkCreateAddressInput>;
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateKitInput = {
  companyId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  storeCode: Scalars['String'];
};

export type CreateKitProductInput = {
  kitUuid: Scalars['String'];
  productSku: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CreateKitWithProductsInput = {
  description?: InputMaybe<Scalars['String']>;
  kitProducts: Array<KitProductInput>;
  name: Scalars['String'];
  storeCode: Scalars['String'];
};

export type CreateLegacyCompanyInput = {
  billing_email?: InputMaybe<Scalars['String']>;
  company_type_id: Scalars['Float'];
  lead_id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  size: CompanySize;
  /** Needed to validate UST field. */
  store: Scalars['String'];
  /** Deprecated, this property will be deleted */
  user_id?: InputMaybe<Scalars['Float']>;
  ust?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CreateManualBookingInput = {
  amount: Scalars['Float'];
  effectiveDate: Scalars['ISO8601Date'];
  paymentMethodType: Scalars['String'];
  paymentReference: Scalars['String'];
  paymentSource: Scalars['String'];
  reason: Scalars['String'];
  resourceIds: Array<Scalars['String']>;
};

export type CreateManualBookingPayload = {
  __typename?: 'CreateManualBookingPayload';
  Movement?: Maybe<Movement>;
  amountToBeRefunded?: Maybe<Scalars['Float']>;
  unAllocatedResources?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOrderFulfillmentCommentInput = {
  content: Scalars['String'];
  orderFulfillmentUid: Scalars['String'];
};

/** Autogenerated input type of CreatePaymentMethod */
export type CreatePaymentMethodInput = {
  cancelUrl?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  confirmUrl?: InputMaybe<Scalars['String']>;
  paymentGatewayId: Scalars['Int'];
  token?: InputMaybe<Scalars['String']>;
  transactionKeyName?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePaymentMethod. */
export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
};

export type CreateResourceTypeInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  slugPath: Array<InputMaybe<Scalars['String']>>;
};

export type CreateResourceTypePayload = {
  __typename?: 'CreateResourceTypePayload';
  resourceType?: Maybe<ResourceType>;
};

export type CreateTermsAndConditionsInput = {
  autoAccept: Scalars['Boolean'];
  daysToDecide: Scalars['Int'];
  disabledFeatures: Array<InputMaybe<Feature>>;
  locales: Array<InputMaybe<LocaleContentInput>>;
  storeCodes: Array<Scalars['String']>;
};

/** Autogenerated input type of CreateUserConsents */
export type CreateUserConsentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  consentAttributes: Array<ConsentAttributeInput>;
};

/** Autogenerated return type of CreateUserConsents. */
export type CreateUserConsentsPayload = {
  __typename?: 'CreateUserConsentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userConsents: Array<UserConsent>;
};

/** Autogenerated input type of CreateWaitingListTicket */
export type CreateWaitingListTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** Autogenerated return type of CreateWaitingListTicket. */
export type CreateWaitingListTicketPayload = {
  __typename?: 'CreateWaitingListTicketPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  waitingListTicket: WaitingListTicket;
};

export type CreditNote = {
  __typename?: 'CreditNote';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  numberSequence: Scalars['String'];
  url: Scalars['String'];
};

export type CursorFilterType = {
  createdAt: Scalars['String'];
  uid: Scalars['String'];
};

export enum CustomerType {
  Business = 'Business',
  Normal = 'Normal',
}

/** Autogenerated input type of CustomerUpdateCompany */
export type CustomerUpdateCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['ID'];
  ust: Scalars['String'];
};

/** Autogenerated return type of CustomerUpdateCompany. */
export type CustomerUpdateCompanyPayload = {
  __typename?: 'CustomerUpdateCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ust: Scalars['String'];
};

export type CustomerWishCancel = Operation & {
  __typename?: 'CustomerWishCancel';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: InboundShipmentStates;
  updates: Array<FlowUpdate>;
};

export type CustomerWishCancelTerm = {
  __typename?: 'CustomerWishCancelTerm';
  available: Scalars['Boolean'];
  availableFrom?: Maybe<Scalars['ISO8601DateTime']>;
  unavailabilityReason?: Maybe<ReturnUnavailabilityReason>;
};

export type CustomerWishKeepCharging = Operation & {
  __typename?: 'CustomerWishKeepCharging';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: InboundShipmentStates;
  updates: Array<FlowUpdate>;
};

export type CustomerWishKeepChargingTerm = {
  __typename?: 'CustomerWishKeepChargingTerm';
  available: Scalars['Boolean'];
  availableFrom?: Maybe<Scalars['ISO8601DateTime']>;
  unavailabilityReason?: Maybe<ReturnUnavailabilityReason>;
};

export type DamageClaim = Operation & {
  __typename?: 'DamageClaim';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: InboundShipmentStates;
  updates: Array<FlowUpdate>;
};

export type DamageClaimTerm = {
  __typename?: 'DamageClaimTerm';
  available: Scalars['Boolean'];
  unavailabilityReason?: Maybe<DamageClaimUnavailabilityReason>;
};

export enum DamageClaimUnavailabilityReason {
  ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
  ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
  CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
}

export type DashboardAccessRights = {
  __typename?: 'DashboardAccessRights';
  activatedAt: Scalars['DateTime'];
  /** Can be null as access can be activated in reaction to system event. User from GroverAPI through 'userId' */
  activatedBy?: Maybe<User>;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  /** User from GroverAPI through 'userId' */
  deactivatedBy?: Maybe<User>;
  /** Status of access to the service */
  status: B2BDashboardAccessRightStateGql;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type DashboardMixDevice = {
  __typename?: 'DashboardMixDevice';
  assetId?: Maybe<Scalars['String']>;
  category: Category;
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  product: Product;
  returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
  serialNumber?: Maybe<Scalars['String']>;
  state: DashboardMixDeviceState;
  subCategory: Category;
  variant: Variant;
};

/** The connection type for DashboardMixDevice. */
export type DashboardMixDeviceConnection = {
  __typename?: 'DashboardMixDeviceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DashboardMixDeviceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DashboardMixDevice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DashboardMixDeviceEdge = {
  __typename?: 'DashboardMixDeviceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DashboardMixDevice>;
};

export enum DashboardMixDeviceState {
  AWAITING_RETURN = 'AWAITING_RETURN',
  NEVER_SENT = 'NEVER_SENT',
  PROCESSING_RETURN = 'PROCESSING_RETURN',
  RESERVED = 'RESERVED',
  RETURN_REJECTED = 'RETURN_REJECTED',
  RETURN_SUCCEEDED = 'RETURN_SUCCEEDED',
  SHIPPING = 'SHIPPING',
  SHIPPING_FAILED = 'SHIPPING_FAILED',
  SHIPPING_RETURN = 'SHIPPING_RETURN',
  SHIPPING_RETURN_FAILED = 'SHIPPING_RETURN_FAILED',
  WITH_CUSTOMER = 'WITH_CUSTOMER',
}

export type DashboardMixSubscription = {
  __typename?: 'DashboardMixSubscription';
  activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentSwap?: Maybe<DashboardMixSwap>;
  id: Scalars['ID'];
  mixDevice?: Maybe<DashboardMixDevice>;
  mixDevices: DashboardMixDeviceConnection;
  orderNumber: Scalars['ID'];
  payments: PaymentsUnionConnection;
  returnLabelUrl?: Maybe<Scalars['String']>;
  shipmentTrackingUrl?: Maybe<Scalars['String']>;
  state: DashboardMixSubscriptionState;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  user: User;
};

export type DashboardMixSubscriptionmixDeviceArgs = {
  id: Scalars['ID'];
};

export type DashboardMixSubscriptionmixDevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  states?: InputMaybe<Array<Scalars['String']>>;
};

export type DashboardMixSubscriptionpaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export enum DashboardMixSubscriptionState {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  FULFILLING = 'FULFILLING',
  PAUSED = 'PAUSED',
  REFUSED = 'REFUSED',
}

export type DashboardMixSwap = {
  __typename?: 'DashboardMixSwap';
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  expiresAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  mixSubscription: DashboardMixSubscription;
  newDevice: DashboardMixDevice;
  oldDevice: DashboardMixDevice;
  orderNumber: Scalars['ID'];
  returnLabelUrl?: Maybe<Scalars['String']>;
  shipmentTrackingUrl?: Maybe<Scalars['String']>;
  shippingProgress?: Maybe<DashboardMixSwapShippingProgress>;
  state: DashboardMixSwapState;
};

export enum DashboardMixSwapShippingProgress {
  AWAITING_RETURN = 'AWAITING_RETURN',
  CHECKING_RETURN = 'CHECKING_RETURN',
  NEW_DEVICE_AWAITING_PICKUP = 'NEW_DEVICE_AWAITING_PICKUP',
  NEW_DEVICE_DELIVERED = 'NEW_DEVICE_DELIVERED',
  NEW_DEVICE_IN_TRANSIT = 'NEW_DEVICE_IN_TRANSIT',
  OLD_DEVICE_IN_TRANSIT = 'OLD_DEVICE_IN_TRANSIT',
  PROBLEM_WITH_RETURN = 'PROBLEM_WITH_RETURN',
}

export enum DashboardMixSwapState {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  ONGOING = 'ONGOING',
}

export type Date = {
  __typename?: 'Date';
  iso8601: Scalars['ISO8601DateTime'];
};

export type DateComparatorInput = {
  _gte?: InputMaybe<Scalars['DateTimeISO']>;
  _lte?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DateComparisonInput = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateUnion = Date | TimeEstimate;

export enum DebtCollectionAgency {
  ALEKTUM = 'ALEKTUM',
  DEBTCO = 'DEBTCO',
  JJ_MARSHALL = 'JJ_MARSHALL',
  KSP = 'KSP',
  PAIR_FINANCE = 'PAIR_FINANCE',
  PROCOBRO = 'PROCOBRO',
  SUMMIR_AR = 'SUMMIR_AR',
  TRUE_ACCORD = 'TRUE_ACCORD',
}

/** More fields for this type will be defined in following PRs */
export type DeclinedEOF = IExternalOrderFulfillment & {
  __typename?: 'DeclinedEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

export type DeductibleFee = {
  __typename?: 'DeductibleFee';
  damageType: Scalars['String'];
  fee: Price;
};

export type DefaultShippingAddress = {
  __typename?: 'DefaultShippingAddress';
  additionalInfo?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTimeISO'];
  zipCode: Scalars['String'];
};

export type DefaultShippingAddressFilterType = {
  orderNumber: Scalars['String'];
};

export type DefaultShippingAddressInputType = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export enum DefaultStoreCode {
  AT = 'AT',
  BUSINESS_AT = 'BUSINESS_AT',
  BUSINESS_DE = 'BUSINESS_DE',
  BUSINESS_ES = 'BUSINESS_ES',
  BUSINESS_NL = 'BUSINESS_NL',
  BUSINESS_US = 'BUSINESS_US',
  DE = 'DE',
  ES = 'ES',
  NL = 'NL',
  US = 'US',
}

/** Autogenerated input type of DeleteAddresses */
export type DeleteAddressesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteAddresses. */
export type DeleteAddressesPayload = {
  __typename?: 'DeleteAddressesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteAuthDevice */
export type DeleteAuthDeviceInput = {
  clientDeviceId: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteAuthDevice. */
export type DeleteAuthDevicePayload = {
  __typename?: 'DeleteAuthDevicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLocaleInput = {
  locale: Scalars['String'];
  version: Scalars['ID'];
};

export type DeleteModel = {
  __typename?: 'DeleteModel';
  /** Number of rows affected */
  affected: Scalars['Float'];
};

/** Autogenerated input type of DeleteOnfido */
export type DeleteOnfidoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of DeleteOnfido. */
export type DeleteOnfidoPayload = {
  __typename?: 'DeleteOnfidoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeleteOrderFulfillmentCommentInput = {
  uid: Scalars['String'];
};

/** Operation started by the `deletePaymentMethod` mutation. */
export type DeletePaymentMethodOperation = PaymentMethodOperation & {
  __typename?: 'DeletePaymentMethodOperation';
  id: Scalars['ID'];
  status: PaymentMethodOperationStatus;
};

/** Result of the `deletePaymentMethod` mutation. */
export type DeletePaymentMethodResult = {
  __typename?: 'DeletePaymentMethodResult';
  /** Background operation started by the mutation, if any. Can be finished immediately. The operation status can be polled via the `paymentMethodOperation` query. */
  operation?: Maybe<DeletePaymentMethodOperation>;
};

export type DeleteProductFromCountdownTimerOutput = {
  __typename?: 'DeleteProductFromCountdownTimerOutput';
  isDeleted: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteUser */
export type DeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of DeleteUser. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeletedKit = {
  __typename?: 'DeletedKit';
  uuid: Scalars['ID'];
};

/** More fields for this type will be defined in following PRs */
export type DeliveredEOF = IExternalOrderFulfillment & {
  __typename?: 'DeliveredEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  /** Collection of shipments belonging to an order */
  shipments?: Maybe<Array<OrderShipment>>;
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

export type DeliveryEstimates = {
  __typename?: 'DeliveryEstimates';
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export enum DeliveryOrderMode {
  Flex = 'Flex',
  FlexLegacy = 'FlexLegacy',
  Mix = 'Mix',
  Swap = 'Swap',
}

export enum DeliveryPlannerSortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type DeliveryProfile = {
  __typename?: 'DeliveryProfile';
  carrier: CarrierName;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  /** Rules using this delivery profile */
  rules?: Maybe<Array<DeliveryProfileRule>>;
  service: CarrierServiceGraphql;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type DeliveryProfileFilter = {
  __typename?: 'DeliveryProfileFilter';
  countries?: Maybe<Array<Scalars['String']>>;
  customerTypes?: Maybe<Array<CustomerType>>;
  orderModes?: Maybe<Array<DeliveryOrderMode>>;
  orders?: Maybe<Array<Scalars['String']>>;
  productGroups?: Maybe<Array<SkuProductGroup>>;
  skuProducts?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Array<DeliveryType>>;
  users?: Maybe<Array<Scalars['String']>>;
  warehouseCodes?: Maybe<Array<DeliveryWarehouseCode>>;
};

export type DeliveryProfileFilterInput = {
  countries?: InputMaybe<Array<Scalars['String']>>;
  customerTypes?: InputMaybe<Array<CustomerType>>;
  orderModes?: InputMaybe<Array<DeliveryOrderMode>>;
  orders?: InputMaybe<Array<Scalars['String']>>;
  skuProducts?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Array<DeliveryType>>;
  users?: InputMaybe<Array<Scalars['String']>>;
  warehouseCodes?: InputMaybe<Array<DeliveryWarehouseCode>>;
};

export type DeliveryProfileInput = {
  carrier: CarrierName;
  name: Scalars['String'];
  service: CarrierServiceInputGraphql;
  uid?: InputMaybe<Scalars['ID']>;
};

export type DeliveryProfileMatchInput = {
  country?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<CustomerType>;
  force?: InputMaybe<Scalars['Boolean']>;
  orderMode?: InputMaybe<DeliveryOrderMode>;
  orderNumber?: InputMaybe<Scalars['String']>;
  preferredCarrier?: InputMaybe<CarrierName>;
  priority?: InputMaybe<Scalars['Int']>;
  skuProducts?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<DeliveryType>;
  userId?: InputMaybe<Scalars['String']>;
  warehouseCode?: InputMaybe<DeliveryWarehouseCode>;
};

export type DeliveryProfileRule = {
  __typename?: 'DeliveryProfileRule';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  filter: DeliveryProfileFilter;
  groupUid?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  profile?: Maybe<DeliveryProfile>;
  profileUid?: Maybe<Scalars['String']>;
  status: RuleStatus;
  type: RuleType;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
};

export type DeliveryProfileRuleInput = {
  description?: InputMaybe<Scalars['String']>;
  expireAt?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DeliveryProfileFilterInput>;
  groupUid?: InputMaybe<Scalars['String']>;
  initiatedBy?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  profileUid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RuleStatus>;
  type?: InputMaybe<RuleType>;
  uid?: InputMaybe<Scalars['ID']>;
};

export type DeliveryProfileRuleMatch = {
  __typename?: 'DeliveryProfileRuleMatch';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  filter: DeliveryProfileFilter;
  name: Scalars['String'];
  priority: Scalars['Int'];
  status: RuleStatus;
  type: RuleType;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type DeliveryProfileRuleMatchResult = {
  __typename?: 'DeliveryProfileRuleMatchResult';
  error?: Maybe<Scalars['String']>;
  matchedRule?: Maybe<DeliveryProfileRuleMatch>;
  profile?: Maybe<DeliveryProfile>;
  riskLevel?: Maybe<Scalars['String']>;
};

export type DeliveryRuleConflict = {
  __typename?: 'DeliveryRuleConflict';
  conflictingRules: Array<DeliveryProfileRule>;
  level: DeliveryRuleConflictLevel;
  message: Scalars['String'];
  scenario?: Maybe<Scalars['String']>;
};

export enum DeliveryRuleConflictLevel {
  Critical = 'Critical',
  None = 'None',
  Warning = 'Warning',
}

export type DeliveryRuleConnection = {
  __typename?: 'DeliveryRuleConnection';
  rules: Array<DeliveryProfileRule>;
  totalCount: Scalars['Int'];
};

export type DeliveryRuleFilterCompareInput = {
  expireAt?: InputMaybe<TimestampComparatorInput>;
  groupUid?: InputMaybe<StringComparatorInput>;
  name?: InputMaybe<StringComparatorInput>;
  priority?: InputMaybe<NumberComparatorInput>;
  profileUid?: InputMaybe<DeliveryRuleProfileUidComparatorInput>;
  status?: InputMaybe<RuleStatusComparatorInput>;
  type?: InputMaybe<RuleTypeComparatorInput>;
};

export type DeliveryRuleFilterInput = {
  expireAt?: InputMaybe<Scalars['String']>;
  groupUid?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  profileUid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RuleStatus>;
  type?: InputMaybe<RuleType>;
  uid?: InputMaybe<Scalars['ID']>;
};

export type DeliveryRuleOperationResult = {
  __typename?: 'DeliveryRuleOperationResult';
  conflicts: Array<DeliveryRuleConflict>;
  rule?: Maybe<DeliveryProfileRule>;
};

export type DeliveryRuleProfileUidComparatorInput = {
  _eq?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
};

export enum DeliveryType {
  Inbound = 'Inbound',
  Internal = 'Internal',
  Outbound = 'Outbound',
}

export enum DeliveryWarehouseCode {
  DisConnectEuKarlsruhe = 'DisConnectEuKarlsruhe',
  IngramMicroEUFLSB = 'IngramMicroEUFLSB',
  Ups = 'Ups',
  UpsSofteonEUNLRNG = 'UpsSofteonEUNLRNG',
  UpsSofteonUSKYLSE = 'UpsSofteonUSKYLSE',
  Wemalo = 'Wemalo',
}

export type DigitalOtpProduct = AddonsAddon & {
  __typename?: 'DigitalOtpProduct';
  description?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['String']>;
  eula?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  infoLink?: Maybe<Scalars['String']>;
  localizations: Array<DigitalProductLocalization>;
  name: Scalars['String'];
  policyApprovalRequired: Scalars['Boolean'];
  providerName: DigitalProductProvider;
  redeemLink?: Maybe<Scalars['String']>;
  relatedProductSkus: Array<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  sourceImages: Array<Scalars['String']>;
  /** Specs stored in a key:value format, separated by a new line symbol */
  technicalSpecs?: Maybe<Scalars['String']>;
  variants: Array<DigitalOtpVariant>;
};

export type DigitalOtpVariant = {
  __typename?: 'DigitalOtpVariant';
  currency: Scalars['String'];
  digitalOtpProduct: DigitalOtpProduct;
  durationInMonths: Scalars['Int'];
  id: Scalars['ID'];
  originalPriceInCents?: Maybe<Scalars['Int']>;
  priceInCents: Scalars['Int'];
};

export type DigitalProductLocalization = {
  __typename?: 'DigitalProductLocalization';
  description?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['String']>;
  eula?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  infoLink?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  redeemLink?: Maybe<Scalars['String']>;
  sourceImages?: Maybe<Array<Scalars['String']>>;
  technicalSpecs?: Maybe<Scalars['String']>;
};

export enum DigitalProductProvider {
  GIGS = 'GIGS',
  MICROMEDIA = 'MICROMEDIA',
  TD_SYNNEX = 'TD_SYNNEX',
  TECH_DATA = 'TECH_DATA',
  VITREX = 'VITREX',
}

export enum DimensionsUnit {
  CENTIMETERS = 'CENTIMETERS',
  METERS = 'METERS',
}

export type DisablePurchaseVoucherTerm = {
  __typename?: 'DisablePurchaseVoucherTerm';
  available: Scalars['Boolean'];
  /** The amount of the discount itself */
  discountAmount?: Maybe<Price>;
  minimumCartPrice?: Maybe<Price>;
  unavailabilityReason?: Maybe<DiscountOfferUnavailabilityReason>;
};

export type Discount = {
  amount: Price;
  recurring: Scalars['Boolean'];
};

export enum DiscountOfferUnavailabilityReason {
  CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
  EXCLUDED_BUSINESS_CONTRACT = 'EXCLUDED_BUSINESS_CONTRACT',
  EXCLUDED_COUNTRY = 'EXCLUDED_COUNTRY',
  HAS_DISABLE_PURCHASE_VOUCHER = 'HAS_DISABLE_PURCHASE_VOUCHER',
  HAS_EXISTING_CONTRACT_DISCOUNT = 'HAS_EXISTING_CONTRACT_DISCOUNT',
  HAS_RECURRING_DISCOUNT = 'HAS_RECURRING_DISCOUNT',
  INVALID_MONTHS_CRITERIA = 'INVALID_MONTHS_CRITERIA',
  NOT_A_FLEX_CONTRACT = 'NOT_A_FLEX_CONTRACT',
  PURCHASE_DISCOUNT_DISABLED_IN_TERMS_AND_CONDITIONS = 'PURCHASE_DISCOUNT_DISABLED_IN_TERMS_AND_CONDITIONS',
  PURCHASE_OPTION_IS_NOT_AVAILABLE = 'PURCHASE_OPTION_IS_NOT_AVAILABLE',
}

export enum DiscountReason {
  AGREEMENT_WITH_CUSTOMER = 'AGREEMENT_WITH_CUSTOMER',
  ASSET_CONDITION = 'ASSET_CONDITION',
  ASSET_NOT_AVAILABLE = 'ASSET_NOT_AVAILABLE',
  CONTRACT_CANCELLED = 'CONTRACT_CANCELLED',
  DISCOUNT_NOT_APPLIED = 'DISCOUNT_NOT_APPLIED',
  DOUBLE_CHARGES = 'DOUBLE_CHARGES',
  EXISTING_CONTRACT_DISCOUNT = 'EXISTING_CONTRACT_DISCOUNT',
  FAILED_DELIVERY = 'FAILED_DELIVERY',
  FREE_SHIPMENT = 'FREE_SHIPMENT',
  LATE_DELIVERY = 'LATE_DELIVERY',
  OTHER = 'OTHER',
  PRICE_CHANGED = 'PRICE_CHANGED',
  RETURN_PROCESS = 'RETURN_PROCESS',
  REVOCATION = 'REVOCATION',
  SURPRISE_DISCOUNT = 'SURPRISE_DISCOUNT',
}

export type DismissModel = {
  __typename?: 'DismissModel';
  campaignUuid: Scalars['String'];
  entity: MarketingEntityType;
  externalEntity: Scalars['String'];
  id: Scalars['ID'];
};

export type DistributionRule = {
  __typename?: 'DistributionRule';
  body: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  description: Scalars['String'];
  expireAt?: Maybe<Scalars['DateTimeISO']>;
  expired: Scalars['Boolean'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  relatedToUid: Scalars['String'];
  stock?: Maybe<Stock>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTimeISO'];
};

export type DistributionRuleCollection = {
  __typename?: 'DistributionRuleCollection';
  count: Scalars['Float'];
  rows: Array<DistributionRule>;
};

export type DistributionRuleFilterInput = {
  name?: InputMaybe<Scalars['String']>;
  relatedToUid?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['ID']>;
};

export type DistributionRuleInput = {
  body: Scalars['String'];
  description: Scalars['String'];
  expireAt?: InputMaybe<Scalars['DateTimeISO']>;
  name: Scalars['String'];
  priority: Scalars['Float'];
  relatedToUid: Scalars['String'];
  uid?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DropUserPhoneNumber */
export type DropUserPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of DropUserPhoneNumber. */
export type DropUserPhoneNumberPayload = {
  __typename?: 'DropUserPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type Duration = {
  __typename?: 'Duration';
  unit: TimeUnit;
  value: Scalars['Int'];
};

export type DurationTerms = {
  __typename?: 'DurationTerms';
  committedLength: Duration;
  rentalPlanLength: Duration;
};

/** Matches address in event checkout.order.order_placed.v1 */
export type EOFShippingAddress = {
  __typename?: 'EOFShippingAddress';
  additionalInfo?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  countryIso: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type EarlyOrderCancellationTerm = {
  __typename?: 'EarlyOrderCancellationTerm';
  available: Scalars['Boolean'];
  unavailableAfter: Scalars['ISO8601DateTime'];
};

export type EmployeeCsv = {
  __typename?: 'EmployeeCsv';
  additionalInfo: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  externalId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type EmployeeImportFileEmpty = ExpectedError & {
  __typename?: 'EmployeeImportFileEmpty';
  code: Scalars['String'];
};

export type EmployeeImportFileInvalid = ExpectedError & {
  __typename?: 'EmployeeImportFileInvalid';
  code: Scalars['String'];
};

export type EmployeeImportFileNotSupportedError = ExpectedError & {
  __typename?: 'EmployeeImportFileNotSupportedError';
  code: Scalars['String'];
  supportedExtensions: Array<Scalars['String']>;
};

export type EmployeeImportFileSizeExceeded = ExpectedError & {
  __typename?: 'EmployeeImportFileSizeExceeded';
  code: Scalars['String'];
  maxSizeSupported: Scalars['String'];
};

export type EmployeeImportRequiredColumnsMissing = ExpectedError & {
  __typename?: 'EmployeeImportRequiredColumnsMissing';
  code: Scalars['String'];
  missingHeaders: Array<Scalars['String']>;
};

export type EmployeeImportRowCountExceeded = ExpectedError & {
  __typename?: 'EmployeeImportRowCountExceeded';
  code: Scalars['String'];
  maxRowCount: Scalars['Int'];
};

export type EmployeeOfGrover = {
  __typename?: 'EmployeeOfGrover';
  email: Scalars['String'];
};

export type EmployeeValidateResult = {
  __typename?: 'EmployeeValidateResult';
  isValid: Scalars['Boolean'];
};

export type EmployeeValidationInput = {
  employeeId: Scalars['String'];
  imei?: InputMaybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  serialNumber?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type EmptyMetadata = {
  __typename?: 'EmptyMetadata';
  description: Scalars['String'];
};

/** Autogenerated input type of EnableInvoicePayments */
export type EnableInvoicePaymentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of EnableInvoicePayments. */
export type EnableInvoicePaymentsPayload = {
  __typename?: 'EnableInvoicePaymentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EndedNote = TextNoteInterface & {
  __typename?: 'EndedNote';
  text?: Maybe<Scalars['String']>;
};

export type EndingTerm = {
  __typename?: 'EndingTerm';
  availableAfter?: Maybe<Scalars['ISO8601DateTime']>;
  lastCommittedPeriod?: Maybe<LastCommittedPeriod>;
  timeUntilAvailable?: Maybe<Duration>;
};

export type EnergyLabel = {
  __typename?: 'EnergyLabel';
  createdAt: Scalars['DateTime'];
  energyRating: EnergyRating;
  energySpecs: Scalars['JSON'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  product: Product;
  productSku: Scalars['String'];
  storeCodes: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EnergyLabelBulkCreateInput = {
  energyLabels: Array<EnergyLabelCreateInput>;
};

export type EnergyLabelCreateInput = {
  energyRating: Scalars['String'];
  energySpecs: Languages;
  imageUrl: Scalars['String'];
  productSku: Scalars['String'];
  storeCodes: Array<Scalars['String']>;
};

export type EnergyLabelError = GenericError & {
  __typename?: 'EnergyLabelError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type EnergyLabelOrError = EnergyLabel | EnergyLabelError;

export type EnergyLabelOrderByInput = {
  id?: InputMaybe<SortOrder>;
};

export type EnergyLabelPaginationOutput = {
  __typename?: 'EnergyLabelPaginationOutput';
  rows: Array<EnergyLabel>;
  totalCount: Scalars['Int'];
};

export type EnergyLabelUpdateInput = {
  energyRating?: InputMaybe<Scalars['String']>;
  energySpecs?: InputMaybe<Languages>;
  imageUrl?: InputMaybe<Scalars['String']>;
  productSku?: InputMaybe<Scalars['String']>;
  storeCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type EnergyLabelWhereInput = {
  storeCode?: InputMaybe<Scalars['String']>;
};

export enum EnergyRating {
  A = 'A',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum EntityType {
  card = 'card',
  order = 'order',
  product = 'product',
  shipment = 'shipment',
  subscription = 'subscription',
}

/** Autogenerated input type of ExecuteLoopRakeTask */
export type ExecuteLoopRakeTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  envVariables: Array<Scalars['String']>;
  rakeTask: Scalars['String'];
};

/** Autogenerated return type of ExecuteLoopRakeTask. */
export type ExecuteLoopRakeTaskPayload = {
  __typename?: 'ExecuteLoopRakeTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of ExecuteRakeTask */
export type ExecuteRakeTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  envVariables: Array<Scalars['String']>;
  rakeTask: Scalars['String'];
};

/** Autogenerated return type of ExecuteRakeTask. */
export type ExecuteRakeTaskPayload = {
  __typename?: 'ExecuteRakeTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type ExistingDiscountTerm = {
  __typename?: 'ExistingDiscountTerm';
  available: Scalars['Boolean'];
  /** The amount of the discount itself */
  discountAmount?: Maybe<Price>;
  discountPercentage?: Maybe<Scalars['Int']>;
  /** The final price after the discount applied */
  discountedPrice?: Maybe<Price>;
  unavailabilityReason?: Maybe<DiscountOfferUnavailabilityReason>;
};

export type ExistingSubscriptionDiscountOffer = SubscriptionDiscountInterface & {
  __typename?: 'ExistingSubscriptionDiscountOffer';
  discountPercentage: Scalars['Int'];
  price: Price;
};

export type ExpectedError = {
  code: Scalars['String'];
};

export type ExperianCollectorDataInput = {
  hdm?: InputMaybe<Scalars['String']>;
  jscPayload?: InputMaybe<Scalars['String']>;
};

export type ExportMutationResult = {
  __typename?: 'ExportMutationResult';
  fileUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ExtendContract */
export type ExtendContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  newRentalPlan: NewRentalPlanInput;
};

/** Autogenerated input type of ExtendContractPause */
export type ExtendContractPauseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  /** Additional days to extend the contract pause by. */
  days: Scalars['Int'];
  reason: PauseReason;
};

/** Autogenerated return type of ExtendContractPause. */
export type ExtendContractPausePayload = {
  __typename?: 'ExtendContractPausePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

/** Autogenerated return type of ExtendContract. */
export type ExtendContractPayload = {
  __typename?: 'ExtendContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

export enum ExtensionUnavailabilityReason {
  CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
  NOT_A_FLEX_CONTRACT = 'NOT_A_FLEX_CONTRACT',
  NO_PLANS_AVAILABLE = 'NO_PLANS_AVAILABLE',
}

export enum ExternalLoginProvider {
  GOOGLE = 'GOOGLE',
}

export type ExternalOrderFulfillmentConnection = {
  __typename?: 'ExternalOrderFulfillmentConnection';
  edges?: Maybe<Array<ExternalOrderFulfillmentEdge>>;
  matchingCount?: Maybe<Scalars['Float']>;
  nodes?: Maybe<Array<ExternalOrderFulfillmentUnion>>;
  pageInfo?: Maybe<OrderFulfilmentPageInfo>;
  totalCount?: Maybe<Scalars['Float']>;
};

export type ExternalOrderFulfillmentEdge = {
  __typename?: 'ExternalOrderFulfillmentEdge';
  cursor: Scalars['String'];
  node: ExternalOrderFulfillmentUnion;
};

export type ExternalOrderFulfillmentFilterInput = {
  joinType: JoinType;
  predicates: Array<ExternalOrderFulfillmentFilterPredicateJointInput>;
};

/** Modifies the default filter applied to query */
export enum ExternalOrderFulfillmentFilterModifier {
  default = 'default',
  openOrders = 'openOrders',
  pastOrders = 'pastOrders',
}

export enum ExternalOrderFulfillmentFilterPredicateAttributeType {
  orderNumber = 'orderNumber',
  typename = 'typename',
}

export type ExternalOrderFulfillmentFilterPredicateInput = {
  attribute: ExternalOrderFulfillmentFilterPredicateAttributeType;
  comparison: ComparisonType;
  type: PredicateType;
  value: Scalars['String'];
};

export type ExternalOrderFulfillmentFilterPredicateJointInput = {
  joinType: JoinType;
  predicates?: InputMaybe<Array<ExternalOrderFulfillmentFilterPredicateInput>>;
};

export type ExternalOrderFulfillmentUnion =
  | DeclinedEOF
  | DeliveredEOF
  | FailedDeliveryEOF
  | NotImplementedEOF
  | PartiallyDeliveredEOF
  | PartiallyFailedDeliveryEOF
  | PartiallyShippedEOF
  | PreparingToShipEOF
  | ReviewingOrderEOF
  | ShippedEOF;

export type ExternalPhysicalOtpProduct = AddonsAddon & {
  __typename?: 'ExternalPhysicalOtpProduct';
  categories: Array<Scalars['String']>;
  description: Scalars['String'];
  detailedDescription: Scalars['String'];
  externalSku: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  price: Price;
};

export type Facet = {
  name: Scalars['String'];
};

/** More fields for this type will be defined in following PRs */
export type FailedDeliveryEOF = IExternalOrderFulfillment & {
  __typename?: 'FailedDeliveryEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  /** Collection of shipments belonging to an order */
  shipments?: Maybe<Array<OrderShipment>>;
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

export enum Feature {
  MIX_CONTRACTS = 'MIX_CONTRACTS',
  PURCHASE_DISCOUNT_DISABLED = 'PURCHASE_DISCOUNT_DISABLED',
  PURCHASE_OPTION = 'PURCHASE_OPTION',
  PURCHASE_REVOCATION_DISABLED = 'PURCHASE_REVOCATION_DISABLED',
  PURCHASE_SELF_SERVICE = 'PURCHASE_SELF_SERVICE',
}

export type FeaturedPaymentInput = {
  failedDaysAgo?: InputMaybe<Scalars['Int']>;
  scheduledInDays?: InputMaybe<Scalars['Int']>;
};

export type FetchAllConnectionResponse = {
  __typename?: 'FetchAllConnectionResponse';
  data?: Maybe<Array<ConnectionDetails>>;
  response: Scalars['Boolean'];
};

export type FieldReview = {
  __typename?: 'FieldReview';
  applied?: Maybe<Scalars['Boolean']>;
  currentValue: Scalars['String'];
  fieldName: Scalars['String'];
  mismatch: Scalars['Boolean'];
  previousValue?: Maybe<Scalars['String']>;
  providerValue: Scalars['String'];
  significant: Scalars['Boolean'];
};

export type FilterArgs = {
  availability?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Array<Scalars['String']>>;
  category?: InputMaybe<Array<Scalars['String']>>;
  deal?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<RangeFilterInput>;
  rentalPlan?: InputMaybe<Scalars['Int']>;
  structuredSpecifications?: InputMaybe<Array<StructuredSpecificationsInput>>;
};

export type FilterButton = {
  __typename?: 'FilterButton';
  filter: SubscriptionListFilter;
  title: Scalars['String'];
};

export type FilterOptions = {
  shippable?: InputMaybe<Scalars['Boolean']>;
};

export type FindAndCountInput = {
  orderMode?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type FinishedModel = {
  __typename?: 'FinishedModel';
  campaignUuid: Scalars['String'];
  entity: MarketingEntityType;
  externalEntity: Scalars['String'];
  id: Scalars['ID'];
  result: Scalars['String'];
};

export type Flex = BasicContract & {
  __typename?: 'Flex';
  actions?: Maybe<Array<ContractAction>>;
  activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated A contract can have multiple allocations, use `Contract.assetAllocations` instead. */
  assetAllocation?: Maybe<AssetAllocation>;
  assetAllocations: Array<AssetAllocation>;
  availableRentalPlans: Array<RentalPlanUpgradeProperties>;
  billingAccountId: Scalars['ID'];
  billingTerms: BillingTerms;
  businessReturnMetadata?: Maybe<BusinessAssetReturnMetadata>;
  cancelPurchaseTerm: CancelPurchaseTerm;
  cancelReturnTerm: CancelReturnTerm;
  countryIso?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentFlow?: Maybe<Flow>;
  currentGood: FlexDevice;
  customerWishCancelTerm?: Maybe<CustomerWishCancelTerm>;
  customerWishKeepChargingTerm?: Maybe<CustomerWishKeepChargingTerm>;
  damageClaimTerm?: Maybe<DamageClaimTerm>;
  debtCollectionAgency?: Maybe<DebtCollectionAgency>;
  disablePurchaseVoucherTerm: DisablePurchaseVoucherTerm;
  durationTerms: DurationTerms;
  earlyOrderCancellationTerm: EarlyOrderCancellationTerm;
  endingTerm: EndingTerm;
  existingDiscountTerm: ExistingDiscountTerm;
  flow?: Maybe<Flows>;
  good: FlexDevice;
  goods: Array<FlexDevice>;
  groverCareTerms: GroverCareTerms;
  history: Array<HistoryItem>;
  id: Scalars['ID'];
  internalDebtCollectionTerm?: Maybe<InternalDebtCollectionTerm>;
  manualPauseTerm: ManualPauseTerm;
  name: Scalars['String'];
  note?: Maybe<SubscriptionNote>;
  order: Order;
  orderNumber: Scalars['ID'];
  pausedAt?: Maybe<Scalars['ISO8601DateTime']>;
  paymentMethod?: Maybe<PaymentMethod>;
  payments: PaymentsUnionConnection;
  previousSubscription?: Maybe<SubscriptionDetails>;
  purchaseTerm?: Maybe<PurchaseTerm>;
  replacementTerm: ReplacementTerm;
  revivalTerm: RevivalTerm;
  revocationTerms?: Maybe<RevocationTerms>;
  shipmentLabels?: Maybe<Array<ShipmentLabel>>;
  state: ContractState;
  storeCode: Scalars['String'];
  surpriseDiscountTerm: SurpriseDiscountTerm;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  terminationComment?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<ContractTerminationReason>;
  tosUpdates: Array<TosUpdate>;
  tosVersion?: Maybe<Scalars['ID']>;
  upsellTerm?: Maybe<UpsellTerm>;
  user: User;
  userId: Scalars['ID'];
};

export type FlexgoodArgs = {
  id: Scalars['ID'];
};

export type FlexgoodsArgs = {
  states?: InputMaybe<Array<ContractGoodState>>;
};

export type FlexpaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentsFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type FlexCartLineItemMeta = LineItemMeta & {
  __typename?: 'FlexCartLineItemMeta';
  allowPurchase?: Maybe<Scalars['Boolean']>;
  allowRentalUpgrade?: Maybe<Scalars['Boolean']>;
  category?: Maybe<LineItemCategory>;
  committedMonths: Scalars['Int'];
  discountAmount?: Maybe<Scalars['Int']>;
  groverCare?: Maybe<GroverCare>;
  price: Price;
  product?: Maybe<Product>;
  total: Price;
  variant: Variant;
  voucherShare?: Maybe<Price>;
};

export type FlexDetails = Flex | SubscriptionDetails;

export type FlexDevice = CatalogItem &
  CatalogItemVariant &
  ContractGoods & {
    __typename?: 'FlexDevice';
    asset?: Maybe<AssetModel>;
    assetId?: Maybe<Scalars['String']>;
    categoryId: Scalars['ID'];
    contractId: Scalars['ID'];
    deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    outboundShipmentLabel?: Maybe<ShipmentLabel>;
    product: Product;
    productSku: Scalars['ID'];
    returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
    serialNumber?: Maybe<Scalars['ID']>;
    shipmentLabel?: Maybe<ShipmentLabel>;
    state: ContractGoodState;
    subCategoryId: Scalars['ID'];
    variant: Variant;
    variantSku: Scalars['ID'];
  };

export type FlexLineItem = {
  __typename?: 'FlexLineItem';
  coreAttribute?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  meta: FlexLineItemMeta;
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

export type FlexLineItemMeta = {
  __typename?: 'FlexLineItemMeta';
  minimumTermMonths: Scalars['Int'];
  price: Price;
  rentalPlans: Array<RentalPlan>;
  selectedPlan?: Maybe<RentalPlan>;
  total: Price;
  variant: Variant;
};

export type FloatComparisonInput = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
};

export enum Flow {
  CANCELLATION = 'CANCELLATION',
  COMPLETION = 'COMPLETION',
  CUSTOMER_WISH_CANCEL = 'CUSTOMER_WISH_CANCEL',
  CUSTOMER_WISH_KEEP_CHARGING = 'CUSTOMER_WISH_KEEP_CHARGING',
  DAMAGE_CLAIM = 'DAMAGE_CLAIM',
  INTERNAL_DEBT_COLLECTION = 'INTERNAL_DEBT_COLLECTION',
  MANUAL_PAUSE = 'MANUAL_PAUSE',
  PURCHASE = 'PURCHASE',
  REPLACEMENT = 'REPLACEMENT',
  REVOCATION = 'REVOCATION',
  SWAPPING = 'SWAPPING',
  UPSELL = 'UPSELL',
}

export enum FlowState {
  AWAITING_RETURN = 'AWAITING_RETURN',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  EXTENDED = 'EXTENDED',
  FAILED = 'FAILED',
  NEW_DELIVERED = 'NEW_DELIVERED',
  OLD_RETURNED = 'OLD_RETURNED',
  PROCESSING_RETURN = 'PROCESSING_RETURN',
  SHIPPING_RETURN = 'SHIPPING_RETURN',
  STARTED = 'STARTED',
}

export enum FlowType {
  B2B_KITS = 'B2B_KITS',
  B2B_OFFER = 'B2B_OFFER',
  B2B_STANDARD = 'B2B_STANDARD',
  B2C_RETAIL = 'B2C_RETAIL',
  B2C_STANDARD = 'B2C_STANDARD',
  B2C_SWAP = 'B2C_SWAP',
}

export type FlowUpdate = {
  __typename?: 'FlowUpdate';
  action: FlowUpdateAction;
  comment?: Maybe<Scalars['String']>;
  lengthInDays: Scalars['Int'];
  reason?: Maybe<FlowUpdateReason>;
};

export enum FlowUpdateAction {
  ENDED = 'ENDED',
  EXPIRATION_EXTENDED = 'EXPIRATION_EXTENDED',
  STARTED = 'STARTED',
}

export enum FlowUpdateReason {
  BULK_RETURN = 'BULK_RETURN',
  LOST_ORDER_INVESTIGATION = 'LOST_ORDER_INVESTIGATION',
  NOT_ABLE_TO_PAY = 'NOT_ABLE_TO_PAY',
  PAUSING_PAYMENTS_UNTIL_RETURN = 'PAUSING_PAYMENTS_UNTIL_RETURN',
}

export type Flows =
  | Completion
  | CustomerWishCancel
  | CustomerWishKeepCharging
  | DamageClaim
  | InternalDebtCollection
  | ManualPause
  | Purchase
  | Replacement
  | Revocation
  | Upsell;

export type ForceApproveCompanyInput = {
  id: Scalars['Float'];
};

/** Autogenerated input type of ForceApprove */
export type ForceApproveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ForceApprove. */
export type ForceApprovePayload = {
  __typename?: 'ForceApprovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ForcePasswordReset */
export type ForcePasswordResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of ForcePasswordReset. */
export type ForcePasswordResetPayload = {
  __typename?: 'ForcePasswordResetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type FreelancerPersonalIDInput = {
  companyId: Scalars['Float'];
  identificationNumber: Scalars['String'];
  identificationType: Scalars['String'];
  store: StoreInput;
  userId: Scalars['Float'];
};

export enum Gateway {
  bankcard = 'bankcard',
  paypal = 'paypal',
  sepa = 'sepa',
}

/** Autogenerated input type of GenerateDisablePurchaseVoucher */
export type GenerateDisablePurchaseVoucherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  discountAmountInCents: Scalars['Int'];
};

/** Autogenerated return type of GenerateDisablePurchaseVoucher. */
export type GenerateDisablePurchaseVoucherPayload = {
  __typename?: 'GenerateDisablePurchaseVoucherPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
  voucherCode: Scalars['String'];
};

export type GenerateInvoiceResponse = {
  __typename?: 'GenerateInvoiceResponse';
  id: Scalars['String'];
  internalUrl: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  ok: Scalars['String'];
  url: Scalars['String'];
};

export type GenerateReturnLabelFailure = {
  __typename?: 'GenerateReturnLabelFailure';
  arrangementId: Scalars['String'];
  message: Scalars['String'];
};

export type GenerateReturnLabelResultUnion =
  | GenerateReturnLabelFailure
  | GenerateReturnLabelSuccess;

export type GenerateReturnLabelSuccess = {
  __typename?: 'GenerateReturnLabelSuccess';
  arrangementId: Scalars['String'];
  shipment: Shipment;
};

export type GenerateReturnLabelsResult = {
  __typename?: 'GenerateReturnLabelsResult';
  rows: Array<GenerateReturnLabelResultUnion>;
};

export type GenericError = {
  code: Scalars['String'];
  message: Scalars['String'];
};

export type GetConnectionDetailsResponse = {
  __typename?: 'GetConnectionDetailsResponse';
  data?: Maybe<ConnectionDetails>;
  response: Scalars['Boolean'];
};

export type GetPaymentOrdersFilters = {
  billingDate?: InputMaybe<Scalars['DateTime']>;
  /** Contract ID  (first payment group for an order lacks contractId) */
  contractId?: InputMaybe<Scalars['String']>;
  contractStatus?: InputMaybe<RecurrentPaymentStatusTypes>;
  contractType?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  group?: InputMaybe<Scalars['String']>;
  groupStrategy?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentPurpose?: InputMaybe<Scalars['String']>;
  paymentStatus?: InputMaybe<PaymentGroupStatusTypes>;
  paymentType?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Money']>;
  quantity?: InputMaybe<Scalars['Float']>;
  scopeUuid?: InputMaybe<Scalars['String']>;
  shippingCost?: InputMaybe<Scalars['Money']>;
  status?: InputMaybe<PaymentGroupStatusTypes>;
  /** Tax included */
  taxIncluded?: InputMaybe<Scalars['Boolean']>;
  uuid?: InputMaybe<Scalars['ID']>;
  walletUuid?: InputMaybe<Scalars['String']>;
};

export type GetPaymentOrdersResponse = {
  __typename?: 'GetPaymentOrdersResponse';
  items: Array<PaymentOrderModel>;
  pageInfo: ListPageInfo;
};

export type GetUserPaymentOrdersFilters = {
  billingDate?: InputMaybe<Scalars['DateTime']>;
  /** Contract ID  (first payment group for an order lacks contractId) */
  contractId?: InputMaybe<Scalars['String']>;
  contractStatus?: InputMaybe<RecurrentPaymentStatusTypes>;
  contractType?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  group?: InputMaybe<Scalars['String']>;
  groupStrategy?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentPurpose?: InputMaybe<Scalars['String']>;
  paymentStatus?: InputMaybe<PaymentGroupStatusTypes>;
  paymentType?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Money']>;
  quantity?: InputMaybe<Scalars['Float']>;
  scopeUuid?: InputMaybe<Scalars['String']>;
  shippingCost?: InputMaybe<Scalars['Money']>;
  status?: InputMaybe<PaymentGroupStatusTypes>;
  /** Tax included */
  taxIncluded?: InputMaybe<Scalars['Boolean']>;
  uuid?: InputMaybe<Scalars['ID']>;
  walletUuid?: InputMaybe<Scalars['String']>;
};

export type GoalInputType = {
  campaignUuid: Scalars['String'];
  conditions: Scalars['AnyScalar'];
  entityIdField: Scalars['String'];
  eventName: Scalars['String'];
  isMember: Scalars['Boolean'];
  topic: Scalars['String'];
};

export type GoalModel = {
  __typename?: 'GoalModel';
  campaignUuid: Scalars['String'];
  conditions?: Maybe<Scalars['JSON']>;
  entityIdField: Scalars['String'];
  eventName: Scalars['String'];
  isMember: Scalars['Boolean'];
  topic: Scalars['String'];
  uuid: Scalars['ID'];
};

export type Goods = FlexDevice | MixDevice;

export type GoodsOrder = {
  __typename?: 'GoodsOrder';
  carrier: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  items?: Maybe<Array<GoodsOrderItem>>;
  meta?: Maybe<GoodsOrderMetadata>;
  outboundOrder?: Maybe<WMSOutboundOrder>;
  outboundOrderUuid: Scalars['String'];
  recipientAddress?: Maybe<GoodsOrderAddressData>;
  senderAddress?: Maybe<GoodsOrderAddressData>;
  shippingProfile: Scalars['String'];
  status?: Maybe<GoodsOrderStatus>;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  uuid: Scalars['ID'];
  whGoodsOrderId: Scalars['String'];
};

export type GoodsOrderAddressData = {
  __typename?: 'GoodsOrderAddressData';
  additionalInfo: Scalars['String'];
  birthdate?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2: Scalars['String'];
  zipCode: Scalars['String'];
};

export type GoodsOrderConnection = {
  __typename?: 'GoodsOrderConnection';
  edges: Array<GoodsOrderEdge>;
  pageInfo?: Maybe<GoodsOrderPageInfo>;
};

export type GoodsOrderEdge = {
  __typename?: 'GoodsOrderEdge';
  cursor: GoodsOrderPaginationCursor;
  node: GoodsOrder;
};

export type GoodsOrderFilterType = {
  createdAt?: InputMaybe<DateComparatorInput>;
  orderMode?: InputMaybe<WMSOrderModeComparatorInput>;
  orderNumber?: InputMaybe<StringComparatorInput>;
  serialNumber?: InputMaybe<StringComparatorInput>;
  status?: InputMaybe<WMSGoodsOrderStatusComparatorInput>;
  userId?: InputMaybe<StringComparatorInput>;
  userType?: InputMaybe<WMSUserTypeComparatorInput>;
  warehouseCode?: InputMaybe<StringComparatorInput>;
  warehouseGoodsOrderId?: InputMaybe<StringComparatorInput>;
};

export type GoodsOrderItem = {
  __typename?: 'GoodsOrderItem';
  assetUid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  description?: Maybe<Scalars['String']>;
  goodsOrderUuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderMode?: Maybe<WMSShippingOrderMode>;
  orderNumber?: Maybe<Scalars['String']>;
  packageUuid?: Maybe<Scalars['String']>;
  salesforceAssetId?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  skuVariant?: Maybe<Scalars['String']>;
  status?: Maybe<GoodsOrderItemStatus>;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  uuid?: Maybe<Scalars['String']>;
  warehouseCode?: Maybe<WMSWarehouseCode>;
};

export enum GoodsOrderItemStatus {
  Cancelled = 'Cancelled',
  Created = 'Created',
}

export type GoodsOrderMetadata = {
  __typename?: 'GoodsOrderMetadata';
  im_order_number?: Maybe<Scalars['String']>;
};

export type GoodsOrderPageInfo = {
  __typename?: 'GoodsOrderPageInfo';
  beginningCursor?: Maybe<GoodsOrderPaginationCursor>;
  endCursor?: Maybe<GoodsOrderPaginationCursor>;
};

export type GoodsOrderPaginationCursor = {
  __typename?: 'GoodsOrderPaginationCursor';
  createdAt: Scalars['String'];
  uuid: Scalars['ID'];
};

export type GoodsOrderPushResponseFailureType = {
  __typename?: 'GoodsOrderPushResponseFailureType';
  errorMessage: Scalars['String'];
  whGoodsOrderId: Scalars['String'];
};

export type GoodsOrderPushResponseNoopType = {
  __typename?: 'GoodsOrderPushResponseNoopType';
  status: Scalars['String'];
  whGoodsOrderId: Scalars['String'];
};

export type GoodsOrderPushResponseSuccessType = {
  __typename?: 'GoodsOrderPushResponseSuccessType';
  whGoodsOrderId: Scalars['String'];
};

export type GoodsOrderPushResponseType = {
  __typename?: 'GoodsOrderPushResponseType';
  failure: Array<GoodsOrderPushResponseFailureType>;
  noop: Array<GoodsOrderPushResponseNoopType>;
  success: Array<GoodsOrderPushResponseSuccessType>;
};

export enum GoodsOrderStatus {
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  Failed = 'Failed',
  Packed = 'Packed',
  Preparing = 'Preparing',
  Registered = 'Registered',
  Shipped = 'Shipped',
}

export type GoodsOrderWarehouseEvent = {
  __typename?: 'GoodsOrderWarehouseEvent';
  description: Scalars['String'];
  name: Scalars['String'];
  serialNumber?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTimeISO'];
  uid: Scalars['ID'];
  warehouseCode: WMSWarehouseCode;
};

export type GoodsOrderWarehouseEventsCollection = {
  __typename?: 'GoodsOrderWarehouseEventsCollection';
  events: Array<GoodsOrderWarehouseEvent>;
};

export type GraphQLFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GraphQLFilterPaymentGroups = {
  /** Contract ID  (first payment group for an order lacks contractId) */
  contractId?: InputMaybe<Scalars['String']>;
  /** Either Order Number or Contract ID for old records */
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  /** Order Number */
  orderNumber?: InputMaybe<Scalars['String']>;
};

export type GroupBilling = {
  __typename?: 'GroupBilling';
  chosenBillingDay?: Maybe<Scalars['Float']>;
  enabled: Scalars['Boolean'];
};

export type GroupPayment = PaymentInterface & {
  __typename?: 'GroupPayment';
  amountBreakdown: Array<PaymentBreakdownItem>;
  basePrice: Price;
  byInvoice: Scalars['Boolean'];
  contracts?: Maybe<ContractConnection>;
  createdAt: Scalars['ISO8601DateTime'];
  discountableLoyaltyPrice: Price;
  discountedLoyaltyPrice: Price;
  dueDate: DateUnion;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceUrl?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['ISO8601DateTime']>;
  price: Price;
  refundedAmount: Price;
  remoteId?: Maybe<Scalars['String']>;
  state: PaymentState;
};

export type GroupPaymentcontractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export enum GroupType {
  CHARGE = 'CHARGE',
  CHARGE_PURCHASE = 'CHARGE_PURCHASE',
  REFUND = 'REFUND',
}

export type GroverAccessToken = {
  __typename?: 'GroverAccessToken';
  accessToken: Scalars['String'];
  createdAt: Scalars['Int'];
  expiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  scope: Scalars['String'];
};

export type GroverApiConditionModel = {
  __typename?: 'GroverApiConditionModel';
  amountOfActiveSubscriptions?: Maybe<Scalars['AnyScalar']>;
  amountOfProcessingSubscriptions?: Maybe<Scalars['AnyScalar']>;
  payment?: Maybe<GroverApiPaymentConditionModel>;
  rentsEScooter?: Maybe<Scalars['Boolean']>;
  rentsGSMDevice?: Maybe<Scalars['Boolean']>;
  rentsProductMoreThanOnce?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<GroverApiSubscriptionConditionModel>;
  userType?: Maybe<Scalars['AnyScalar']>;
};

export type GroverApiInputType = {
  amountOfActiveSubscriptions?: InputMaybe<Scalars['AnyScalar']>;
  amountOfProcessingSubscriptions?: InputMaybe<Scalars['AnyScalar']>;
  rentsEScooter?: InputMaybe<Scalars['Boolean']>;
  rentsGSMDevice?: InputMaybe<Scalars['Boolean']>;
  rentsProductMoreThanOnce?: InputMaybe<Scalars['Boolean']>;
  subscription?: InputMaybe<GroverApiSubscriptionConditionInputType>;
  userType?: InputMaybe<UserTypes>;
};

export type GroverApiPaymentConditionModel = {
  __typename?: 'GroverApiPaymentConditionModel';
  dateIsEstimated?: Maybe<Scalars['AnyScalar']>;
  state?: Maybe<Scalars['AnyScalar']>;
  type?: Maybe<Scalars['AnyScalar']>;
};

export type GroverApiSubscriptionConditionInputType = {
  canUpgradePlan?: InputMaybe<Scalars['Boolean']>;
  eligibleForReturn?: InputMaybe<Scalars['Boolean']>;
};

export type GroverApiSubscriptionConditionModel = {
  __typename?: 'GroverApiSubscriptionConditionModel';
  canUpgradePlan?: Maybe<Scalars['AnyScalar']>;
  eligibleForReturn?: Maybe<Scalars['AnyScalar']>;
};

export type GroverAsset = {
  __typename?: 'GroverAsset';
  activatedAt?: Maybe<Scalars['DateTime']>;
  asset?: Maybe<AssetModel>;
  /** Juno asset id */
  assetId?: Maybe<Scalars['ID']>;
  assetStatus: WorkAssetStatus;
  brandName: Scalars['String'];
  categoryId: Scalars['String'];
  companyId: Scalars['ID'];
  contract: Contract;
  contractId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  employee?: Maybe<GroverEmployee>;
  employeeId?: Maybe<Scalars['ID']>;
  externalId: Scalars['ID'];
  /** Product name */
  name: Scalars['String'];
  orderNumber: Scalars['String'];
  price: Price;
  product: Product;
  productSku?: Maybe<Scalars['String']>;
  returnAvailableAfter?: Maybe<Scalars['DateTime']>;
  returnMetadata?: Maybe<BusinessAssetReturnMetadata>;
  serialNumber?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  subCategoryId: Scalars['String'];
  terminatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ID']>;
  uuid: Scalars['ID'];
  variantSku?: Maybe<Scalars['String']>;
};

export type GroverCare = {
  __typename?: 'GroverCare';
  coverage: Scalars['Int'];
  deductibleFees?: Maybe<Array<DeductibleFee>>;
  price: Price;
};

export enum GroverCareDamageType {
  IRREPARABLE = 'IRREPARABLE',
  TECHNICAL = 'TECHNICAL',
  VISIBLE = 'VISIBLE',
}

export type GroverCareDeductibleFee = {
  __typename?: 'GroverCareDeductibleFee';
  coverage: Scalars['Int'];
  currency: Scalars['String'];
  damageType: GroverCareDamageType;
  feeInCents: Scalars['Int'];
  storeCode: Scalars['String'];
};

export type GroverCareTerms = {
  __typename?: 'GroverCareTerms';
  coverage: Scalars['Int'];
  deductibleFees: Array<DeductibleFee>;
  price: Price;
};

export type GroverEmployee = {
  __typename?: 'GroverEmployee';
  address?: Maybe<Address>;
  assets?: Maybe<Array<GroverAsset>>;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  monthlySubscriptionTotal: Array<Price>;
  name: Scalars['String'];
  /** Used for federation, don't use it directly */
  orderNumber?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ID']>;
  uuid: Scalars['ID'];
};

export type Header = {
  __typename?: 'Header';
  brands?: Maybe<HeaderBrands>;
  cartCount?: Maybe<Scalars['Int']>;
  categoryMenu: CategoryMenu;
  consumer: HeaderConsumer;
  favoritesCount: Scalars['Int'];
  homeLink: HeaderLink;
  links: Array<HeaderLink>;
  locale: HeaderLocale;
  loggedIn: Scalars['Boolean'];
  ssr: Scalars['Boolean'];
  store: Scalars['String'];
  storeId: Scalars['String'];
  translations: Scalars['JSON'];
  userBar: UserBar;
};

export type HeaderBrands = {
  __typename?: 'HeaderBrands';
  allBrands: Array<Brand>;
  marketingCard?: Maybe<MarketingCard>;
  newBrands: Array<Brand>;
};

export type HeaderCategory = {
  __typename?: 'HeaderCategory';
  brands?: Maybe<Array<Brand>>;
  children?: Maybe<Array<HeaderCategory>>;
  coverImageThumbnailUrl?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  link: HeaderLink;
  marketingCard?: Maybe<MarketingCard>;
  permalink: Scalars['String'];
  trendingV2?: Maybe<Array<HeaderLink>>;
};

export enum HeaderConsumer {
  catalog = 'catalog',
  dashboard = 'dashboard',
  payments = 'payments',
}

export type HeaderImage = {
  __typename?: 'HeaderImage';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type HeaderLink = {
  __typename?: 'HeaderLink';
  external: Scalars['Boolean'];
  preventDefault?: Maybe<Scalars['Boolean']>;
  showInDesktop: Scalars['Boolean'];
  showInMobile: Scalars['Boolean'];
  svgPath?: Maybe<Scalars['String']>;
  targetBlank: Scalars['Boolean'];
  text: Scalars['String'];
  url: Scalars['String'];
};

export enum HeaderLocale {
  de = 'de',
  en = 'en',
  es = 'es',
  nl = 'nl',
}

export type HeaderOption = {
  __typename?: 'HeaderOption';
  defaultLocale?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type HeaderSearchResultV2 = {
  __typename?: 'HeaderSearchResultV2';
  sections: Array<SearchResultSectionV2>;
  showAllLink: HeaderLink;
  suggestions: Array<Scalars['String']>;
};

export enum HeaderStore {
  at = 'at',
  business = 'business',
  business_at = 'business_at',
  business_es = 'business_es',
  business_nl = 'business_nl',
  business_us = 'business_us',
  de = 'de',
  es = 'es',
  nl = 'nl',
  us = 'us',
}

export type HeaderUser = {
  __typename?: 'HeaderUser';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  userType: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type HintContentModel = {
  __typename?: 'HintContentModel';
  /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
  bodyTemplate: Scalars['String'];
  uuid: Scalars['ID'];
};

export type HintModel = {
  __typename?: 'HintModel';
  /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
  bodyTemplate: Scalars['String'];
  uuid: Scalars['ID'];
};

export type HistoryItem = {
  __typename?: 'HistoryItem';
  fromState: ContractState;
  metadata: HistoryMetadata;
  timestamp: Scalars['ISO8601DateTime'];
  toState: ContractState;
};

export type HistoryMetadata = EmptyMetadata | TriggerEventMetadata | UserActionMetadata;

export type IExternalOrderFulfillment = {
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

/** Base interface for warehouse events */
export type IWarehouseEvent = {
  createdAt: Scalars['DateTimeISO'];
  eventType: Scalars['String'];
  serialNumber: Scalars['String'];
  skuVariantCode: Scalars['String'];
  uid: Scalars['ID'];
  warehouseCode: WMSWarehouseCode;
};

export type IdComparisonInput = {
  _eq?: InputMaybe<Scalars['ID']>;
};

export type ImageAssets = {
  coverImageUrl?: InputMaybe<Scalars['String']>;
  iconSvgPath?: InputMaybe<Scalars['String']>;
};

export enum ImageFolders {
  ENERGY_LABELS = 'ENERGY_LABELS',
  VARIANTS = 'VARIANTS',
}

export type ImageUploadData = {
  __typename?: 'ImageUploadData';
  cloudinaryUrl: Scalars['String'];
  originalImageName: Scalars['String'];
  url: Scalars['String'];
};

export type ImageUploadError = {
  __typename?: 'ImageUploadError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ImageUploadResult = ImageUploadData | ImageUploadError;

export type ImportEmployeesError = {
  __typename?: 'ImportEmployeesError';
  columnConflicts: Array<ColumnConflict>;
  employee: EmployeeCsv;
};

export type ImportEmployeesFromS3 = {
  key: Scalars['String'];
  shouldUpsert: Scalars['Boolean'];
};

export type ImportEmployeesResponse =
  | EmployeeImportFileEmpty
  | EmployeeImportFileInvalid
  | EmployeeImportFileNotSupportedError
  | EmployeeImportFileSizeExceeded
  | EmployeeImportRequiredColumnsMissing
  | EmployeeImportRowCountExceeded
  | ImportEmployeesResult;

export type ImportEmployeesResult = {
  __typename?: 'ImportEmployeesResult';
  createdCount: Scalars['Int'];
  errors: Array<ImportEmployeesError>;
  updatedCount: Scalars['Int'];
};

export type InCentsModel = {
  __typename?: 'InCentsModel';
  currency: Scalars['String'];
  inCents: Scalars['ID'];
};

export enum InboundShipmentStates {
  AWAITING_RETURN = 'AWAITING_RETURN',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  PROCESSING_RETURN = 'PROCESSING_RETURN',
  SHIPPING_RETURN = 'SHIPPING_RETURN',
  STARTED = 'STARTED',
}

export enum InfraRoutingDecision {
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  YES = 'YES',
}

export type InfraRoutingDecisionComparisonInput = {
  _eq?: InputMaybe<InfraRoutingDecision>;
  _neq?: InputMaybe<InfraRoutingDecision>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntegerComparisonInput = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
};

export type InternalDebtCollection = Operation & {
  __typename?: 'InternalDebtCollection';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: InboundShipmentStates;
  updates: Array<FlowUpdate>;
};

export type InternalDebtCollectionTerm = {
  __typename?: 'InternalDebtCollectionTerm';
  available: Scalars['Boolean'];
  availableFrom?: Maybe<Scalars['ISO8601DateTime']>;
  unavailabilityReason?: Maybe<ReturnUnavailabilityReason>;
};

export type InternalDiscount = Discount & {
  __typename?: 'InternalDiscount';
  amount: Price;
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  percentage?: Maybe<Scalars['String']>;
  reason?: Maybe<DiscountReason>;
  recurring: Scalars['Boolean'];
};

export type InternalProductsWhereInput = {
  and?: InputMaybe<Array<InternalProductsWhereInput>>;
  pricing?: InputMaybe<PricingComparatorInput>;
  resourceSkus?: InputMaybe<SkuComparatorInput>;
  storeCode?: InputMaybe<StoreCodeComparatorInput>;
};

export type InternalRentalPlan = {
  __typename?: 'InternalRentalPlan';
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  relatedProduct?: Maybe<Product>;
  resourceSku: Scalars['String'];
  storeCode: Scalars['String'];
  terms: Array<RentalPlanTerm>;
  updatedAt: Scalars['DateTime'];
};

export type InternalRentalPlanEdge = {
  __typename?: 'InternalRentalPlanEdge';
  cursor: Scalars['String'];
  node: InternalRentalPlan;
};

export type Invoice = {
  __typename?: 'Invoice';
  billingAddress: AddressInvoice;
  consolidated: Scalars['Boolean'];
  contracts?: Maybe<Array<InvoiceContract>>;
  country: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  creditNotes?: Maybe<Array<CreditNote>>;
  dueOn?: Maybe<Scalars['ISO8601DateTime']>;
  fromDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  internalUrl: Scalars['String'];
  monthlyTotal: Price;
  number: Scalars['String'];
  paymentMethod: InvoicePaymentMethod;
  paymentType: Scalars['String'];
  resourceId?: Maybe<Scalars['ID']>;
  shippingAddress: AddressInvoice;
  status: InvoiceStatus;
  toDate?: Maybe<Scalars['ISO8601DateTime']>;
  url: Scalars['String'];
  user: User;
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  nodes?: Maybe<Array<Invoice>>;
  pageInfo: PageInfo;
};

export type InvoiceContract = {
  __typename?: 'InvoiceContract';
  id: Scalars['String'];
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export enum InvoiceEventType {
  CREATED = 'CREATED',
  PERIODDATESUPDATED = 'PERIODDATESUPDATED',
  REGENERATED = 'REGENERATED',
}

export enum InvoicePaymentMethod {
  creditCard = 'creditCard',
  invoice = 'invoice',
  paypal = 'paypal',
  sepa = 'sepa',
  unknown = 'unknown',
}

export enum InvoiceStatus {
  canceled = 'canceled',
  created = 'created',
  partiallyCanceled = 'partiallyCanceled',
  unknown = 'unknown',
}

export type InvoicesFilterInput = {
  consolidated?: InputMaybe<Scalars['Boolean']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  dateCreated?: InputMaybe<Scalars['ISO8601DateTime']>;
  fromDateCreated?: InputMaybe<Scalars['ISO8601DateTime']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  paymentDue?: InputMaybe<Scalars['ISO8601DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  toDateCreated?: InputMaybe<Scalars['ISO8601DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type Item = RentalItem;

export type ItemMetaInput = {
  allowPurchase?: InputMaybe<Scalars['Boolean']>;
  allowRentalUpgrade?: InputMaybe<Scalars['Boolean']>;
  committedMonths: Scalars['Int'];
  discountAmount?: InputMaybe<Scalars['Int']>;
  groverCareCoverage?: InputMaybe<Scalars['Int']>;
  hasGroverCareDeductibles?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  rentalPlanId?: InputMaybe<Scalars['Int']>;
  variant?: InputMaybe<LineItemVariantInput>;
};

export enum JoinType {
  AND = 'AND',
  OR = 'OR',
}

export enum JunoOrderMode {
  Flex = 'Flex',
  FlexLegacy = 'FlexLegacy',
  Mix = 'Mix',
  Swap = 'Swap',
}

export type KeywordFilter = {
  __typename?: 'KeywordFilter';
  category?: Maybe<Category>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  storeCodes: Array<Scalars['String']>;
  type: ProductFilterType;
};

export type KeywordFilterOrError = KeywordFilter | ProductFilterError;

export type Kit = {
  __typename?: 'Kit';
  commonRentalPlans: Array<KitCommonRentalPlan>;
  /** Experimental: Don't use yet until Grover API deprecation. */
  commonRentalPlansV2: Array<KitCommonRentalPlan>;
  company?: Maybe<Company>;
  companyId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  kitProducts: Array<KitProduct>;
  name: Scalars['String'];
  numberOfKitProducts: Scalars['Int'];
  state: KitState;
  store?: Maybe<Store>;
  storeCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<User>;
  uuid: Scalars['ID'];
};

export type KitCommonRentalPlan = {
  __typename?: 'KitCommonRentalPlan';
  duration: Duration;
  total: Price;
};

export type KitEdge = {
  __typename?: 'KitEdge';
  cursor: Scalars['String'];
  node?: Maybe<Kit>;
};

export type KitProduct = {
  __typename?: 'KitProduct';
  createdAt: Scalars['DateTime'];
  kit: Kit;
  kitUuid: Scalars['ID'];
  product?: Maybe<Product>;
  productSku: Scalars['String'];
  quantity: Scalars['Int'];
  storeCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type KitProductInput = {
  productSku: Scalars['String'];
  quantity: Scalars['Int'];
};

export type KitSortInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  storeCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
};

export enum KitState {
  disabled = 'disabled',
  enabled = 'enabled',
}

export type KitsFilterInput = {
  joinType?: InputMaybe<JoinType>;
  predicates: Array<KitsFilterPredicateJointInput>;
};

export enum KitsFilterPredicateAttributeType {
  companyId = 'companyId',
  createdAt = 'createdAt',
  createdBy = 'createdBy',
  description = 'description',
  name = 'name',
  state = 'state',
  storeCode = 'storeCode',
  updatedBy = 'updatedBy',
}

export type KitsFilterPredicateInput = {
  attribute: KitsFilterPredicateAttributeType;
  comparison: ComparisonType;
  type: PredicateType;
  value: Scalars['String'];
};

export type KitsFilterPredicateJointInput = {
  joinType?: InputMaybe<JoinType>;
  predicates: Array<KitsFilterPredicateInput>;
};

export enum LEDGER_ACTION {
  LEDGER_ACTIONS_ALL = 'LEDGER_ACTIONS_ALL',
  LEDGER_AUTOMATIC_CHARGE = 'LEDGER_AUTOMATIC_CHARGE',
  LEDGER_AUTOMATIC_CHARGE_CONFIRM = 'LEDGER_AUTOMATIC_CHARGE_CONFIRM',
  LEDGER_MANUAL_BOOKING = 'LEDGER_MANUAL_BOOKING',
  LEDGER_PBI_CHARGE = 'LEDGER_PBI_CHARGE',
  LEDGER_PBI_CHARGE_CONFIRM = 'LEDGER_PBI_CHARGE_CONFIRM',
  LEDGER_READ_ALL = 'LEDGER_READ_ALL',
  LEDGER_READ_MOVEMENT = 'LEDGER_READ_MOVEMENT',
  LEDGER_READ_RESOURCE = 'LEDGER_READ_RESOURCE',
  LEDGER_READ_TRANSACTION = 'LEDGER_READ_TRANSACTION',
  LEDGER_REFUND = 'LEDGER_REFUND',
  LEDGER_REFUND_CONFIRM = 'LEDGER_REFUND_CONFIRM',
}

export type Languages = {
  de?: InputMaybe<Scalars['String']>;
  en?: InputMaybe<Scalars['String']>;
  es?: InputMaybe<Scalars['String']>;
  nl?: InputMaybe<Scalars['String']>;
};

export type LastCommittedPeriod = Period & {
  __typename?: 'LastCommittedPeriod';
  ends: Scalars['ISO8601DateTime'];
  number: Scalars['Int'];
  starts: Scalars['ISO8601DateTime'];
};

export type LaunchFlowActionModel = {
  __typename?: 'LaunchFlowActionModel';
  flow: Scalars['String'];
};

export enum LevelTypes {
  orderPayment = 'orderPayment',
  root = 'root',
  subscription = 'subscription',
  subscriptionPayment = 'subscriptionPayment',
}

export type LineItem = {
  __typename?: 'LineItem';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  minimumTermMonths: Scalars['Int'];
  name: Scalars['String'];
  price: Price;
  quantity: Scalars['Int'];
  total: Price;
  variant: Variant;
};

export type LineItemAddInput = {
  committedMonths: Scalars['Int'];
  discountAmount?: InputMaybe<Scalars['Int']>;
  groverCareCoverage?: InputMaybe<Scalars['Int']>;
  hasGroverCareDeductibles?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Int']>;
  rentalPlanId?: InputMaybe<Scalars['Int']>;
  variantId?: InputMaybe<Scalars['ID']>;
  variantSku: Scalars['String'];
};

export type LineItemCatalogMeta = LineItemMeta & {
  __typename?: 'LineItemCatalogMeta';
  category?: Maybe<LineItemCategory>;
  groverCare?: Maybe<GroverCare>;
  product?: Maybe<Product>;
  variant: Variant;
};

export type LineItemCategory = {
  __typename?: 'LineItemCategory';
  categoryId?: Maybe<Scalars['ID']>;
  subCategoryId?: Maybe<Scalars['ID']>;
};

export type LineItemCategoryInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  subCategoryId: Scalars['ID'];
};

export type LineItemMeta = {
  groverCare?: Maybe<GroverCare>;
  product?: Maybe<Product>;
  variant: Variant;
};

export type LineItemProduct = {
  __typename?: 'LineItemProduct';
  coreAttribute?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type LineItemProductInput = {
  sku: Scalars['String'];
};

export type LineItemUpdateInput = {
  id: Scalars['Int'];
  meta?: InputMaybe<ItemMetaInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type LineItemVariant = {
  __typename?: 'LineItemVariant';
  colorCodes?: Maybe<Array<Scalars['String']>>;
  colorTitle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  mixable?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['ID']>;
};

export type LineItemVariantInput = {
  id?: InputMaybe<Scalars['ID']>;
  sku: Scalars['String'];
};

export type LinkActionModel = {
  __typename?: 'LinkActionModel';
  path: Scalars['String'];
  text: Scalars['String'];
};

export type ListPageInfo = {
  __typename?: 'ListPageInfo';
  hasNextPage: Scalars['Boolean'];
  nextOffset: Scalars['Int'];
  total: Scalars['Int'];
};

export type LocaleContent = {
  __typename?: 'LocaleContent';
  content: Scalars['String'];
  locale: Scalars['String'];
  summary: Scalars['String'];
};

export type LocaleContentInput = {
  content: Scalars['String'];
  locale: Scalars['String'];
  summary: Scalars['String'];
};

export enum LogisticsDimensionsUnit {
  Centimeters = 'Centimeters',
  Inches = 'Inches',
}

export enum LogisticsHazmatClass {
  Class1 = 'Class1',
  Class2 = 'Class2',
  Class3 = 'Class3',
  Class4 = 'Class4',
  Class5 = 'Class5',
  Class6 = 'Class6',
  Class7 = 'Class7',
  Class8 = 'Class8',
  Class9 = 'Class9',
}

export enum LogisticsIdentifier {
  IMEI = 'IMEI',
  SerialNumber = 'SerialNumber',
  VIN = 'VIN',
}

export type LoopSimulatorModel = {
  __typename?: 'LoopSimulatorModel';
  externalSubscription: Scalars['String'];
  recurrentPaymentUuid: Scalars['String'];
};

export type LoyaltyActionResultBooking = {
  __typename?: 'LoyaltyActionResultBooking';
  amount: Scalars['Int'];
  id: Scalars['String'];
  metadata: ActionMetadata;
  status: LoyaltyActionStatus;
  type: LoyaltyActionType;
  uniqueKey: Scalars['String'];
  userId: Scalars['String'];
};

export enum LoyaltyActionStatus {
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  NEW = 'NEW',
  PROCESSED = 'PROCESSED',
}

export enum LoyaltyActionType {
  ADD = 'ADD',
  CANCEL = 'CANCEL',
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
}

export type LoyaltyAdminCreditTransactionCapture = {
  __typename?: 'LoyaltyAdminCreditTransactionCapture';
  captureId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creditTransactionId: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyAdminCreditTransactionResult = {
  __typename?: 'LoyaltyAdminCreditTransactionResult';
  action?: Maybe<LoyaltyActionResultBooking>;
  amount: Scalars['Int'];
  capture?: Maybe<LoyaltyAdminCreditTransactionCapture>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inputTransaction?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  startAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyCreditTransactionStatus;
  statusUpdatesAt?: Maybe<Scalars['DateTime']>;
  store: Scalars['String'];
  userId: Scalars['String'];
};

export type LoyaltyAdminPanelResult = {
  __typename?: 'LoyaltyAdminPanelResult';
  balance: Array<LoyaltyBalanceMultiStore>;
  rules?: Maybe<Array<LoyaltyAdminRuleResult>>;
  transactions?: Maybe<Array<LoyaltyAdminCreditTransactionResult>>;
  userId: Scalars['String'];
};

export type LoyaltyAdminPanelStatisticResult = {
  __typename?: 'LoyaltyAdminPanelStatisticResult';
  defaultMonthlyLimitForManualGrant: Scalars['Int'];
  defaultMonthlyLimitForReferral: Scalars['Int'];
  earnedCreditsAmountForMonthOnReferral: Scalars['Int'];
  spentCreditsAmountForMonth: Scalars['Int'];
  totalCreditsAmountByCountry: Array<LoyaltyTotalCreditsAmountByCountryResult>;
};

export type LoyaltyAdminRuleResult = {
  __typename?: 'LoyaltyAdminRuleResult';
  conditions?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  rule: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyBalanceMultiStore = {
  __typename?: 'LoyaltyBalanceMultiStore';
  amount: Scalars['Int'];
  store: Scalars['String'];
};

export type LoyaltyBalanceResult = {
  __typename?: 'LoyaltyBalanceResult';
  balance: Scalars['Int'];
  userId: Scalars['String'];
};

export enum LoyaltyCountry {
  AUSTRIA = 'AUSTRIA',
  GERMANY = 'GERMANY',
  NETHERLANDS = 'NETHERLANDS',
  SPAIN = 'SPAIN',
  USA = 'USA',
}

export type LoyaltyCreditTransactionCaptureResult = {
  __typename?: 'LoyaltyCreditTransactionCaptureResult';
  captureId: Scalars['String'];
  userId: Scalars['String'];
};

export type LoyaltyCreditTransactionCommitInfo = {
  amount: Scalars['Int'];
  captureId: Scalars['String'];
  paymentId?: InputMaybe<Scalars['String']>;
};

export type LoyaltyCreditTransactionResult = {
  __typename?: 'LoyaltyCreditTransactionResult';
  amount: Scalars['Int'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inputTransaction?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  startAt?: Maybe<Scalars['DateTime']>;
  status: LoyaltyCreditTransactionStatus;
  statusUpdatesAt?: Maybe<Scalars['DateTime']>;
  store: Scalars['String'];
  userId: Scalars['String'];
};

export enum LoyaltyCreditTransactionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  OVER_LIMIT = 'OVER_LIMIT',
}

export type LoyaltyFilterOptionsInfo = {
  /** Order by: [field name]: 1 | -1 */
  order?: InputMaybe<Scalars['AnyScalar']>;
  pagination: LoyaltyPaginationInfo;
  /** Where: [field name]: { _eq | _like | _lt | _lte | _gt | _gte : <value> } */
  where?: InputMaybe<Scalars['AnyScalar']>;
};

export type LoyaltyPaginationInfo = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type LoyaltyShopProductResult = {
  __typename?: 'LoyaltyShopProductResult';
  description: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['String'];
  price: Scalars['Int'];
  status: Scalars['String'];
  type: Scalars['String'];
};

export type LoyaltyShopPurchaseMetadataUnion = LoyaltyVoucherMetadata;

export type LoyaltyShopPurchaseResult = {
  __typename?: 'LoyaltyShopPurchaseResult';
  id: Scalars['String'];
  metadata: LoyaltyShopPurchaseMetadataUnion;
  price: Scalars['Int'];
  product: LoyaltyShopProductResult;
  productId: Scalars['String'];
  userId: Scalars['String'];
};

export type LoyaltyTotalCreditsAmountByCountryResult = {
  __typename?: 'LoyaltyTotalCreditsAmountByCountryResult';
  amount: Scalars['Int'];
  store: Scalars['String'];
};

export type LoyaltyUserBalanceResult = {
  __typename?: 'LoyaltyUserBalanceResult';
  balance: Scalars['Int'];
  country: LoyaltyCountry;
};

export type LoyaltyVoucherMetadata = {
  __typename?: 'LoyaltyVoucherMetadata';
  code: Scalars['String'];
  expirationDate: Scalars['DateTime'];
};

export type ManualGrantInput = {
  cashAmountInCents: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  country: LoyaltyCountry;
  performedBy?: InputMaybe<Scalars['Int']>;
  reason: ManualGrantReason;
  userId: Scalars['Int'];
};

export enum ManualGrantReason {
  CUSTOMER_SATISFACTION = 'CUSTOMER_SATISFACTION',
  DELAYED_DELIVERY = 'DELAYED_DELIVERY',
  PURCHASE_OPTION_UPSELL = 'PURCHASE_OPTION_UPSELL',
}

export type ManualPause = Operation & {
  __typename?: 'ManualPause';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: ManualPauseStates;
  updates: Array<FlowUpdate>;
};

export enum ManualPauseStates {
  COMPLETED = 'COMPLETED',
  EXTENDED = 'EXTENDED',
  STARTED = 'STARTED',
}

export type ManualPauseTerm = {
  __typename?: 'ManualPauseTerm';
  available: Scalars['Boolean'];
  extensionAvailable: Scalars['Boolean'];
  extensionUnavailabilityReason?: Maybe<PauseExtensionUnavailabilityReason>;
  unavailabilityReason?: Maybe<PauseUnavailabilityReason>;
};

export type MarketingCard = {
  __typename?: 'MarketingCard';
  buttonV2?: Maybe<HeaderLink>;
  header?: Maybe<Scalars['String']>;
  image?: Maybe<HeaderImage>;
};

export enum MarketingEntityType {
  orderPayment = 'orderPayment',
  subscription = 'subscription',
  subscriptionPayment = 'subscriptionPayment',
  user = 'user',
}

export type MatchFacet = Facet & {
  __typename?: 'MatchFacet';
  name: Scalars['String'];
  values: Array<MatchFacetValues>;
};

export type MatchFacetValues = {
  __typename?: 'MatchFacetValues';
  count: Scalars['Int'];
  value: Scalars['String'];
};

export type MetaDataInput = {
  marketingCampaignReference?: InputMaybe<Scalars['String']>;
  marketingSourceReference?: InputMaybe<Scalars['String']>;
  swapSubscriptionId?: InputMaybe<Scalars['String']>;
};

export type MetricsModel = {
  __typename?: 'MetricsModel';
  /** Number of users that dismissed the campaigns */
  dismissed: Scalars['Float'];
  /** Number of users that meet the campaign goal */
  finished: Scalars['Float'];
  /** Number of users that are a member of the campaign */
  usersInCampaign: Scalars['Float'];
};

export type Mix = BasicContract & {
  __typename?: 'Mix';
  actions?: Maybe<Array<ContractAction>>;
  activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  billingAccountId: Scalars['ID'];
  billingTerms: BillingTerms;
  cancelPurchaseTerm: CancelPurchaseTerm;
  cancelReturnTerm: CancelReturnTerm;
  countryIso?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentFlow?: Maybe<Flow>;
  currentGoods: Array<MixDevice>;
  customerWishCancelTerm?: Maybe<CustomerWishCancelTerm>;
  customerWishKeepChargingTerm?: Maybe<CustomerWishKeepChargingTerm>;
  damageClaimTerm?: Maybe<DamageClaimTerm>;
  durationTerms: DurationTerms;
  earlyOrderCancellationTerm: EarlyOrderCancellationTerm;
  endingTerm: EndingTerm;
  flow?: Maybe<Flows>;
  good: MixDevice;
  goods: Array<MixDevice>;
  groverCareTerms: GroverCareTerms;
  history: Array<HistoryItem>;
  id: Scalars['ID'];
  internalDebtCollectionTerm?: Maybe<InternalDebtCollectionTerm>;
  manualPauseTerm: ManualPauseTerm;
  order: Order;
  orderNumber: Scalars['ID'];
  pausedAt?: Maybe<Scalars['ISO8601DateTime']>;
  paymentMethod?: Maybe<PaymentMethod>;
  payments: PaymentsUnionConnection;
  replacementTerm: ReplacementTerm;
  revivalTerm: RevivalTerm;
  revocationTerms?: Maybe<RevocationTerms>;
  shipmentLabels?: Maybe<Array<ShipmentLabel>>;
  state: ContractState;
  storeCode: Scalars['String'];
  surpriseDiscountTerm: SurpriseDiscountTerm;
  swap: Swap;
  swappingTerms: SwappingTerms;
  swaps: Array<Swap>;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  terminationComment?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<ContractTerminationReason>;
  tosUpdates: Array<TosUpdate>;
  tosVersion?: Maybe<Scalars['ID']>;
  upsellTerm?: Maybe<UpsellTerm>;
  user: User;
  userId: Scalars['ID'];
};

export type MixgoodArgs = {
  id: Scalars['ID'];
};

export type MixgoodsArgs = {
  states?: InputMaybe<Array<ContractGoodState>>;
};

export type MixpaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MixswapArgs = {
  orderNumber: Scalars['ID'];
};

export type MixswapsArgs = {
  states?: InputMaybe<Array<SwapState>>;
};

export type MixCartOrder = {
  __typename?: 'MixCartOrder';
  billingAccountId?: Maybe<Scalars['ID']>;
  billingAddress?: Maybe<Address>;
  billingAddressId?: Maybe<Scalars['ID']>;
  checkoutConfig?: Maybe<CheckoutConfig>;
  completedAt?: Maybe<Scalars['DateISOString']>;
  contractId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateISOString']>;
  currency?: Maybe<Scalars['String']>;
  estimatedTax?: Maybe<Price>;
  flowType?: Maybe<FlowType>;
  groverCareTotal: Price;
  guestToken?: Maybe<Scalars['String']>;
  itemTotal: Price;
  itemTotalDiscount?: Maybe<Price>;
  items: Array<Item>;
  lineItems: Array<Maybe<MixLineItem>>;
  netTotal?: Maybe<Price>;
  offer?: Maybe<OrderOffer>;
  orderMode: OrderMode;
  orderNumber: Scalars['ID'];
  /** @deprecated paymentMethod is deprecated. Use paymentMethodExtended or paymentMethodId and billingAccountId instead. */
  paymentMethod?: Maybe<PaymentMethodStub>;
  paymentMethodExtended?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  placedAt?: Maybe<Scalars['DateISOString']>;
  requirements: Array<CartRequirement>;
  shippingAddress?: Maybe<Address>;
  shippingAddressId?: Maybe<Scalars['ID']>;
  shippingCost?: Maybe<Price>;
  step?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  submittedShippingAddress?: Maybe<Address>;
  total: Price;
  user?: Maybe<User>;
  vat?: Maybe<Scalars['String']>;
  vatTotal?: Maybe<Price>;
  voucher?: Maybe<CartVoucher>;
};

export type MixDevice = CatalogItem &
  CatalogItemVariant &
  ContractGoods & {
    __typename?: 'MixDevice';
    asset?: Maybe<AssetModel>;
    assetId?: Maybe<Scalars['String']>;
    categoryId: Scalars['ID'];
    contractId: Scalars['ID'];
    deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    product: Product;
    productSku: Scalars['ID'];
    returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
    serialNumber?: Maybe<Scalars['ID']>;
    shipmentLabel?: Maybe<ShipmentLabel>;
    state: ContractGoodState;
    subCategoryId: Scalars['ID'];
    swappable: Scalars['Boolean'];
    variant: Variant;
    variantSku: Scalars['ID'];
  };

export type MixDeviceswappableArgs = {
  withProduct: ProductVariantInput;
};

/** The connection type for MixDevice. */
export type MixDeviceConnection = {
  __typename?: 'MixDeviceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MixDeviceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MixDevice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MixDeviceEdge = {
  __typename?: 'MixDeviceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MixDevice>;
};

export type MixLineItem = OrderLineItem & {
  __typename?: 'MixLineItem';
  addons?: Maybe<Array<Maybe<CartAddon>>>;
  addonsV2: Array<CartAddonV2>;
  id: Scalars['ID'];
  meta: LineItemMeta;
  quantity?: Maybe<Scalars['Int']>;
};

export type MixLineItemInput = {
  meta?: InputMaybe<ItemMetaInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type MixSummary = {
  __typename?: 'MixSummary';
  contract: Contract;
  contractId: Scalars['ID'];
  devices: Array<MixDevice>;
  orderNumber: Scalars['ID'];
  pastDevices?: Maybe<MixDeviceConnection>;
  payments: PaymentsUnionConnection;
  state: ContractState;
  swap?: Maybe<Swap>;
};

export type MixSummarypastDevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MixSummarypaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MonthsToOwn = {
  __typename?: 'MonthsToOwn';
  availableSince: Scalars['ISO8601Date'];
  monthsRequired: Scalars['Int'];
  monthsToCoverRemaining: Scalars['Int'];
};

export enum Mood {
  angry = 'angry',
  happy = 'happy',
  inLove = 'inLove',
  neutral = 'neutral',
  sad = 'sad',
}

export type MoodOption = {
  __typename?: 'MoodOption';
  mood: Mood;
};

export type MoodOptions = {
  __typename?: 'MoodOptions';
  options: Array<MoodOption>;
};

export type MoodResult = {
  __typename?: 'MoodResult';
  angryVotes: Scalars['Int'];
  happyVotes: Scalars['Int'];
  inLoveVotes: Scalars['Int'];
  neutralVotes: Scalars['Int'];
  sadVotes: Scalars['Int'];
};

export type MoodResultSorted = {
  __typename?: 'MoodResultSorted';
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type MoodResults = {
  __typename?: 'MoodResults';
  moodResults: Array<MoodResult>;
  sortedMoodResults: Array<MoodResultSorted>;
  total: TotalResults;
};

/** TODO: fix recursive usage as explained here https://github.com/99designs/gqlgen#how-do-i-prevent-fetching-child-objects-that-might-not-be-used */
export type Movement = {
  __typename?: 'Movement';
  ID: Scalars['String'];
  context: MovementContext;
  contextReason: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  errors?: Maybe<Scalars['JSON']>;
  externalID: Scalars['String'];
  metadatas?: Maybe<Array<MovementMetadata>>;
  movementGroupingID?: Maybe<Scalars['String']>;
  movementResources: Array<MovementResource>;
  paymentMethodID?: Maybe<Scalars['String']>;
  paymentProcess: MovementPaymentProcess;
  status: MovementStatuses;
  statusDetails: Array<MovementStatusDetail>;
  statusSetAt: Scalars['ISO8601DateTime'];
  transaction?: Maybe<Transaction>;
};

export type MovementConnection = {
  __typename?: 'MovementConnection';
  edges?: Maybe<Array<MovementEdge>>;
  nodes?: Maybe<Array<Movement>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum MovementContext {
  CHARGE_BACK = 'CHARGE_BACK',
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
}

export type MovementEdge = {
  __typename?: 'MovementEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Movement>;
};

export type MovementMetadata = {
  __typename?: 'MovementMetadata';
  createdAt: Scalars['ISO8601DateTime'];
  paymentMethodType: Scalars['String'];
  paymentReference: Scalars['String'];
  paymentSource: Scalars['String'];
  providerResponse?: Maybe<Scalars['JSON']>;
};

export enum MovementPaymentProcess {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  PAY_BY_INVOICE = 'PAY_BY_INVOICE',
}

export type MovementRequestEvent = {
  __typename?: 'MovementRequestEvent';
  createdAt: Scalars['ISO8601DateTime'];
  event?: Maybe<Scalars['JSON']>;
};

export type MovementResource = {
  __typename?: 'MovementResource';
  amountToAllocate: Scalars['Float'];
  movement: Movement;
  movementID: Scalars['String'];
  resource: Resource;
  resourceID: Scalars['String'];
};

export type MovementStatusDetail = {
  __typename?: 'MovementStatusDetail';
  createdAt: Scalars['ISO8601DateTime'];
  errors?: Maybe<Scalars['JSON']>;
  status: MovementStatuses;
};

export enum MovementStatuses {
  FAILED = 'FAILED',
  NEW = 'NEW',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
}

export type Mutation = {
  __typename?: 'Mutation';
  addAccount: AccountModel;
  addAssetsToStockTransfer: StockTransferModel;
  addItemsToCart?: Maybe<MixCartOrder>;
  addLocale: TermsAndConditionsLocales;
  addPayByInvoiceAccount: AccountModel;
  /** Add a new payment method for the current user. */
  addPaymentMethod: AddPaymentMethodResult;
  addProductsToCountdownTimer: AddProductsToCountdownTimerOutput;
  addToFavorites?: Maybe<AddToFavoritesPayload>;
  addWalletAccount: AccountModel;
  allocateManually: AllocationResult;
  applyDiscount?: Maybe<ApplyDiscountPayload>;
  applyFreeYearOffer?: Maybe<ApplyFreeYearOfferPayload>;
  applyLoyaltyDiscount?: Maybe<ApplyLoyaltyDiscountPayload>;
  applySurpriseDiscount?: Maybe<ApplySurpriseDiscountPayload>;
  applyVoucher?: Maybe<MixCartOrder>;
  assignAssetsToEmployee: WorkEmployee;
  assignEmployeeToAsset: WorkAsset;
  attemptInvoiceGeneration: GenerateInvoiceResponse;
  batchCancelContracts?: Maybe<BatchCancelContractsPayload>;
  batchPauseContracts?: Maybe<BatchPauseContractsPayload>;
  batchReviveContracts?: Maybe<BatchReviveContractsPayload>;
  bigMigrationCreateRecurrentPayments: BigMigrationCreateRecurrentPaymentsPayload;
  bigMigrationRevertRecurrentPayments: BigMigrationCreateRecurrentPaymentsPayload;
  bookPBI: BookPBIPayload;
  bulkAssociateAddons: BulkAssociationAddonsResult;
  bulkB2BDashboardActivate: Array<DashboardAccessRights>;
  bulkChargeForDamage: BulkChargeForDamagedAssetsResult;
  bulkConditionUpdateAsset: Array<AssetBulkConditionUpdateResult>;
  bulkRegenerateInvoices?: Maybe<Scalars['JSON']>;
  bulkStatusUpdateAsset: Array<AssetBulkStatusUpdateResult>;
  bulkUpdateSerialNumber: Array<AssetBulkSerialNumberUpdateResult>;
  bulkWarehouseCodeUpdateAsset: Array<AssetBulkWarehouseCodeUpdateResult>;
  bumpReservationPriority: Array<Reservation>;
  businessReturnAsset: WorkAsset;
  cancelContract?: Maybe<CancelContractPayload>;
  cancelContractReturn?: Maybe<CancelContractReturnPayload>;
  cancelInboundShipmentsByContractId: CancelShipmentsResult;
  cancelOrder: OrderCancellation;
  cancelOrderContracts?: Maybe<CancelOrderContractsPayload>;
  cancelOrderEarly?: Maybe<CancelOrderEarlyPayload>;
  cancelPayments: Array<PaymentOrderActionResponseItemGeneric>;
  cancelPurchase?: Maybe<CancelPurchasePayload>;
  cancelReplacement: ReplacementModel;
  cancelSubscription?: Maybe<CancelSubscriptionPayload>;
  cancelSwap?: Maybe<CancelSwapPayload>;
  cancelWarehouseGoodsOrder: CancelGoodsOrderResponseType;
  cartAddressSet?: Maybe<MixCartOrder>;
  cartPaymentMethodSet?: Maybe<MixCartOrder>;
  castVote: Vote;
  categoriesUpdatePosition: CatalogAffectedRowsOutput;
  categoryCreate: CategoryOrError;
  categoryDelete: CategoryOrError;
  categoryUpdate: CategoryOrError;
  clearAssetEmployeeAssignment: WorkAsset;
  clearExpiredFlow?: Maybe<ClearExpiredFlowPayload>;
  completeContract?: Maybe<CompleteContractPayload>;
  confirmAutomaticCharge: ConfirmAutomaticChargePayload;
  confirmRefund: ConfirmRefundPayload;
  /** Creates a campaign action, you can add several actions for one campaign */
  createAction: UuidModel;
  createAddonAssociation: Scalars['Boolean'];
  createAddress?: Maybe<CreateAddressPayload>;
  createAddressV2?: Maybe<Array<Address>>;
  createBundle?: Maybe<BundleOrError>;
  /** Creates a campaign. Please make sure that you have a proper translations for bodyTemplate */
  createCampaign: UuidModel;
  createCheckoutAttempt?: Maybe<CreateCheckoutAttempt>;
  createCompanyAddress?: Maybe<CreateCompanyAddressPayload>;
  /** Creates a condition for a campaign, could be created before campaign or after, but in this case you have to update conditionUuid for campaign */
  createCondition: UuidModel;
  /** @deprecated It will be replaced by `createReferralConnection` */
  createConnection: Scalars['Boolean'];
  createCountdownTimer: CountdownTimer;
  /** Creates a campaign goal (Kafka event handler) */
  createGoal: UuidModel;
  createKit: Kit;
  createKitProduct: KitProduct;
  createKitWithProducts: Kit;
  /** @deprecated This mutation will be replaced with createCompany mutation - B2BP-257 */
  createLegacyCompany: Company;
  createManualBooking: CreateManualBookingPayload;
  createManyBundles: AffectedRowsOutput;
  createOrderFulfillmentComment: OrderFulfillmentComment;
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  createReferralConnection: CreateConnectionResponse;
  createReplacement: ReplacementModel;
  createResourceType: CreateResourceTypePayload;
  createS3PresignedUrl: PresignedUrlCreateResult;
  createSalesOrder: SalesOrderResponseType;
  createShipmentLifecycleEvent?: Maybe<ShipmentLifecycleEvent>;
  createShippingAddress: ShippingAddress;
  createStockTransfer: StockTransferModel;
  createTermsAndConditions: TermsAndConditionsLocales;
  /** Creates a translation */
  createTranslate: UuidModel;
  createUserConsents?: Maybe<CreateUserConsentsPayload>;
  createWaitingListTicket?: Maybe<CreateWaitingListTicketPayload>;
  createWorkEmployee: WorkEmployee;
  /** Update Company mutation to be used by customers. UST is only updated when its current value is blank */
  customerUpdateCompany?: Maybe<CustomerUpdateCompanyPayload>;
  /** Deletes an action */
  deleteAction: DeleteModel;
  deleteAddresses?: Maybe<DeleteAddressesPayload>;
  deleteAuthDevice?: Maybe<DeleteAuthDevicePayload>;
  deleteAvailabilityCampaign: AvailabilityCampaign;
  deleteBundle?: Maybe<Bundle>;
  /** Deletes a campaign */
  deleteCampaign: DeleteModel;
  /** Deletes a condition */
  deleteCondition: DeleteModel;
  deleteDismiss: DeleteModel;
  /** Deletes a campaign dismiss for selected entity */
  deleteDismissCampaign: DeleteModel;
  deleteDistributionRule: Array<DistributionRule>;
  deleteFinish: DeleteModel;
  /** Deletes a finished campaign for selected entity */
  deleteFinishCampaign: DeleteModel;
  /** Deletes a campaign goal (Kafka event handler) */
  deleteGoal: DeleteModel;
  deleteKit: DeletedKit;
  deleteKitProduct: KitProduct;
  deleteLocale: TermsAndConditionsLocales;
  deleteManyBundles: AffectedRowsOutput;
  deleteOnfido?: Maybe<DeleteOnfidoPayload>;
  deleteOrderFulfillmentComment: Scalars['Boolean'];
  deletePaymentMethod: DeletePaymentMethodResult;
  deleteProductsFromCountdownTimer: DeleteProductFromCountdownTimerOutput;
  deleteTermsAndConditions: TermsAndConditionsLocales;
  /** Deletes a translation */
  deleteTranslate: DeleteModel;
  deleteUser?: Maybe<DeleteUserPayload>;
  dismiss: DismissModel;
  /** Dismiss a campaign for selected entity */
  dismissCampaign: DismissModel;
  dropUserPhoneNumber?: Maybe<DropUserPhoneNumberPayload>;
  enableCompanyPayByInvoice?: Maybe<EnableInvoicePaymentsPayload>;
  energyLabelCreate: EnergyLabelOrError;
  energyLabelDelete?: Maybe<EnergyLabelOrError>;
  energyLabelUpdate?: Maybe<EnergyLabelOrError>;
  energyLabelsUpsertMany: CatalogAffectedRowsOutput;
  executeLoopRakeTask?: Maybe<ExecuteLoopRakeTaskPayload>;
  executeRakeTask?: Maybe<ExecuteRakeTaskPayload>;
  exportShipments: ExportMutationResult;
  extendContract?: Maybe<ExtendContractPayload>;
  extendContractPause?: Maybe<ExtendContractPausePayload>;
  forceApproveCompany?: Maybe<ForceApprovePayload>;
  forceCompanyApproval: Company;
  forcePasswordReset?: Maybe<ForcePasswordResetPayload>;
  freelancerPersonalIDMutation?: Maybe<Scalars['Boolean']>;
  generateDisablePurchaseVoucher?: Maybe<GenerateDisablePurchaseVoucherPayload>;
  generateReturnLabels: GenerateReturnLabelsResult;
  getVotingTicket: Ticket;
  imageUpload: ImageUploadResult;
  importEmployeesFromS3: ImportEmployeesResponse;
  importEmployeesPresignedUrl: WorkS3Presignedurl;
  loyaltyCaptureAmount: LoyaltyCreditTransactionCaptureResult;
  loyaltyCommitCapturedTransactions: LoyaltyCreditTransactionCaptureResult;
  loyaltyGrantCash: LoyaltyActionResultBooking;
  loyaltyReleaseCapturedTransactions: LoyaltyCreditTransactionCaptureResult;
  loyaltyShopPurchase: LoyaltyShopPurchaseResult;
  markStockTransferAsCompleted: StockTransferModel;
  orderAddonAddToLineItem?: Maybe<MixCartOrder>;
  orderAddonRemoveFromLineItem?: Maybe<MixCartOrder>;
  /** @deprecated orderAddressAdd is deprecated. Use cartAddressSet instead. */
  orderAddressAdd?: Maybe<MixCartOrder>;
  /** @deprecated orderAddressChange is deprecated. Use cartAddressSet instead. */
  orderAddressChange?: Maybe<MixCartOrder>;
  orderCreate?: Maybe<MixCartOrder>;
  orderLineItemAdd?: Maybe<MixCartOrder>;
  orderLineItemEdit?: Maybe<MixCartOrder>;
  orderLineItemRemove?: Maybe<MixCartOrder>;
  orderLineItemsRemove?: Maybe<MixCartOrder>;
  orderOfferAccept?: Maybe<OrderOffer>;
  orderOfferCreate?: Maybe<OrderOffer>;
  orderOfferLineItemEdit?: Maybe<MixCartOrder>;
  orderOfferReject?: Maybe<OrderOffer>;
  orderOfferUpdate?: Maybe<OrderOffer>;
  orderOffersDelete?: Maybe<Array<Maybe<OrderOffer>>>;
  /** @deprecated orderPaymentMethodChange is deprecated. Use cartPaymentMethodSet instead. */
  orderPaymentMethodChange?: Maybe<MixCartOrder>;
  orderSubmit?: Maybe<MixCartOrder>;
  parseXlsx: ParseXlsxResult;
  pauseContract?: Maybe<PauseContractPayload>;
  privacyCaseManualRequest: PrivacyManualRequestModel;
  productFilterKeywordCreate: KeywordFilterOrError;
  productFilterKeywordDelete: KeywordFilterOrError;
  productFilterKeywordUpdate: KeywordFilterOrError;
  productFilterRangeCreate: RangeFilterOrError;
  productFilterRangeDelete: RangeFilterOrError;
  productFilterRangeUpdate: RangeFilterOrError;
  productsWidgetCreate: ProductsWidgetOrError;
  productsWidgetDelete?: Maybe<ProductsWidgetOrError>;
  productsWidgetUpdate?: Maybe<ProductsWidgetOrError>;
  providerLogin?: Maybe<ProviderLoginPayload>;
  publishOrderAllocatedEvent: PublishOrderAllocatedResult;
  publishTermsAndConditions: TermsAndConditionsLocales;
  putOnHoldPayments: Array<PaymentOrderActionResponseItemGeneric>;
  reactivateContract?: Maybe<ReactivateContractPayload>;
  reallocateAsset?: Maybe<ReallocateAssetPayload>;
  refundPayments: Array<PaymentOrderActionResponseItem>;
  regenerateInvoice?: Maybe<Scalars['JSON']>;
  registerIncomingAssets: RegisterIncomingAssetsResult;
  removeAddonAssociations: RemoveAddonAssociationsResult;
  removeAssetsFromStockTransfer: StockTransferModel;
  /** Receives a list of images and return a list of images with the background removed in pairs of old image URL and new image URL. */
  removeBackgroundBatch: Array<RemoveBackgroundBatchResult>;
  removeDiscount?: Maybe<RemoveDiscountPayload>;
  removeFromFavorites?: Maybe<RemoveFromFavoritesPayload>;
  removeSKUFromStockTransfer: StockTransferModel;
  removeWorkAsset: WorkAsset;
  removeWorkEmployee: WorkEmployee;
  rentalPlanDeleteMany: CatalogAffectedRowsOutput;
  rentalPlanImport: CatalogAffectedRowsOutput;
  rentalPlanParseXlsx: RentalPlanXlsxResultOrError;
  /** Update payment method's contracts and subscriptions to a different payment method,  delete the old method if needed. */
  replacePaymentMethod: ReplacePaymentMethodResult;
  reportContractAsFound?: Maybe<ReportContractAsFoundPayload>;
  reportContractAsStolen?: Maybe<ReportContractAsStolenPayload>;
  repushGoodsOrder: RepushGoodsOrderResponseType;
  requestAutomaticCharge: RequestAutomaticChargePayload;
  requestBPICharge: RequestBPIChargePayload;
  requestRefund: RequestRefundPayload;
  resetVerification?: Maybe<ResetVerificationPayload>;
  resetVoucher?: Maybe<MixCartOrder>;
  retailEmployeeValidate?: Maybe<EmployeeValidateResult>;
  retryLegacyCareEvent: Scalars['Boolean'];
  retryPayments: Array<PaymentOrderActionResponseItem>;
  retrySchedules: BillingRetryOperation;
  reuploadStockTransfer: StockTransferModel;
  revertDisablePurchaseVoucher?: Maybe<RevertDisablePurchaseVoucherPayload>;
  revertDiscountOffer?: Maybe<RevertDiscountOfferPayload>;
  reviveContract?: Maybe<ReviveContractPayload>;
  reviveSubscription?: Maybe<ReviveSubscriptionPayload>;
  saveUserPersonalIdentification?: Maybe<Scalars['Boolean']>;
  /** You can set condition after a campaign creation. This is useful if you what to update campaign conditions */
  setConditionUuid: CampaignModel;
  /** Set payment method for a contract/subscription. */
  setPaymentMethod: SetPaymentMethodResult;
  startPurchase?: Maybe<StartPurchasePayload>;
  startReallocation: AssetAllocation;
  startReplacement?: Maybe<StartReplacementPayload>;
  submitBankAccountSnapshotData?: Maybe<SubmitBankAccountSnapshotDataPayload>;
  /** @deprecated Use submit_bank_account_snapshot_data instead */
  submitKontomatikSession?: Maybe<SubmitKontomatikSessionPayload>;
  submitVerificationDecision?: Maybe<SubmitVerificationDecisionPayload>;
  /** Mutation owned by Acquisition Pillar, defined in company-service */
  syncCompanyLead: SalesforceLead;
  syncContractsAsset?: Maybe<SyncContractsAssetPayload>;
  syncProductVariants?: Maybe<SyncProductVariantsPayload>;
  syncReceeve?: Maybe<Scalars['String']>;
  syncShipment: SyncShipmentResultType;
  syncSubscriptionStatus?: Maybe<SyncSubscriptionStatusPayload>;
  syncUserPaymentMethods?: Maybe<SyncUserPaymentMethodsPayload>;
  syncVerificationState?: Maybe<SyncVerificationStatePayload>;
  taskCancel: Task;
  taskCreateProductsUpload: TaskProductsUploadResult;
  taskCreateRentalPlanUpload: TaskRentalPlanUploadResult;
  taskExportBundles: TaskBundlesExportResult;
  taskExportRentalPlans: TaskRentalPlanExportResult;
  toggleB2BDashboardAccess: DashboardAccessRights;
  toggleKitState: Kit;
  unlockUser?: Maybe<UnlockUserPayload>;
  updateAddress?: Maybe<UpdateAddressPayload>;
  updateAgreedPurchasePrice?: Maybe<UpdateAgreedPurchasePricePayload>;
  updateAssociationAddons: AddonsMainProduct;
  updateBundle?: Maybe<BundleOrError>;
  /** All campaigns are created with PAUSED status by default, you can change the status to ACTIVE and opposite any time */
  updateCampaignStatus: CampaignModel;
  updateCompany?: Maybe<UpdateCompanyPayload>;
  updateCompanyAddress?: Maybe<CreateCompanyAddressPayload>;
  /**
   * Mutation owned by Acquisition Pillar, defined in company-service
   * @deprecated This mutation will be replaced with updateCompany mutation - B2BP-257
   */
  updateCompanyStatus: Company;
  updateConsolidatedBillingDay?: Maybe<Scalars['Float']>;
  updateContractTermsAndConditions?: Maybe<UpdateContractTermsAndConditionsPayload>;
  updateCountdownTimer: CountdownTimer;
  updateCustomPurchasePriceMarkup?: Maybe<UpdateCustomPurchasePriceMarkupPayload>;
  updateDaysToDecide: TermsAndConditionsLocales;
  updateDisabledFeatures: TermsAndConditionsLocales;
  updateKit: Kit;
  /** @deprecated This mutation will be replaced with updateCompany mutation - B2BP-257 */
  updateLegacyCompany: Company;
  updateLocale: TermsAndConditionsLocales;
  /** Updates status of an enrich mapping */
  updateNotificationEnrichMapping: NotificationEnrichMapping;
  /** Updates a notification trigger filter */
  updateNotificationTriggerFilter: NotificationTriggerFilter;
  updateOrderFulfillmentComment: OrderFulfillmentComment;
  /** @deprecated Consider using mutation setPaymentMethod. */
  updatePaymentMethodForContract: UpdatePaymentMethodForContractPayload;
  updateProduct?: Maybe<UpdateProductPayload>;
  updateProductLogistics: UpdateProductLogisticsSuccessOrError;
  updateProductSpecifications: UpdateProductSpecificationsSuccessOrError;
  updateSerialNumber: AssetModel;
  updateStoreCodes: TermsAndConditionsLocales;
  updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  updateSubscriptionTermsAndConditions?: Maybe<UpdateSubscriptionTermsAndConditionsPayload>;
  updateTaxRateRegenerateInvoices?: Maybe<Scalars['JSON']>;
  updateTrackingInformation: UpdateTrackingInformationResultType;
  /** Updates a translation */
  updateTranslate: UpdateModel;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserNewsletterOptInState?: Maybe<UpdateUserNewsletterOptInStatePayload>;
  updateUserTeams?: Maybe<UpdateUserTeamsPayload>;
  updateWorkAsset: WorkAsset;
  updateWorkEmployee: WorkEmployee;
  upsertAvailabilityCampaign: AvailabilityCampaign;
  upsertCatalogGrouping: CatalogGroupingUpsertSuccessOrError;
  upsertDefaultShippingAddress: DefaultShippingAddress;
  upsertDistributionRule: DistributionRule;
  upsertManyBundles: AffectedRowsOutput;
  upsertProfile: DeliveryProfile;
  upsertRule: DeliveryRuleOperationResult;
  /** Prepare a payment method to be used for the given order at checkout. May trigger authorization if it deems Auth & Capture flow applicable. */
  usePaymentMethod: UsePaymentMethodResult;
  useSurpriseDiscount?: Maybe<UseSurpriseDiscountPayload>;
};

export type MutationaddAccountArgs = {
  options: AddAccountInput;
};

export type MutationaddAssetsToStockTransferArgs = {
  refId: Scalars['String'];
  serialNumbers: Array<Scalars['String']>;
};

export type MutationaddItemsToCartArgs = {
  items: Array<LineItemAddInput>;
  orderNumber: Scalars['ID'];
};

export type MutationaddLocaleArgs = {
  input: AddLocaleInput;
};

export type MutationaddPayByInvoiceAccountArgs = {
  options: AddPayByInvoiceAccountInput;
};

export type MutationaddPaymentMethodArgs = {
  input: AddPaymentMethodInput;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationaddProductsToCountdownTimerArgs = {
  addProductsToCountdownTimerInput: AddProductsToCountdownTimerInput;
};

export type MutationaddToFavoritesArgs = {
  input: AddToFavoritesInput;
};

export type MutationaddWalletAccountArgs = {
  options: AddWalletAccountInput;
};

export type MutationallocateManuallyArgs = {
  assetUid: Scalars['String'];
  reservationUid: Scalars['String'];
};

export type MutationapplyDiscountArgs = {
  input: ApplyDiscountInput;
};

export type MutationapplyFreeYearOfferArgs = {
  input: ApplyFreeYearOfferInput;
};

export type MutationapplyLoyaltyDiscountArgs = {
  input: ApplyLoyaltyDiscountInput;
};

export type MutationapplySurpriseDiscountArgs = {
  input: ApplySurpriseDiscountInput;
};

export type MutationapplyVoucherArgs = {
  orderNumber?: InputMaybe<Scalars['ID']>;
  voucher?: InputMaybe<VoucherRedemptionInput>;
};

export type MutationassignAssetsToEmployeeArgs = {
  assetUuids: Array<Scalars['String']>;
  employeeUuid: Scalars['String'];
};

export type MutationassignEmployeeToAssetArgs = {
  assetUuid: Scalars['String'];
  employeeUuid: Scalars['String'];
};

export type MutationattemptInvoiceGenerationArgs = {
  ignoreTotals?: InputMaybe<Scalars['Boolean']>;
  paymentGroupId: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationbatchCancelContractsArgs = {
  input: BatchCancelContractsInput;
};

export type MutationbatchPauseContractsArgs = {
  input: BatchPauseContractsInput;
};

export type MutationbatchReviveContractsArgs = {
  input: BatchReviveContractsInput;
};

export type MutationbigMigrationCreateRecurrentPaymentsArgs = {
  input: BigMigrationCreateRecurrentPaymentsInput;
};

export type MutationbigMigrationRevertRecurrentPaymentsArgs = {
  input: BigMigrationRevertRecurrentPaymentsInput;
};

export type MutationbookPBIArgs = {
  input: BookPBIInput;
};

export type MutationbulkAssociateAddonsArgs = {
  input: BulkAssociationAddonsInput;
};

export type MutationbulkB2BDashboardActivateArgs = {
  bulkB2BDashboardAccessInput: BulkDashboardAccessInput;
};

export type MutationbulkChargeForDamageArgs = {
  data: BulkChargeForDamagedAssetsInput;
};

export type MutationbulkConditionUpdateAssetArgs = {
  update: Array<AssetBulkConditionUpdateInput>;
};

export type MutationbulkRegenerateInvoicesArgs = {
  autoRegenerateFields?: InputMaybe<AutoRegenerateFields>;
  fields: Scalars['JSON'];
  invoiceIdsList: Array<Scalars['String']>;
  useLegacyNumber?: InputMaybe<Scalars['Boolean']>;
};

export type MutationbulkStatusUpdateAssetArgs = {
  skipStatusCheck?: InputMaybe<Scalars['Boolean']>;
  update: Array<AssetBulkUpdateInput>;
};

export type MutationbulkUpdateSerialNumberArgs = {
  update: AssetBulkSerialNumberUpdateInput;
};

export type MutationbulkWarehouseCodeUpdateAssetArgs = {
  update: Array<AssetBulkWarehouseCodeUpdateInput>;
};

export type MutationbumpReservationPriorityArgs = {
  orderNumber?: InputMaybe<Scalars['String']>;
  priority: Scalars['Float'];
  uid?: InputMaybe<Scalars['String']>;
};

export type MutationbusinessReturnAssetArgs = {
  input: ReturnAssetInput;
};

export type MutationcancelContractArgs = {
  input: CancelContractInput;
};

export type MutationcancelContractReturnArgs = {
  input: CancelContractReturnInput;
};

export type MutationcancelInboundShipmentsByContractIdArgs = {
  contractId: Scalars['String'];
};

export type MutationcancelOrderArgs = {
  input: OrderCancellationInput;
};

export type MutationcancelOrderContractsArgs = {
  input: CancelOrderContractsInput;
};

export type MutationcancelOrderEarlyArgs = {
  input: CancelOrderEarlyInput;
};

export type MutationcancelPaymentsArgs = {
  payments: Array<PaymentOrdersRequest>;
};

export type MutationcancelPurchaseArgs = {
  input: CancelPurchaseInput;
};

export type MutationcancelReplacementArgs = {
  replacementUid: Scalars['String'];
};

export type MutationcancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};

export type MutationcancelSwapArgs = {
  input: CancelSwapInput;
};

export type MutationcancelWarehouseGoodsOrderArgs = {
  goodsOrderUuid: Scalars['String'];
};

export type MutationcartAddressSetArgs = {
  input: CartAddressSetInput;
  orderNumber: Scalars['ID'];
};

export type MutationcartPaymentMethodSetArgs = {
  input: CartPaymentMethodSetInput;
  orderNumber: Scalars['ID'];
};

export type MutationcastVoteArgs = {
  comment?: InputMaybe<Scalars['String']>;
  ticket: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
  voteSlug: Scalars['String'];
};

export type MutationcategoriesUpdatePositionArgs = {
  data: Array<CategoryPositionUpdateInput>;
};

export type MutationcategoryCreateArgs = {
  data: CategoryCreateInput;
};

export type MutationcategoryDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationcategoryUpdateArgs = {
  data: CategoryUpdateInput;
  id: Scalars['ID'];
};

export type MutationclearAssetEmployeeAssignmentArgs = {
  assetUuid: Scalars['String'];
};

export type MutationclearExpiredFlowArgs = {
  input: ClearExpiredFlowInput;
};

export type MutationcompleteContractArgs = {
  input: CompleteContractInput;
};

export type MutationconfirmAutomaticChargeArgs = {
  input: ConfirmAutomaticChargeInput;
};

export type MutationconfirmRefundArgs = {
  input: ConfirmRefundInput;
};

export type MutationcreateActionArgs = {
  action: ActionInputType;
};

export type MutationcreateAddonAssociationArgs = {
  input: CreateAddonAssociationInput;
};

export type MutationcreateAddressArgs = {
  input: CreateAddressInput;
};

export type MutationcreateAddressV2Args = {
  input: CreateAddressInputV2;
};

export type MutationcreateBundleArgs = {
  data: BundleCreateInput;
};

export type MutationcreateCampaignArgs = {
  campaign: CampaignInputType;
};

export type MutationcreateCheckoutAttemptArgs = {
  orderNumber?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
};

export type MutationcreateCompanyAddressArgs = {
  input: CreateCompanyAddressInput;
};

export type MutationcreateConditionArgs = {
  condition: ConditionInputType;
};

export type MutationcreateConnectionArgs = {
  referralCode: Scalars['String'];
};

export type MutationcreateCountdownTimerArgs = {
  countdownTimerCreateInput: CountdownTimerCreateInput;
};

export type MutationcreateGoalArgs = {
  goal: GoalInputType;
};

export type MutationcreateKitArgs = {
  createKitInput: CreateKitInput;
};

export type MutationcreateKitProductArgs = {
  createKitProductInput: CreateKitProductInput;
};

export type MutationcreateKitWithProductsArgs = {
  createKitWithProductsInput: CreateKitWithProductsInput;
};

export type MutationcreateLegacyCompanyArgs = {
  input: CreateLegacyCompanyInput;
};

export type MutationcreateManualBookingArgs = {
  input: CreateManualBookingInput;
};

export type MutationcreateManyBundlesArgs = {
  data: BundleBulkCreateInput;
};

export type MutationcreateOrderFulfillmentCommentArgs = {
  comment: CreateOrderFulfillmentCommentInput;
};

export type MutationcreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};

export type MutationcreateReferralConnectionArgs = {
  referralCode: Scalars['String'];
};

export type MutationcreateReplacementArgs = {
  allocationUid: Scalars['String'];
  outboundShippingAddressUid?: InputMaybe<Scalars['String']>;
  reason: ReplacementReason;
  returnShipment?: InputMaybe<ReturnShipmentInput>;
  returnShippingAddressUid?: InputMaybe<Scalars['String']>;
};

export type MutationcreateResourceTypeArgs = {
  input: CreateResourceTypeInput;
};

export type MutationcreateS3PresignedUrlArgs = {
  input: PresignedUrlCreateInput;
};

export type MutationcreateSalesOrderArgs = {
  salesOrder: SalesOrderRequestType;
};

export type MutationcreateShipmentLifecycleEventArgs = {
  event: ShipmentLifecycleEventInput;
};

export type MutationcreateShippingAddressArgs = {
  address: ShippingAddressInputType;
  orderNumber: Scalars['String'];
  setAsDefault?: InputMaybe<Scalars['Boolean']>;
};

export type MutationcreateStockTransferArgs = {
  newWarehouseCode: WarehouseCode;
  oldWarehouseCode: WarehouseCode;
  refId: Scalars['String'];
  serialNumbers: Array<Scalars['String']>;
};

export type MutationcreateTermsAndConditionsArgs = {
  input: CreateTermsAndConditionsInput;
};

export type MutationcreateTranslateArgs = {
  translate: TranslateInputType;
};

export type MutationcreateUserConsentsArgs = {
  input: CreateUserConsentsInput;
};

export type MutationcreateWaitingListTicketArgs = {
  input: CreateWaitingListTicketInput;
};

export type MutationcreateWorkEmployeeArgs = {
  createEmployeeInput: CreateEmployeeInput;
};

export type MutationcustomerUpdateCompanyArgs = {
  input: CustomerUpdateCompanyInput;
};

export type MutationdeleteActionArgs = {
  uuid: Scalars['String'];
};

export type MutationdeleteAddressesArgs = {
  input: DeleteAddressesInput;
};

export type MutationdeleteAuthDeviceArgs = {
  input: DeleteAuthDeviceInput;
};

export type MutationdeleteAvailabilityCampaignArgs = {
  AvailabilityCampaign: AvailabilityCampaignFilterInput;
};

export type MutationdeleteBundleArgs = {
  where: BundleWhereUniqueInput;
};

export type MutationdeleteCampaignArgs = {
  uuid: Scalars['String'];
};

export type MutationdeleteConditionArgs = {
  uuid: Scalars['String'];
};

export type MutationdeleteDismissArgs = {
  campaignUuid: Scalars['String'];
  externalCustomer: Scalars['String'];
};

export type MutationdeleteDismissCampaignArgs = {
  campaignUuid: Scalars['String'];
  entity: Scalars['String'];
  externalEntity: Scalars['String'];
};

export type MutationdeleteDistributionRuleArgs = {
  uid: Scalars['String'];
};

export type MutationdeleteFinishArgs = {
  campaignUuid: Scalars['String'];
  externalCustomer: Scalars['String'];
};

export type MutationdeleteFinishCampaignArgs = {
  campaignUuid: Scalars['String'];
  entity: Scalars['String'];
  externalEntity: Scalars['String'];
};

export type MutationdeleteGoalArgs = {
  uuid: Scalars['String'];
};

export type MutationdeleteKitArgs = {
  uuid: Scalars['String'];
};

export type MutationdeleteKitProductArgs = {
  uuid: Scalars['String'];
};

export type MutationdeleteLocaleArgs = {
  input: DeleteLocaleInput;
};

export type MutationdeleteManyBundlesArgs = {
  where?: InputMaybe<BundleWhereInput>;
};

export type MutationdeleteOnfidoArgs = {
  input: DeleteOnfidoInput;
};

export type MutationdeleteOrderFulfillmentCommentArgs = {
  comment: DeleteOrderFulfillmentCommentInput;
};

export type MutationdeletePaymentMethodArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationdeleteProductsFromCountdownTimerArgs = {
  countdownTimerId: Scalars['ID'];
  productSku: Scalars['String'];
};

export type MutationdeleteTermsAndConditionsArgs = {
  version: Scalars['ID'];
};

export type MutationdeleteTranslateArgs = {
  uuid: Scalars['String'];
};

export type MutationdeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationdismissArgs = {
  campaignUuid: Scalars['String'];
};

export type MutationdismissCampaignArgs = {
  campaignUuid: Scalars['String'];
  entity: Scalars['String'];
  externalEntity: Scalars['String'];
};

export type MutationdropUserPhoneNumberArgs = {
  input: DropUserPhoneNumberInput;
};

export type MutationenableCompanyPayByInvoiceArgs = {
  input: EnableInvoicePaymentsInput;
};

export type MutationenergyLabelCreateArgs = {
  data: EnergyLabelCreateInput;
};

export type MutationenergyLabelDeleteArgs = {
  id: Scalars['Int'];
};

export type MutationenergyLabelUpdateArgs = {
  data: EnergyLabelUpdateInput;
  id: Scalars['Int'];
};

export type MutationenergyLabelsUpsertManyArgs = {
  data: EnergyLabelBulkCreateInput;
};

export type MutationexecuteLoopRakeTaskArgs = {
  input: ExecuteLoopRakeTaskInput;
};

export type MutationexecuteRakeTaskArgs = {
  input: ExecuteRakeTaskInput;
};

export type MutationexportShipmentsArgs = {
  filter?: InputMaybe<Array<ShipmentFilterType>>;
};

export type MutationextendContractArgs = {
  input: ExtendContractInput;
};

export type MutationextendContractPauseArgs = {
  input: ExtendContractPauseInput;
};

export type MutationforceApproveCompanyArgs = {
  input: ForceApproveInput;
};

export type MutationforceCompanyApprovalArgs = {
  input: ForceApproveCompanyInput;
};

export type MutationforcePasswordResetArgs = {
  input: ForcePasswordResetInput;
};

export type MutationfreelancerPersonalIDMutationArgs = {
  data: FreelancerPersonalIDInput;
};

export type MutationgenerateDisablePurchaseVoucherArgs = {
  input: GenerateDisablePurchaseVoucherInput;
};

export type MutationgenerateReturnLabelsArgs = {
  packageArrangements: Array<PackageArrangementType>;
  returnReason: Scalars['String'];
};

export type MutationgetVotingTicketArgs = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<EntityType>;
  meta?: InputMaybe<Scalars['JSON']>;
  slug: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type MutationimageUploadArgs = {
  cloudinaryPublicId?: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
  folderName?: InputMaybe<ImageFolders>;
};

export type MutationimportEmployeesFromS3Args = {
  options: ImportEmployeesFromS3;
};

export type MutationloyaltyCaptureAmountArgs = {
  amount: Scalars['Int'];
};

export type MutationloyaltyCommitCapturedTransactionsArgs = {
  commitInfo: LoyaltyCreditTransactionCommitInfo;
};

export type MutationloyaltyGrantCashArgs = {
  input: ManualGrantInput;
};

export type MutationloyaltyReleaseCapturedTransactionsArgs = {
  captureId: Scalars['String'];
};

export type MutationloyaltyShopPurchaseArgs = {
  productId: Scalars['String'];
};

export type MutationmarkStockTransferAsCompletedArgs = {
  refId: Scalars['String'];
};

export type MutationorderAddonAddToLineItemArgs = {
  addonId: Scalars['ID'];
  itemId: Scalars['ID'];
  orderNumber: Scalars['ID'];
  selectedAddonVariantId?: InputMaybe<Scalars['String']>;
};

export type MutationorderAddonRemoveFromLineItemArgs = {
  addonId: Scalars['ID'];
  itemId: Scalars['ID'];
  orderNumber: Scalars['ID'];
};

export type MutationorderAddressAddArgs = {
  input?: InputMaybe<CartOrderUpdateInput>;
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type MutationorderAddressChangeArgs = {
  input?: InputMaybe<CartOrderUpdateInput>;
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type MutationorderCreateArgs = {
  order?: InputMaybe<CartOrderCreateInput>;
};

export type MutationorderLineItemAddArgs = {
  item?: InputMaybe<MixLineItemInput>;
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type MutationorderLineItemEditArgs = {
  item: LineItemUpdateInput;
  orderNumber: Scalars['ID'];
};

export type MutationorderLineItemRemoveArgs = {
  itemId?: InputMaybe<Scalars['Int']>;
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type MutationorderLineItemsRemoveArgs = {
  itemIds: Array<Scalars['Int']>;
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type MutationorderOfferAcceptArgs = {
  offerAccept?: InputMaybe<OrderOfferAcceptInput>;
};

export type MutationorderOfferCreateArgs = {
  offer?: InputMaybe<OrderOfferCreateInput>;
};

export type MutationorderOfferLineItemEditArgs = {
  item: OfferLineItemUpdateInput;
  orderNumber: Scalars['ID'];
};

export type MutationorderOfferRejectArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type MutationorderOfferUpdateArgs = {
  offerUpdate?: InputMaybe<OrderOfferUpdateInput>;
};

export type MutationorderOffersDeleteArgs = {
  ids: Array<Scalars['Int']>;
};

export type MutationorderPaymentMethodChangeArgs = {
  input?: InputMaybe<CartOrderUpdateInput>;
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type MutationorderSubmitArgs = {
  input?: InputMaybe<CartOrderSubmitInput>;
  orderNumber?: InputMaybe<Scalars['ID']>;
  twoFACode?: InputMaybe<Scalars['Int']>;
};

export type MutationparseXlsxArgs = {
  input: ParseXlsxInput;
};

export type MutationpauseContractArgs = {
  input: PauseContractInput;
};

export type MutationprivacyCaseManualRequestArgs = {
  gdprRequest: PrivacyGDPRRequest;
  userId: Scalars['String'];
};

export type MutationproductFilterKeywordCreateArgs = {
  data: ProductFilterKeywordCreateInput;
};

export type MutationproductFilterKeywordDeleteArgs = {
  id: Scalars['Int'];
};

export type MutationproductFilterKeywordUpdateArgs = {
  data: ProductFilterKeywordUpdateInput;
  id: Scalars['Int'];
};

export type MutationproductFilterRangeCreateArgs = {
  data: ProductFilterRangeCreateInput;
};

export type MutationproductFilterRangeDeleteArgs = {
  id: Scalars['Int'];
};

export type MutationproductFilterRangeUpdateArgs = {
  data: ProductFilterRangeUpdateInput;
  id: Scalars['Int'];
};

export type MutationproductsWidgetCreateArgs = {
  data: ProductsWidgetCreateInput;
};

export type MutationproductsWidgetDeleteArgs = {
  id: Scalars['Int'];
};

export type MutationproductsWidgetUpdateArgs = {
  data: ProductsWidgetUpdateInput;
  id: Scalars['Int'];
};

export type MutationproviderLoginArgs = {
  input: ProviderLoginInput;
};

export type MutationpublishOrderAllocatedEventArgs = {
  orderNumber: Scalars['String'];
};

export type MutationpublishTermsAndConditionsArgs = {
  version: Scalars['ID'];
};

export type MutationputOnHoldPaymentsArgs = {
  payments: Array<PaymentOrdersRequest>;
};

export type MutationreactivateContractArgs = {
  input: ReactivateContractInput;
};

export type MutationreallocateAssetArgs = {
  input: ReallocateAssetInput;
};

export type MutationrefundPaymentsArgs = {
  payments: Array<PaymentOrderRefundRequest>;
};

export type MutationregenerateInvoiceArgs = {
  invoiceData: Scalars['JSON'];
  invoiceId: Scalars['String'];
  useLegacyNumber?: InputMaybe<Scalars['Boolean']>;
};

export type MutationremoveAddonAssociationsArgs = {
  input: RemoveAddonAssociationsInput;
};

export type MutationremoveAssetsFromStockTransferArgs = {
  refId: Scalars['String'];
  serialNumbers: Array<Scalars['String']>;
};

export type MutationremoveBackgroundBatchArgs = {
  urls: Array<Scalars['String']>;
};

export type MutationremoveDiscountArgs = {
  input: RemoveDiscountInput;
};

export type MutationremoveFromFavoritesArgs = {
  input: RemoveFromFavoritesInput;
};

export type MutationremoveSKUFromStockTransferArgs = {
  refId: Scalars['String'];
  skuVariantCodes: Array<Scalars['String']>;
};

export type MutationremoveWorkAssetArgs = {
  uuid: Scalars['String'];
};

export type MutationremoveWorkEmployeeArgs = {
  uuid: Scalars['String'];
};

export type MutationrentalPlanDeleteManyArgs = {
  where: Array<RentalPlanDeleteWhereInput>;
};

export type MutationrentalPlanImportArgs = {
  input: RentalPlanImportInput;
};

export type MutationrentalPlanParseXlsxArgs = {
  input: RentalPlanXlsxInput;
};

export type MutationreplacePaymentMethodArgs = {
  input: ReplacePaymentMethodInput;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationreportContractAsFoundArgs = {
  input: ReportContractAsFoundInput;
};

export type MutationreportContractAsStolenArgs = {
  input: ReportContractAsStolenInput;
};

export type MutationrepushGoodsOrderArgs = {
  goodsOrderUuid: Scalars['String'];
};

export type MutationrequestAutomaticChargeArgs = {
  input: RequestAutomaticChargeInput;
};

export type MutationrequestBPIChargeArgs = {
  input: RequestBPIChargeInput;
};

export type MutationrequestRefundArgs = {
  input: RequestRefundInput;
};

export type MutationresetVerificationArgs = {
  input: ResetVerificationInput;
};

export type MutationresetVoucherArgs = {
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type MutationretailEmployeeValidateArgs = {
  input: EmployeeValidationInput;
};

export type MutationretryLegacyCareEventArgs = {
  messageValue: Scalars['String'];
  topic: Scalars['String'];
};

export type MutationretryPaymentsArgs = {
  payments: Array<PaymentOrderRetryRequest>;
};

export type MutationretrySchedulesArgs = {
  comment: Scalars['String'];
  contractId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationreuploadStockTransferArgs = {
  refId: Scalars['String'];
};

export type MutationrevertDisablePurchaseVoucherArgs = {
  input: RevertDisablePurchaseVoucherInput;
};

export type MutationrevertDiscountOfferArgs = {
  input: RevertDiscountOfferInput;
};

export type MutationreviveContractArgs = {
  input: ReviveContractInput;
};

export type MutationreviveSubscriptionArgs = {
  input: ReviveSubscriptionInput;
};

export type MutationsaveUserPersonalIdentificationArgs = {
  identificationNumber: Scalars['ID'];
};

export type MutationsetConditionUuidArgs = {
  conditionUuid: Scalars['String'];
  uuid: Scalars['String'];
};

export type MutationsetPaymentMethodArgs = {
  input: SetPaymentMethodInput;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationstartPurchaseArgs = {
  input: StartPurchaseInput;
};

export type MutationstartReallocationArgs = {
  allocationUid: Scalars['String'];
  newAssetStatus?: InputMaybe<AssetStatus>;
};

export type MutationstartReplacementArgs = {
  input: StartReplacementInput;
};

export type MutationsubmitBankAccountSnapshotDataArgs = {
  input: SubmitBankAccountSnapshotDataInput;
};

export type MutationsubmitKontomatikSessionArgs = {
  input: SubmitKontomatikSessionInput;
};

export type MutationsubmitVerificationDecisionArgs = {
  input: SubmitVerificationDecisionInput;
};

export type MutationsyncCompanyLeadArgs = {
  input: SyncCompanyLeadInput;
};

export type MutationsyncContractsAssetArgs = {
  input: SyncContractsAssetInput;
};

export type MutationsyncProductVariantsArgs = {
  input: SyncProductVariantsInput;
};

export type MutationsyncReceeveArgs = {
  paymentOrderEvent?: InputMaybe<PaymentOrderEvent>;
};

export type MutationsyncShipmentArgs = {
  shipmentId: Scalars['String'];
};

export type MutationsyncSubscriptionStatusArgs = {
  input: SyncSubscriptionStatusInput;
};

export type MutationsyncUserPaymentMethodsArgs = {
  input: SyncUserPaymentMethodsInput;
};

export type MutationsyncVerificationStateArgs = {
  input: SyncVerificationStateInput;
};

export type MutationtaskCancelArgs = {
  id: Scalars['ID'];
};

export type MutationtaskCreateProductsUploadArgs = {
  input: ProductsUploadTaskCreateInput;
};

export type MutationtaskCreateRentalPlanUploadArgs = {
  input: RentalPlanUploadTaskCreateInput;
};

export type MutationtaskExportRentalPlansArgs = {
  where?: InputMaybe<RentalPlanExportWhereInput>;
};

export type MutationtoggleB2BDashboardAccessArgs = {
  toggleB2BDashboardAccessInput: ToggleDashboardAccessInput;
};

export type MutationtoggleKitStateArgs = {
  toggleKitStateInput: ToggleKitStateInput;
};

export type MutationunlockUserArgs = {
  input: UnlockUserInput;
};

export type MutationupdateAddressArgs = {
  input: UpdateAddressInput;
};

export type MutationupdateAgreedPurchasePriceArgs = {
  input: UpdateAgreedPurchasePriceInput;
};

export type MutationupdateAssociationAddonsArgs = {
  input: UpdateAssociationAddonsInput;
};

export type MutationupdateBundleArgs = {
  data: BundleUpdateInput;
  where: BundleWhereUniqueInput;
};

export type MutationupdateCampaignStatusArgs = {
  status: StatusTypes;
  uuid: Scalars['String'];
};

export type MutationupdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationupdateCompanyAddressArgs = {
  input: CreateCompanyAddressInput;
};

export type MutationupdateCompanyStatusArgs = {
  input: UpdateCompanyStatusInput;
};

export type MutationupdateConsolidatedBillingDayArgs = {
  dayOfMonth?: InputMaybe<Scalars['Float']>;
  targetUserId?: InputMaybe<Scalars['String']>;
};

export type MutationupdateContractTermsAndConditionsArgs = {
  input: UpdateContractTermsAndConditionsInput;
};

export type MutationupdateCountdownTimerArgs = {
  countdownTimerUpdateInput: CountdownTimerUpdateInput;
  id: Scalars['ID'];
};

export type MutationupdateCustomPurchasePriceMarkupArgs = {
  input: UpdateCustomPurchasePriceMarkupInput;
};

export type MutationupdateDaysToDecideArgs = {
  input: UpdateDaysToDecideInput;
};

export type MutationupdateDisabledFeaturesArgs = {
  input: UpdateDisabledFeaturesInput;
};

export type MutationupdateKitArgs = {
  updateKitInput: UpdateKitInput;
};

export type MutationupdateLegacyCompanyArgs = {
  input: UpdateLegacyCompanyInput;
};

export type MutationupdateLocaleArgs = {
  input: UpdateLocaleInput;
};

export type MutationupdateNotificationEnrichMappingArgs = {
  enrichMapping: NotificationEnrichMappingUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationupdateNotificationTriggerFilterArgs = {
  trigger: NotificationTriggerFilterUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationupdateOrderFulfillmentCommentArgs = {
  comment: UpdateOrderFulfillmentCommentInput;
};

export type MutationupdatePaymentMethodForContractArgs = {
  input: UpdatePaymentMethodForContractInput;
};

export type MutationupdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationupdateProductLogisticsArgs = {
  data: ProductLogisticsInput;
  sku: Scalars['String'];
};

export type MutationupdateProductSpecificationsArgs = {
  sku: Scalars['String'];
  specifications: Array<ProductSpecificationInput>;
};

export type MutationupdateSerialNumberArgs = {
  update: UpdateSerialNumberInput;
};

export type MutationupdateStoreCodesArgs = {
  input: UpdateStoreCodesInput;
};

export type MutationupdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type MutationupdateSubscriptionTermsAndConditionsArgs = {
  input: UpdateSubscriptionTermsAndConditionsInput;
};

export type MutationupdateTaxRateRegenerateInvoicesArgs = {
  invoiceIds: Array<Scalars['String']>;
  orderNumbers: Array<Scalars['String']>;
  taxRate: Scalars['Float'];
  useLegacyNumber?: InputMaybe<Scalars['Boolean']>;
};

export type MutationupdateTrackingInformationArgs = {
  carrier?: InputMaybe<ShipmentCarrier>;
  shipmentId: Scalars['String'];
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type MutationupdateTranslateArgs = {
  translate: TranslateUpdateInputType;
  uuid: Scalars['String'];
};

export type MutationupdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationupdateUserNewsletterOptInStateArgs = {
  input: UpdateUserNewsletterOptInStateInput;
};

export type MutationupdateUserTeamsArgs = {
  input: UpdateUserTeamsInput;
};

export type MutationupdateWorkAssetArgs = {
  updateAssetInput: UpdateAssetInput;
};

export type MutationupdateWorkEmployeeArgs = {
  updateEmployeeInput: UpdateEmployeeInput;
};

export type MutationupsertAvailabilityCampaignArgs = {
  AvailabilityCampaign: AvailabilityCampaignUpsertInput;
};

export type MutationupsertCatalogGroupingArgs = {
  data: CatalogGroupingUpsertInput;
};

export type MutationupsertDefaultShippingAddressArgs = {
  address: DefaultShippingAddressInputType;
  orderNumber: Scalars['String'];
};

export type MutationupsertDistributionRuleArgs = {
  rule: DistributionRuleInput;
};

export type MutationupsertManyBundlesArgs = {
  data: BundleBulkCreateInput;
};

export type MutationupsertProfileArgs = {
  profile: DeliveryProfileInput;
};

export type MutationupsertRuleArgs = {
  rule: DeliveryProfileRuleInput;
};

export type MutationusePaymentMethodArgs = {
  input: UsePaymentMethodInput;
};

export type MutationuseSurpriseDiscountArgs = {
  input: UseSurpriseDiscountInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NethoneOrder = {
  __typename?: 'NethoneOrder';
  createdAt?: Maybe<Scalars['DateTime']>;
  orderNumber?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type NewRentalPlanInput = {
  extendedCommittedMonths: Scalars['Int'];
  /** New rental plan price in cents, without previous discounts */
  priceInCents: Scalars['Int'];
};

export type NextPaymentNote = {
  __typename?: 'NextPaymentNote';
  date?: Maybe<Scalars['ISO8601DateTime']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type Node = {
  id: Scalars['ID'];
};

/** More fields for this type will be defined in following PRs */
export type NotImplementedEOF = IExternalOrderFulfillment & {
  __typename?: 'NotImplementedEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

export enum NotificationChannel {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SMS = 'SMS',
}

export type NotificationEnrichMapping = {
  __typename?: 'NotificationEnrichMapping';
  createdAt: Scalars['DateTime'];
  enrichType: Scalars['String'];
  mapping: Scalars['JSON'];
  status: NotificationEnrichMappingStatus;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type NotificationEnrichMappingListInput = {
  enrichType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<NotificationEnrichMappingStatus>;
  uuid?: InputMaybe<Scalars['String']>;
};

export enum NotificationEnrichMappingStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export type NotificationEnrichMappingUpdateInput = {
  status: NotificationEnrichMappingStatus;
};

export type NotificationSendResult = Node & {
  __typename?: 'NotificationSendResult';
  address: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  channel: NotificationChannel;
  correlation: Scalars['String'];
  createdAt: Scalars['DateTime'];
  enrichContext: Scalars['JSON'];
  enrichType: Scalars['String'];
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  status: NotificationSendResultStatus;
  template: Scalars['String'];
  triggerFilter?: Maybe<NotificationTriggerFilter>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type NotificationSendResultAsPDF = {
  __typename?: 'NotificationSendResultAsPDF';
  id: Scalars['ID'];
  pdfBase64: Scalars['String'];
  pdfLink: Scalars['String'];
};

export enum NotificationSendResultStatus {
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
  SUCCESS = 'SUCCESS',
}

export type NotificationTriggerFilter = {
  __typename?: 'NotificationTriggerFilter';
  channels: Array<NotificationChannel>;
  conditions: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  deferInSeconds: Scalars['Float'];
  enrichMapping?: Maybe<NotificationEnrichMapping>;
  enrichType: Scalars['String'];
  forceSend: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: NotificationTriggerFilterStatus;
  template: Scalars['String'];
  thirtyDayVolume: Scalars['Int'];
  totalSent: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationTriggerFilterListInput = {
  channel?: InputMaybe<NotificationChannel>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<NotificationTriggerFilterStatus>;
};

export enum NotificationTriggerFilterStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export type NotificationTriggerFilterUpdateInput = {
  forceSend?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<NotificationTriggerFilterStatus>;
};

export type NumberComparatorInput = {
  _eq?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
};

export type OfferItemMetaInput = {
  allowPurchase?: InputMaybe<Scalars['Boolean']>;
  allowRentalUpgrade?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<LineItemCategoryInput>;
  committedMonths?: InputMaybe<Scalars['Int']>;
  discountAmount?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  product?: InputMaybe<LineItemProductInput>;
  rentalPlanId?: InputMaybe<Scalars['Int']>;
  variant?: InputMaybe<LineItemVariantInput>;
};

export type OfferLineItemUpdateInput = {
  id: Scalars['Int'];
  meta?: InputMaybe<OfferItemMetaInput>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type OffsetPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OhOh = {
  __typename?: 'OhOh';
  error?: Maybe<Scalars['String']>;
};

export type OnfidoCheck = CheckProviderReport & {
  __typename?: 'OnfidoCheck';
  reportUrl: Scalars['String'];
  result?: Maybe<ProviderCheckResult>;
};

export type Operation = {
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  updates: Array<FlowUpdate>;
};

export type Order = {
  __typename?: 'Order';
  billingAddress?: Maybe<Address>;
  /** @deprecated Legacy field, please use placedAt field instead. */
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Will be removed. */
  couponRecurrent?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  /** @deprecated Legacy field, please use discountTotal field instead. */
  discountAmount: Price;
  discountTotal?: Maybe<Scalars['Int']>;
  estimatedTax?: Maybe<Scalars['Int']>;
  /** @deprecated Will be removed. */
  guestToken?: Maybe<Scalars['String']>;
  /** @deprecated Legacy field, please use billingAddress field instead. */
  homeAddress?: Maybe<Address>;
  id: Scalars['ID'];
  itemTotal?: Maybe<Scalars['Int']>;
  items: Array<Maybe<OrderItem>>;
  /** @deprecated Legacy field, please use itemTotal field instead. */
  itemsTotal: Price;
  /** @deprecated Legacy field, please use items field. */
  lineItems: Array<LineItem>;
  /** @deprecated Will be removed. Switch to using id. */
  number: Scalars['String'];
  paymentMethodId?: Maybe<Scalars['Int']>;
  placedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Legacy field, please use total and currency fields instead. */
  price: Price;
  shippingAddress?: Maybe<Address>;
  shippingCost?: Maybe<Scalars['Int']>;
  /** @deprecated Legacy field, please use shippingCost field instead. */
  shippingPrice: Price;
  status?: Maybe<Scalars['String']>;
  /** @deprecated Will be removed. */
  step?: Maybe<Scalars['String']>;
  /** @deprecated Legacy field, please use storeCode field instead. */
  store: Store;
  storeCode?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  type?: Maybe<OrderType>;
  /** @deprecated userId is deprecated. Use cart query to get User information instead. */
  userId?: Maybe<Scalars['Int']>;
};

export type OrderAsset = {
  __typename?: 'OrderAsset';
  allocationUid?: Maybe<Scalars['String']>;
  assetUid?: Maybe<Scalars['String']>;
  contractFlow?: Maybe<ContractFlow>;
  contractId?: Maybe<Scalars['String']>;
  contractState?: Maybe<ContractState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lifecycleEventUids?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  productSku?: Maybe<Scalars['String']>;
  replacementCreatedAt?: Maybe<Scalars['String']>;
  replacementUid?: Maybe<Scalars['String']>;
  reservationCreatedAt?: Maybe<Scalars['String']>;
  reservationStatus?: Maybe<ReservationStatus>;
  reservationUid?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  /** ID of the latest shipment related to an asset */
  shipmentUid?: Maybe<Scalars['String']>;
  stockId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Variant from GroverAPI through 'variantSku' */
  variant?: Maybe<Variant>;
  variantSku?: Maybe<Scalars['String']>;
  warehouseCode?: Maybe<Scalars['String']>;
  warehouseGoodsOrderId?: Maybe<Scalars['String']>;
  warehouseGoodsOrderState?: Maybe<WarehouseGoodsOrderStatus>;
  /** Asset from work-service through 'assetUid' */
  workAsset?: Maybe<WorkAsset>;
};

export enum OrderBillingType {
  Purchase = 'Purchase',
  Rent = 'Rent',
}

export enum OrderByDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type OrderCancellation = {
  __typename?: 'OrderCancellation';
  cancelledContractIds?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type OrderCancellationInput = {
  cancellationReason: OrderCancellationReason;
  orderNumber: Scalars['String'];
};

/** Reasons for order cancellation */
export enum OrderCancellationReason {
  CUSTOMER_REQUEST_VIA_CS = 'CUSTOMER_REQUEST_VIA_CS',
  DUPLICATE_ORDER = 'DUPLICATE_ORDER',
  FRAUD_DETECTED = 'FRAUD_DETECTED',
  IN_DEBT_COLLECTION = 'IN_DEBT_COLLECTION',
  OTHER = 'OTHER',
}

export type OrderDetails = {
  __typename?: 'OrderDetails';
  billingAddress: OrderDetailsAddress;
  lineItems: Array<OrderDetailsLineItem>;
  netTotal?: Maybe<OrderDetailsPricing>;
  shippingAddress: OrderDetailsAddress;
  shippingCost: OrderDetailsPricing;
  total: OrderDetailsPricing;
};

export type OrderDetailsAddress = {
  __typename?: 'OrderDetailsAddress';
  additional_info?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  country_iso: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type OrderDetailsCategory = {
  __typename?: 'OrderDetailsCategory';
  category_id?: Maybe<Scalars['String']>;
  sub_category_id?: Maybe<Scalars['String']>;
};

export type OrderDetailsLineItem = {
  __typename?: 'OrderDetailsLineItem';
  category?: Maybe<OrderDetailsCategory>;
  committed_months?: Maybe<Scalars['Float']>;
  item_discount?: Maybe<OrderDetailsPricing>;
  item_price: OrderDetailsPricing;
  item_total?: Maybe<OrderDetailsPricing>;
  line_item_id: Scalars['Float'];
  plan_upgrade_allowed?: Maybe<Scalars['Boolean']>;
  purchase_option_allowed: Scalars['Boolean'];
  quantity: Scalars['Float'];
  rental_mode: Scalars['String'];
  variant: OrderDetailsVariant;
};

export type OrderDetailsPricing = {
  __typename?: 'OrderDetailsPricing';
  currency: Scalars['String'];
  in_cents: Scalars['Float'];
};

export type OrderDetailsVariant = {
  __typename?: 'OrderDetailsVariant';
  name: Scalars['String'];
  product_sku: Scalars['String'];
  variant_id: Scalars['String'];
  variant_sku: Scalars['String'];
};

export type OrderFulfillment = {
  __typename?: 'OrderFulfillment';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  comments: Array<OrderFulfillmentComment>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  lifecycleEvents?: Maybe<Array<OrderFulfillmentLifecycleEvent>>;
  /**
   * JSON stringified version of a collection of assets belonging to an order
   * @deprecated Replaced by the 'assets' field
   */
  orderAssets?: Maybe<Scalars['String']>;
  orderDetails?: Maybe<OrderDetails>;
  orderMode?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  orderRiskAssessment?: Maybe<OrderRiskAssessment>;
  /**
   * JSON stringified version of a collection of shipments belonging to an order
   * @deprecated Replaced by the 'shipments' field
   */
  orderShipments?: Maybe<Scalars['String']>;
  /** This is a field used only for risk analysis purposes */
  riskConsolidatedField?: Maybe<RiskConsolidatedState>;
  /** Collection of shipments belonging to an order */
  shipments?: Maybe<Array<OrderShipment>>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  /** User from GroverAPI through 'userId' */
  user?: Maybe<User>;
  userId: Scalars['String'];
  userType?: Maybe<Scalars['String']>;
};

export type OrderFulfillmentCollection = {
  __typename?: 'OrderFulfillmentCollection';
  rows: Array<OrderFulfillment>;
  totalCount: Scalars['Int'];
};

export type OrderFulfillmentComment = {
  __typename?: 'OrderFulfillmentComment';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  orderFulfillmentUid: Scalars['String'];
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type OrderFulfillmentConnection = {
  __typename?: 'OrderFulfillmentConnection';
  edges: Array<OrderFulfillmentEdge>;
  pageInfo?: Maybe<OrderFulfillmentPageInfo>;
};

export type OrderFulfillmentEdge = {
  __typename?: 'OrderFulfillmentEdge';
  cursor: OrderFulfillmentPaginationCursor;
  node: OrderFulfillment;
};

export type OrderFulfillmentFilterInput = {
  createdAt?: InputMaybe<TimestampComparatorInput>;
  orderMode?: InputMaybe<OrderModeComparatorInput>;
  orderNumber?: InputMaybe<StringComparatorInput>;
  state?: InputMaybe<OrderFulfillmentStateComparatorInput>;
  storeCountryCode?: InputMaybe<StringComparatorInput>;
  storeId?: InputMaybe<NumberComparatorInput>;
  updatedAt?: InputMaybe<TimestampComparatorInput>;
  userId?: InputMaybe<StringComparatorInput>;
  userType?: InputMaybe<UserTypeComparatorInput>;
};

export type OrderFulfillmentLifecycleEvent = {
  __typename?: 'OrderFulfillmentLifecycleEvent';
  aggregationUpdate?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name: OrderFulfillmentLifecycleEventState;
  orderNumber: Scalars['String'];
  sourceEntityUid?: Maybe<Scalars['ID']>;
  timestamp: Scalars['DateTime'];
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export enum OrderFulfillmentLifecycleEventState {
  AllocationFulfilled = 'AllocationFulfilled',
  AllocationPartiallyFulfilled = 'AllocationPartiallyFulfilled',
  AllocationPending = 'AllocationPending',
  ContractActivated = 'ContractActivated',
  ContractAssetAssigned = 'ContractAssetAssigned',
  ContractCancelled = 'ContractCancelled',
  ContractDraft = 'ContractDraft',
  ContractEnded = 'ContractEnded',
  ContractFulfilling = 'ContractFulfilling',
  ContractPaused = 'ContractPaused',
  ContractReactivated = 'ContractReactivated',
  ContractRefused = 'ContractRefused',
  ContractUpdate = 'ContractUpdate',
  EarlyOrderCancellation = 'EarlyOrderCancellation',
  OrderCancelled = 'OrderCancelled',
  OrderMigrated = 'OrderMigrated',
  OrderPlaced = 'OrderPlaced',
  OrderPlacedOffline = 'OrderPlacedOffline',
  PaymentCancelled = 'PaymentCancelled',
  PaymentFailed = 'PaymentFailed',
  PaymentPaid = 'PaymentPaid',
  PaymentPartiallyPaid = 'PaymentPartiallyPaid',
  PaymentPartiallyRefunded = 'PaymentPartiallyRefunded',
  PaymentRefunded = 'PaymentRefunded',
  PaymentUpdateReceived = 'PaymentUpdateReceived',
  ReplacementAllocated = 'ReplacementAllocated',
  ReservationApproved = 'ReservationApproved',
  ReservationCancelled = 'ReservationCancelled',
  ReservationDeclined = 'ReservationDeclined',
  ReservationDeleted = 'ReservationDeleted',
  ReservationExpired = 'ReservationExpired',
  ReservationPaid = 'ReservationPaid',
  ReservationUpdate = 'ReservationUpdate',
  ReturnCancelled = 'ReturnCancelled',
  ReturnCreated = 'ReturnCreated',
  ReturnDelivered = 'ReturnDelivered',
  ReturnFailedToDeliver = 'ReturnFailedToDeliver',
  ReturnInTransit = 'ReturnInTransit',
  RiskDecisionApproved = 'RiskDecisionApproved',
  RiskDecisionDeclined = 'RiskDecisionDeclined',
  RiskDecisionError = 'RiskDecisionError',
  RiskDecisionManualReview = 'RiskDecisionManualReview',
  RiskDecisionUpdate = 'RiskDecisionUpdate',
  ShipmentCancelled = 'ShipmentCancelled',
  ShipmentCreated = 'ShipmentCreated',
  ShipmentDelivered = 'ShipmentDelivered',
  ShipmentFailedToDeliver = 'ShipmentFailedToDeliver',
  ShipmentInTransit = 'ShipmentInTransit',
  ShipmentReadyForPickupByReceiver = 'ShipmentReadyForPickupByReceiver',
  WarehouseOrderAccepted = 'WarehouseOrderAccepted',
  WarehouseOrderCancelled = 'WarehouseOrderCancelled',
  WarehouseOrderProcessing = 'WarehouseOrderProcessing',
  WarehouseOrderSent = 'WarehouseOrderSent',
  WarehouseOrderShipped = 'WarehouseOrderShipped',
}

export enum OrderFulfillmentOrderMode {
  FLEX = 'FLEX',
  FLEX_LEGACY = 'FLEX_LEGACY',
  MIX = 'MIX',
  SWAP = 'SWAP',
}

export type OrderFulfillmentPageInfo = {
  __typename?: 'OrderFulfillmentPageInfo';
  beginningCursor?: Maybe<OrderFulfillmentPaginationCursor>;
  endCursor?: Maybe<OrderFulfillmentPaginationCursor>;
};

export type OrderFulfillmentPaginationCursor = {
  __typename?: 'OrderFulfillmentPaginationCursor';
  createdAt: Scalars['String'];
  uid: Scalars['ID'];
};

export enum OrderFulfillmentSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum OrderFulfillmentState {
  AllocationFulfilled = 'AllocationFulfilled',
  AllocationPartiallyFulfilled = 'AllocationPartiallyFulfilled',
  AllocationPending = 'AllocationPending',
  Initial = 'Initial',
  OrderCancelled = 'OrderCancelled',
  OrderCompleted = 'OrderCompleted',
  OrderPaused = 'OrderPaused',
  OrderPlaced = 'OrderPlaced',
  OrderRefused = 'OrderRefused',
  PaymentsPending = 'PaymentsPending',
  RiskDecisionManualReviewPending = 'RiskDecisionManualReviewPending',
  RiskDecisionPending = 'RiskDecisionPending',
  ShipmentsCancelled = 'ShipmentsCancelled',
  ShipmentsCreated = 'ShipmentsCreated',
  ShipmentsDelivered = 'ShipmentsDelivered',
  ShipmentsFailedToDeliver = 'ShipmentsFailedToDeliver',
  ShipmentsInTransit = 'ShipmentsInTransit',
  ShipmentsPartiallyCancelled = 'ShipmentsPartiallyCancelled',
  ShipmentsPartiallyDelivered = 'ShipmentsPartiallyDelivered',
  ShipmentsPartiallyFailedToDeliver = 'ShipmentsPartiallyFailedToDeliver',
  ShipmentsPartiallyInTransit = 'ShipmentsPartiallyInTransit',
  ShipmentsReadyForPickupByReceiver = 'ShipmentsReadyForPickupByReceiver',
  WarehouseOrderAccepted = 'WarehouseOrderAccepted',
  WarehouseOrderCancelled = 'WarehouseOrderCancelled',
  WarehouseOrderProcessing = 'WarehouseOrderProcessing',
  WarehouseOrderSent = 'WarehouseOrderSent',
  WarehouseOrderShipped = 'WarehouseOrderShipped',
}

export type OrderFulfillmentStateComparatorInput = {
  _eq?: InputMaybe<OrderFulfillmentState>;
  _in?: InputMaybe<Array<OrderFulfillmentState>>;
  _neq?: InputMaybe<OrderFulfillmentState>;
  _nin?: InputMaybe<Array<OrderFulfillmentState>>;
};

export type OrderFulfilmentPageInfo = {
  __typename?: 'OrderFulfilmentPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  categoryId?: Maybe<Scalars['Int']>;
  committedMonths?: Maybe<Scalars['Int']>;
  discountAmount?: Maybe<Scalars['Int']>;
  groverCare?: Maybe<GroverCare>;
  price?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Int']>;
  rentalPlanId?: Maybe<Scalars['Int']>;
  subCategoryId?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  variant?: Maybe<Variant>;
};

export type OrderLineItem = {
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export enum OrderMode {
  FLEX = 'FLEX',
  MIX = 'MIX',
}

export type OrderModeComparatorInput = {
  _eq?: InputMaybe<OrderFulfillmentOrderMode>;
  _neq?: InputMaybe<OrderFulfillmentOrderMode>;
};

export type OrderOffer = {
  __typename?: 'OrderOffer';
  assetsCount?: Maybe<Scalars['Int']>;
  assetsTotal?: Maybe<Scalars['Float']>;
  companyAccount: User;
  createdAt: Scalars['DateISOString'];
  expiresIn?: Maybe<Scalars['DateISOString']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<MixCartOrder>;
  owner: User;
  rejectedBy?: Maybe<User>;
  status: OrderOfferStatus;
  updatedAt?: Maybe<Scalars['DateISOString']>;
  waitingFor?: Maybe<Scalars['Int']>;
};

export type OrderOfferAcceptInput = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
  orderPlacementInput: CartOrderSubmitInput;
};

export type OrderOfferCreateInput = {
  companyAccountId: Scalars['Int'];
  name: Scalars['String'];
  shippingCost: Scalars['Float'];
  storeCode: Scalars['String'];
};

export type OrderOfferEdge = {
  __typename?: 'OrderOfferEdge';
  cursor: Scalars['String'];
  node?: Maybe<Array<Maybe<OrderOffer>>>;
};

export type OrderOfferFiltersInput = {
  companyAccountId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateISOString']>;
  expiresIn?: InputMaybe<Scalars['DateISOString']>;
  name?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  ownerEmail?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OrderOfferStatus>;
  updatedAt?: InputMaybe<Scalars['DateISOString']>;
};

export enum OrderOfferStatus {
  ACCEPTED = 'ACCEPTED',
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
}

export type OrderOfferUpdateInput = {
  companyAccountId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  shippingCost?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<OrderOfferStatus>;
};

export type OrderOffersConnection = {
  __typename?: 'OrderOffersConnection';
  edges?: Maybe<OrderOfferEdge>;
  matchingCount?: Maybe<Scalars['Int']>;
  nodes?: Maybe<Array<Maybe<OrderOffer>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OrderPayment = PaymentInterface & {
  __typename?: 'OrderPayment';
  amountBreakdown: Array<PaymentBreakdownItem>;
  basePrice: Price;
  byInvoice: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  discountableLoyaltyPrice: Price;
  discountedLoyaltyPrice: Price;
  dueDate: DateUnion;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceUrl?: Maybe<Scalars['String']>;
  marketingOrderPaymentData: Array<HintModel>;
  order: OrderUnion;
  paidDate?: Maybe<Scalars['ISO8601DateTime']>;
  price: Price;
  refundedAmount: Price;
  remoteId?: Maybe<Scalars['String']>;
  state: PaymentState;
};

export type OrderRiskAssessment = {
  __typename?: 'OrderRiskAssessment';
  decision: Scalars['String'];
  decisionIsFinal: Scalars['String'];
  decisionReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type OrderShipment = {
  __typename?: 'OrderShipment';
  assetUids?: Maybe<Array<Scalars['String']>>;
  carrier?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  flow?: Maybe<Scalars['String']>;
  lifecycleEventUids?: Maybe<Array<Scalars['String']>>;
  service?: Maybe<ShipmentServiceType>;
  shipment?: Maybe<Shipment>;
  shipmentUid: Scalars['String'];
  state: ShipmentEventState;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingURL?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderShippingPreferences = {
  __typename?: 'OrderShippingPreferences';
  includeCompanyName?: Maybe<Scalars['Boolean']>;
  shipToName?: Maybe<Scalars['String']>;
};

export enum OrderType {
  business = 'business',
  normal = 'normal',
}

export type OrderUnion = MixCartOrder | Order;

export type OrdersFilterInput = {
  cancellable: Scalars['Boolean'];
};

export enum Os {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  LINUX = 'LINUX',
  MAC_OS = 'MAC_OS',
  OTHER = 'OTHER',
  WINDOWS = 'WINDOWS',
}

export type PackageAddress = {
  senderAddress: ShippingAddressInput;
  setAsDefault?: InputMaybe<Scalars['Boolean']>;
};

export type PackageArrangementType = {
  address?: InputMaybe<PackageAddress>;
  assetUids: Array<Scalars['String']>;
  carrier?: InputMaybe<ShipmentCarrier>;
  dimensions?: InputMaybe<PackageDimensions>;
  id: Scalars['String'];
  ignoreCarrierRules?: InputMaybe<ShippingIgnoreCarrierRulesOptions>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type PackageDimensions = {
  height: Scalars['Float'];
  length: Scalars['Float'];
  unit?: DimensionsUnit;
  width: Scalars['Float'];
};

export type PackageItem = {
  __typename?: 'PackageItem';
  allocationUid?: Maybe<Scalars['String']>;
  assetAllocation?: Maybe<AssetAllocation>;
  assetUid?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  salesforceAssetId?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  shipment: Shipment;
  shipmentUid?: Maybe<Scalars['String']>;
  skuVariant?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  variant?: Maybe<Variant>;
};

export type PackageItemCollection = {
  __typename?: 'PackageItemCollection';
  count: Scalars['Float'];
  rows: Array<PackageItem>;
};

export type PackageItemFilter = {
  contractId?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  shipmentUid?: InputMaybe<Scalars['String']>;
  skuVariant?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginatedBillingChargeRequest = {
  __typename?: 'PaginatedBillingChargeRequest';
  currentCount: Scalars['Int'];
  edges: Array<PaginatedBillingChargeRequestRelayEdge>;
  pageInfo: RelayPageInfo;
  previousCount: Scalars['Int'];
};

export type PaginatedBillingChargeRequestRelayEdge = {
  __typename?: 'PaginatedBillingChargeRequestRelayEdge';
  cursor: Scalars['String'];
  node: BillingChargeRequest;
};

export type PaginatedBillingGroupingStrategies = {
  __typename?: 'PaginatedBillingGroupingStrategies';
  currentCount: Scalars['Int'];
  edges: Array<PaginatedBillingGroupingStrategiesRelayEdge>;
  pageInfo: RelayPageInfo;
  previousCount: Scalars['Int'];
};

export type PaginatedBillingGroupingStrategiesRelayEdge = {
  __typename?: 'PaginatedBillingGroupingStrategiesRelayEdge';
  cursor: Scalars['String'];
  node: BillingGroupingStrategyObject;
};

export type PaginatedBillingGroups = {
  __typename?: 'PaginatedBillingGroups';
  currentCount: Scalars['Int'];
  edges: Array<PaginatedBillingGroupsRelayEdge>;
  pageInfo: RelayPageInfo;
  previousCount: Scalars['Int'];
};

export type PaginatedBillingGroupsRelayEdge = {
  __typename?: 'PaginatedBillingGroupsRelayEdge';
  cursor: Scalars['String'];
  node: BillingGroup;
};

export type PaginatedBillingSchedules = {
  __typename?: 'PaginatedBillingSchedules';
  currentCount: Scalars['Int'];
  edges: Array<PaginatedBillingSchedulesRelayEdge>;
  pageInfo: RelayPageInfo;
  previousCount: Scalars['Int'];
};

export type PaginatedBillingSchedulesRelayEdge = {
  __typename?: 'PaginatedBillingSchedulesRelayEdge';
  cursor: Scalars['String'];
  node: BillingScheduleUnion;
};

export type PaginatedInternalProducts = {
  __typename?: 'PaginatedInternalProducts';
  edges?: Maybe<Array<ProductEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedKitConnection = {
  __typename?: 'PaginatedKitConnection';
  edges?: Maybe<Array<Maybe<KitEdge>>>;
  matchingCount?: Maybe<Scalars['Int']>;
  nodes?: Maybe<Array<Kit>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatedRentalPlans = {
  __typename?: 'PaginatedRentalPlans';
  edges?: Maybe<Array<InternalRentalPlanEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedScheduleInput = {
  contractId?: InputMaybe<Scalars['String']>;
  /** Whether the schedule list should include payments from Billing Service. If true, "after" argument should be omitted, since there is no way to paginate both databases. Default is "false". */
  includeBillingLegacy?: InputMaybe<Scalars['Boolean']>;
  /** Whether future schedules, the ones that were not charged yet, should be included in the response list. Default is "true" */
  includeFutureSchedules?: InputMaybe<Scalars['Boolean']>;
};

export type PaginatedTasks = {
  __typename?: 'PaginatedTasks';
  rows: Array<Task>;
  totalCount: Scalars['Int'];
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PaginationInputType = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type ParseXlsxError = {
  __typename?: 'ParseXlsxError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ParseXlsxInput = {
  bucket: Scalars['String'];
  fileName: Scalars['String'];
  mapper: Scalars['JSON'];
};

export type ParseXlsxResult = ParseXlsxError | ParsedXlsx;

export type ParsedXlsx = {
  __typename?: 'ParsedXlsx';
  json: Scalars['JSON'];
};

/** More fields for this type will be defined in following PRs */
export type PartiallyDeliveredEOF = IExternalOrderFulfillment & {
  __typename?: 'PartiallyDeliveredEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  /** Collection of shipments belonging to an order */
  shipments?: Maybe<Array<OrderShipment>>;
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

/** More fields for this type will be defined in following PRs */
export type PartiallyFailedDeliveryEOF = IExternalOrderFulfillment & {
  __typename?: 'PartiallyFailedDeliveryEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  /** Collection of shipments belonging to an order */
  shipments?: Maybe<Array<OrderShipment>>;
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

/** More fields for this type will be defined in following PRs */
export type PartiallyShippedEOF = IExternalOrderFulfillment & {
  __typename?: 'PartiallyShippedEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  /** Collection of shipments belonging to an order */
  shipments?: Maybe<Array<OrderShipment>>;
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

export type Partner = {
  __typename?: 'Partner';
  id: Scalars['ID'];
  name: Scalars['String'];
  stores: Array<Store>;
};

export type PartnerWhereInput = {
  name?: InputMaybe<StringComparisonInput>;
};

/** Autogenerated input type of PauseContract */
export type PauseContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  days: Scalars['Int'];
  reason: PauseReason;
};

/** Autogenerated return type of PauseContract. */
export type PauseContractPayload = {
  __typename?: 'PauseContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

export enum PauseExtensionUnavailabilityReason {
  MAX_DAYS_PAUSED_REACHED = 'MAX_DAYS_PAUSED_REACHED',
  PAUSE_NOT_IN_PROGRESS = 'PAUSE_NOT_IN_PROGRESS',
}

export enum PauseReason {
  BULK_RETURN = 'BULK_RETURN',
  LOST_ORDER_INVESTIGATION = 'LOST_ORDER_INVESTIGATION',
  NOT_ABLE_TO_PAY = 'NOT_ABLE_TO_PAY',
  PAUSING_PAYMENTS_UNTIL_RETURN = 'PAUSING_PAYMENTS_UNTIL_RETURN',
}

export enum PauseUnavailabilityReason {
  ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
  ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
  CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
}

export type PausedNote = TextNoteInterface & {
  __typename?: 'PausedNote';
  text?: Maybe<Scalars['String']>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  walletUuid?: Maybe<Scalars['String']>;
};

export type PaymentBreakdownGroverCareItem = PaymentBreakdownItem & {
  __typename?: 'PaymentBreakdownGroverCareItem';
  amount: Price;
};

export type PaymentBreakdownGroverCashItem = PaymentBreakdownItem & {
  __typename?: 'PaymentBreakdownGroverCashItem';
  amount: Price;
};

export type PaymentBreakdownItem = {
  amount: Price;
};

export type PaymentBreakdownOtherAmountItem = PaymentBreakdownItem & {
  __typename?: 'PaymentBreakdownOtherAmountItem';
  amount: Price;
};

export type PaymentBreakdownOtherDiscountItem = PaymentBreakdownItem & {
  __typename?: 'PaymentBreakdownOtherDiscountItem';
  amount: Price;
};

export type PaymentBreakdownRentalItem = PaymentBreakdownItem & {
  __typename?: 'PaymentBreakdownRentalItem';
  amount: Price;
};

export type PaymentBreakdownShippingItem = PaymentBreakdownItem & {
  __typename?: 'PaymentBreakdownShippingItem';
  amount: Price;
};

export type PaymentBreakdownVoucherItem = PaymentBreakdownItem & {
  __typename?: 'PaymentBreakdownVoucherItem';
  amount: Price;
  code?: Maybe<Scalars['String']>;
};

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  account: PaymentAccount;
  gateway: Scalars['String'];
  reference: Scalars['String'];
};

export enum PaymentEnum {
  GROUP_PAYMENT = 'GROUP_PAYMENT',
  ORDER_PAYMENT = 'ORDER_PAYMENT',
  PURCHASE_PAYMENT = 'PURCHASE_PAYMENT',
  SUBSCRIPTION_PAYMENT = 'SUBSCRIPTION_PAYMENT',
}

export type PaymentEnumTypeComparisonInput = {
  _eq?: InputMaybe<PaymentEnum>;
  _neq?: InputMaybe<PaymentEnum>;
};

export type PaymentGateway = {
  __typename?: 'PaymentGateway';
  flowType: PaymentGatewayFlowType;
  id: Scalars['ID'];
  meta?: Maybe<PaymentGatewayMeta>;
  name: Scalars['String'];
  type: PaymentGatewayType;
};

export enum PaymentGatewayFlowType {
  CREDIT_CARD = 'CREDIT_CARD',
  REDIRECT = 'REDIRECT',
}

export type PaymentGatewayMeta = {
  __typename?: 'PaymentGatewayMeta';
  provider?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
};

export enum PaymentGatewayType {
  BANKCARD = 'BANKCARD',
  PAYPAL = 'PAYPAL',
  SEPA = 'SEPA',
}

export type PaymentGroupModel = {
  __typename?: 'PaymentGroupModel';
  amountPaid: Scalars['Money'];
  billingDate: Scalars['String'];
  currency: Scalars['String'];
  dueDate: Scalars['String'];
  invoice?: Maybe<PaymentsInvoice>;
  movementId?: Maybe<Scalars['String']>;
  paymentModifiers: Array<PaymentModifierModel>;
  paymentOrders: Array<PaymentOrderModel>;
  scopeUuid?: Maybe<Scalars['String']>;
  status: PaymentGroupStatusTypes;
  total: Scalars['Money'];
  transactions: Array<TransactionModel>;
  uuid: Scalars['ID'];
  wallet: WalletModel;
  walletUuid: Scalars['String'];
};

export enum PaymentGroupStatusTypes {
  cancelled = 'cancelled',
  failed = 'failed',
  new = 'new',
  paid = 'paid',
  partialPaid = 'partialPaid',
  partialRefund = 'partialRefund',
  pending = 'pending',
  refund = 'refund',
  unknown = 'unknown',
}

export type PaymentGroupsModel = {
  __typename?: 'PaymentGroupsModel';
  group: Scalars['ID'];
  modifiers: Array<PaymentModifierModel>;
  paymentOrders: Array<PaymentOrderModel>;
  scopeUuid?: Maybe<Scalars['String']>;
  status: PaymentGroupStatusTypes;
  transactions: Array<TransactionModel>;
  wallet: WalletModel;
  walletUuid: Scalars['String'];
};

export type PaymentInterface = {
  amountBreakdown: Array<PaymentBreakdownItem>;
  basePrice: Price;
  createdAt: Scalars['ISO8601DateTime'];
  discountableLoyaltyPrice: Price;
  discountedLoyaltyPrice: Price;
  invoiceNumber?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['ISO8601DateTime']>;
  price: Price;
  refundedAmount: Price;
  remoteId?: Maybe<Scalars['String']>;
  state: PaymentState;
};

export type PaymentMethod = ByInvoice | Card | Paypal | Sepa;

/** Payment method information coming from Wallet service. Eventually will deprecate the PaymentMethod type. */
export type PaymentMethod2 = {
  /** ID of the corresponding account in Billing Service. */
  billingAccountId?: Maybe<Scalars['String']>;
  /** Date/time of the method creation. Empty in case of a legacy method. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Present if the method deletion is currently forbidden, contains details about the reason. Warning: currently does not return "has linked contracts", which is one of the possible reasons for deletion lock. Check the linkedContracts field instead. */
  deletionLock?: Maybe<PaymentMethodDeletionLock>;
  /** ID of the corresponding method in Grover API. */
  groverApiId?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Method's external ID in IXOPAY. */
  ixopayReferenceId?: Maybe<Scalars['String']>;
  /** Contracts and subscriptions that are using this method. */
  linkedContracts: Array<FlexDetails>;
  /** URL to redirect the user to if authorization is required by the payment provider. */
  redirectUrl?: Maybe<Scalars['String']>;
  /** Error code classifying the method registration failure, if any. */
  registrationErrorCode?: Maybe<TransactionErrorCode>;
  status: PaymentMethodStatus;
  /** Get status of this method in relation to the given order. */
  statusForOrder: PaymentMethodStatusForOrderResult;
};

/** Payment method information coming from Wallet service. Eventually will deprecate the PaymentMethod type. */
export type PaymentMethod2statusForOrderArgs = {
  orderNumber: Scalars['String'];
};

/** 3-D Secure data used during the addition of a payment method. */
export type PaymentMethod3dsData = {
  colorDepth?: InputMaybe<Scalars['String']>;
  javaEnabled?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  screenHeight?: InputMaybe<Scalars['String']>;
  screenWidth?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

/** Information about a deletion lock set on a payment method. */
export type PaymentMethodDeletionLock = {
  __typename?: 'PaymentMethodDeletionLock';
  reason: PaymentMethodDeletionLockReason;
};

export enum PaymentMethodDeletionLockReason {
  /** A new order has been made with the payment method and is currently in processing. */
  NEW_ORDER = 'NEW_ORDER',
  /** Deletion of the payment method is not supported at the moment. */
  NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export type PaymentMethodInterface = {
  billingAccountId?: Maybe<Scalars['String']>;
  contracts: Array<BasicContract>;
  id: Scalars['ID'];
};

/** Payment method background operation like replacement, bulk contract update, etc. */
export type PaymentMethodOperation = {
  id: Scalars['ID'];
  status: PaymentMethodOperationStatus;
};

export enum PaymentMethodOperationStatus {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

/** Payment method authorization info in relation to an order. */
export type PaymentMethodOrderAuthorization = {
  __typename?: 'PaymentMethodOrderAuthorization';
  /** Indicates whether authorization attempts limit for the order was reached. Only present if authorization was failed. */
  attemptLimitReached?: Maybe<Scalars['Boolean']>;
  /** URL to redirect the user to if the payment provider requires it for authorization. */
  redirectUrl?: Maybe<Scalars['String']>;
  status: PaymentMethodOrderAuthorizationStatus;
};

export enum PaymentMethodOrderAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
  CAPTURED = 'CAPTURED',
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PENDING_CAPTURE = 'PENDING_CAPTURE',
  PENDING_REDIRECT = 'PENDING_REDIRECT',
  RELEASED = 'RELEASED',
  REQUIRED = 'REQUIRED',
}

export type PaymentMethodPresenter = {
  __typename?: 'PaymentMethodPresenter';
  /** @deprecated Use ChangePaymentMethod instead */
  action: Scalars['String'];
  /** @deprecated Use ChangePaymentMethod instead */
  email?: Maybe<Scalars['String']>;
  /** @deprecated Use subscription payment method instead */
  ibanFirstDigits?: Maybe<Scalars['String']>;
  /** @deprecated Use subscription payment method instead */
  ibanLastDigits?: Maybe<Scalars['String']>;
  /** @deprecated Use ChangePaymentMethod instead */
  last4Digits?: Maybe<Scalars['String']>;
  /** @deprecated Use ChangePaymentMethod instead */
  type?: Maybe<Scalars['String']>;
};

export enum PaymentMethodStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  PENDING_REDIRECT = 'PENDING_REDIRECT',
}

/** Result of the statusForOrder query. */
export type PaymentMethodStatusForOrderResult = {
  __typename?: 'PaymentMethodStatusForOrderResult';
  /** @deprecated Use authorization.attemptLimitReached field. */
  attemptLimitReached?: Maybe<Scalars['Boolean']>;
  /** Authorization status, if any. Applicable in Authorize & Capture flow. */
  authorization?: Maybe<PaymentMethodOrderAuthorization>;
};

export type PaymentMethodStub = {
  __typename?: 'PaymentMethodStub';
  billingAccountId?: Maybe<Scalars['ID']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
};

/** Filter for the paymentMethods query */
export type PaymentMethodsFilterType = {
  /** Whether or not invalid methods should be returned, default is false. */
  includeInvalid?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type PaymentModifierModel = {
  __typename?: 'PaymentModifierModel';
  amount: Scalars['Money'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  group: Scalars['String'];
  type: Scalars['String'];
  unit: Scalars['String'];
  uuid: Scalars['ID'];
};

export type PaymentOption = {
  __typename?: 'PaymentOption';
  /** @deprecated This field is present for compatibility with the legacy frontend and should be avoided. */
  flowType: PaymentOptionFlow;
  /** @deprecated This field is present for compatibility with the legacy frontend and should be avoided. */
  id: Scalars['ID'];
  /** IXOPAY-specific data. */
  ixopay: PaymentOptionIxopay;
  /** @deprecated This field is present for compatibility with the legacy frontend and should be avoided. This name is not localized and the consumer is encouraged to render a localized version based on the `type` field. */
  name: Scalars['String'];
  /** Whether or not Auth & Capture flow will be used when adding methods of this option. */
  requiresAuthorization: Scalars['Boolean'];
  type: PaymentOptionType;
};

export enum PaymentOptionFlow {
  CREDIT_CARD = 'CREDIT_CARD',
  REDIRECT = 'REDIRECT',
}

export type PaymentOptionIxopay = {
  __typename?: 'PaymentOptionIxopay';
  /** Integration key needed for the payments.js IXOPAY library. */
  publicKey?: Maybe<Scalars['String']>;
};

export enum PaymentOptionType {
  BANKCARD = 'BANKCARD',
  INVOICE = 'INVOICE',
  PAYPAL = 'PAYPAL',
  SEPA = 'SEPA',
}

export type PaymentOrderActionResponseItem = {
  __typename?: 'PaymentOrderActionResponseItem';
  accountFrom?: Maybe<Scalars['String']>;
  accountTo?: Maybe<Scalars['String']>;
  amount: Scalars['Money'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  failedReason?: Maybe<Scalars['String']>;
  gatewayResponse?: Maybe<Scalars['JSON']>;
  /** Reference to Ledger Movement id */
  movementId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Scalars['String']>;
  paymentOrderUuid: Scalars['String'];
  proxied: Scalars['Boolean'];
  refundReason?: Maybe<RefundReason>;
  status: Scalars['String'];
  type: Scalars['String'];
  uuid: Scalars['ID'];
};

export type PaymentOrderActionResponseItemGeneric = {
  __typename?: 'PaymentOrderActionResponseItemGeneric';
  paymentOrderUuid: Scalars['String'];
};

export enum PaymentOrderContractType {
  FLEX = 'FLEX',
  MIX = 'MIX',
}

export enum PaymentOrderCurrencyEnum {
  EUR = 'EUR',
  GCR = 'GCR',
  USD = 'USD',
}

export type PaymentOrderEvent = {
  amount_in_cents: Scalars['Int'];
  attempts_to_pay: Scalars['Int'];
  billing_service_payment_process: BillingServicePaymentProcess;
  consolidated: Scalars['Boolean'];
  contract_id: Scalars['String'];
  contract_type: PaymentOrderContractType;
  country_code: Scalars['String'];
  created_at: Scalars['ISO8601DateTime'];
  currency: PaymentOrderCurrencyEnum;
  customer_id: Scalars['String'];
  due_date: Scalars['ISO8601DateTime'];
  failed_reason: Scalars['String'];
  id: Scalars['String'];
  order_id: Scalars['String'];
  payment_group_id: Scalars['String'];
  payment_method: Scalars['String'];
  period_number: Scalars['Int'];
  previous_failed_count: Scalars['Int'];
  status: PaymentOrderStatusEnum;
  subscription_payment_type: PaymentOrderSubscriptionPaymentType;
  type: PaymentOrderType;
  updated_at: Scalars['ISO8601DateTime'];
};

export type PaymentOrderHistoryResponse = {
  __typename?: 'PaymentOrderHistoryResponse';
  count: Scalars['Int'];
  history: Array<PaymentOrderModel>;
};

export type PaymentOrderModel = {
  __typename?: 'PaymentOrderModel';
  actions?: Maybe<Array<TransactionActionModel>>;
  additionalData?: Maybe<Scalars['JSON']>;
  amount: Scalars['Money'];
  amountWithTax?: Maybe<Scalars['Money']>;
  attempts?: Maybe<Array<Scalars['DateTime']>>;
  billingDate: Scalars['DateTime'];
  billingPeriod: BillingPeriod;
  contractId?: Maybe<Scalars['String']>;
  contractType: Scalars['String'];
  countryCode: Scalars['String'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  group: Scalars['String'];
  groupStrategy: Scalars['String'];
  ledgerResource?: Maybe<Resource>;
  lineItems?: Maybe<Array<Scalars['JSON']>>;
  managedByScheduler: Scalars['Boolean'];
  migratedAt?: Maybe<Scalars['DateTime']>;
  /** Reference to first Ledger Movement id processed for this datapoint */
  movementId?: Maybe<Scalars['ID']>;
  orderNumber?: Maybe<Scalars['String']>;
  overdueAt?: Maybe<Scalars['DateTime']>;
  paymentDetails: PaymentDetails;
  paymentGroup: PaymentGroupModel;
  paymentPurpose?: Maybe<Scalars['String']>;
  paymentStatus: PaymentGroupStatusTypes;
  paymentType: PaymentOrderPaymentTypes;
  period?: Maybe<Scalars['Float']>;
  price: Scalars['Money'];
  quantity: Scalars['Float'];
  /** Reference to Ledger-api Resource id */
  resourceId?: Maybe<Scalars['ID']>;
  /** Reference to Billing-Scheduler Schedule id */
  scheduleId?: Maybe<Scalars['ID']>;
  scopeUuid?: Maybe<Scalars['String']>;
  shippingCost: Scalars['Money'];
  status: PaymentGroupStatusTypes;
  taxIncluded: Scalars['Boolean'];
  uuid: Scalars['ID'];
  wallet?: Maybe<WalletModel>;
  walletUuid: Scalars['String'];
};

export enum PaymentOrderPaymentTypes {
  groverCare = 'groverCare',
  purchase = 'purchase',
  shipping = 'shipping',
  subscription = 'subscription',
}

export type PaymentOrderRefundRequest = {
  amount: Scalars['Float'];
  comment: Scalars['String'];
  currency: Scalars['String'];
  id: Scalars['String'];
  reason: Scalars['String'];
  transactionUuid: Scalars['String'];
};

export type PaymentOrderRetryRequest = {
  amount: Scalars['Float'];
  comment: Scalars['String'];
  currency: Scalars['String'];
  id: Scalars['String'];
  paymentGroupId: Scalars['String'];
  reason: Scalars['String'];
  retryDate: Scalars['String'];
};

export enum PaymentOrderStatusEnum {
  cancelled = 'cancelled',
  failed = 'failed',
  new = 'new',
  paid = 'paid',
  partial_paid = 'partial_paid',
  partial_refund = 'partial_refund',
  pending = 'pending',
  refund = 'refund',
}

export enum PaymentOrderSubscriptionPaymentType {
  FIRST = 'FIRST',
  RECURRENT = 'RECURRENT',
}

export type PaymentOrderTotalStat = {
  __typename?: 'PaymentOrderTotalStat';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type PaymentOrderTotals = {
  __typename?: 'PaymentOrderTotals';
  totalAmountDue: Array<PaymentOrderTotalStat>;
  totalAmountPaid: Array<PaymentOrderTotalStat>;
  totalAmountPlan: Array<PaymentOrderTotalStat>;
};

export enum PaymentOrderType {
  purchase = 'purchase',
  shipping = 'shipping',
  subscription = 'subscription',
}

export type PaymentOrdersRequest = {
  amount: Scalars['Float'];
  comment: Scalars['String'];
  currency: Scalars['String'];
  id: Scalars['String'];
  reason: Scalars['String'];
};

export enum PaymentState {
  CANCELLED = 'CANCELLED',
  DUE = 'DUE',
  FAILED = 'FAILED',
  PAID = 'PAID',
  SCHEDULED = 'SCHEDULED',
}

export type PaymentStateTypeComparisonInput = {
  _eq?: InputMaybe<PaymentState>;
  _neq?: InputMaybe<PaymentState>;
};

export type PaymentSummaryInterface = {
  state: PaymentSummaryState;
  total: Price;
};

export type PaymentSummaryMultiple = PaymentSummaryInterface & {
  __typename?: 'PaymentSummaryMultiple';
  count: Scalars['Int'];
  state: PaymentSummaryState;
  total: Price;
};

export type PaymentSummarySingle = PaymentSummaryInterface & {
  __typename?: 'PaymentSummarySingle';
  payment: PaymentsUnion;
  state: PaymentSummaryState;
  total: Price;
};

export enum PaymentSummaryState {
  DUE = 'DUE',
  FAILED = 'FAILED',
  SCHEDULED = 'SCHEDULED',
}

export type PaymentsFilterInput = {
  defaultStoreCode?: InputMaybe<DefaultStoreCode>;
  groupPaymentId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Array<PaymentState>>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
};

export type PaymentsInvoice = {
  __typename?: 'PaymentsInvoice';
  internalUrl?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  invoiceSequenceNumber?: Maybe<Scalars['String']>;
  paymentGroupId: Scalars['ID'];
  publicUrl?: Maybe<Scalars['String']>;
};

export type PaymentsSummaryByStateUnion = PaymentSummaryMultiple | PaymentSummarySingle;

/** The connection type for PaymentsSummaryByStateUnion. */
export type PaymentsSummaryByStateUnionConnection = {
  __typename?: 'PaymentsSummaryByStateUnionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentsSummaryByStateUnionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentsSummaryByStateUnion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PaymentsSummaryByStateUnionEdge = {
  __typename?: 'PaymentsSummaryByStateUnionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentsSummaryByStateUnion>;
};

export type PaymentsUnion = GroupPayment | OrderPayment | PurchasePayment | SubscriptionPayment;

/** The connection type for PaymentsUnion. */
export type PaymentsUnionConnection = {
  __typename?: 'PaymentsUnionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentsUnionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentsUnion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PaymentsUnionEdge = {
  __typename?: 'PaymentsUnionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentsUnion>;
};

export type PaymentsWhereInput = {
  and?: InputMaybe<Array<PaymentsWhereInput>>;
  dueDate?: InputMaybe<DateComparisonInput>;
  paidDate?: InputMaybe<DateComparisonInput>;
  state?: InputMaybe<PaymentStateTypeComparisonInput>;
  type?: InputMaybe<PaymentEnumTypeComparisonInput>;
};

export type Paypal = PaymentMethodInterface & {
  __typename?: 'Paypal';
  addressConfirmed?: Maybe<Scalars['Boolean']>;
  billingAccountId?: Maybe<Scalars['String']>;
  contracts: Array<BasicContract>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  merchantTransactionId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingCountry?: Maybe<Scalars['String']>;
  shippingFirstname?: Maybe<Scalars['String']>;
  shippingPostcode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** Billing or shipping address of a PayPal account associated with a payment method. */
export type PaypalAddress = {
  __typename?: 'PaypalAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  /** 2-letter uppercase ISO code for the address country. */
  countryCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** PayPal payment method from Wallet service. Eventually will deprecate the Paypal type. */
export type PaypalPaymentMethod = PaymentMethod2 & {
  __typename?: 'PaypalPaymentMethod';
  accountName?: Maybe<Scalars['String']>;
  /** ID of the corresponding account in Billing Service. */
  billingAccountId?: Maybe<Scalars['String']>;
  billingAddress: PaypalAddress;
  /** Date/time of the method creation. Empty in case of a legacy method. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Present if the method deletion is currently forbidden, contains details about the reason. Warning: currently does not return "has linked contracts", which is one of the possible reasons for deletion lock. Check the linkedContracts field instead. */
  deletionLock?: Maybe<PaymentMethodDeletionLock>;
  /** ID of the corresponding method in Grover API. */
  groverApiId?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Method's external ID in IXOPAY. */
  ixopayReferenceId?: Maybe<Scalars['String']>;
  /** Contracts and subscriptions that are using this method. */
  linkedContracts: Array<FlexDetails>;
  /** URL to redirect the user to if authorization is required by the payment provider. */
  redirectUrl?: Maybe<Scalars['String']>;
  /** Error code classifying the method registration failure, if any. */
  registrationErrorCode?: Maybe<TransactionErrorCode>;
  shippingAddress: PaypalAddress;
  status: PaymentMethodStatus;
  /** Get status of this method in relation to the given order. */
  statusForOrder: PaymentMethodStatusForOrderResult;
  verified?: Maybe<Scalars['Boolean']>;
};

/** PayPal payment method from Wallet service. Eventually will deprecate the Paypal type. */
export type PaypalPaymentMethodstatusForOrderArgs = {
  orderNumber: Scalars['String'];
};

export type Period = {
  ends: Scalars['ISO8601DateTime'];
  number: Scalars['Int'];
  starts: Scalars['ISO8601DateTime'];
};

export type PeriodAmount = {
  __typename?: 'PeriodAmount';
  amount: Price;
  name: Scalars['String'];
  type: PeriodAmountKind;
};

export enum PeriodAmountKind {
  BASE_PRICE = 'BASE_PRICE',
  GROVER_CARE = 'GROVER_CARE',
  ONE_TIME_DISCOUNT = 'ONE_TIME_DISCOUNT',
  RECURRING_DISCOUNT = 'RECURRING_DISCOUNT',
}

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  roles: Array<Team>;
  title: Scalars['String'];
};

export type PersonalIdentification = {
  __typename?: 'PersonalIdentification';
  identificationNumber: Scalars['String'];
  identificationSubType?: Maybe<PersonalIdentificationSubType>;
  identificationType: PersonalIdentificationType;
  user: User;
};

export enum PersonalIdentificationSubType {
  DNI = 'DNI',
  NIE = 'NIE',
}

export enum PersonalIdentificationType {
  NATIONAL_ID = 'NATIONAL_ID',
}

export type Plaid = ProviderInterface & {
  __typename?: 'Plaid';
  linkToken: Scalars['String'];
  requestId: Scalars['String'];
  tracingId: Scalars['String'];
};

export enum Platform {
  CHROME = 'CHROME',
  EDGE = 'EDGE',
  FIREFOX = 'FIREFOX',
  GROVER_APP = 'GROVER_APP',
  OTHER = 'OTHER',
  SAFARI = 'SAFARI',
}

export enum PlatformTypes {
  app = 'app',
  web = 'web',
}

export type Poll = {
  __typename?: 'Poll';
  activeVersion?: Maybe<PollVersion>;
  description: Scalars['String'];
  entityType?: Maybe<EntityType>;
  id: Scalars['ID'];
  mode: PollMode;
  name: Scalars['String'];
  pollVersions?: Maybe<Array<PollVersion>>;
  slug: Scalars['String'];
  version?: Maybe<PollVersion>;
};

export type PollversionArgs = {
  version: Scalars['Int'];
};

export type PollCardCommentStepModel = {
  __typename?: 'PollCardCommentStepModel';
  body: Scalars['String'];
  buttonLabel: Scalars['String'];
  title: Scalars['String'];
};

export type PollCardContentModel = {
  __typename?: 'PollCardContentModel';
  actions?: Maybe<Array<ActionUnionModel>>;
  copy: PollCardCopyModel;
  pollSlug: Scalars['String'];
  uuid: Scalars['ID'];
};

export type PollCardCopyModel = {
  __typename?: 'PollCardCopyModel';
  commentStep: PollCardCommentStepModel;
  negativeOutcome: PollCardItemModel;
  positiveOutcome: PollCardItemModel;
  voteStep: PollCardItemModel;
};

export type PollCardItemModel = {
  __typename?: 'PollCardItemModel';
  body: Scalars['String'];
  title: Scalars['String'];
};

export type PollCardModel = {
  __typename?: 'PollCardModel';
  actions?: Maybe<Array<ActionUnionModel>>;
  copy: PollCardCopyModel;
  pollSlug: Scalars['String'];
  uuid: Scalars['ID'];
};

export enum PollMode {
  mood = 'mood',
  singleChoice = 'singleChoice',
  thumbs = 'thumbs',
}

export type PollOptions = MoodOptions | SingleChoiceOptions | ThumbsOptions;

export type PollOrderInput = {
  createdAt?: InputMaybe<OrderByDirection>;
  updatedAt?: InputMaybe<OrderByDirection>;
};

export type PollResults = MoodResults | SingleChoiceResults | ThumbsResults;

export type PollVersion = {
  __typename?: 'PollVersion';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  options: PollOptions;
  poll: Poll;
  results?: Maybe<PollResults>;
  version: Scalars['Int'];
};

export type PollVersionresultsArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
};

export type PollsConnection = {
  __typename?: 'PollsConnection';
  edges: Array<PollsEdge>;
  pageInfo: PageInfo;
};

export type PollsEdge = {
  __typename?: 'PollsEdge';
  cursor: Scalars['String'];
  node: Poll;
};

export type PollsFilterInput = {
  entityType?: InputMaybe<EntityType>;
  mode?: InputMaybe<PollMode>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum PredicateType {
  date = 'date',
  number = 'number',
  string = 'string',
}

export type Preorder = {
  __typename?: 'Preorder';
  allocationDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  startDate: Scalars['DateTime'];
  storeCodes: Array<Scalars['String']>;
  variantSkus: Array<Scalars['String']>;
  variants: Array<Variant>;
};

/** More fields for this type will be defined in following PRs */
export type PreparingToShipEOF = IExternalOrderFulfillment & {
  __typename?: 'PreparingToShipEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

export type PresignedUrl = {
  __typename?: 'PresignedUrl';
  fileUri: Scalars['String'];
};

export type PresignedUrlCreateInput = {
  bucket: Scalars['String'];
  fileName: Scalars['String'];
};

export type PresignedUrlCreateResult = PresignedUrl | PresignedUrlError;

export type PresignedUrlError = {
  __typename?: 'PresignedUrlError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  currency: Scalars['String'];
  inCents: Scalars['Int'];
};

export type PriceInput = {
  currency: Scalars['String'];
  inCents: Scalars['Int'];
};

export type PricingComparatorInput = {
  _eq?: InputMaybe<Scalars['String']>;
};

export type PricingDetails = {
  __typename?: 'PricingDetails';
  discountAmount: Price;
  itemsTotal: Price;
  shippingPrice: Price;
  voucherCode?: Maybe<Scalars['String']>;
};

export type PrintReturnLabel = {
  __typename?: 'PrintReturnLabel';
  /** @deprecated Use grapql __typename instead */
  action: Scalars['String'];
  availableAfter?: Maybe<Scalars['ISO8601DateTime']>;
  remainingMonths?: Maybe<Scalars['String']>;
  unavailableReason?: Maybe<Scalars['String']>;
};

export enum PrivacyCaseSource {
  MANUAL_REQUEST = 'MANUAL_REQUEST',
  REDSHIFT_INACTIVE_CUSTOMER = 'REDSHIFT_INACTIVE_CUSTOMER',
}

export enum PrivacyCaseStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  OVERDUE = 'OVERDUE',
}

export type PrivacyCaseStatusModel = {
  __typename?: 'PrivacyCaseStatusModel';
  externalId?: Maybe<Scalars['String']>;
  gdprRequest: PrivacyGDPRRequest;
  id: Scalars['String'];
  privacyLifecycleEvents: Array<PrivacyLifecycleEventModel>;
  source: PrivacyCaseSource;
  status: PrivacyCaseStatus;
  userId: Scalars['String'];
};

export enum PrivacyGDPRRequest {
  ANONYMIZATION = 'ANONYMIZATION',
  DELETION = 'DELETION',
}

export type PrivacyLifecycleEventModel = {
  __typename?: 'PrivacyLifecycleEventModel';
  comment: Scalars['String'];
  serviceName: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['String'];
};

export type PrivacyManualRequestModel = {
  __typename?: 'PrivacyManualRequestModel';
  gdprRequest: PrivacyGDPRRequest;
  id: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['String'];
};

export type ProcessingNote = TextNoteInterface & {
  __typename?: 'ProcessingNote';
  text?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  accessories?: Maybe<Array<Scalars['String']>>;
  addonRecommendations: Array<AddonsAddon>;
  available: Scalars['Boolean'];
  brandName: Scalars['String'];
  bundle?: Maybe<Bundle>;
  buyoutCalculation: BuyoutCalculation;
  category: Category;
  categoryId?: Maybe<Scalars['ID']>;
  cheapestRentalPlan?: Maybe<RentalPlan>;
  cloudinaryId: Scalars['String'];
  coreAttribute?: Maybe<Scalars['String']>;
  countdownTimers?: Maybe<Array<CountdownTimer>>;
  createdAt: Scalars['ISO8601DateTime'];
  energyLabel?: Maybe<EnergyLabel>;
  highlight?: Maybe<ProductHighlight>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  /** Field used for PDP */
  inStores?: Maybe<Array<Store>>;
  isNew: Scalars['Boolean'];
  logistics?: Maybe<ProductLogistics>;
  marketPrice: Price;
  name: Scalars['String'];
  originalImageUrl: Scalars['String'];
  originalPictures: Array<Scalars['String']>;
  pricing: Array<ProductPricing>;
  /** @deprecated Use promotional_labels (plural) instead */
  promotionalLabel?: Maybe<Scalars['String']>;
  promotionalLabels: Array<Scalars['String']>;
  recommendedForId?: Maybe<Scalars['String']>;
  recommendedForSku?: Maybe<Scalars['String']>;
  /** Field used for PDP */
  relatedProducts?: Maybe<Array<Product>>;
  rentalPlans: Array<RentalPlan>;
  returnSteps: Array<ReturnSteps>;
  sku: Scalars['ID'];
  slug: Scalars['String'];
  specifications?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
  variants: Array<Variant>;
};

export type ProductaddonRecommendationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type ProductbuyoutCalculationArgs = {
  monthlyPriceInCents: Scalars['Int'];
  oneTimeDiscountInCents: Scalars['Int'];
  recurrentDiscountInCents: Scalars['Int'];
};

export type ProductrelatedProductsArgs = {
  inStock?: InputMaybe<Scalars['Boolean']>;
  similarProductsCount?: InputMaybe<Scalars['Int']>;
};

export type ProductrentalPlansArgs = {
  minLengthInMonths?: InputMaybe<Scalars['Int']>;
};

export type ProductvariantsArgs = {
  ean?: InputMaybe<Scalars['String']>;
};

export type ProductContentImageSuggestion = {
  __typename?: 'ProductContentImageSuggestion';
  originalUrl: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export type ProductContentSuggestion = {
  __typename?: 'ProductContentSuggestion';
  specifications?: Maybe<Array<ProductSpecificationSuggestion>>;
  variants: Array<ProductContentSuggestionVariant>;
};

export type ProductContentSuggestionVariant = {
  __typename?: 'ProductContentSuggestionVariant';
  id: Scalars['ID'];
  images?: Maybe<Array<ProductContentImageSuggestion>>;
  sku: Scalars['String'];
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['String'];
  node: Product;
};

export type ProductFilter = KeywordFilter | RangeFilter;

export type ProductFilterError = GenericError & {
  __typename?: 'ProductFilterError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ProductFilterKeywordCreateInput = {
  categoryId: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Int']>;
  storeCodes: Array<Scalars['String']>;
};

export type ProductFilterKeywordUpdateInput = {
  categoryId?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  storeCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductFilterOrderByInput = {
  id?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
};

export type ProductFilterRangeConfigCreateInput = {
  bucketMax?: InputMaybe<Scalars['Float']>;
  bucketMin?: InputMaybe<Scalars['Float']>;
  bucketSize?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type ProductFilterRangeCreateInput = {
  categoryId: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Int']>;
  rangeFilterConfig: ProductFilterRangeConfigCreateInput;
  storeCodes: Array<Scalars['String']>;
};

export type ProductFilterRangeUpdateInput = {
  categoryId?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  rangeFilterConfig?: InputMaybe<ProductFilterRangeConfigCreateInput>;
  storeCodes?: InputMaybe<Array<Scalars['String']>>;
};

export enum ProductFilterType {
  KEYWORD = 'KEYWORD',
  RANGE = 'RANGE',
}

export type ProductFilterWhereInput = {
  categoryId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  storeCodes?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<ProductFilterType>;
};

export type ProductHighlight = {
  __typename?: 'ProductHighlight';
  categoryId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  storeCode: Scalars['String'];
  trial?: Maybe<Scalars['Boolean']>;
};

export type ProductLogistics = {
  __typename?: 'ProductLogistics';
  dimensionsUnit?: Maybe<LogisticsDimensionsUnit>;
  hazmatClass?: Maybe<LogisticsHazmatClass>;
  height?: Maybe<Scalars['Float']>;
  hsCode?: Maybe<Scalars['String']>;
  identifier?: Maybe<LogisticsIdentifier>;
  length?: Maybe<Scalars['Float']>;
  piCode?: Maybe<Scalars['String']>;
  sectionCode?: Maybe<Scalars['String']>;
  unCode?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type ProductLogisticsInput = {
  dimensionsUnit?: InputMaybe<LogisticsDimensionsUnit>;
  hazmatClass?: InputMaybe<LogisticsHazmatClass>;
  height?: InputMaybe<Scalars['Float']>;
  hsCode?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<LogisticsIdentifier>;
  length?: InputMaybe<Scalars['Float']>;
  piCode?: InputMaybe<Scalars['String']>;
  sectionCode?: InputMaybe<Scalars['String']>;
  unCode?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type ProductPricing = ProductPurchasePricing | ProductRentPricing;

export type ProductPurchasePricing = {
  __typename?: 'ProductPurchasePricing';
  oldPrice?: Maybe<Price>;
  price: Price;
  resourceSku: Scalars['String'];
  storeCode: Scalars['String'];
};

export type ProductRentPricing = {
  __typename?: 'ProductRentPricing';
  cheapestRentalPlan: RentalPlan;
  rentalPlans: Array<RentalPlan>;
};

export type ProductSpecificationInput = {
  items: Array<ProductSpecificationItemInput>;
  language: Scalars['String'];
};

export type ProductSpecificationItemInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProductSpecificationSuggestion = {
  __typename?: 'ProductSpecificationSuggestion';
  name: Scalars['String'];
  recommendedValue?: Maybe<Scalars['String']>;
  storedValue?: Maybe<Scalars['String']>;
};

export type ProductVariantCount = {
  __typename?: 'ProductVariantCount';
  companyId: Scalars['ID'];
  count: Scalars['Int'];
};

export type ProductVariantInput = {
  categoryId: Scalars['ID'];
  productSku: Scalars['ID'];
  serialNumber?: InputMaybe<Scalars['ID']>;
  subCategoryId: Scalars['ID'];
  variantSku: Scalars['ID'];
};

export type ProductVariantsCountInput = {
  companyIds: Array<Scalars['ID']>;
  state: Array<ContractState>;
  type: ContractType;
};

export type ProductWhereInput = {
  ean?: InputMaybe<StringComparisonInput>;
  id?: InputMaybe<IntegerComparisonInput>;
  sku?: InputMaybe<StringComparisonInput>;
  slug?: InputMaybe<StringComparisonInput>;
};

export type ProductsQuery = {
  __typename?: 'ProductsQuery';
  categories?: Maybe<Array<Category>>;
  facets?: Maybe<Array<Facet>>;
  pagination: Pagination;
  products: Array<Product>;
};

export type ProductsUploadTask = Task & {
  __typename?: 'ProductsUploadTask';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['Int'];
  errors?: Maybe<Array<Scalars['String']>>;
  fileName: Scalars['String'];
  fileUri: Scalars['String'];
  id: Scalars['ID'];
  status: TaskStatus;
};

export type ProductsUploadTaskCreateInput = {
  fileName: Scalars['String'];
  fileUri: Scalars['String'];
};

export type ProductsWhereInput = {
  slugs?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductsWidget = {
  __typename?: 'ProductsWidget';
  active: Scalars['Boolean'];
  countdown?: Maybe<CountdownTimer>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  productSkus: Array<Scalars['String']>;
  products?: Maybe<Array<Product>>;
  properties?: Maybe<Scalars['JSON']>;
  storeCodes: Array<Scalars['String']>;
  type: WidgetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductsWidgetproductsArgs = {
  storeCode?: InputMaybe<Scalars['String']>;
};

export type ProductsWidgetCreateInput = {
  active?: Scalars['Boolean'];
  name: Scalars['String'];
  position?: Scalars['Float'];
  productSkus?: InputMaybe<Array<Scalars['String']>>;
  properties?: InputMaybe<WidgetProperties>;
  storeCodes: Array<Scalars['String']>;
  type: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type ProductsWidgetError = GenericError & {
  __typename?: 'ProductsWidgetError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ProductsWidgetOrError = ProductsWidget | ProductsWidgetError;

export type ProductsWidgetOrderByInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
};

export type ProductsWidgetPaginationOutput = {
  __typename?: 'ProductsWidgetPaginationOutput';
  rows: Array<ProductsWidget>;
  totalCount: Scalars['Int'];
};

export type ProductsWidgetUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  productSkus?: InputMaybe<Array<Scalars['String']>>;
  properties?: InputMaybe<WidgetProperties>;
  storeCodes?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ProductsWidgetWhereInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<WidgetType>;
};

export type ProfileFilterInput = {
  carrier?: InputMaybe<CarrierName>;
  name?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['ID']>;
};

export enum ProviderCheckResult {
  CLEAR = 'CLEAR',
  CONSIDER = 'CONSIDER',
}

export type ProviderInterface = {
  tracingId: Scalars['String'];
};

/** Autogenerated input type of ProviderLogin */
export type ProviderLoginInput = {
  authCode: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  oauthProvider: ExternalLoginProvider;
  twoFaCode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ProviderLogin. */
export type ProviderLoginPayload = {
  __typename?: 'ProviderLoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  token: GroverAccessToken;
};

export type PublishOrderAllocatedResult = {
  __typename?: 'PublishOrderAllocatedResult';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Purchase = Operation & {
  __typename?: 'Purchase';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: PurchaseState;
  updates: Array<FlowUpdate>;
};

export enum PurchaseMode {
  CUSTOM_PRICE = 'CUSTOM_PRICE',
  MONTHS_TO_OWN = 'MONTHS_TO_OWN',
  /** Old way of purchasing with Salesforce using a fixed price */
  OLD = 'OLD',
}

export type PurchasePayment = PaymentInterface & {
  __typename?: 'PurchasePayment';
  amountBreakdown: Array<PaymentBreakdownItem>;
  basePrice: Price;
  contract?: Maybe<Contract>;
  createdAt: Scalars['ISO8601DateTime'];
  datePaid?: Maybe<Scalars['ISO8601DateTime']>;
  discountableLoyaltyPrice: Price;
  discountedLoyaltyPrice: Price;
  dueDate: DateUnion;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceUrl?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['ISO8601DateTime']>;
  price: Price;
  refundedAmount: Price;
  remoteId?: Maybe<Scalars['String']>;
  state: PaymentState;
  /** @deprecated Use Contract field */
  subscriptionDetails?: Maybe<SubscriptionDetails>;
};

export type PurchaseRequest = {
  __typename?: 'PurchaseRequest';
  name?: Maybe<Scalars['String']>;
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
};

export type PurchaseRequestItem = {
  __typename?: 'PurchaseRequestItem';
  name?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
};

export enum PurchaseState {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  STARTED = 'STARTED',
}

export type PurchaseTerm = {
  __typename?: 'PurchaseTerm';
  available: Scalars['Boolean'];
  availableAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentPrice: Price;
  deactivated: Scalars['Boolean'];
  initialPrice: Price;
  maxCustomPrice?: Maybe<Price>;
  monthsRequired: Scalars['Int'];
  priceAgreedOn: Scalars['Boolean'];
  purchaseMode: PurchaseMode;
  remainingMonths: Scalars['Int'];
  selfServiceAvailable: Scalars['Boolean'];
  unavailabilityReason?: Maybe<PurchaseUnavailabilityReason>;
  unavailableSince?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum PurchaseUnavailabilityReason {
  ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
  B2B_CONTRACT_NOT_COMPLETED = 'B2B_CONTRACT_NOT_COMPLETED',
  CONTRACT_HAS_BEEN_PURCHASED = 'CONTRACT_HAS_BEEN_PURCHASED',
  CONTRACT_HAS_OUTSTANDING_DEBT = 'CONTRACT_HAS_OUTSTANDING_DEBT',
  CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
  CUSTOM_PRICE_OFFER_NOT_AVAILABLE = 'CUSTOM_PRICE_OFFER_NOT_AVAILABLE',
  DISABLE_PURCHASE_VOUCHER = 'DISABLE_PURCHASE_VOUCHER',
  DISCOUNT_OFFER_ACCEPTED = 'DISCOUNT_OFFER_ACCEPTED',
  EXISTING_CONTRACT_DISCOUNT = 'EXISTING_CONTRACT_DISCOUNT',
  EXISTING_DISCOUNT_OFFER_ACCEPTED = 'EXISTING_DISCOUNT_OFFER_ACCEPTED',
  PURCHASE_OPTION_DEACTIVATED = 'PURCHASE_OPTION_DEACTIVATED',
  RENT_IS_ZERO = 'RENT_IS_ZERO',
  SWAP_DISCOUNT_OFFER_ACCEPTED = 'SWAP_DISCOUNT_OFFER_ACCEPTED',
  TOS_EXCLUDES_PURCHASE_OPTION = 'TOS_EXCLUDES_PURCHASE_OPTION',
}

export type QualitativeShippingSummaryType = {
  __typename?: 'QualitativeShippingSummaryType';
  attemptedDeliveryAddressesLeadingToAccessPoint: Array<Scalars['String']>;
  failedDeliveryAddresses: Array<Scalars['String']>;
  successfulDeliveryAddresses: Array<Scalars['String']>;
  successfulDeliveryAtAccessPointAddresses: Array<Scalars['String']>;
};

export type QuantitativeShippingSummaryType = {
  __typename?: 'QuantitativeShippingSummaryType';
  failedDeliveries: Scalars['Float'];
  idVerificationAt?: Maybe<Scalars['DateTimeISO']>;
  successfulDeliveries: Scalars['Float'];
  successfulDeliveriesByAccessPoint: Scalars['Float'];
  successfulDeliveriesByProfile: Array<ShippingSummaryDeliveryProfileMetricType>;
};

export type Query = {
  __typename?: 'Query';
  B2BDashboardAccessRight: DashboardAccessRights;
  /** Returns marketing data for the user level */
  MarketingContent: Array<CardTypeUnionModel>;
  addonAssociation: AddonAssociation;
  addonAssociationById: AddonAssociation;
  addonAssociations: AddonAssociationsResult;
  asset: AssetModel;
  assetAllocationCollection: AssetAllocationCollection;
  assetAllocations: Array<AssetAllocation>;
  assetCapitalSources: Array<Scalars['String']>;
  assetSerialNumber: SerialNumberLookup;
  assetStatusTransitions: Array<AssetStatusTransition>;
  assetSuppliers: Array<Scalars['String']>;
  assetWarehouseCodes: Array<Scalars['String']>;
  assets: AssetCollection;
  availabilityCampaigns: AvailabilityCampaignCollection;
  billingBreakdown: BillingBreakdown;
  billingChargeRequest: BillingChargeRequest;
  billingChargeRequests: PaginatedBillingChargeRequest;
  billingGroup: BillingGroup;
  billingGroupingStrategies: PaginatedBillingGroupingStrategies;
  billingGroupingStrategy: BillingGroupingStrategyObject;
  billingGroups: PaginatedBillingGroups;
  billingRetryOperation: BillingRetryOperation;
  billingSchedule: BillingSchedule;
  billingSchedules: PaginatedBillingSchedules;
  bulkMatchProfile: Array<BulkDeliveryProfileRuleMatchResult>;
  bundle?: Maybe<Bundle>;
  bundles: BundlePaginationOutput;
  /** Returns a campaign by uuid */
  campaign: CampaignModel;
  /** Returns all campaigns, can be filtered and paginated (default limit 100) */
  campaignList: Array<CampaignModel>;
  cart?: Maybe<MixCartOrder>;
  cartWithKit?: Maybe<CartWithKit>;
  cartWithKitByOrderId?: Maybe<CartWithKit>;
  catalogGrouping?: Maybe<CatalogGrouping>;
  catalogGroupings: Array<CatalogGrouping>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  checkoutAttempt?: Maybe<NethoneOrder>;
  companies: CompanyConnection;
  company: Company;
  companyTypes: Array<CompanyType>;
  contract?: Maybe<Contract>;
  contractPaymentGroups: Array<ContractPaymentGroupModel>;
  contractRecurrentPayment: ContractServiceRecurrentModel;
  contracts?: Maybe<ContractConnection>;
  contractsRequiringDecision: Array<Contract>;
  dashboardGroupPayment?: Maybe<GroupPayment>;
  dashboardMixSubscription?: Maybe<DashboardMixSubscription>;
  defaultShippingAddress: DefaultShippingAddress;
  defaultShippingAddressHistory: Array<DefaultShippingAddress>;
  deliveryProfiles: Array<DeliveryProfile>;
  deliveryRules: Array<DeliveryProfileRule>;
  deliveryRulesConnection: DeliveryRuleConnection;
  distributionRules: DistributionRuleCollection;
  energyLabel?: Maybe<EnergyLabel>;
  energyLabels: EnergyLabelPaginationOutput;
  externalOrderFulfillment?: Maybe<ExternalOrderFulfillmentUnion>;
  externalOrderFulfillmentConnection: ExternalOrderFulfillmentConnection;
  favorites: Array<Product>;
  featuredPayment?: Maybe<PaymentsSummaryByStateUnion>;
  fetchAddresses?: Maybe<Array<Maybe<Address>>>;
  fetchAllConnections: FetchAllConnectionResponse;
  findAllCountdownTimers: CountdownTimerPaginationOutput;
  /** Returns last 100 campaign dismisses */
  findAllDismisses: Array<DismissModel>;
  /** Returns last 100 finished campaigns */
  findAllFinished: Array<FinishedModel>;
  findCountdownTimerById?: Maybe<CountdownTimer>;
  gbpPayments?: Maybe<PaymentsUnionConnection>;
  getActiveCampaignsForUser: Array<CampaignUserStateModel>;
  getAllPaymentStats: PaymentOrderTotals;
  getAllPayments: GetPaymentOrdersResponse;
  getCampaignsForUser: Array<CampaignUserStateModel>;
  getCartByUser?: Maybe<MixCartOrder>;
  getConnectionDetails: GetConnectionDetailsResponse;
  getCurrentReferralCampaign: ReferralCampaign;
  /** Returns notification enrich mappings */
  getNotificationEnrichMappings: Array<NotificationEnrichMapping>;
  getOrder?: Maybe<Order>;
  getPaymentById: PaymentOrderModel;
  getPaymentOrderCancelReasons: Array<Reason>;
  getPaymentOrderHistory: PaymentOrderHistoryResponse;
  getPaymentOrderHoldReasons: Array<Reason>;
  getPaymentOrderRefundReasons: Array<Reason>;
  getPaymentOrderRetryReasons: Array<Reason>;
  getUserPaymentStats: PaymentOrderTotals;
  getVote: Vote;
  goodsOrderConnection: GoodsOrderConnection;
  goodsOrderCount: Scalars['Float'];
  goodsOrderWarehouseEvents: GoodsOrderWarehouseEventsCollection;
  header: Header;
  headerSearch: HeaderSearchResultV2;
  internalProducts: PaginatedInternalProducts;
  invoice?: Maybe<Invoice>;
  invoiceByResourceId?: Maybe<Invoice>;
  invoiceEventPayload?: Maybe<Scalars['JSON']>;
  invoices?: Maybe<InvoiceConnection>;
  isUsersFirstPollVote: Scalars['Boolean'];
  kit?: Maybe<Kit>;
  kits: PaginatedKitConnection;
  latestTermsAndConditions?: Maybe<TermsAndConditions>;
  legacySubscriptions?: Maybe<SubscriptionDetailsConnection>;
  loyaltyAdminInfo: LoyaltyAdminPanelResult;
  loyaltyAdminPanelStatistic: LoyaltyAdminPanelStatisticResult;
  loyaltyAdminRules: Array<LoyaltyAdminRuleResult>;
  loyaltyAdminTransactions: Array<LoyaltyAdminCreditTransactionResult>;
  loyaltyBalance: LoyaltyBalanceResult;
  loyaltyCreditTransactionList: Array<LoyaltyCreditTransactionResult>;
  loyaltyShopProductList: Array<LoyaltyShopProductResult>;
  loyaltyShopPurchaseList: Array<LoyaltyShopPurchaseResult>;
  matchCarriers: ApplicableCarrierResultGraphql;
  matchProfile: DeliveryProfileRuleMatchResult;
  movement?: Maybe<Movement>;
  movements?: Maybe<MovementConnection>;
  notificationSendResult?: Maybe<NotificationSendResult>;
  notificationSendResultAsPDF?: Maybe<NotificationSendResultAsPDF>;
  notificationSendResults: SendResultConnection;
  /** Returns notification trigger filter by uuid */
  notificationTriggerFilter: NotificationTriggerFilter;
  /** Returns all notification trigger filters */
  notificationTriggerFilters: Array<NotificationTriggerFilter>;
  order?: Maybe<CartOrder>;
  /** @deprecated Use 'orderFulfillments' instead */
  orderFulfillmentCollection: OrderFulfillmentCollection;
  orderFulfillments: OrderFulfillmentCollection;
  orderFulfillmentsConnection: OrderFulfillmentConnection;
  orderFulfillmentsCount: Scalars['Float'];
  orderOffer?: Maybe<OrderOffer>;
  orderOfferByOrder?: Maybe<OrderOffer>;
  orderOffersPaginated?: Maybe<OrderOffersConnection>;
  packageItems: PackageItemCollection;
  partners: Array<Partner>;
  paymentGateways: Array<PaymentGateway>;
  paymentGroup: PaymentGroupModel;
  paymentGroups: Array<PaymentGroupsModel>;
  /** Get a payment method by ID. The ID can be Wallet ID, billing account ID, Grover API ID or IXOPAY reference ID. */
  paymentMethod?: Maybe<PaymentMethod2>;
  /** Get a long-running background operation related to a payment method. */
  paymentMethodOperation?: Maybe<PaymentMethodOperation>;
  /** List payment methods of a user. The user is identified either by the request or the user ID filter. */
  paymentMethods: Array<PaymentMethod2>;
  /** List payment options (payment method types) available to the current user. */
  paymentOptions: Array<PaymentOption>;
  paymentSummaries: PaymentsSummaryByStateUnionConnection;
  payments?: Maybe<PaymentsUnionConnection>;
  permissions: Array<Permission>;
  poll: Poll;
  pollCollection: PollsConnection;
  polls: Array<Poll>;
  privacyCaseStatusRequest: Array<PrivacyCaseStatusModel>;
  product?: Maybe<Product>;
  productContentSuggestion?: Maybe<ProductContentSuggestion>;
  productFilter?: Maybe<ProductFilter>;
  productFilters: Array<ProductFilter>;
  productVariantsCount: Array<ProductVariantCount>;
  products?: Maybe<Array<Product>>;
  productsWidget?: Maybe<ProductsWidget>;
  productsWidgets: ProductsWidgetPaginationOutput;
  recentlyViewed: Array<Product>;
  recommendedProducts: RecommendedProductsQuery;
  recurrentPayment: RecurrentPaymentModel;
  recurrentPayments: Array<RecurrentPaymentModel>;
  rentalPlans: PaginatedRentalPlans;
  reservations: ReservationCollection;
  resource?: Maybe<Resource>;
  resources?: Maybe<ResourceConnection>;
  resourcesGroupedByOrders?: Maybe<ResourcesGroupedByOrders>;
  retailEmployeeApprovalStatus?: Maybe<Scalars['Boolean']>;
  retrieveAddress?: Maybe<Address>;
  searchProducts: ProductsQuery;
  shipments: ShipmentCollection;
  shippingAddresses: ShippingAddressCollection;
  shippingRequests: ShippingRequestCollection;
  stockCollection: StockCollection;
  stockTransfers: StockTransferCollection;
  stockVariantAvailability: StockVariantAvailabilityCollection;
  stocks: Array<Stock>;
  store?: Maybe<Store>;
  storeCollection?: Maybe<StoreOffsetCollection>;
  storeStockConfiguration: StoreStockConfigurationGraphql;
  storeVariantAvailability: StoreVariantAvailabilityCollection;
  stores?: Maybe<StoreConnection>;
  subscriptionCollection?: Maybe<SubscriptionListEntryConnection>;
  subscriptionDetails?: Maybe<FlexDetails>;
  subscriptionSummary: Array<SubscriptionSummary>;
  subscriptionsRequiringDecision: Array<SubscriptionDetails>;
  task?: Maybe<Task>;
  tasks: PaginatedTasks;
  teams: Array<Team>;
  termsAndConditions?: Maybe<TermsAndConditions>;
  termsAndConditionsList?: Maybe<TermsAndConditionsConnection>;
  termsAndConditionsLocales?: Maybe<TermsAndConditionsLocales>;
  transaction?: Maybe<Transaction>;
  transactions?: Maybe<TransactionConnection>;
  unrecheable?: Maybe<Array<Maybe<OhOh>>>;
  user?: Maybe<User>;
  userExists: Scalars['Boolean'];
  userShippingSummary: UserShippingSummaryType;
  users?: Maybe<UserConnection>;
  validateCode: ReferralCodeValidation;
  validateMixProductSelection: ContractValidationResult;
  validateSalesOrderAssets: ValidSalesOrderAssetsType;
  verification?: Maybe<Verification>;
  verifications?: Maybe<VerificationConnection>;
  votes: VotesConnection;
  wallet: WalletModel;
  walletByUserId: WalletModel;
  wallets: Array<WalletModel>;
  warehouseEvents: WarehouseEvent;
  workAddress?: Maybe<Address>;
  workAsset?: Maybe<WorkAsset>;
  workAssets: WorkAssetsConnection;
  workEmployee?: Maybe<WorkEmployee>;
  workEmployees: WorkEmployeeConnection;
  /** Finds the employees that match by name, phone or email */
  workEmployeesByPersonalInfo: Array<WorkEmployee>;
};

export type QueryB2BDashboardAccessRightArgs = {
  companyId: Scalars['Int'];
};

export type QueryMarketingContentArgs = {
  page?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<PlatformTypes>;
};

export type QueryaddonAssociationArgs = {
  input: AddonAssociationInput;
};

export type QueryaddonAssociationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryaddonAssociationsArgs = {
  input: AddonAssociationsInput;
};

export type QueryassetArgs = {
  uid: Scalars['String'];
};

export type QueryassetAllocationCollectionArgs = {
  filter?: InputMaybe<AssetAllocationFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QueryassetAllocationsArgs = {
  filter?: InputMaybe<AssetAllocationFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
};

export type QueryassetSerialNumberArgs = {
  serialNumber: Scalars['String'];
  sku?: InputMaybe<Scalars['String']>;
};

export type QueryassetStatusTransitionsArgs = {
  status?: InputMaybe<AssetStatus>;
};

export type QueryassetsArgs = {
  filter?: InputMaybe<AssetFilterInput>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryavailabilityCampaignsArgs = {
  filter?: InputMaybe<AvailabilityCampaignFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QuerybillingBreakdownArgs = {
  contractId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QuerybillingChargeRequestArgs = {
  id: Scalars['String'];
};

export type QuerybillingChargeRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  descending?: Scalars['Boolean'];
  first?: Scalars['Int'];
};

export type QuerybillingGroupArgs = {
  id: Scalars['String'];
};

export type QuerybillingGroupingStrategiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  descending?: Scalars['Boolean'];
  first?: Scalars['Int'];
};

export type QuerybillingGroupingStrategyArgs = {
  id: Scalars['String'];
};

export type QuerybillingGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  descending?: Scalars['Boolean'];
  first?: Scalars['Int'];
};

export type QuerybillingRetryOperationArgs = {
  id: Scalars['ID'];
};

export type QuerybillingScheduleArgs = {
  id: Scalars['String'];
};

export type QuerybillingSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  descending?: Scalars['Boolean'];
  filter: PaginatedScheduleInput;
  first?: Scalars['Int'];
};

export type QuerybulkMatchProfileArgs = {
  filters: Array<BulkDeliveryProfileRuleMatchInput>;
};

export type QuerybundleArgs = {
  where: BundleWhereUniqueInput;
};

export type QuerybundlesArgs = {
  cursor?: InputMaybe<BundleWhereUniqueInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BundleOrderByInput>;
  where?: InputMaybe<BundleWhereInput>;
};

export type QuerycampaignArgs = {
  campaignUuid: Scalars['String'];
};

export type QuerycampaignListArgs = {
  filter?: InputMaybe<CampaignFilterInputType>;
  pagination?: InputMaybe<PaginationInputType>;
};

export type QuerycartArgs = {
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type QuerycartWithKitArgs = {
  uuid: Scalars['String'];
};

export type QuerycartWithKitByOrderIdArgs = {
  orderId: Scalars['String'];
};

export type QuerycatalogGroupingArgs = {
  name: Scalars['String'];
};

export type QuerycategoriesArgs = {
  parentId?: InputMaybe<Scalars['ID']>;
  storeCode?: InputMaybe<Scalars['String']>;
};

export type QuerycategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  permalink?: InputMaybe<Scalars['String']>;
};

export type QuerycheckoutAttemptArgs = {
  reference: Scalars['String'];
};

export type QuerycompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompaniesWhereInput>;
};

export type QuerycompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  viban?: InputMaybe<Scalars['String']>;
};

export type QuerycompanyTypesArgs = {
  country: Scalars['String'];
};

export type QuerycontractArgs = {
  id: Scalars['ID'];
};

export type QuerycontractPaymentGroupsArgs = {
  filter: ContractPaymentGroupFilterInput;
};

export type QuerycontractRecurrentPaymentArgs = {
  externalContract: Scalars['String'];
};

export type QuerycontractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ContractFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QuerycontractsRequiringDecisionArgs = {
  version: Scalars['ID'];
};

export type QuerydashboardGroupPaymentArgs = {
  id: Scalars['ID'];
};

export type QuerydefaultShippingAddressArgs = {
  filter: DefaultShippingAddressFilterType;
};

export type QuerydefaultShippingAddressHistoryArgs = {
  filter: DefaultShippingAddressFilterType;
};

export type QuerydeliveryProfilesArgs = {
  filter?: ProfileFilterInput;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortColumnType>>;
};

export type QuerydeliveryRulesArgs = {
  filter?: DeliveryRuleFilterInput;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortColumnType>>;
};

export type QuerydeliveryRulesConnectionArgs = {
  filter?: InputMaybe<DeliveryRuleFilterCompareInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortColumnType>>;
};

export type QuerydistributionRulesArgs = {
  filter?: InputMaybe<DistributionRuleFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QueryenergyLabelArgs = {
  id: Scalars['Int'];
};

export type QueryenergyLabelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EnergyLabelOrderByInput>;
  where?: InputMaybe<EnergyLabelWhereInput>;
};

export type QueryexternalOrderFulfillmentArgs = {
  orderNumber?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryexternalOrderFulfillmentConnectionArgs = {
  filterInput?: InputMaybe<ExternalOrderFulfillmentFilterInput>;
  modifier?: InputMaybe<ExternalOrderFulfillmentFilterModifier>;
  paginationInput?: InputMaybe<RelayPaginationInput>;
};

export type QueryfeaturedPaymentArgs = {
  input: FeaturedPaymentInput;
};

export type QueryfetchAddressesArgs = {
  allAddresses?: InputMaybe<Scalars['Boolean']>;
  filterOptions?: InputMaybe<FilterOptions>;
};

export type QueryfetchAllConnectionsArgs = {
  filter: AllConnectionsFilter;
};

export type QueryfindAllCountdownTimersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CountdownTimerOrderByInput>;
  where?: InputMaybe<CountdownTimerWhereInput>;
};

export type QueryfindCountdownTimerByIdArgs = {
  id: Scalars['ID'];
};

export type QuerygbpPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
  where?: InputMaybe<PaymentsWhereInput>;
};

export type QuerygetActiveCampaignsForUserArgs = {
  userId: Scalars['String'];
};

export type QuerygetAllPaymentStatsArgs = {
  filter: GetPaymentOrdersFilters;
};

export type QuerygetAllPaymentsArgs = {
  filter: GetPaymentOrdersFilters;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QuerygetCampaignsForUserArgs = {
  userId: Scalars['String'];
};

export type QuerygetCartByUserArgs = {
  userIdentifier: Scalars['String'];
};

export type QuerygetConnectionDetailsArgs = {
  guestId?: InputMaybe<Scalars['String']>;
};

export type QuerygetNotificationEnrichMappingsArgs = {
  filter?: InputMaybe<NotificationEnrichMappingListInput>;
};

export type QuerygetOrderArgs = {
  orderNumber: Scalars['String'];
};

export type QuerygetPaymentByIdArgs = {
  id: Scalars['String'];
};

export type QuerygetPaymentOrderHistoryArgs = {
  contractId: Scalars['String'];
};

export type QuerygetUserPaymentStatsArgs = {
  filter: GetUserPaymentOrdersFilters;
};

export type QuerygetVoteArgs = {
  ticket: Scalars['String'];
};

export type QuerygoodsOrderConnectionArgs = {
  after?: InputMaybe<WMSCursorFilterInput>;
  before?: InputMaybe<WMSCursorFilterInput>;
  filter?: InputMaybe<Array<GoodsOrderFilterType>>;
  limit?: InputMaybe<Scalars['Float']>;
};

export type QuerygoodsOrderCountArgs = {
  filter?: InputMaybe<Array<GoodsOrderFilterType>>;
};

export type QuerygoodsOrderWarehouseEventsArgs = {
  whGoodsOrderId: Scalars['String'];
};

export type QueryheaderArgs = {
  consumer: HeaderConsumer;
  orderNumber?: InputMaybe<Scalars['String']>;
  orderToken?: InputMaybe<Scalars['String']>;
  ssr?: InputMaybe<Scalars['Boolean']>;
};

export type QueryheaderSearchArgs = {
  consumer: HeaderConsumer;
  q: Scalars['String'];
};

export type QueryinternalProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InternalProductsWhereInput>;
};

export type QueryinvoiceArgs = {
  id: Scalars['String'];
};

export type QueryinvoiceByResourceIdArgs = {
  resourceId: Scalars['String'];
};

export type QueryinvoiceEventPayloadArgs = {
  id: Scalars['String'];
  invoiceType?: InputMaybe<InvoiceEventType>;
};

export type QueryinvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<InvoicesFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryisUsersFirstPollVoteArgs = {
  pollSlug: Scalars['String'];
};

export type QuerykitArgs = {
  uuid: Scalars['String'];
};

export type QuerykitsArgs = {
  filterInput?: InputMaybe<KitsFilterInput>;
  paginationInput: RelayPaginationInput;
  sortInput?: InputMaybe<KitSortInput>;
};

export type QuerylatestTermsAndConditionsArgs = {
  storeCode: Scalars['String'];
};

export type QuerylegacySubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryloyaltyAdminInfoArgs = {
  userId: Scalars['String'];
};

export type QueryloyaltyAdminPanelStatisticArgs = {
  startDate: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryloyaltyAdminRulesArgs = {
  filterInfo: LoyaltyFilterOptionsInfo;
};

export type QueryloyaltyAdminTransactionsArgs = {
  filterInfo: LoyaltyFilterOptionsInfo;
};

export type QuerymatchCarriersArgs = {
  filter: DeliveryProfileMatchInput;
};

export type QuerymatchProfileArgs = {
  filter: DeliveryProfileMatchInput;
};

export type QuerymovementArgs = {
  id: Scalars['ID'];
};

export type QuerymovementsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type QuerynotificationSendResultArgs = {
  id: Scalars['ID'];
};

export type QuerynotificationSendResultAsPDFArgs = {
  id: Scalars['ID'];
};

export type QuerynotificationSendResultsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SendResultWhereInput>;
};

export type QuerynotificationTriggerFilterArgs = {
  uuid: Scalars['ID'];
};

export type QuerynotificationTriggerFiltersArgs = {
  filter?: InputMaybe<NotificationTriggerFilterListInput>;
};

export type QueryorderArgs = {
  orderNumber: Scalars['String'];
};

export type QueryorderFulfillmentCollectionArgs = {
  filter?: InputMaybe<FindAndCountInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QueryorderFulfillmentsArgs = {
  filter?: InputMaybe<OrderFulfillmentFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<ColumnSortType>>;
};

export type QueryorderFulfillmentsConnectionArgs = {
  after?: InputMaybe<CursorFilterType>;
  before?: InputMaybe<CursorFilterType>;
  filter?: InputMaybe<OrderFulfillmentFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
};

export type QueryorderFulfillmentsCountArgs = {
  filter?: InputMaybe<OrderFulfillmentFilterInput>;
};

export type QueryorderOfferArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryorderOfferByOrderArgs = {
  orderNumber?: InputMaybe<Scalars['ID']>;
};

export type QueryorderOffersPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<OrderOfferFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QuerypackageItemsArgs = {
  filter?: InputMaybe<PackageItemFilter>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QuerypartnersArgs = {
  where?: InputMaybe<PartnerWhereInput>;
};

export type QuerypaymentGroupArgs = {
  uuid: Scalars['String'];
};

export type QuerypaymentGroupsArgs = {
  filter: GraphQLFilterPaymentGroups;
};

export type QuerypaymentMethodArgs = {
  id: Scalars['ID'];
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QuerypaymentMethodOperationArgs = {
  id: Scalars['ID'];
};

export type QuerypaymentMethodsArgs = {
  filter?: InputMaybe<PaymentMethodsFilterType>;
};

export type QuerypaymentSummariesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input: FeaturedPaymentInput;
  last?: InputMaybe<Scalars['Int']>;
};

export type QuerypaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
  where?: InputMaybe<PaymentsWhereInput>;
};

export type QuerypollArgs = {
  slug: Scalars['String'];
};

export type QuerypollCollectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PollsFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PollOrderInput>;
};

export type QuerypollsArgs = {
  filter?: InputMaybe<PollsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryprivacyCaseStatusRequestArgs = {
  userId: Scalars['String'];
};

export type QueryproductArgs = {
  id?: InputMaybe<Scalars['ID']>;
  where?: InputMaybe<ProductWhereInput>;
};

export type QueryproductContentSuggestionArgs = {
  sku: Scalars['String'];
};

export type QueryproductFilterArgs = {
  id: Scalars['Int'];
};

export type QueryproductFiltersArgs = {
  orderBy?: InputMaybe<ProductFilterOrderByInput>;
  where?: InputMaybe<ProductFilterWhereInput>;
};

export type QueryproductVariantsCountArgs = {
  filter: ProductVariantsCountInput;
};

export type QueryproductsArgs = {
  skus?: InputMaybe<Array<Scalars['String']>>;
  where?: InputMaybe<ProductsWhereInput>;
  withCategory?: InputMaybe<Scalars['Boolean']>;
};

export type QueryproductsWidgetArgs = {
  id: Scalars['Int'];
};

export type QueryproductsWidgetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductsWidgetOrderByInput>;
  where?: InputMaybe<ProductsWidgetWhereInput>;
};

export type QueryrecentlyViewedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryrecurrentPaymentArgs = {
  uuid: Scalars['String'];
};

export type QueryrecurrentPaymentsArgs = {
  filter: GraphQLFilter;
};

export type QueryrentalPlansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RentalPlanWhereInput>;
};

export type QueryreservationsArgs = {
  filter?: InputMaybe<ReservationFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QueryresourceArgs = {
  id: Scalars['ID'];
};

export type QueryresourcesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResourcesWhere>;
};

export type QueryresourcesGroupedByOrdersArgs = {
  first: Scalars['Int'];
  where: ResourcesWhere;
};

export type QueryretailEmployeeApprovalStatusArgs = {
  input?: InputMaybe<CheckEmployeeApprovalInput>;
};

export type QueryretrieveAddressArgs = {
  providerId?: InputMaybe<Scalars['ID']>;
};

export type QuerysearchProductsArgs = {
  filters?: InputMaybe<FilterArgs>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};

export type QueryshipmentsArgs = {
  filter?: InputMaybe<Array<ShipmentFilterType>>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<ColumnOrderType>>;
};

export type QueryshippingAddressesArgs = {
  filter?: InputMaybe<ShippingAddressFilterType>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QueryshippingRequestsArgs = {
  filter?: InputMaybe<ShippingRequestFilterType>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QuerystockCollectionArgs = {
  filter?: InputMaybe<StockFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QuerystockTransfersArgs = {
  filter?: InputMaybe<StockTransferFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerystockVariantAvailabilityArgs = {
  filter?: InputMaybe<StockVariantAvailabilityFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QuerystocksArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Float']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type QuerystoreArgs = {
  id?: InputMaybe<Scalars['ID']>;
  storeCode?: InputMaybe<Scalars['String']>;
};

export type QuerystoreCollectionArgs = {
  filter?: InputMaybe<StoresFilterInput>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type QuerystoreVariantAvailabilityArgs = {
  filter?: InputMaybe<StoreVariantAvailabilityFilterInput>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QuerystoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  enabledOnFooter?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  storeType?: InputMaybe<StoreTypes>;
};

export type QuerysubscriptionCollectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: SubscriptionListFilterInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type QuerysubscriptionDetailsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  number?: InputMaybe<Scalars['String']>;
};

export type QuerysubscriptionsRequiringDecisionArgs = {
  version: Scalars['ID'];
};

export type QuerytaskArgs = {
  id: Scalars['ID'];
};

export type QuerytasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where: TasksWhereInput;
};

export type QuerytermsAndConditionsArgs = {
  version: Scalars['ID'];
};

export type QuerytermsAndConditionsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TermsAndConditionsFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QuerytermsAndConditionsLocalesArgs = {
  version: Scalars['ID'];
};

export type QuerytransactionArgs = {
  id: Scalars['ID'];
};

export type QuerytransactionsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryuserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryuserExistsArgs = {
  email: Scalars['String'];
};

export type QueryuserShippingSummaryArgs = {
  userId: Scalars['String'];
};

export type QueryusersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UsersWhereInput>;
};

export type QueryvalidateCodeArgs = {
  input: ReferralCodeValidationInput;
};

export type QueryvalidateMixProductSelectionArgs = {
  products: Array<ProductVariantInput>;
};

export type QueryvalidateSalesOrderAssetsArgs = {
  salesOrder: SalesOrderAssetInput;
};

export type QueryverificationArgs = {
  id: Scalars['ID'];
};

export type QueryverificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VerificationsFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryvotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<VoteOrderInput>;
  where?: InputMaybe<VoteWhereInput>;
};

export type QuerywalletArgs = {
  uuid: Scalars['String'];
};

export type QuerywalletByUserIdArgs = {
  userId: Scalars['String'];
};

export type QuerywalletsArgs = {
  filter: GraphQLFilter;
};

export type QuerywarehouseEventsArgs = {
  filter?: InputMaybe<Array<WarehouseEventsFilterType>>;
};

export type QueryworkAddressArgs = {
  uuid: Scalars['ID'];
};

export type QueryworkAssetArgs = {
  uuid: Scalars['String'];
};

export type QueryworkAssetsArgs = {
  filterInput?: InputMaybe<WorkAssetsFilterInput>;
  paginationInput: WorkPaginationInput;
  sortInput?: InputMaybe<WorkAssetSortInput>;
};

export type QueryworkEmployeeArgs = {
  uuid: Scalars['String'];
};

export type QueryworkEmployeesArgs = {
  filterInput?: InputMaybe<WorkEmployeesFilterInput>;
  paginationInput: WorkPaginationInput;
  sortInput?: InputMaybe<WorkEmployeeSortInput>;
};

export type QueryworkEmployeesByPersonalInfoArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  needle: Scalars['String'];
};

export type RangeFacet = Facet & {
  __typename?: 'RangeFacet';
  max: Scalars['Int'];
  min: Scalars['Int'];
  name: Scalars['String'];
};

export type RangeFilter = {
  __typename?: 'RangeFilter';
  category?: Maybe<Category>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  rangeFilterConfig: RangeFilterConfig;
  storeCodes: Array<Scalars['String']>;
  type: ProductFilterType;
};

export type RangeFilterConfig = {
  __typename?: 'RangeFilterConfig';
  bucketMax?: Maybe<Scalars['Float']>;
  bucketMin?: Maybe<Scalars['Float']>;
  bucketSize?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
};

export type RangeFilterInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type RangeFilterOrError = ProductFilterError | RangeFilter;

/** Autogenerated input type of ReactivateContract */
export type ReactivateContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of ReactivateContract. */
export type ReactivateContractPayload = {
  __typename?: 'ReactivateContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

/** Autogenerated input type of ReallocateAsset */
export type ReallocateAssetInput = {
  assetUid: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of ReallocateAsset. */
export type ReallocateAssetPayload = {
  __typename?: 'ReallocateAssetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type ReallocationAvailability = {
  __typename?: 'ReallocationAvailability';
  available: Scalars['Boolean'];
  isAssetAllocatedToNewOrder?: Maybe<Scalars['Boolean']>;
  noStock?: Maybe<Scalars['Boolean']>;
  unavailabilityReason?: Maybe<Scalars['String']>;
};

export type Reason = {
  __typename?: 'Reason';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type RecommendedProductsQuery = {
  __typename?: 'RecommendedProductsQuery';
  products: Array<Product>;
};

export type RecurrentPaymentModel = {
  __typename?: 'RecurrentPaymentModel';
  additionalData: RecurrentType;
  externalCustomer: Scalars['String'];
  isManagedByScheduler?: Maybe<Scalars['Boolean']>;
  paymentGroups: Array<PaymentGroupModel>;
  scopeUuid?: Maybe<Scalars['String']>;
  status: RecurrentPaymentStatusTypes;
  type: Scalars['String'];
  uuid: Scalars['ID'];
};

export enum RecurrentPaymentStatusTypes {
  active = 'active',
  cancelled = 'cancelled',
  failed = 'failed',
  finished = 'finished',
  paused = 'paused',
}

export type RecurrentType = LoopSimulatorModel | SimpleSubscriptionModel;

export type ReferralCampaign = {
  __typename?: 'ReferralCampaign';
  defaultStoreCode: Scalars['String'];
  guestCashAmount: Scalars['Int'];
  hostCashAmount: Scalars['Int'];
};

export type ReferralCodeValidation = {
  __typename?: 'ReferralCodeValidation';
  defaultStoreCode?: Maybe<Scalars['String']>;
  hostFirstName: Scalars['String'];
  isReferralProgramUpdatesEnabled: Scalars['Boolean'];
  isValid: Scalars['Boolean'];
};

export type ReferralCodeValidationInput = {
  code: Scalars['String'];
};

/** Shows the current step of a referral connection. AccountCreated, FirstPaymentPaid, ContractActivated, Revoked */
export enum ReferralConnectionStep {
  AccountCreated = 'AccountCreated',
  ContractActivated = 'ContractActivated',
  FirstPaymentPaid = 'FirstPaymentPaid',
  Revoked = 'Revoked',
}

/** Referral service create connection response codes. */
export enum ReferralCreateConnectionResponse {
  AlreadyHasConnection = 'AlreadyHasConnection',
  CreatedSuccessfully = 'CreatedSuccessfully',
  DifferentStoreCodes = 'DifferentStoreCodes',
  HasContractsOrSubscriptions = 'HasContractsOrSubscriptions',
  HostLimitExceeded = 'HostLimitExceeded',
  IsNotEligible = 'IsNotEligible',
  OwnCode = 'OwnCode',
}

export enum RefundReason {
  agreementWithCustomer = 'agreementWithCustomer',
  assetCondition = 'assetCondition',
  assetNotAvailable = 'assetNotAvailable',
  contractCanceled = 'contractCanceled',
  doubleCharges = 'doubleCharges',
  failedDelivery = 'failedDelivery',
  freeShipment = 'freeShipment',
  lateDelivery = 'lateDelivery',
  priceChanged = 'priceChanged',
  returnProcess = 'returnProcess',
  revocation = 'revocation',
  voucherDiscountNotApplied = 'voucherDiscountNotApplied',
}

export type RegisterIncomingAssetsResult = {
  __typename?: 'RegisterIncomingAssetsResult';
  error: Scalars['Boolean'];
  message: Scalars['String'];
};

export type RelayPageInfo = {
  __typename?: 'RelayPageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type RelayPaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  /** Is optional, if provided following validation is applied: min 1 and max 1000 */
  first?: InputMaybe<Scalars['Int']>;
  /** Is optional, if provided following validation is applied: min 1 and max 1000 */
  last?: InputMaybe<Scalars['Int']>;
};

export type RemoveAddonAssociationsInput = {
  ids: Array<Scalars['Int']>;
};

export type RemoveAddonAssociationsResult = {
  __typename?: 'RemoveAddonAssociationsResult';
  count: Scalars['Int'];
};

export type RemoveBackgroundBatchResult = {
  __typename?: 'RemoveBackgroundBatchResult';
  newUrl: Scalars['String'];
  originalUrl: Scalars['String'];
};

/** Autogenerated input type of RemoveDiscount */
export type RemoveDiscountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  discountId: Scalars['ID'];
};

/** Autogenerated return type of RemoveDiscount. */
export type RemoveDiscountPayload = {
  __typename?: 'RemoveDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

/** Autogenerated input type of RemoveFromFavorites */
export type RemoveFromFavoritesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sku: Scalars['String'];
};

/** Autogenerated return type of RemoveFromFavorites. */
export type RemoveFromFavoritesPayload = {
  __typename?: 'RemoveFromFavoritesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  favorites: Array<Product>;
};

export type RentalItem = CartItem & {
  __typename?: 'RentalItem';
  allowPurchase: Scalars['Boolean'];
  allowRentalUpgrade: Scalars['Boolean'];
  discountAmount: Scalars['Int'];
  groverCare?: Maybe<GroverCare>;
  id: Scalars['ID'];
  /** price is a static snapshot of a price */
  price: Price;
  product: Product;
  quantity: Scalars['Int'];
  /** RentalPlan is a dynamic field, and if the order is submitted, it might not reflect the exact RentalPlan prices as they were during submission */
  rentalPlan: RentalPlan;
  rentalPlanDuration: Scalars['Int'];
  variant: Variant;
};

export type RentalPlan = {
  __typename?: 'RentalPlan';
  /** @deprecated Don't use! It's for catalog proposal and will be removed soon. */
  categoryId: Scalars['String'];
  discount?: Maybe<RentalPlanDiscount>;
  groverCarePrices: Array<RentalPlanGroverCarePrice>;
  id: Scalars['ID'];
  length: Duration;
  oldPrice?: Maybe<Price>;
  price: Price;
  productSku: Scalars['String'];
  storeId: Scalars['ID'];
};

export type RentalPlanDeleteWhereInput = {
  resourceSku: Scalars['String'];
  storeCode: Scalars['String'];
};

export type RentalPlanDiscount = {
  __typename?: 'RentalPlanDiscount';
  percentage: Scalars['Float'];
  rentalPlan?: Maybe<RentalPlan>;
  rentalPlanId: Scalars['Int'];
};

export type RentalPlanExportTask = Task & {
  __typename?: 'RentalPlanExportTask';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['Int'];
  fileName: Scalars['String'];
  fileUri?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: TaskStatus;
};

export type RentalPlanExportWhereInput = {
  and?: InputMaybe<Array<RentalPlanExportWhereInput>>;
  resourceSkus?: InputMaybe<SkuComparatorInput>;
  storeCode?: InputMaybe<StoreCodeComparatorInput>;
};

export type RentalPlanGroverCarePrice = {
  __typename?: 'RentalPlanGroverCarePrice';
  coverage: Scalars['Int'];
  price: Price;
};

export type RentalPlanImportInput = {
  bucketName: Scalars['String'];
  fileUri: Scalars['String'];
};

export type RentalPlanParsedXlsx = {
  __typename?: 'RentalPlanParsedXlsx';
  currency: Scalars['String'];
  priceChangeReason?: Maybe<Scalars['String']>;
  priceChangeTag?: Maybe<Scalars['String']>;
  resourceSku: Scalars['String'];
  storeCode: Scalars['String'];
  terms: Array<RentalPlanTermXlsx>;
};

export type RentalPlanTerm = {
  __typename?: 'RentalPlanTerm';
  active: Scalars['Boolean'];
  compareAtPriceInCents?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Int']>;
  minimumTermMonths: Scalars['Int'];
  priceInCents: Scalars['Int'];
};

export type RentalPlanTermXlsx = {
  __typename?: 'RentalPlanTermXlsx';
  compareAtPriceInCents?: Maybe<Scalars['Int']>;
  minimumTermMonths: Scalars['Int'];
  priceInCents: Scalars['Int'];
};

export type RentalPlanUpgradeProperties = {
  __typename?: 'RentalPlanUpgradeProperties';
  basePrice: Price;
  buyoutMonthsRequired: Scalars['Int'];
  buyoutPrice: Price;
  groverCareCoverage: Scalars['Int'];
  groverCarePrice: Price;
  id: Scalars['ID'];
  minimumLength: Duration;
  oldPrice?: Maybe<Price>;
  price: Price;
};

export type RentalPlanUploadError = {
  message: Scalars['String'];
};

export type RentalPlanUploadFailedTask = Task & {
  __typename?: 'RentalPlanUploadFailedTask';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['Int'];
  errors: Array<RentalPlanUploadError>;
  fileName: Scalars['String'];
  fileUri: Scalars['String'];
  id: Scalars['ID'];
  scheduledFor?: Maybe<Scalars['DateTime']>;
  status: TaskStatus;
};

export type RentalPlanUploadTask = Task & {
  __typename?: 'RentalPlanUploadTask';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['Int'];
  fileName: Scalars['String'];
  fileUri: Scalars['String'];
  id: Scalars['ID'];
  scheduledFor?: Maybe<Scalars['DateTime']>;
  status: TaskStatus;
};

export type RentalPlanUploadTaskCreateInput = {
  fileName: Scalars['String'];
  fileUri: Scalars['String'];
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
};

export type RentalPlanUploadTaskParserError = RentalPlanUploadError & {
  __typename?: 'RentalPlanUploadTaskParserError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type RentalPlanUploadTaskRowError = RentalPlanUploadError & {
  __typename?: 'RentalPlanUploadTaskRowError';
  message: Scalars['String'];
  row: Scalars['String'];
};

export type RentalPlanWhereInput = {
  and?: InputMaybe<Array<RentalPlanWhereInput>>;
  resourceSkus?: InputMaybe<SkuComparatorInput>;
  storeCode?: InputMaybe<StoreCodeComparatorInput>;
};

export type RentalPlanXlsxInput = {
  bucket: Scalars['String'];
  fileName: Scalars['String'];
};

export type RentalPlanXlsxResult = {
  __typename?: 'RentalPlanXlsxResult';
  rentalPlans: Array<RentalPlanParsedXlsx>;
};

export type RentalPlanXlsxResultOrError = ParseXlsxError | RentalPlanXlsxResult;

/** Input for the replacePaymentMethod mutation. */
export type ReplacePaymentMethodInput = {
  /** Whether the old method should be deleted after the operation. */
  deleteOld: Scalars['Boolean'];
  /** ID of the replacement method. */
  newPaymentMethodId: Scalars['ID'];
  /** ID of the method to be replaced. */
  oldPaymentMethodId: Scalars['ID'];
};

/** Operation started by the `replacePaymentMethod` mutation. */
export type ReplacePaymentMethodOperation = PaymentMethodOperation & {
  __typename?: 'ReplacePaymentMethodOperation';
  id: Scalars['ID'];
  status: PaymentMethodOperationStatus;
};

/** Result of the `replacePaymentMethod` mutation. */
export type ReplacePaymentMethodResult = {
  __typename?: 'ReplacePaymentMethodResult';
  /** Background operation started by the mutation, if any. Can be finished immediately. The operation status can be polled via the `paymentMethodOperation` query. */
  operation?: Maybe<ReplacePaymentMethodOperation>;
};

export type ReplacedLineItemInput = {
  deviceId: Scalars['String'];
  meta: ItemMetaInput;
};

export type ReplacedLineItemMeta = LineItemMeta & {
  __typename?: 'ReplacedLineItemMeta';
  category?: Maybe<LineItemCategory>;
  deviceId?: Maybe<Scalars['ID']>;
  groverCare?: Maybe<GroverCare>;
  product?: Maybe<Product>;
  replacedItem?: Maybe<LineItemCatalogMeta>;
  variant: Variant;
};

export type Replacement = Operation & {
  __typename?: 'Replacement';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  replacement?: Maybe<ReplacementModel>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: ReplacementState;
  updates: Array<FlowUpdate>;
};

export type ReplacementAvailability = {
  __typename?: 'ReplacementAvailability';
  available: Scalars['Boolean'];
  unavailabilityReason?: Maybe<Scalars['String']>;
};

export type ReplacementModel = {
  __typename?: 'ReplacementModel';
  allocationUid?: Maybe<Scalars['String']>;
  assetUid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  createdBy?: Maybe<Scalars['String']>;
  customerId: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  orderMode: JunoOrderMode;
  orderNumber: Scalars['String'];
  reason?: Maybe<ReplacementReason>;
  replacedAllocationUid: Scalars['String'];
  replacedOrderNumber?: Maybe<Scalars['String']>;
  reservationUid?: Maybe<Scalars['String']>;
  skuVariantCode?: Maybe<Scalars['String']>;
  status: ReplacementStatus;
  statusUpdatedAt?: Maybe<Scalars['DateTimeISO']>;
  storeId: Scalars['Float'];
  storeUid?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export enum ReplacementReason {
  CosmeticWear = 'CosmeticWear',
  Damage = 'Damage',
  Doa = 'Doa',
  FailedToDeliver = 'FailedToDeliver',
  Incomplete = 'Incomplete',
  IssueAtWarehouse = 'IssueAtWarehouse',
  LimitedFunction = 'LimitedFunction',
  LockedAsset = 'LockedAsset',
  Lost = 'Lost',
  NotRefurbished = 'NotRefurbished',
  SoftwareIssue = 'SoftwareIssue',
  WrongAsset = 'WrongAsset',
}

export enum ReplacementState {
  COMPLETED = 'COMPLETED',
  NEW_DELIVERED = 'NEW_DELIVERED',
  OLD_RETURNED = 'OLD_RETURNED',
  STARTED = 'STARTED',
}

export enum ReplacementStatus {
  Allocated = 'Allocated',
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  FailedDelivery = 'FailedDelivery',
  PendingAllocation = 'PendingAllocation',
  Shipped = 'Shipped',
  WmsProcessing = 'WmsProcessing',
}

export type ReplacementTerm = {
  __typename?: 'ReplacementTerm';
  available: Scalars['Boolean'];
  unavailabilityReason?: Maybe<ReplacementUnavailabilityReason>;
};

export enum ReplacementUnavailabilityReason {
  ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
  ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
  ASSET_NOT_ALLOCATED = 'ASSET_NOT_ALLOCATED',
  ASSET_NOT_WITH_CUSTOMER = 'ASSET_NOT_WITH_CUSTOMER',
  CONTRACT_NOT_ACTIVE = 'CONTRACT_NOT_ACTIVE',
}

/** Autogenerated input type of ReportContractAsFound */
export type ReportContractAsFoundInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of ReportContractAsFound. */
export type ReportContractAsFoundPayload = {
  __typename?: 'ReportContractAsFoundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of ReportContractAsStolen */
export type ReportContractAsStolenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of ReportContractAsStolen. */
export type ReportContractAsStolenPayload = {
  __typename?: 'ReportContractAsStolenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type ReportDamage = {
  __typename?: 'ReportDamage';
  /** @deprecated Use grapql __typename instead */
  action: Scalars['String'];
  claimFormUrl?: Maybe<Scalars['String']>;
  unavailableReason?: Maybe<Scalars['String']>;
};

export type RepushGoodsOrderResponseType = {
  __typename?: 'RepushGoodsOrderResponseType';
  msg?: Maybe<Scalars['String']>;
  repushResult?: Maybe<GoodsOrderPushResponseType>;
  success: Scalars['Boolean'];
};

export type RequestAutomaticChargeBreakdownCreateInput = {
  netValue: Scalars['Float'];
  taxRate: Scalars['Float'];
  taxValue: Scalars['Float'];
  value: Scalars['Float'];
};

export type RequestAutomaticChargeBreakdownInput = {
  ID: Scalars['ID'];
  allocate: Scalars['Float'];
  create?: InputMaybe<RequestAutomaticChargeBreakdownCreateInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  type: Scalars['String'];
};

export type RequestAutomaticChargeInput = {
  breakdown: Array<RequestAutomaticChargeBreakdownInput>;
  country: Scalars['String'];
  currency: Scalars['String'];
  paymentMethodID: Scalars['ID'];
  scheduleID: Scalars['ID'];
};

export type RequestAutomaticChargePayload = {
  __typename?: 'RequestAutomaticChargePayload';
  movement?: Maybe<Movement>;
};

export type RequestBPIChargeBreakdownCreateInput = {
  taxRate: Scalars['Float'];
  value: Scalars['Float'];
};

export type RequestBPIChargeBreakdownInput = {
  ID: Scalars['ID'];
  allocate: Scalars['Float'];
  create?: InputMaybe<RequestAutomaticChargeBreakdownCreateInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  type: Scalars['String'];
};

export type RequestBPIChargeInput = {
  breakdown: Array<RequestAutomaticChargeBreakdownInput>;
  country: Scalars['String'];
  currency: Scalars['String'];
  paymentMethodID: Scalars['ID'];
  scheduleID: Scalars['ID'];
};

export type RequestBPIChargePayload = {
  __typename?: 'RequestBPIChargePayload';
  movement?: Maybe<Movement>;
};

export type RequestRefundBreakdownInput = {
  amount: Scalars['Float'];
  externalID: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type RequestRefundInput = {
  breakdown?: InputMaybe<Array<RequestRefundBreakdownInput>>;
  movementExternalID?: InputMaybe<Scalars['String']>;
  movementID?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  refundExternalID?: InputMaybe<Scalars['String']>;
  resourceBreakdown?: InputMaybe<Array<RequestRefundResourceBreakdownInput>>;
};

export type RequestRefundPayload = {
  __typename?: 'RequestRefundPayload';
  movements: Array<Maybe<Movement>>;
};

export type RequestRefundResourceBreakdownInput = {
  ID: Scalars['ID'];
  amount: Scalars['Float'];
};

export type Reservation = {
  __typename?: 'Reservation';
  attemptUid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  customerId: Scalars['Int'];
  customerType: ReservationCustomerType;
  /** number of days in pending allocation */
  daysPending?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  expirationDate?: Maybe<Scalars['DateTimeISO']>;
  initialQuantity?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['Int']>;
  orderMode: JunoOrderMode;
  orderNumber: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  replacedSkuVariantCodes?: Maybe<Array<Scalars['String']>>;
  skuUid?: Maybe<Scalars['String']>;
  skuVariantCode?: Maybe<Scalars['String']>;
  status: ReservationStatus;
  statusUpdatedAt?: Maybe<Scalars['DateTimeISO']>;
  stock?: Maybe<Stock>;
  stockUid: Scalars['String'];
  store?: Maybe<Store>;
  storeId: Scalars['Int'];
  storeUid: Scalars['String'];
  type: OrderBillingType;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  variant: Variant;
};

export type ReservationCollection = {
  __typename?: 'ReservationCollection';
  count: Scalars['Float'];
  rows: Array<Reservation>;
};

export enum ReservationCustomerType {
  BusinessCustomer = 'BusinessCustomer',
  NormalCustomer = 'NormalCustomer',
}

export type ReservationFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  orderMode?: InputMaybe<JunoOrderMode>;
  orderNumber?: InputMaybe<Scalars['String']>;
  skuVariantCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReservationStatus>;
  stockUid?: InputMaybe<Scalars['ID']>;
  storeId?: InputMaybe<Scalars['Float']>;
  uid?: InputMaybe<Scalars['ID']>;
};

export enum ReservationStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Confirmed = 'Confirmed',
  Declined = 'Declined',
  Deleted = 'Deleted',
  Expired = 'Expired',
  Fulfilled = 'Fulfilled',
  Paid = 'Paid',
  Pending = 'Pending',
}

/** Autogenerated input type of ResetVerification */
export type ResetVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of ResetVerification. */
export type ResetVerificationPayload = {
  __typename?: 'ResetVerificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type Resource = {
  __typename?: 'Resource';
  ID: Scalars['ID'];
  contract?: Maybe<Flex>;
  contractId?: Maybe<Scalars['String']>;
  countryISO: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  currentAllocatedAmount: Scalars['Float'];
  currentAllocatedTaxes: Scalars['Float'];
  dueDate: Scalars['ISO8601DateTime'];
  externalID: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  metadata?: Maybe<Scalars['JSON']>;
  orderNumber: Scalars['String'];
  period?: Maybe<Scalars['Int']>;
  resourceMovements: Array<MovementResource>;
  resourceTransactions?: Maybe<Array<ResourceTransaction>>;
  slug: Scalars['String'];
  status: ResourceStatus;
  taxAmount: Scalars['Float'];
  taxRate: Scalars['Float'];
  type: Scalars['String'];
  value: Scalars['Float'];
  valueWithoutTax: Scalars['Float'];
};

export type ResourceConnection = {
  __typename?: 'ResourceConnection';
  edges?: Maybe<Array<ResourceEdge>>;
  nodes?: Maybe<Array<Resource>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ResourceEdge = {
  __typename?: 'ResourceEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Resource>;
};

export enum ResourceStatus {
  CANCELLED = 'CANCELLED',
  OVERBOOKED = 'OVERBOOKED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  REFUNDED = 'REFUNDED',
  UNPAID = 'UNPAID',
}

export type ResourceStatusComparisonInput = {
  _eq?: InputMaybe<ResourceStatus>;
  _neq?: InputMaybe<ResourceStatus>;
};

export type ResourceTransaction = {
  __typename?: 'ResourceTransaction';
  amount: Scalars['Float'];
  amountWithoutTax: Scalars['Float'];
  resource: Resource;
  resourceID: Scalars['String'];
  taxAmount: Scalars['Float'];
  taxRate: Scalars['Float'];
  transaction: Transaction;
  transactionID: Scalars['String'];
};

export type ResourceType = {
  __typename?: 'ResourceType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path: Array<Scalars['String']>;
};

export type ResourcesContract = {
  __typename?: 'ResourcesContract';
  contract: Flex;
  id: Scalars['ID'];
  orderNumber: Scalars['ID'];
  resources: Array<Resource>;
  summary: ResourcesContractSummary;
};

export type ResourcesContractSummary = {
  __typename?: 'ResourcesContractSummary';
  outstandingResourcesCount: Scalars['Int'];
  resourcesCount: Scalars['Int'];
  totalOutstandingAmount: Scalars['Float'];
};

export type ResourcesGroupedByOrders = {
  __typename?: 'ResourcesGroupedByOrders';
  orders: Array<ResourcesOrder>;
  searchByOrderId?: Maybe<Scalars['ID']>;
  searchByUserId?: Maybe<Scalars['ID']>;
  summary: ResourcesGroupedByOrdersSummary;
};

export type ResourcesGroupedByOrdersSummary = {
  __typename?: 'ResourcesGroupedByOrdersSummary';
  contractsCount: Scalars['Int'];
  currency: Scalars['String'];
  outstandingContractsCount: Scalars['Int'];
  totalOutstandingAmount: Scalars['Float'];
};

export type ResourcesOrder = {
  __typename?: 'ResourcesOrder';
  contracts: Array<ResourcesContract>;
  countryIso: Scalars['String'];
  firstPayment?: Maybe<Resource>;
  id: Scalars['ID'];
  otp: Array<Resource>;
  summary: ResourcesOrderSummary;
};

export type ResourcesOrderSummary = {
  __typename?: 'ResourcesOrderSummary';
  outstandingContractsCount: Scalars['Int'];
  totalOutstandingAmount: Scalars['Float'];
};

export type ResourcesWhere = {
  contractID?: InputMaybe<IdComparisonInput>;
  customerID?: InputMaybe<IdComparisonInput>;
  dueAmount?: InputMaybe<FloatComparisonInput>;
  dueDate?: InputMaybe<DateComparisonInput>;
  externalID?: InputMaybe<IdComparisonInput>;
  invoiceNumber?: InputMaybe<StringComparisonInput>;
  orderID?: InputMaybe<IdComparisonInput>;
  paidAmount?: InputMaybe<FloatComparisonInput>;
  resourceID?: InputMaybe<IdComparisonInput>;
  resourceType?: InputMaybe<StringComparisonInput>;
  status?: InputMaybe<ResourceStatusComparisonInput>;
  storeCountry?: InputMaybe<StringComparisonInput>;
};

export type ReturnAssetInput = {
  assetUuid: Scalars['String'];
  email: Scalars['String'];
};

export type ReturnShipmentInput = {
  carrier?: InputMaybe<ShipmentCarrierInput>;
  dimensions: ShipmentDimensionsInput;
  weight: Scalars['Float'];
};

export type ReturnSteps = AccessoriesReturnStep | ConfirmableReturnStep | ReturnStepsOutcome;

export type ReturnStepsOutcome = {
  __typename?: 'ReturnStepsOutcome';
  name: Scalars['String'];
};

export enum ReturnUnavailabilityReason {
  ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
  ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
  CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
  NOT_HANDED_OVER_TO_DCA = 'NOT_HANDED_OVER_TO_DCA',
  NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE',
  PRODUCT_NOT_WITH_CUSTOMER = 'PRODUCT_NOT_WITH_CUSTOMER',
}

/** Autogenerated input type of RevertDisablePurchaseVoucher */
export type RevertDisablePurchaseVoucherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of RevertDisablePurchaseVoucher. */
export type RevertDisablePurchaseVoucherPayload = {
  __typename?: 'RevertDisablePurchaseVoucherPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

/** Autogenerated input type of RevertDiscountOffer */
export type RevertDiscountOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of RevertDiscountOffer. */
export type RevertDiscountOfferPayload = {
  __typename?: 'RevertDiscountOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptionDetails: SubscriptionDetails;
};

/** More fields for this type will be defined in following PRs */
export type ReviewingOrderEOF = IExternalOrderFulfillment & {
  __typename?: 'ReviewingOrderEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

export type RevivalTerm = {
  __typename?: 'RevivalTerm';
  available: Scalars['Boolean'];
  unavailabilityReason?: Maybe<RevivalUnavailabilityReason>;
};

export enum RevivalUnavailabilityReason {
  ASSET_NEVER_SHIPPED = 'ASSET_NEVER_SHIPPED',
  ASSET_NOT_WITH_CUSTOMER = 'ASSET_NOT_WITH_CUSTOMER',
  CONTRACT_NOT_TERMINATED = 'CONTRACT_NOT_TERMINATED',
}

/** Autogenerated input type of ReviveContract */
export type ReviveContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of ReviveContract. */
export type ReviveContractPayload = {
  __typename?: 'ReviveContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of ReviveSubscription */
export type ReviveSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of ReviveSubscription. */
export type ReviveSubscriptionPayload = {
  __typename?: 'ReviveSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptionDetails: SubscriptionDetails;
};

export type Revocation = Operation & {
  __typename?: 'Revocation';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: InboundShipmentStates;
  updates: Array<FlowUpdate>;
};

export type RevocationTerms = {
  __typename?: 'RevocationTerms';
  availabilityPeriod: Duration;
  available: Scalars['Boolean'];
  availableUntil?: Maybe<Scalars['ISO8601DateTime']>;
  unavailabilityReason?: Maybe<RevocationUnavailabilityReason>;
};

export enum RevocationUnavailabilityReason {
  ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS',
  ANOTHER_FLOW_IN_PROGRESS = 'ANOTHER_FLOW_IN_PROGRESS',
  AVAILABILITY_PERIOD_ENDED = 'AVAILABILITY_PERIOD_ENDED',
  CONTRACT_TERMINATED = 'CONTRACT_TERMINATED',
  NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE',
  REVOCATION_DISABLED = 'REVOCATION_DISABLED',
}

export enum RiskConsolidatedState {
  Approved = 'Approved',
  Declined = 'Declined',
  Paid = 'Paid',
  Submitted = 'Submitted',
}

export enum RuleStatus {
  Active = 'Active',
  Disabled = 'Disabled',
}

export type RuleStatusComparatorInput = {
  _eq?: InputMaybe<RuleStatus>;
  _neq?: InputMaybe<RuleStatus>;
};

export enum RuleType {
  Permanent = 'Permanent',
  Temporary = 'Temporary',
}

export type RuleTypeComparatorInput = {
  _eq?: InputMaybe<RuleType>;
  _neq?: InputMaybe<RuleType>;
};

export type SalesOrderAssetInput = {
  assetUid?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
};

export enum SalesOrderAssetStatus {
  CrossSale = 'CrossSale',
  Deleted = 'Deleted',
  Donated = 'Donated',
  InDebtCollection = 'InDebtCollection',
  InRepair = 'InRepair',
  InStock = 'InStock',
  InboundDamaged = 'InboundDamaged',
  InboundQuarantine = 'InboundQuarantine',
  InboundUnallocable = 'InboundUnallocable',
  Incomplete = 'Incomplete',
  Insolvency = 'Insolvency',
  InvestigationCarrier = 'InvestigationCarrier',
  InvestigationWarehouse = 'InvestigationWarehouse',
  Irreparable = 'Irreparable',
  LockedDevice = 'LockedDevice',
  Lost = 'Lost',
  LostSolved = 'LostSolved',
  Office = 'Office',
  OnLoan = 'OnLoan',
  Purchased = 'Purchased',
  Recovered = 'Recovered',
  Refurbished = 'Refurbished',
  Refurbishing = 'Refurbishing',
  Reserved = 'Reserved',
  Returned = 'Returned',
  ReturnedToSupplier = 'ReturnedToSupplier',
  Selling = 'Selling',
  SentForRefurbishment = 'SentForRefurbishment',
  Sold = 'Sold',
  TransferredToWarehouse = 'TransferredToWarehouse',
  WaitingForRefurbishment = 'WaitingForRefurbishment',
  Warranty = 'Warranty',
  WrittenOffDc = 'WrittenOffDc',
  WrittenOffOps = 'WrittenOffOps',
}

export type SalesOrderAssetWithErrorMessageType = {
  __typename?: 'SalesOrderAssetWithErrorMessageType';
  assetUid?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<SalesOrderAssetStatus>;
  supplierName?: Maybe<Scalars['String']>;
  warehouseCode?: Maybe<Scalars['String']>;
};

export type SalesOrderRequestType = {
  assets: Array<SalesOrderAssetInput>;
  customerType: WMSCustomerType;
  recipientAddress: AddressInputType;
  salesOrderNumber: Scalars['String'];
  shippingProfile: WMSCarrierServiceLevel;
};

export type SalesOrderResponseType = {
  __typename?: 'SalesOrderResponseType';
  assets?: Maybe<Array<SalesOrderAssetWithErrorMessageType>>;
  errors?: Maybe<Array<Scalars['String']>>;
  salesOrderNumber?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SalesforceLead = {
  __typename?: 'SalesforceLead';
  companyId: Scalars['Float'];
  leadId: Scalars['String'];
};

export enum SearchResultSectionItemTypeV2 {
  category = 'category',
  popularCategory = 'popularCategory',
  popularSearch = 'popularSearch',
  product = 'product',
}

export type SearchResultSectionItemV2 = {
  __typename?: 'SearchResultSectionItemV2';
  analyticsTerms: Array<AnalyticsTermV2>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  link: HeaderLink;
  parentCategoryText?: Maybe<Scalars['String']>;
  type?: Maybe<SearchResultSectionItemTypeV2>;
};

export type SearchResultSectionV2 = {
  __typename?: 'SearchResultSectionV2';
  emptyText?: Maybe<Scalars['String']>;
  highlight?: Maybe<Scalars['String']>;
  items: Array<SearchResultSectionItemV2>;
  title: Scalars['String'];
};

export type Section = {
  __typename?: 'Section';
  filter?: Maybe<SubscriptionListFilter>;
  items: Array<SubscriptionListEntry>;
  quantity: Scalars['Int'];
  title: Scalars['String'];
};

export type SendResultChannelComparisonInput = {
  _eq?: InputMaybe<NotificationChannel>;
};

export type SendResultConnection = {
  __typename?: 'SendResultConnection';
  edges: Array<SendResultEdge>;
  nodes: Array<NotificationSendResult>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SendResultEdge = {
  __typename?: 'SendResultEdge';
  cursor: Scalars['String'];
  node: NotificationSendResult;
};

export type SendResultStatusComparisonInput = {
  _eq?: InputMaybe<NotificationSendResultStatus>;
};

export type SendResultWhereInput = {
  and?: InputMaybe<Array<SendResultWhereInput>>;
  channel?: InputMaybe<SendResultChannelComparisonInput>;
  status?: InputMaybe<SendResultStatusComparisonInput>;
  triggerName?: InputMaybe<StringComparisonInput>;
  userId?: InputMaybe<IdComparisonInput>;
  userIdAndOrderNumber?: InputMaybe<UserIdAndOrderNumber>;
};

export type Sepa = PaymentMethodInterface & {
  __typename?: 'Sepa';
  bankName?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
  contracts: Array<BasicContract>;
  ibanFirstDigits?: Maybe<Scalars['String']>;
  ibanLastDigits?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mandateId?: Maybe<Scalars['String']>;
  merchantTransactionId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
};

/** SEPA payment method from Wallet service. Eventually will deprecate the Sepa type. */
export type SepaPaymentMethod = PaymentMethod2 & {
  __typename?: 'SepaPaymentMethod';
  /** Name of the account owner. */
  accountOwner?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  /** ID of the corresponding account in Billing Service. */
  billingAccountId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  /** Date/time of the method creation. Empty in case of a legacy method. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Present if the method deletion is currently forbidden, contains details about the reason. Warning: currently does not return "has linked contracts", which is one of the possible reasons for deletion lock. Check the linkedContracts field instead. */
  deletionLock?: Maybe<PaymentMethodDeletionLock>;
  /** ID of the corresponding method in Grover API. */
  groverApiId?: Maybe<Scalars['Float']>;
  ibanFirstDigits?: Maybe<Scalars['String']>;
  ibanLastDigits?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Method's external ID in IXOPAY. */
  ixopayReferenceId?: Maybe<Scalars['String']>;
  /** Contracts and subscriptions that are using this method. */
  linkedContracts: Array<FlexDetails>;
  mandateId?: Maybe<Scalars['String']>;
  /** URL to redirect the user to if authorization is required by the payment provider. */
  redirectUrl?: Maybe<Scalars['String']>;
  /** Error code classifying the method registration failure, if any. */
  registrationErrorCode?: Maybe<TransactionErrorCode>;
  status: PaymentMethodStatus;
  /** Get status of this method in relation to the given order. */
  statusForOrder: PaymentMethodStatusForOrderResult;
};

/** SEPA payment method from Wallet service. Eventually will deprecate the Sepa type. */
export type SepaPaymentMethodstatusForOrderArgs = {
  orderNumber: Scalars['String'];
};

export type SerialNumberLookup = {
  __typename?: 'SerialNumberLookup';
  exists: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<AssetStatus>;
};

export type SerialNumberUpdate = {
  currentSerialNumber: Scalars['String'];
  newSerialNumber: Scalars['String'];
};

/** Input for the setPaymentMethod mutation. */
export type SetPaymentMethodInput = {
  /** IDs of the contracts and/or subscriptions being updated. */
  contractIds: Array<Scalars['ID']>;
  paymentMethodId: Scalars['ID'];
};

/** Operation started by the `setPaymentMethod` mutation. */
export type SetPaymentMethodOperation = PaymentMethodOperation & {
  __typename?: 'SetPaymentMethodOperation';
  id: Scalars['ID'];
  status: PaymentMethodOperationStatus;
};

/** Result of the `setPaymentMethod` mutation. */
export type SetPaymentMethodResult = {
  __typename?: 'SetPaymentMethodResult';
  /** Background operation started by the mutation, if any. Can be finished immediately. The operation status can be polled via the `paymentMethodOperation` query. */
  operation?: Maybe<SetPaymentMethodOperation>;
};

export type Shipment = {
  __typename?: 'Shipment';
  carrier?: Maybe<ShipmentCarrier>;
  company?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  /** senderAddress for inbound & recipientAddress for outbound */
  customerAddress?: Maybe<ShippingAddress>;
  customerType?: Maybe<Scalars['String']>;
  daysSinceOpened?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  description?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  numberOfAssets?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['Float']>;
  orderMode: Scalars['String'];
  orderNumber: Scalars['String'];
  packages?: Maybe<Array<PackageItem>>;
  pickupAddress?: Maybe<ShippingAddress>;
  pickupAddressUid?: Maybe<Scalars['String']>;
  proofOfDeliveryUrls?: Maybe<Array<Scalars['String']>>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<ShippingAddress>;
  recipientAddressUid?: Maybe<Scalars['String']>;
  senderAddress?: Maybe<ShippingAddress>;
  senderAddressUid?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  shipmentHistory: Array<ShipmentHistoryItem>;
  shippingLabelUrl?: Maybe<Scalars['String']>;
  shippingProfile?: Maybe<Scalars['String']>;
  shippingRequest: ShippingRequest;
  shippingRequestUid: Scalars['String'];
  status: ShipmentLifecycleStatus;
  statusUpdatedAt?: Maybe<Scalars['DateTimeISO']>;
  trackingEvents?: Maybe<Array<ShipmentLifecycleEvent>>;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  userId?: Maybe<Scalars['String']>;
  warehouseCode?: Maybe<ShippingWarehouseCode>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export enum ShipmentCarrier {
  ANGEL_DE = 'ANGEL_DE',
  CARGO_INTERNATIONAL = 'CARGO_INTERNATIONAL',
  DHL = 'DHL',
  DHL_EXPRESS = 'DHL_EXPRESS',
  DPAG = 'DPAG',
  DPD = 'DPD',
  GLS = 'GLS',
  GO = 'GO',
  HERMES = 'HERMES',
  ILOXX = 'ILOXX',
  PARCEL_ONE = 'PARCEL_ONE',
  UPS = 'UPS',
}

export enum ShipmentCarrierInput {
  ANGEL_DE = 'ANGEL_DE',
  CARGO_INTERNATIONAL = 'CARGO_INTERNATIONAL',
  DHL = 'DHL',
  DHL_EXPRESS = 'DHL_EXPRESS',
  DPAG = 'DPAG',
  DPD = 'DPD',
  GLS = 'GLS',
  GO = 'GO',
  HERMES = 'HERMES',
  ILOXX = 'ILOXX',
  PARCEL_ONE = 'PARCEL_ONE',
  UPS = 'UPS',
}

export type ShipmentCollection = {
  __typename?: 'ShipmentCollection';
  count: Scalars['Float'];
  rows: Array<Shipment>;
};

export type ShipmentDimensionsInput = {
  height: Scalars['Float'];
  length: Scalars['Float'];
  width: Scalars['Float'];
};

export enum ShipmentEventState {
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  Exception = 'Exception',
  FailedToDeliver = 'FailedToDeliver',
  ReadyForPickupByReceiver = 'ReadyForPickupByReceiver',
  Shipped = 'Shipped',
}

export type ShipmentFilterType = {
  assetUid?: InputMaybe<StringComparatorInput>;
  carrier?: InputMaybe<StringComparatorInput>;
  contractId?: InputMaybe<StringComparatorInput>;
  countryCode?: InputMaybe<StringComparatorInput>;
  createdAt?: InputMaybe<DateComparatorInput>;
  customerType?: InputMaybe<StringComparatorInput>;
  orderMode?: InputMaybe<ShippingRequestModeComparatorInput>;
  orderNumber?: InputMaybe<StringComparatorInput>;
  serialNumber?: InputMaybe<StringComparatorInput>;
  service?: InputMaybe<StringComparatorInput>;
  status?: InputMaybe<ShippingStatusComparatorInput>;
  trackingId?: InputMaybe<StringComparatorInput>;
  uid?: InputMaybe<StringComparatorInput>;
  userId?: InputMaybe<StringComparatorInput>;
  warehouseCode?: InputMaybe<StringComparatorInput>;
};

export type ShipmentHistoryItem = {
  __typename?: 'ShipmentHistoryItem';
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTimeISO']>;
  type?: Maybe<ShipmentHistoryItemType>;
  uid?: Maybe<Scalars['String']>;
};

export enum ShipmentHistoryItemType {
  delivery_exception = 'delivery_exception',
  status_change = 'status_change',
}

export type ShipmentLabel = {
  __typename?: 'ShipmentLabel';
  assetUid: Scalars['String'];
  carrier: ShipmentCarrier;
  qrCodeUrl?: Maybe<Scalars['String']>;
  shippingLabelUrl?: Maybe<Scalars['String']>;
  trackingStatus?: Maybe<ShipmentTrackingStatus>;
  trackingUrl?: Maybe<Scalars['String']>;
  type?: Maybe<ShipmentServiceType>;
};

export type ShipmentLifecycleEvent = {
  __typename?: 'ShipmentLifecycleEvent';
  carrier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operation?: Maybe<ShipmentOperation>;
  shipmentUid?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
};

export type ShipmentLifecycleEventInput = {
  carrier?: InputMaybe<ShipmentCarrier>;
  description?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<ShipmentLifecycleStatus>;
  shipmentUid: Scalars['String'];
  timestamp: Scalars['DateTimeISO'];
};

export enum ShipmentLifecycleStatus {
  ArrivedAtCarrierFacility = 'ArrivedAtCarrierFacility',
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  DeliveryRefused = 'DeliveryRefused',
  DepartedFromCarrierFacility = 'DepartedFromCarrierFacility',
  FailedDeliveryCandidate = 'FailedDeliveryCandidate',
  FailedToDeliver = 'FailedToDeliver',
  InTransit = 'InTransit',
  LabelCreated = 'LabelCreated',
  OutForDelivery = 'OutForDelivery',
  Packed = 'Packed',
  PickAndPacking = 'PickAndPacking',
  PickedUpByCarrier = 'PickedUpByCarrier',
  ProcessingAtCarrierFacility = 'ProcessingAtCarrierFacility',
  ReadyForPickupByReceiver = 'ReadyForPickupByReceiver',
  ScannedAtCarrierFacility = 'ScannedAtCarrierFacility',
}

export type ShipmentOperation = UpdateTrackingInformation;

export enum ShipmentOperationName {
  TrackingDetailsUpdated = 'TrackingDetailsUpdated',
}

export enum ShipmentServiceType {
  INBOUND = 'INBOUND',
  INTERNAL = 'INTERNAL',
  OUTBOUND = 'OUTBOUND',
}

export enum ShipmentTrackingStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  FailedToDeliver = 'FailedToDeliver',
  InTransit = 'InTransit',
  LabelCreated = 'LabelCreated',
  ReadyForPickupByReceiver = 'ReadyForPickupByReceiver',
}

/** More fields for this type will be defined in following PRs */
export type ShippedEOF = IExternalOrderFulfillment & {
  __typename?: 'ShippedEOF';
  /** Collection of assets belonging to an order */
  assets?: Maybe<Array<OrderAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<GroverEmployee>;
  order?: Maybe<MixCartOrder>;
  orderMode?: Maybe<OrderFulfillmentOrderMode>;
  orderNumber: Scalars['String'];
  /** Collection of shipments belonging to an order */
  shipments?: Maybe<Array<OrderShipment>>;
  shippingAddress?: Maybe<EOFShippingAddress>;
  shippingPreferences?: Maybe<OrderShippingPreferences>;
  state: OrderFulfillmentState;
  stateUpdatedAt: Scalars['DateTime'];
  storeCode?: Maybe<Scalars['String']>;
  storeCountryCode?: Maybe<Scalars['String']>;
  storeCountryId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  storeType?: Maybe<StoreTypes>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userType?: Maybe<UserTypes>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  additionalInfo?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTimeISO'];
  zipCode: Scalars['String'];
};

export type ShippingAddressCollection = {
  __typename?: 'ShippingAddressCollection';
  count: Scalars['Float'];
  rows: Array<ShippingAddress>;
};

export type ShippingAddressFilterType = {
  shipmentUid?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type ShippingAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type ShippingAddressInputType = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export enum ShippingIgnoreCarrierRulesOptions {
  All = 'All',
  None = 'None',
  PreferredProfile = 'PreferredProfile',
  SpecialRule = 'SpecialRule',
}

export enum ShippingOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ShippingPreferences = {
  includeCompanyName: Scalars['Boolean'];
  shipToName: Scalars['String'];
};

export type ShippingProgress = {
  __typename?: 'ShippingProgress';
  actions?: Maybe<Array<Action>>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  state: Scalars['String'];
  subSteps?: Maybe<Array<SubStep>>;
};

export type ShippingRequest = {
  __typename?: 'ShippingRequest';
  additionalInfo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  daysSinceOpened?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  numberOfShipments?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['String']>;
  orderMode: ShippingRequestMode;
  orderNumber: Scalars['String'];
  shipments: Array<Shipment>;
  status?: Maybe<ShippingRequestStatus>;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  userId?: Maybe<Scalars['String']>;
};

export type ShippingRequestCollection = {
  __typename?: 'ShippingRequestCollection';
  count: Scalars['Float'];
  rows: Array<ShippingRequest>;
};

export type ShippingRequestFilterType = {
  orderMode?: InputMaybe<ShippingRequestMode>;
  orderNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ShippingRequestStatus>;
  uid?: InputMaybe<Scalars['String']>;
};

export enum ShippingRequestMode {
  FLEX = 'FLEX',
  FLEX_LEGACY = 'FLEX_LEGACY',
  MIX = 'MIX',
  SWAP = 'SWAP',
}

export type ShippingRequestModeComparatorInput = {
  _eq?: InputMaybe<ShippingRequestMode>;
  _neq?: InputMaybe<ShippingRequestMode>;
};

export enum ShippingRequestStatus {
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  EXCEPTION = 'EXCEPTION',
  OPEN = 'OPEN',
}

export type ShippingStatusComparatorInput = {
  _eq?: InputMaybe<ShipmentLifecycleStatus>;
  _in?: InputMaybe<Array<ShipmentLifecycleStatus>>;
  _neq?: InputMaybe<ShipmentLifecycleStatus>;
  _nin?: InputMaybe<Array<ShipmentLifecycleStatus>>;
};

export type ShippingSummaryDeliveryProfileMetricType = {
  __typename?: 'ShippingSummaryDeliveryProfileMetricType';
  profile: Scalars['String'];
  value: Scalars['Float'];
};

export enum ShippingWarehouseCode {
  DisConnectEuKarlsruhe = 'DisConnectEuKarlsruhe',
  IngramMicroEUFLSB = 'IngramMicroEUFLSB',
  Ups = 'Ups',
  UpsSofteonEUNLRNG = 'UpsSofteonEUNLRNG',
  UpsSofteonUSKYLSE = 'UpsSofteonUSKYLSE',
  Wemalo = 'Wemalo',
}

export type SimpleSubscriptionModel = {
  __typename?: 'SimpleSubscriptionModel';
  amount: Scalars['Money'];
  billingPeriod: Scalars['String'];
  currency: Scalars['String'];
  description: Scalars['String'];
  externalCustomer: Scalars['String'];
  recurrentPaymentUuid: Scalars['String'];
};

export type SingleChoiceOption = {
  __typename?: 'SingleChoiceOption';
  label: Scalars['String'];
  slug: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
};

export type SingleChoiceOptions = {
  __typename?: 'SingleChoiceOptions';
  options: Array<SingleChoiceOption>;
};

export type SingleChoiceResult = {
  __typename?: 'SingleChoiceResult';
  slug: Scalars['String'];
  votes: Scalars['Int'];
};

export type SingleChoiceResults = {
  __typename?: 'SingleChoiceResults';
  resultsPerOption: Array<SingleChoiceResult>;
  total: TotalResults;
};

export type SkuComparatorInput = {
  _eq?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
};

export type SkuProductGroup = {
  __typename?: 'SkuProductGroup';
  name: Scalars['String'];
  skuProducts?: Maybe<Array<Scalars['String']>>;
};

export enum Sort {
  NEW_ARRIVALS = 'NEW_ARRIVALS',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  RANK = 'RANK',
}

export type SortColumnType = {
  column: Scalars['String'];
  direction: DeliveryPlannerSortOrderEnum;
};

/** represents the sorting order: asc, or desc */
export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type SpecFacet = Facet & {
  __typename?: 'SpecFacet';
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  values: Array<SpecFacetValues>;
};

export type SpecFacetValues = {
  __typename?: 'SpecFacetValues';
  count: Scalars['Int'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  value: Scalars['String'];
};

export type StandaloneDiscountOffer = {
  __typename?: 'StandaloneDiscountOffer';
  discounts: Array<SubscriptionDiscount>;
  productImageUrl: Scalars['String'];
  subscriptionId: Scalars['ID'];
};

/** Autogenerated input type of StartPurchase */
export type StartPurchaseInput = {
  agreedPurchasePriceInCents?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of StartPurchase. */
export type StartPurchasePayload = {
  __typename?: 'StartPurchasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Flex;
};

/** Autogenerated input type of StartReplacement */
export type StartReplacementInput = {
  allocationId: Scalars['String'];
  assetId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  outboundShippingAddressUid?: InputMaybe<Scalars['String']>;
  replacementReason: ReplacementReason;
  returnShipment?: InputMaybe<ReturnShipmentInput>;
  returnShippingAddressUid?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of StartReplacement. */
export type StartReplacementPayload = {
  __typename?: 'StartReplacementPayload';
  assetId?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
};

export enum StatusTypes {
  active = 'active',
  paused = 'paused',
}

export type Stock = {
  __typename?: 'Stock';
  assets?: Maybe<Array<AssetModel>>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  description?: Maybe<Scalars['String']>;
  inStockCount?: Maybe<Scalars['Float']>;
  isRecirculated?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  rules?: Maybe<Array<DistributionRule>>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  /** takes storeId and returns priority from corresponding store-stock-link */
  storePriority?: Maybe<Scalars['Float']>;
  stores?: Maybe<Array<Maybe<Store>>>;
  type?: Maybe<StockType>;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
};

export type StockstorePriorityArgs = {
  storeId?: InputMaybe<Scalars['ID']>;
};

export type StockCollection = {
  __typename?: 'StockCollection';
  count: Scalars['Float'];
  rows: Array<Stock>;
};

export type StockFilterInput = {
  isRecirculated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  searchString?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<StockType>>;
  uid?: InputMaybe<Scalars['ID']>;
};

export type StockTransferCollection = {
  __typename?: 'StockTransferCollection';
  count: Scalars['Float'];
  rows: Array<StockTransferModel>;
};

export type StockTransferFilterInput = {
  newWarehouseCode?: InputMaybe<WarehouseCode>;
  oldWarehouseCode?: InputMaybe<WarehouseCode>;
  refId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type StockTransferModel = {
  __typename?: 'StockTransferModel';
  completedAt?: Maybe<Scalars['DateTimeISO']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  initiatedAt: Scalars['DateTimeISO'];
  missingSerialNumbers: Array<Scalars['String']>;
  newWarehouseCode: Scalars['String'];
  oldWarehouseCode: Scalars['String'];
  refId: Scalars['String'];
  serialNumbers: Array<Scalars['String']>;
  skus: Array<StockTransferSKU>;
  state: Scalars['String'];
  transferred: Array<Scalars['String']>;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
};

export type StockTransferSKU = {
  __typename?: 'StockTransferSKU';
  quantity: Scalars['Int'];
  sku_variant_code: Scalars['String'];
};

export enum StockType {
  Main = 'Main',
  Miscellaneous = 'Miscellaneous',
  Partner = 'Partner',
}

export type StockVariantAvailability = {
  __typename?: 'StockVariantAvailability';
  availableCount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  inStockCount: Scalars['Int'];
  onLoanCount: Scalars['Int'];
  reservedCount: Scalars['Int'];
  sku: Scalars['String'];
  skuUid: Scalars['String'];
  skuVariantCode?: Maybe<Scalars['String']>;
  stockUid: Scalars['String'];
  stockVariantAvailability: StockVariantAvailability;
  totalCount: Scalars['Int'];
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
};

export type StockVariantAvailabilityCollection = {
  __typename?: 'StockVariantAvailabilityCollection';
  count: Scalars['Float'];
  rows: Array<StockVariantAvailability>;
};

export type StockVariantAvailabilityFilterInput = {
  skuUid?: InputMaybe<Scalars['String']>;
  skuVariantCode?: InputMaybe<Scalars['String']>;
  stockUid?: InputMaybe<Scalars['String']>;
};

export type Store = {
  __typename?: 'Store';
  alternativeLocales?: Maybe<Array<Scalars['String']>>;
  code: Scalars['ID'];
  countryId?: Maybe<Scalars['Int']>;
  countryIso: Scalars['String'];
  defaultCurrency: Scalars['String'];
  defaultLocale: Scalars['String'];
  deliveryTimeFromDays?: Maybe<Scalars['Int']>;
  deliveryTimeToDays?: Maybe<Scalars['Int']>;
  enabledOnFooter: Scalars['Boolean'];
  id: Scalars['ID'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  offline: Scalars['Boolean'];
  shippingCost: Price;
  stocks?: Maybe<Array<Stock>>;
  storeType?: Maybe<StoreTypes>;
  url: Scalars['String'];
};

export enum StoreCode {
  AT = 'AT',
  BUSINESS_AT = 'BUSINESS_AT',
  BUSINESS_DE = 'BUSINESS_DE',
  BUSINESS_ES = 'BUSINESS_ES',
  BUSINESS_NL = 'BUSINESS_NL',
  BUSINESS_US = 'BUSINESS_US',
  DE = 'DE',
  ES = 'ES',
  NL = 'NL',
  US = 'US',
}

export type StoreCodeComparatorInput = {
  _in?: InputMaybe<Array<Scalars['String']>>;
};

/** The connection type for Store. */
export type StoreConnection = {
  __typename?: 'StoreConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StoreEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Store>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StoreEdge = {
  __typename?: 'StoreEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Store>;
};

export type StoreInput = {
  country_code: Scalars['String'];
  country_id: Scalars['Int'];
  store_code: Scalars['String'];
  store_id: Scalars['Int'];
  store_type: Scalars['String'];
};

export type StoreOffsetCollection = {
  __typename?: 'StoreOffsetCollection';
  nodes: Array<Store>;
  totalCount: Scalars['Int'];
};

export type StoreStockConfigurationGraphql = {
  __typename?: 'StoreStockConfigurationGraphql';
  stocks?: Maybe<Array<Stock>>;
  storeStockLinks?: Maybe<Array<StoreStockLink>>;
};

export type StoreStockConfigurationGraphqlstocksArgs = {
  name?: InputMaybe<Scalars['String']>;
};

export type StoreStockLink = {
  __typename?: 'StoreStockLink';
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  priority?: Maybe<Scalars['Float']>;
  stockUid?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['ID']>;
  storeUid?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
};

export enum StoreTypes {
  BUSINESS = 'BUSINESS',
  GROVER_PAY = 'GROVER_PAY',
  LEGACY = 'LEGACY',
  MAIN = 'MAIN',
  RETAIL = 'RETAIL',
}

export type StoreVariantAvailability = {
  __typename?: 'StoreVariantAvailability';
  availabilityMode?: Maybe<AvailabilityMode>;
  availableCount: Scalars['Int'];
  createdAt: Scalars['DateTimeISO'];
  inStockCount: Scalars['Int'];
  mixVariantUid?: Maybe<Scalars['String']>;
  orderMode?: Maybe<JunoOrderMode>;
  reservedCount: Scalars['Int'];
  sku?: Maybe<Scalars['String']>;
  skuUid: Scalars['String'];
  skuVariantCode?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['Float']>;
  storeUid: Scalars['String'];
  uid: Scalars['ID'];
  updatedAt: Scalars['DateTimeISO'];
};

export type StoreVariantAvailabilityCollection = {
  __typename?: 'StoreVariantAvailabilityCollection';
  count: Scalars['Float'];
  rows: Array<StoreVariantAvailability>;
};

export type StoreVariantAvailabilityFilterInput = {
  availabilityMode?: InputMaybe<AvailabilityMode>;
  skuUid?: InputMaybe<Scalars['String']>;
  skuVariantCode?: InputMaybe<Scalars['String']>;
  stockUid?: InputMaybe<Scalars['String']>;
  storeId?: InputMaybe<Scalars['Float']>;
};

export type StoresFilterInput = {
  code?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Array<Scalars['ID']>>;
  storeType?: InputMaybe<Array<StoreTypes>>;
};

export type StringComparatorInput = {
  _eq?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _substr?: InputMaybe<Scalars['String']>;
};

export type StringComparisonInput = {
  _eq?: InputMaybe<Scalars['String']>;
  _ew?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _substr?: InputMaybe<Scalars['String']>;
  _sw?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type StructuredSpecificationsInput = {
  name: Scalars['String'];
  values: Array<StructuredSpecificationsValue>;
};

export type StructuredSpecificationsValue = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SubStep = {
  __typename?: 'SubStep';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  state: Scalars['String'];
};

/** Autogenerated input type of SubmitBankAccountSnapshotData */
export type SubmitBankAccountSnapshotDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  kontomatik?: InputMaybe<BankAccountSnapshotDataKontomatikInput>;
  plaid?: InputMaybe<BankAccountSnapshotDataPlaidInput>;
};

/** Autogenerated return type of SubmitBankAccountSnapshotData. */
export type SubmitBankAccountSnapshotDataPayload = {
  __typename?: 'SubmitBankAccountSnapshotDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SubmitKontomatikSession */
export type SubmitKontomatikSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['ID'];
  sessionIdSignature: Scalars['String'];
};

/** Autogenerated return type of SubmitKontomatikSession. */
export type SubmitKontomatikSessionPayload = {
  __typename?: 'SubmitKontomatikSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SubmitVerificationDecision */
export type SubmitVerificationDecisionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  verificationDecision: VerificationDecisionInput;
};

/** Autogenerated return type of SubmitVerificationDecision. */
export type SubmitVerificationDecisionPayload = {
  __typename?: 'SubmitVerificationDecisionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  verification: Verification;
};

export type SubscriptionAction =
  | Buyout
  | CancelSubscription
  | ChangePaymentMethod
  | PaymentMethodPresenter
  | PrintReturnLabel
  | ReportDamage
  | SupportRequest
  | UpgradeRentalPlan;

export type SubscriptionBadgeContentModel = {
  __typename?: 'SubscriptionBadgeContentModel';
  actions?: Maybe<Array<ActionUnionModel>>;
  /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
  bodyTemplate: Scalars['String'];
  svgPath: Scalars['String'];
  uuid: Scalars['ID'];
};

export type SubscriptionBadgeModel = {
  __typename?: 'SubscriptionBadgeModel';
  actions?: Maybe<Array<ActionUnionModel>>;
  /** Markdown template. Commonly a placeholder for translations. Example: {{thankyou}}. thankyou is a translate placeholder */
  bodyTemplate: Scalars['String'];
  svgPath: Scalars['String'];
  uuid: Scalars['ID'];
};

export enum SubscriptionCancellationReason {
  ASSET_RETURNED = 'ASSET_RETURNED',
  CUSTOMER_REPORTED_ISSUE = 'CUSTOMER_REPORTED_ISSUE',
  CUSTOMER_REQUEST_VIA_CS = 'CUSTOMER_REQUEST_VIA_CS',
  DAMAGED_ON_DELIVERY = 'DAMAGED_ON_DELIVERY',
  DUPLICATE_ORDER = 'DUPLICATE_ORDER',
  EARLY_TERMINATION = 'EARLY_TERMINATION',
  FAILED_DELIVERY = 'FAILED_DELIVERY',
  FRAUD = 'FRAUD',
  INCOMPLETE_DELIVERY = 'INCOMPLETE_DELIVERY',
  IN_DEBT_COLLECTION = 'IN_DEBT_COLLECTION',
  ITEM_LOST = 'ITEM_LOST',
  NOT_INVESTING_ANYMORE = 'NOT_INVESTING_ANYMORE',
  OFFLINE_CASE = 'OFFLINE_CASE',
  OTHER = 'OTHER',
  PRODUCT_NOT_AVAILABLE = 'PRODUCT_NOT_AVAILABLE',
  SOLD = 'SOLD',
  SUPPLIER_DELIVERY_TIME = 'SUPPLIER_DELIVERY_TIME',
  WIDERRUF = 'WIDERRUF',
}

export type SubscriptionCard = {
  __typename?: 'SubscriptionCard';
  contractExtensionTerms?: Maybe<ContractExtensionTerms>;
  id: Scalars['ID'];
  /** Returns marketing data for the subscription level */
  marketingSubscriptionData: Array<CardTypeUnionModel>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<SubscriptionNote>;
  product: Product;
  state: SubscriptionState;
  subscriptionExtensionTerms?: Maybe<SubscriptionExtensionTerms>;
  swapOffer: SwapOffer;
  variant: Variant;
};

/** The connection type for SubscriptionCard. */
export type SubscriptionCardConnection = {
  __typename?: 'SubscriptionCardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubscriptionCardEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SubscriptionCard>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubscriptionCardEdge = {
  __typename?: 'SubscriptionCardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SubscriptionCard>;
};

export type SubscriptionDetails = {
  __typename?: 'SubscriptionDetails';
  asset?: Maybe<Asset>;
  assetAllocation?: Maybe<AssetAllocation>;
  basePrice: Price;
  contractNumber?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
  discounts: Array<SubscriptionDiscount>;
  groverCare: SubscriptionGroverCare;
  id: Scalars['ID'];
  minimumDuration?: Maybe<Duration>;
  missingNextRecurrentPayments: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<SubscriptionNote>;
  /** @deprecated Legacy id, will be removed */
  number: Scalars['String'];
  order: Order;
  /** Outbound shipments information */
  outboundShipmentLabels: Array<ShipmentLabel>;
  pausedAt?: Maybe<Scalars['ISO8601DateTime']>;
  paymentMethod?: Maybe<PaymentMethod>;
  payments: PaymentsUnionConnection;
  price: Price;
  product?: Maybe<Product>;
  /** @deprecated Legacy id, will be removed */
  sfId?: Maybe<Scalars['ID']>;
  /** @deprecated Legacy id, will be removed */
  sfOrderId?: Maybe<Scalars['ID']>;
  shipmentLabels?: Maybe<Array<ShipmentLabel>>;
  shippingAddress?: Maybe<Address>;
  shippingProgress?: Maybe<Array<ShippingProgress>>;
  state: SubscriptionState;
  subscriptionActions: Array<SubscriptionAction>;
  surpriseDiscount?: Maybe<SurpriseDiscountOffer>;
  swapOffer: SwapOffer;
  tosUpdates: Array<TosUpdate>;
  tosVersion?: Maybe<Scalars['ID']>;
  user: User;
  variant?: Maybe<Variant>;
};

export type SubscriptionDetailspaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for SubscriptionDetails. */
export type SubscriptionDetailsConnection = {
  __typename?: 'SubscriptionDetailsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubscriptionDetailsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SubscriptionDetails>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubscriptionDetailsEdge = {
  __typename?: 'SubscriptionDetailsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SubscriptionDetails>;
};

export type SubscriptionDiscount = ExistingSubscriptionDiscountOffer | VoucherDiscountOffer;

export type SubscriptionDiscountInterface = {
  discountPercentage: Scalars['Int'];
  price: Price;
};

export type SubscriptionExtensionTerms = {
  __typename?: 'SubscriptionExtensionTerms';
  available: Scalars['Boolean'];
  savingsAmount: Price;
  unavailabilityReason?: Maybe<SubscriptionExtensionUnavailabilityReason>;
};

export enum SubscriptionExtensionUnavailabilityReason {
  FLEX_CONTRACT = 'FLEX_CONTRACT',
  NOT_ACTIVE_AND_DELIVERED = 'NOT_ACTIVE_AND_DELIVERED',
  NO_PLANS_AVAILABLE = 'NO_PLANS_AVAILABLE',
}

export type SubscriptionFilterInput = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  minDaysPausedInTransit?: InputMaybe<Scalars['Int']>;
  orderNumber?: InputMaybe<Scalars['ID']>;
  serialNumber?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Array<SubscriptionState>>;
  storeCode?: InputMaybe<DefaultStoreCode>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionGroverCare = {
  __typename?: 'SubscriptionGroverCare';
  coverage: Scalars['Int'];
  deductibleFees: Array<DeductibleFee>;
  price: Price;
  sku: Scalars['String'];
  subscriptionId: Scalars['ID'];
};

export type SubscriptionListEntry = SubscriptionCard | SubscriptionStack;

/** The connection type for SubscriptionListEntry. */
export type SubscriptionListEntryConnection = {
  __typename?: 'SubscriptionListEntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubscriptionListEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SubscriptionListEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubscriptionListEntryEdge = {
  __typename?: 'SubscriptionListEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SubscriptionListEntry>;
};

export type SubscriptionListFilter = {
  __typename?: 'SubscriptionListFilter';
  orderId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  state: Array<SubscriptionState>;
};

export type SubscriptionListFilterInput = {
  orderId?: InputMaybe<Scalars['ID']>;
  productId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Array<SubscriptionState>>;
};

export type SubscriptionNote =
  | AttentionNote
  | EndedNote
  | NextPaymentNote
  | PausedNote
  | ProcessingNote;

export type SubscriptionPayment = PaymentInterface & {
  __typename?: 'SubscriptionPayment';
  amountBreakdown: Array<PaymentBreakdownItem>;
  basePrice: Price;
  contract?: Maybe<Contract>;
  createdAt: Scalars['ISO8601DateTime'];
  discountableLoyaltyPrice: Price;
  discountedLoyaltyPrice: Price;
  dueDate: DateUnion;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceUrl?: Maybe<Scalars['String']>;
  marketingSubscriptionPaymentData: Array<HintModel>;
  paidDate?: Maybe<Scalars['ISO8601DateTime']>;
  price: Price;
  refundedAmount: Price;
  remoteId?: Maybe<Scalars['String']>;
  state: PaymentState;
  /** @deprecated Use Contract field */
  subscriptionDetails?: Maybe<SubscriptionDetails>;
};

export type SubscriptionStack = {
  __typename?: 'SubscriptionStack';
  filter: SubscriptionListFilter;
  product: Product;
  quantity: Scalars['Int'];
};

export enum SubscriptionState {
  active = 'active',
  cancelled = 'cancelled',
  declined = 'declined',
  ended = 'ended',
  paused = 'paused',
  processing = 'processing',
}

export type SubscriptionSummary =
  | FilterButton
  | Section
  | StandaloneDiscountOffer
  | SwapDiscountOffer;

export type SupportRequest = {
  __typename?: 'SupportRequest';
  /** @deprecated Use grapql __typename instead */
  action: Scalars['String'];
  orderNumber?: Maybe<Scalars['String']>;
};

export type SurpriseDiscountOffer = {
  __typename?: 'SurpriseDiscountOffer';
  newBuyoutTerms?: Maybe<MonthsToOwn>;
  percentage?: Maybe<Scalars['Int']>;
};

export type SurpriseDiscountTerm = {
  __typename?: 'SurpriseDiscountTerm';
  available: Scalars['Boolean'];
  discountAmountInCents?: Maybe<Scalars['Int']>;
  discountPercentage?: Maybe<Scalars['Int']>;
  newPriceInCents?: Maybe<Scalars['Int']>;
  newPurchaseOptionAvailableSince?: Maybe<Scalars['ISO8601DateTime']>;
  newPurchaseOptionRequiredMonths?: Maybe<Scalars['Int']>;
  unavailabilityReason?: Maybe<SurpriseDiscountUnavailabilityReason>;
};

export enum SurpriseDiscountUnavailabilityReason {
  ALREADY_HAS_RECURRING_DISCOUNT = 'ALREADY_HAS_RECURRING_DISCOUNT',
  CONTRACT_IS_NOT_ACTIVE = 'CONTRACT_IS_NOT_ACTIVE',
  NOT_A_FLEX_CONTRACT = 'NOT_A_FLEX_CONTRACT',
  OFFER_IS_NOT_AVAILABLE = 'OFFER_IS_NOT_AVAILABLE',
  USER_ALREADY_USED_SURPRISE_DISCOUNT = 'USER_ALREADY_USED_SURPRISE_DISCOUNT',
}

export type Swap = {
  __typename?: 'Swap';
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  contractId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  expiresAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  mixSubscription: Mix;
  newDevice: MixDevice;
  oldDevice: MixDevice;
  orderNumber: Scalars['ID'];
  shipmentLabels?: Maybe<Array<ShipmentLabel>>;
  shippingLabelUrl?: Maybe<Scalars['String']>;
  state: SwapState;
  trackingUrl?: Maybe<Scalars['String']>;
  user: User;
  userId: Scalars['ID'];
};

export enum SwapCancellationError {
  DEVICE_IS_NOT_AWAITING_RETURN = 'DEVICE_IS_NOT_AWAITING_RETURN',
  SWAP_IS_NOT_ONGOING = 'SWAP_IS_NOT_ONGOING',
  SWAP_NOT_FOUND = 'SWAP_NOT_FOUND',
}

export type SwapDiscountOffer = SubscriptionDiscountInterface & {
  __typename?: 'SwapDiscountOffer';
  discountPercentage: Scalars['Int'];
  minimalCartPrice: Price;
  price: Price;
  product: Product;
  subscriptionDetails: SubscriptionDetails;
};

export type SwapOffer = {
  __typename?: 'SwapOffer';
  available: Scalars['Boolean'];
};

export enum SwapState {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  ONGOING = 'ONGOING',
}

export enum SwapUnavailabilityReason {
  CONTRACT_NOT_ACTIVE = 'CONTRACT_NOT_ACTIVE',
  FLAG_DISABLED = 'FLAG_DISABLED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  SWAP_ALREADY_ONGOING = 'SWAP_ALREADY_ONGOING',
  TOO_EARLY = 'TOO_EARLY',
}

export type SwappingTerms = {
  __typename?: 'SwappingTerms';
  available: Scalars['Boolean'];
  availableAfter?: Maybe<Scalars['ISO8601DateTime']>;
  timeUntilAvailable?: Maybe<Duration>;
  unavailabilityReason?: Maybe<SwapUnavailabilityReason>;
};

export type SyncCompanyLeadInput = {
  company_id: Scalars['Float'];
};

export type SyncContractsAssetError = {
  __typename?: 'SyncContractsAssetError';
  contractId: Scalars['ID'];
  message: Scalars['String'];
};

export type SyncContractsAssetInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractIds: Array<Scalars['ID']>;
};

export type SyncContractsAssetPayload = {
  __typename?: 'SyncContractsAssetPayload';
  assets?: Maybe<Array<WorkAsset>>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<SyncContractsAssetError>>;
};

/** Autogenerated input type of SyncProductVariants */
export type SyncProductVariantsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of SyncProductVariants. */
export type SyncProductVariantsPayload = {
  __typename?: 'SyncProductVariantsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  productVariants?: Maybe<Array<FlexDevice>>;
};

export type SyncShipmentFailure = {
  __typename?: 'SyncShipmentFailure';
  message: Scalars['String'];
};

export type SyncShipmentResultType = SyncShipmentFailure | SyncShipmentSuccess;

export type SyncShipmentSuccess = {
  __typename?: 'SyncShipmentSuccess';
  events: Array<ShipmentLifecycleEvent>;
  status: ShipmentLifecycleStatus;
};

/** Autogenerated input type of SyncSubscriptionStatus */
export type SyncSubscriptionStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of SyncSubscriptionStatus. */
export type SyncSubscriptionStatusPayload = {
  __typename?: 'SyncSubscriptionStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptionDetails: SubscriptionDetails;
};

/** Autogenerated input type of SyncUserPaymentMethods */
export type SyncUserPaymentMethodsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SyncUserPaymentMethods. */
export type SyncUserPaymentMethodsPayload = {
  __typename?: 'SyncUserPaymentMethodsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
};

/** Autogenerated input type of SyncVerificationState */
export type SyncVerificationStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of SyncVerificationState. */
export type SyncVerificationStatePayload = {
  __typename?: 'SyncVerificationStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Task = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdByUserId: Scalars['Int'];
  id: Scalars['ID'];
  status: TaskStatus;
};

export type TaskBundlesExportResult = BundlesExportTask | TaskError;

export type TaskError = {
  __typename?: 'TaskError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type TaskProductsUploadResult = ProductsUploadTask | TaskError;

export type TaskRentalPlanExportResult = RentalPlanExportTask | TaskError;

export type TaskRentalPlanUploadResult = RentalPlanUploadTask | TaskError;

export enum TaskStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  DELAYED = 'DELAYED',
  FAILED = 'FAILED',
  PAUSED = 'PAUSED',
  STUCK = 'STUCK',
  WAITING = 'WAITING',
}

export enum TaskType {
  BUNDLES_EXPORT = 'BUNDLES_EXPORT',
  PRODUCTS_UPLOAD = 'PRODUCTS_UPLOAD',
  RENTAL_PLAN_EXPORT = 'RENTAL_PLAN_EXPORT',
  RENTAL_PLAN_UPLOAD = 'RENTAL_PLAN_UPLOAD',
}

export type TasksWhereInput = {
  type: TaskType;
};

export type Team = {
  __typename?: 'Team';
  description?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  title: Scalars['String'];
};

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  autoAccept: Scalars['Boolean'];
  content: Scalars['String'];
  createdAt: Scalars['DateISOString'];
  createdBy: Scalars['ID'];
  daysToDecide: Scalars['Int'];
  disabledFeatures: Array<Maybe<Feature>>;
  publishedAt?: Maybe<Scalars['DateISOString']>;
  status: TermsAndConditionsStatus;
  storeCodes: Array<Scalars['String']>;
  summary: Scalars['String'];
  updatedAt: Scalars['DateISOString'];
  version: Scalars['ID'];
};

/** The connection type for TermsAndConditions. */
export type TermsAndConditionsConnection = {
  __typename?: 'TermsAndConditionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TermsAndConditionsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TermsAndConditionsLocales>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TermsAndConditionsEdge = {
  __typename?: 'TermsAndConditionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TermsAndConditionsLocales>;
};

export type TermsAndConditionsFilterInput = {
  status?: InputMaybe<TermsAndConditionsStatus>;
  storeCode?: InputMaybe<Scalars['String']>;
};

export type TermsAndConditionsLocales = {
  __typename?: 'TermsAndConditionsLocales';
  autoAccept: Scalars['Boolean'];
  createdAt: Scalars['DateISOString'];
  createdBy: Scalars['ID'];
  daysToDecide: Scalars['Int'];
  disabledFeatures: Array<Maybe<Feature>>;
  locales: Array<LocaleContent>;
  publishedAt?: Maybe<Scalars['DateISOString']>;
  status: TermsAndConditionsStatus;
  storeCodes: Array<Scalars['String']>;
  updatedAt: Scalars['DateISOString'];
  version: Scalars['ID'];
};

export enum TermsAndConditionsStatus {
  CREATED = 'CREATED',
  PUBLISHED = 'PUBLISHED',
}

export type TextNoteInterface = {
  text?: Maybe<Scalars['String']>;
};

export enum ThumbsDirection {
  down = 'down',
  up = 'up',
}

export type ThumbsOption = {
  __typename?: 'ThumbsOption';
  direction: ThumbsDirection;
};

export type ThumbsOptions = {
  __typename?: 'ThumbsOptions';
  options: Array<ThumbsOption>;
};

export type ThumbsResult = {
  __typename?: 'ThumbsResult';
  downVotes: Scalars['Int'];
  upVotes: Scalars['Int'];
};

export type ThumbsResults = {
  __typename?: 'ThumbsResults';
  thumbsResults: Array<ThumbsResult>;
  total: TotalResults;
};

export type Ticket = {
  __typename?: 'Ticket';
  entityId: Scalars['String'];
  entityType: EntityType;
  meta: Scalars['JSON'];
  payload: PollOptions;
  pollMode: PollMode;
  ticket: Scalars['String'];
};

export type TimeEstimate = {
  __typename?: 'TimeEstimate';
  atLeast: Duration;
  atMost?: Maybe<Duration>;
};

export enum TimeUnit {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export type TimestampComparatorInput = {
  _gte?: InputMaybe<Scalars['DateTime']>;
  _lte?: InputMaybe<Scalars['DateTime']>;
};

export type Tink = ProviderInterface & {
  __typename?: 'Tink';
  tracingId: Scalars['String'];
  xs2aSessionKey: Scalars['String'];
  xs2aTransactionId: Scalars['String'];
};

export type ToggleDashboardAccessInput = {
  /** CompanyId that the access should be toggled for. */
  companyId: Scalars['Int'];
};

export type ToggleKitStateInput = {
  state: KitState;
  uuid: Scalars['String'];
};

export enum TosDecision {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export type TosUpdate = {
  __typename?: 'TosUpdate';
  createdAt: Scalars['ISO8601DateTime'];
  decision: TosDecision;
  version: Scalars['ID'];
};

export type TotalResults = {
  __typename?: 'TotalResults';
  userCount: Scalars['Int'];
  voteCount: Scalars['Int'];
};

export type TrackingInformationProperties = {
  __typename?: 'TrackingInformationProperties';
  carrier?: Maybe<ShipmentCarrier>;
  trackingNumber?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  ID: Scalars['String'];
  amount: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  direction: TransactionDirection;
  effectiveDate: Scalars['ISO8601DateTime'];
  movement: Movement;
  transactionResources: Array<ResourceTransaction>;
};

export type TransactionActionModel = {
  __typename?: 'TransactionActionModel';
  actionComment: Scalars['String'];
  actionReason: Scalars['String'];
  actionType: ActionType;
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  paymentOrderUuid: Scalars['String'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  status: ActionStatus;
  transactionUuid?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  uuid: Scalars['ID'];
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges?: Maybe<Array<TransactionEdge>>;
  nodes?: Maybe<Array<Transaction>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum TransactionDirection {
  IN = 'IN',
  OUT = 'OUT',
}

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Transaction>;
};

export enum TransactionErrorCode {
  BANKCARD_3DS_DECLINED = 'BANKCARD_3DS_DECLINED',
  BANKCARD_3DS_FAILED = 'BANKCARD_3DS_FAILED',
  BANKCARD_CLAIMED_AS_LOST = 'BANKCARD_CLAIMED_AS_LOST',
  BANKCARD_CLAIMED_AS_STOLEN = 'BANKCARD_CLAIMED_AS_STOLEN',
  BANKCARD_EXPIRED = 'BANKCARD_EXPIRED',
  BANKCARD_FRAUDULENT = 'BANKCARD_FRAUDULENT',
  BANKCARD_INSUFFICIENT_FUNDS = 'BANKCARD_INSUFFICIENT_FUNDS',
  BANKCARD_INVALID = 'BANKCARD_INVALID',
  BANKCARD_UNSUPPORTED = 'BANKCARD_UNSUPPORTED',
  BLOCKED_BY_RISK_CHECK = 'BLOCKED_BY_RISK_CHECK',
  INVALID_CAPTURE_AMOUNT = 'INVALID_CAPTURE_AMOUNT',
  INVALID_REFUND_AMOUNT = 'INVALID_REFUND_AMOUNT',
  OPERATION_FAILED = 'OPERATION_FAILED',
  PAYPAL_ACCOUNT_CLOSED = 'PAYPAL_ACCOUNT_CLOSED',
  PROVIDER_COMMUNICATION_PROBLEM = 'PROVIDER_COMMUNICATION_PROBLEM',
  SEPA_INVALID_BIC = 'SEPA_INVALID_BIC',
  SEPA_INVALID_IBAN = 'SEPA_INVALID_IBAN',
  TRANSACTION_CANCELLED = 'TRANSACTION_CANCELLED',
  TRANSACTION_CANCELLED_BY_USER = 'TRANSACTION_CANCELLED_BY_USER',
  TRANSACTION_DECLINED = 'TRANSACTION_DECLINED',
  TRANSACTION_EXPIRED = 'TRANSACTION_EXPIRED',
  TRANSACTION_NOT_REFUNDABLE = 'TRANSACTION_NOT_REFUNDABLE',
}

export type TransactionModel = {
  __typename?: 'TransactionModel';
  accountFrom?: Maybe<Scalars['String']>;
  accountTo?: Maybe<Scalars['String']>;
  amount: Scalars['Money'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  failedReason?: Maybe<Scalars['String']>;
  gatewayResponse?: Maybe<Scalars['JSON']>;
  /** Reference to Ledger Movement id */
  movementId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Scalars['String']>;
  proxied: Scalars['Boolean'];
  refundReason?: Maybe<RefundReason>;
  status: Scalars['String'];
  type: Scalars['String'];
  uuid: Scalars['ID'];
};

export type TranslateInputType = {
  de: Scalars['String'];
  en: Scalars['String'];
  es: Scalars['String'];
  nl: Scalars['String'];
  placeholder: Scalars['String'];
};

export type TranslateUpdateInputType = {
  de?: InputMaybe<Scalars['String']>;
  en?: InputMaybe<Scalars['String']>;
  es?: InputMaybe<Scalars['String']>;
  nl?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type TriggerEventMetadata = {
  __typename?: 'TriggerEventMetadata';
  name: Scalars['String'];
  rawPayload: Scalars['JSON'];
  topic: Scalars['String'];
};

/** Autogenerated input type of UnlockUser */
export type UnlockUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of UnlockUser. */
export type UnlockUserPayload = {
  __typename?: 'UnlockUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of UpdateAddress */
export type UpdateAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryIso?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<Scalars['Boolean']>;
  stateAbbr?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAddress. */
export type UpdateAddressPayload = {
  __typename?: 'UpdateAddressPayload';
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAgreedPurchasePrice */
export type UpdateAgreedPurchasePriceInput = {
  agreedPurchasePriceInCents: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['ID'];
  contractIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of UpdateAgreedPurchasePrice. */
export type UpdateAgreedPurchasePricePayload = {
  __typename?: 'UpdateAgreedPurchasePricePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contracts: Array<Contract>;
};

export type UpdateAllAssociationInput = {
  addonsSku?: InputMaybe<Array<Scalars['String']>>;
  digitalOtpProductSkus?: InputMaybe<Array<Scalars['String']>>;
  mainProductSku: Scalars['String'];
  storeCode: Scalars['String'];
};

export type UpdateAssetInput = {
  activatedAt?: InputMaybe<Scalars['DateTime']>;
  assetStatus?: InputMaybe<Scalars['String']>;
  brandName?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contractId?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  terminatedAt?: InputMaybe<Scalars['DateTime']>;
  uuid: Scalars['String'];
};

export type UpdateAssociationAddonsInput = {
  addonsSku?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
};

export type UpdateCompanyDataInput = {
  companyTypeId?: InputMaybe<Scalars['Int']>;
  hrv?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<CompanySize>;
  status?: InputMaybe<Scalars['String']>;
  ust?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateCompany */
export type UpdateCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  data: UpdateCompanyDataInput;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateCompany. */
export type UpdateCompanyPayload = {
  __typename?: 'UpdateCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company: Company;
};

export type UpdateCompanyStatusInput = {
  status: CompanyStatus;
};

/** Autogenerated input type of UpdateContractTermsAndConditions */
export type UpdateContractTermsAndConditionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  decision: TosDecision;
  version: Scalars['ID'];
};

/** Autogenerated return type of UpdateContractTermsAndConditions. */
export type UpdateContractTermsAndConditionsPayload = {
  __typename?: 'UpdateContractTermsAndConditionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

/** Autogenerated input type of UpdateCustomPurchasePriceMarkup */
export type UpdateCustomPurchasePriceMarkupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractIds?: InputMaybe<Array<Scalars['ID']>>;
  dryRun?: InputMaybe<Scalars['Boolean']>;
  limitFirst?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateCustomPurchasePriceMarkup. */
export type UpdateCustomPurchasePriceMarkupPayload = {
  __typename?: 'UpdateCustomPurchasePriceMarkupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateDaysToDecideInput = {
  daysToDecide: Scalars['Int'];
  version: Scalars['ID'];
};

export type UpdateDisabledFeaturesInput = {
  disabledFeatures: Array<InputMaybe<Feature>>;
  version: Scalars['ID'];
};

export type UpdateEmployeeInput = {
  address?: InputMaybe<WorkUpdateAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateImageInput = {
  url: Scalars['ID'];
};

export type UpdateKitInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateLegacyCompanyInput = {
  billing_email?: InputMaybe<Scalars['String']>;
  company_type_id?: InputMaybe<Scalars['Float']>;
  hrv?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  legal_representative_email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rent_approximation?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<CompanySize>;
  status?: InputMaybe<CompanyStatus>;
  /** Needed to validate UST field. */
  store?: InputMaybe<Scalars['String']>;
  ust?: InputMaybe<Scalars['String']>;
  viban?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateLocaleInput = {
  content: Scalars['String'];
  locale: Scalars['String'];
  summary: Scalars['String'];
  version: Scalars['ID'];
};

export type UpdateModel = {
  __typename?: 'UpdateModel';
  /** Number of rows affected */
  affected: Scalars['Float'];
};

export type UpdateOrderFulfillmentCommentInput = {
  content: Scalars['String'];
  orderFulfillmentUid?: InputMaybe<Scalars['String']>;
  uid: Scalars['String'];
};

/** Input type for updating an account for a contract in the Billing system */
export type UpdatePaymentMethodForContractInput = {
  billingAccountId: Scalars['ID'];
  contractId: Scalars['ID'];
};

export type UpdatePaymentMethodForContractPayload = {
  __typename?: 'UpdatePaymentMethodForContractPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateProduct */
export type UpdateProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sku: Scalars['ID'];
  variants?: InputMaybe<Array<UpdateVariantInput>>;
};

export type UpdateProductLogisticsError = GenericError & {
  __typename?: 'UpdateProductLogisticsError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateProductLogisticsSuccess = {
  __typename?: 'UpdateProductLogisticsSuccess';
  success: Scalars['Boolean'];
};

export type UpdateProductLogisticsSuccessOrError =
  | UpdateProductLogisticsError
  | UpdateProductLogisticsSuccess;

/** Autogenerated return type of UpdateProduct. */
export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

export type UpdateProductSpecificationsError = GenericError & {
  __typename?: 'UpdateProductSpecificationsError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateProductSpecificationsSuccess = {
  __typename?: 'UpdateProductSpecificationsSuccess';
  success: Scalars['Boolean'];
};

export type UpdateProductSpecificationsSuccessOrError =
  | UpdateProductSpecificationsError
  | UpdateProductSpecificationsSuccess;

export type UpdateSerialNumberInput = {
  assetId: Scalars['String'];
  newSerialNumber: Scalars['String'];
};

export type UpdateStoreCodesInput = {
  storeCodes: Array<Scalars['String']>;
  version: Scalars['ID'];
};

/** Autogenerated input type of UpdateSubscription */
export type UpdateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  subscriptionId: Scalars['ID'];
};

/** Autogenerated return type of UpdateSubscription. */
export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscriptionDetails: SubscriptionDetails;
};

/** Autogenerated input type of UpdateSubscriptionTermsAndConditions */
export type UpdateSubscriptionTermsAndConditionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  decision: TosDecision;
  subscriptionId: Scalars['ID'];
  version: Scalars['ID'];
};

/** Autogenerated return type of UpdateSubscriptionTermsAndConditions. */
export type UpdateSubscriptionTermsAndConditionsPayload = {
  __typename?: 'UpdateSubscriptionTermsAndConditionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  subscriptionDetails: SubscriptionDetails;
};

export type UpdateTrackingInformation = {
  __typename?: 'UpdateTrackingInformation';
  name: ShipmentOperationName;
  newProperties: TrackingInformationProperties;
  oldProperties: TrackingInformationProperties;
};

export type UpdateTrackingInformationFailure = {
  __typename?: 'UpdateTrackingInformationFailure';
  message: Scalars['String'];
};

export type UpdateTrackingInformationResultType =
  | UpdateTrackingInformationFailure
  | UpdateTrackingInformationSuccess;

export type UpdateTrackingInformationSuccess = {
  __typename?: 'UpdateTrackingInformationSuccess';
  carrier: ShipmentCarrier;
  events: Array<ShipmentLifecycleEvent>;
  status: ShipmentLifecycleStatus;
  trackingNumber: Scalars['String'];
};

export type UpdateUserDataInput = {
  birthdate?: InputMaybe<Scalars['ISO8601Date']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  data: UpdateUserDataInput;
  userId: Scalars['ID'];
};

/** Autogenerated input type of UpdateUserNewsletterOptInState */
export type UpdateUserNewsletterOptInStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newState: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateUserNewsletterOptInState. */
export type UpdateUserNewsletterOptInStatePayload = {
  __typename?: 'UpdateUserNewsletterOptInStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of UpdateUserTeams */
export type UpdateUserTeamsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of UpdateUserTeams. */
export type UpdateUserTeamsPayload = {
  __typename?: 'UpdateUserTeamsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type UpdateVariantInput = {
  images?: InputMaybe<Array<UpdateImageInput>>;
  sku: Scalars['ID'];
};

export type UpgradeRentalPlan = {
  __typename?: 'UpgradeRentalPlan';
  availablePlans?: Maybe<Array<RentalPlan>>;
};

export type Upsell = Operation & {
  __typename?: 'Upsell';
  actor: Actor;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Not in use, will be removed */
  owner?: Maybe<User>;
  startedAt: Scalars['ISO8601DateTime'];
  /** @deprecated Null if stated by grover employee, use actor instead */
  startedBy?: Maybe<User>;
  state: InboundShipmentStates;
  updates: Array<FlowUpdate>;
};

export type UpsellTerm = {
  __typename?: 'UpsellTerm';
  available: Scalars['Boolean'];
  availableFrom?: Maybe<Scalars['ISO8601DateTime']>;
  unavailabilityReason?: Maybe<ReturnUnavailabilityReason>;
};

/** Input for the usePaymentMethod mutation. */
export type UsePaymentMethodInput = {
  /** Where to redirect the customer if authorization is cancelled. */
  cancelRedirectUrl: Scalars['String'];
  /** Where to redirect the customer if authorization fails. */
  errorRedirectUrl: Scalars['String'];
  /** The order for which to use the method. */
  orderNumber: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
  /** Where to redirect the customer if authorization succeeds. */
  successRedirectUrl: Scalars['String'];
  /** 3-D Secure-related data, applicable to bank cards. */
  threeDSData?: InputMaybe<PaymentMethod3dsData>;
};

/** Result of the usePaymentMethod mutation. */
export type UsePaymentMethodResult = {
  __typename?: 'UsePaymentMethodResult';
  /** URL to redirect the user to if authorization is required by the payment provider. */
  redirectUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UseSurpriseDiscount */
export type UseSurpriseDiscountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
};

/** Autogenerated return type of UseSurpriseDiscount. */
export type UseSurpriseDiscountPayload = {
  __typename?: 'UseSurpriseDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contract: Contract;
};

export type User = {
  __typename?: 'User';
  activeSubscriptionValue: Price;
  addresses?: Maybe<Array<Address>>;
  anotherOrderPayment?: Maybe<OrderPayment>;
  anotherReferralContract?: Maybe<Flex>;
  billingAddress?: Maybe<Address>;
  birthdate: Scalars['String'];
  canBeDeleted: Scalars['Boolean'];
  company?: Maybe<Company>;
  contracts: ContractConnection;
  country?: Maybe<Country>;
  createdAt: Scalars['ISO8601DateTime'];
  defaultStore: Store;
  deletionUnavailabilityAdditionalInfo: UserDeletionUnavailabilityAdditionalInfo;
  deletionUnavailabilityReasons: Array<UserDeletionUnavailabilityReason>;
  devices?: Maybe<AuthDeviceConnection>;
  eligibleReferral: Scalars['Boolean'];
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  failedLoginAttempts: Scalars['Int'];
  firstName: Scalars['String'];
  groupBilling?: Maybe<GroupBilling>;
  guestConnections: Array<ConnectionModel>;
  hasContractsOrSubscriptions: Scalars['Boolean'];
  hasGroverCashHistory: Scalars['Boolean'];
  hostConnection?: Maybe<ConnectionModel>;
  hostConnections: Array<ConnectionModel>;
  id: Scalars['ID'];
  /** @deprecated use VerificationSummary.verification_check_available type */
  idCheckAvailable: Scalars['Boolean'];
  /** @deprecated use VerificationSummary.verification_check_required type */
  idCheckRequired: Scalars['Boolean'];
  lastName: Scalars['String'];
  locked: Scalars['Boolean'];
  loyaltyBalance: Array<LoyaltyUserBalanceResult>;
  mixSummary?: Maybe<MixSummary>;
  monthlyTotal: Price;
  newsletterOptInState: Scalars['Boolean'];
  notifications: SendResultConnection;
  /** when filter is cancellable US orders are excluded */
  orders?: Maybe<CartOrderConnection>;
  passwordResetForced?: Maybe<Scalars['Boolean']>;
  paymentMethods: Array<PaymentMethod>;
  payments?: Maybe<PaymentsUnionConnection>;
  pendingSubscriptionValue: Price;
  permissions: Scalars['String'];
  personalIdentifications?: Maybe<Array<PersonalIdentification>>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified: Scalars['Boolean'];
  referralCode: Scalars['String'];
  resetPasswordSentAt?: Maybe<Scalars['ISO8601DateTime']>;
  shippingAddress?: Maybe<Address>;
  shouldUseSalesforce: InfraRoutingDecision;
  signupLanguage: Scalars['String'];
  standaloneDiscountContracts: ContractConnection;
  subscriptionAllowance?: Maybe<Scalars['Int']>;
  subscriptionLimit?: Maybe<Scalars['Int']>;
  subscriptionSummary: Array<SubscriptionSummary>;
  subscriptionsTotal?: Maybe<Scalars['Int']>;
  swapDiscountOffer?: Maybe<SwapDiscountOffer>;
  teams?: Maybe<Array<Team>>;
  type: UserType;
  userConsents: Array<UserConsent>;
  /** @deprecated use VerificationSummary type */
  verificationState: UserVerificationState;
  verificationSummary: VerificationSummary;
  verifications?: Maybe<VerificationConnection>;
  votes?: Maybe<VotesConnection>;
  waitingListTickets?: Maybe<Array<WaitingListTicket>>;
  /** @deprecated use VerificationSummary.BankAccountSnapshotInitData type */
  xs2a: Xs2a;
};

export type UseranotherOrderPaymentArgs = {
  orderNumber: Scalars['String'];
};

export type UseranotherReferralContractArgs = {
  orderNumber: Scalars['String'];
};

export type UsercontractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ContractFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserdevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientDeviceId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserloyaltyBalanceArgs = {
  countries: Array<LoyaltyCountry>;
};

export type UsernotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderNumber?: InputMaybe<Scalars['String']>;
};

export type UserordersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrdersFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserpaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentsFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserstandaloneDiscountContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserverificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UservotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<VoteOrderInput>;
  where?: InputMaybe<VoteWhereInput>;
};

export type UserActionMetadata = {
  __typename?: 'UserActionMetadata';
  actor: Actor;
  cancellationReason?: Maybe<ContractTerminationReason>;
  description?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type UserBar = {
  __typename?: 'UserBar';
  accountLink: HeaderLink;
  accountLinks?: Maybe<Array<Array<AccountMenuLink>>>;
  accountMenuLinksV2?: Maybe<Array<Array<AccountMenuLink>>>;
  dashboardLinksV2?: Maybe<Array<HeaderLink>>;
  hintLink?: Maybe<AccountMenuHint>;
  menuLinks?: Maybe<Array<Array<HeaderLink>>>;
  user?: Maybe<HeaderUser>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserConsent = {
  __typename?: 'UserConsent';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  value?: Maybe<UserConsentValue>;
};

export enum UserConsentValue {
  NO = 'NO',
  NONE = 'NONE',
  YES = 'YES',
}

export type UserDeletionUnavailabilityAdditionalInfo = {
  __typename?: 'UserDeletionUnavailabilityAdditionalInfo';
  activeAllocations: Array<Scalars['ID']>;
  activeSalesforceSubscriptions: Array<Scalars['ID']>;
  nonTerminalSubscriptions: Array<Scalars['ID']>;
  processingOrders: Array<Scalars['ID']>;
};

export enum UserDeletionUnavailabilityReason {
  HAS_ACTIVE_ALLOCATIONS = 'HAS_ACTIVE_ALLOCATIONS',
  HAS_ACTIVE_SALESFORCE_SUBSCRIPTIONS = 'HAS_ACTIVE_SALESFORCE_SUBSCRIPTIONS',
  HAS_NON_TERMINAL_DASHBOARD_SUBSCRIPTIONS = 'HAS_NON_TERMINAL_DASHBOARD_SUBSCRIPTIONS',
  HAS_PROCESSING_ORDERS = 'HAS_PROCESSING_ORDERS',
  IS_SOFT_DELETED = 'IS_SOFT_DELETED',
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserIdAndOrderNumber = {
  orderNumber: Scalars['String'];
  userId: Scalars['ID'];
};

export type UserShippingSummaryType = {
  __typename?: 'UserShippingSummaryType';
  qualitative: QualitativeShippingSummaryType;
  quantitative: QuantitativeShippingSummaryType;
  userId: Scalars['String'];
};

export enum UserType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

export type UserTypeComparatorInput = {
  _eq?: InputMaybe<UserTypes>;
  _neq?: InputMaybe<UserTypes>;
};

export type UserTypeComparisonInput = {
  _eq?: InputMaybe<UserType>;
  _neq?: InputMaybe<UserType>;
};

export enum UserTypes {
  business = 'business',
  private = 'private',
}

export enum UserVerificationState {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  VERIFIED = 'VERIFIED',
}

export type UsersWhereInput = {
  and?: InputMaybe<Array<UsersWhereInput>>;
  companyName?: InputMaybe<StringComparisonInput>;
  email?: InputMaybe<StringComparisonInput>;
  id?: InputMaybe<IntegerComparisonInput>;
  name?: InputMaybe<StringComparisonInput>;
  phoneNumber?: InputMaybe<StringComparisonInput>;
  shouldUseSalesforce?: InputMaybe<InfraRoutingDecisionComparisonInput>;
  type?: InputMaybe<UserTypeComparisonInput>;
};

export type UuidModel = {
  __typename?: 'UuidModel';
  uuid: Scalars['ID'];
};

export type ValidSalesOrderAssetsType = {
  __typename?: 'ValidSalesOrderAssetsType';
  assets?: Maybe<Array<SalesOrderAssetWithErrorMessageType>>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['String'];
};

export type ValidationResult = {
  valid: Scalars['Boolean'];
};

export type Variant = {
  __typename?: 'Variant';
  articleNumber?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  colorCodes?: Maybe<Array<Scalars['String']>>;
  colorTitle?: Maybe<Scalars['String']>;
  coreAttribute?: Maybe<Scalars['String']>;
  deliveryEstimates?: Maybe<DeliveryEstimates>;
  eans?: Maybe<Array<Scalars['String']>>;
  flags?: Maybe<Array<VariantFlag>>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  mixable: Scalars['Boolean'];
  originalImageUrl: Scalars['String'];
  originalPictures: Array<Scalars['String']>;
  preorder?: Maybe<Preorder>;
  product?: Maybe<Product>;
  sku: Scalars['ID'];
  upcs?: Maybe<Array<Scalars['String']>>;
};

export type VariantFlag = {
  __typename?: 'VariantFlag';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  store: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variantSku: Scalars['String'];
};

export type Verification = {
  __typename?: 'Verification';
  checkProviderReport: CheckProviderReport;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  processedBy?: Maybe<User>;
  reviewReasons: Array<Scalars['String']>;
  reviews: Array<VerificationReview>;
  state: VerificationState;
  user: User;
  waitingForDays: Scalars['Int'];
};

export type VerificationCheck = {
  __typename?: 'VerificationCheck';
  id: Scalars['ID'];
  provider: VerificationProvider;
  providerProduct: Scalars['String'];
  remoteId: Scalars['ID'];
  result?: Maybe<ProviderCheckResult>;
  verification?: Maybe<Verification>;
  verificationType: VerificationCheckType;
};

export enum VerificationCheckType {
  BANK_ACCOUNT_SNAPSHOT = 'BANK_ACCOUNT_SNAPSHOT',
  ID_VERIFICATION = 'ID_VERIFICATION',
}

/** The connection type for Verification. */
export type VerificationConnection = {
  __typename?: 'VerificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VerificationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Verification>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum VerificationDecision {
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINE',
}

export type VerificationDecisionInput = {
  decision: VerificationDecision;
  fieldsForCorrection?: InputMaybe<Array<Scalars['String']>>;
  verificationId: Scalars['ID'];
};

/** An edge in a connection. */
export type VerificationEdge = {
  __typename?: 'VerificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Verification>;
};

export enum VerificationProvider {
  KONTOMATIK = 'KONTOMATIK',
  ONFIDO = 'ONFIDO',
  PLAID = 'PLAID',
  TINK = 'TINK',
}

export type VerificationRequest = {
  __typename?: 'VerificationRequest';
  id: Scalars['ID'];
  state: VerificationRequestState;
  verificationCheck?: Maybe<VerificationCheck>;
};

/** The connection type for VerificationRequest. */
export type VerificationRequestConnection = {
  __typename?: 'VerificationRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VerificationRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<VerificationRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VerificationRequestEdge = {
  __typename?: 'VerificationRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<VerificationRequest>;
};

export enum VerificationRequestState {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export type VerificationReview = FieldReview;

export enum VerificationState {
  FAILED = 'FAILED',
  PASSED = 'PASSED',
  PENDING = 'PENDING',
}

export type VerificationSummary = {
  __typename?: 'VerificationSummary';
  bankAccountSnapshotInitData?: Maybe<ProviderInterface>;
  verificationCheckAvailable: Scalars['Boolean'];
  verificationCheckRequired: Scalars['Boolean'];
  verificationRequests: VerificationRequestConnection;
  verificationState: UserVerificationState;
};

export type VerificationSummaryverificationRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VerificationsFilterInput = {
  state?: InputMaybe<VerificationState>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Vote = Node & {
  __typename?: 'Vote';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityType>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['JSON']>;
  pollVersion?: Maybe<PollVersion>;
  user?: Maybe<User>;
  userId: Scalars['String'];
  voteSlug: Scalars['String'];
};

export type VoteOrderInput = {
  createdAt?: InputMaybe<OrderByDirection>;
  updatedAt?: InputMaybe<OrderByDirection>;
};

export type VoteWhereInput = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<EntityType>;
  pollSlug?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  voteSlug?: InputMaybe<Scalars['String']>;
  withComments?: InputMaybe<Scalars['Boolean']>;
};

export type VotesConnection = {
  __typename?: 'VotesConnection';
  edges: Array<VotesEdge>;
  pageInfo: PageInfo;
};

export type VotesEdge = {
  __typename?: 'VotesEdge';
  cursor: Scalars['String'];
  node: Vote;
};

export type VoucherDiscountOffer = SubscriptionDiscountInterface & {
  __typename?: 'VoucherDiscountOffer';
  discountPercentage: Scalars['Int'];
  minimalCartPrice: Price;
  price: Price;
};

export type VoucherRedemptionInput = {
  code: Scalars['String'];
  userEmail?: InputMaybe<Scalars['String']>;
};

export enum WMSCarrierServiceLevel {
  DhlIdentityCheck = 'DhlIdentityCheck',
  DhlInternationalPremium = 'DhlInternationalPremium',
  DhlNamedPersonOnly = 'DhlNamedPersonOnly',
  DhlStandard = 'DhlStandard',
  Hermes = 'Hermes',
  Omni = 'Omni',
  Standard = 'Standard',
  Ups2ndDayAir = 'Ups2ndDayAir',
  UpsDirectDeliveryOnly = 'UpsDirectDeliveryOnly',
  UpsGround = 'UpsGround',
  UpsNextDayAir = 'UpsNextDayAir',
  UpsNextDayAirSaver = 'UpsNextDayAirSaver',
  UpsStandard = 'UpsStandard',
  UpsStandardDirectDeliveryOnly = 'UpsStandardDirectDeliveryOnly',
  UpsSurePost = 'UpsSurePost',
}

export type WMSCursorFilterInput = {
  createdAt: Scalars['String'];
  uuid: Scalars['String'];
};

export enum WMSCustomerType {
  Business = 'Business',
  Normal = 'Normal',
}

export type WMSGoodsOrderStatusComparatorInput = {
  _eq?: InputMaybe<GoodsOrderStatus>;
  _in?: InputMaybe<Array<GoodsOrderStatus>>;
  _neq?: InputMaybe<GoodsOrderStatus>;
  _nin?: InputMaybe<Array<GoodsOrderStatus>>;
};

export enum WMSOrderMode {
  FLEX = 'FLEX',
  FLEX_LEGACY = 'FLEX_LEGACY',
  MIX = 'MIX',
}

export type WMSOrderModeComparatorInput = {
  _eq?: InputMaybe<WMSOrderMode>;
  _neq?: InputMaybe<WMSOrderMode>;
};

export type WMSOutboundOrder = {
  __typename?: 'WMSOutboundOrder';
  customerType?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
};

export enum WMSShippingOrderMode {
  Flex = 'Flex',
  FlexLegacy = 'FlexLegacy',
  Mix = 'Mix',
  SalesOrder = 'SalesOrder',
  Swap = 'Swap',
}

export enum WMSUserType {
  Business = 'Business',
  Normal = 'Normal',
}

export type WMSUserTypeComparatorInput = {
  _eq?: InputMaybe<WMSUserType>;
  _neq?: InputMaybe<WMSUserType>;
};

export enum WMSWarehouseCode {
  DisConnectEUKARLSRUHE = 'DisConnectEUKARLSRUHE',
  IngramMicroEUFLSB = 'IngramMicroEUFLSB',
  Splus = 'Splus',
  SynerlogisDE = 'SynerlogisDE',
  UpsSofteonEUNLRNG = 'UpsSofteonEUNLRNG',
  UpsSofteonUSKYLSE = 'UpsSofteonUSKYLSE',
}

export type WaitingList = {
  __typename?: 'WaitingList';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  store: Store;
};

export type WaitingListTicket = {
  __typename?: 'WaitingListTicket';
  id: Scalars['ID'];
  state: WaitingListTicketState;
  waitingList: WaitingList;
};

export enum WaitingListTicketState {
  INVITED = 'INVITED',
  WAITING = 'WAITING',
}

export type WalletModel = {
  __typename?: 'WalletModel';
  accounts: Array<AccountType>;
  chosenBillingDay?: Maybe<Scalars['Float']>;
  externalCustomer: Scalars['String'];
  strategy: Scalars['String'];
  uuid: Scalars['ID'];
};

export type WarehouseAssetEvent = IWarehouseEvent & {
  __typename?: 'WarehouseAssetEvent';
  createdAt: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  eventType: Scalars['String'];
  /** In case of a CLARIFICATION event this will be provided */
  foundSerialNumber?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  skuVariantCode: Scalars['String'];
  /** The sub-type of asset event (e.g. CLARIFICATION) */
  subType: Scalars['String'];
  uid: Scalars['ID'];
  warehouseCode: WMSWarehouseCode;
};

export enum WarehouseCode {
  DisConnectEUKARLSRUHE = 'DisConnectEUKARLSRUHE',
  IngramMicroEUFLSB = 'IngramMicroEUFLSB',
  Ups = 'Ups',
  UpsSofteonEUNLRNG = 'UpsSofteonEUNLRNG',
  UpsSofteonUSKYLSE = 'UpsSofteonUSKYLSE',
  Wemalo = 'Wemalo',
}

export type WarehouseEvent = WarehouseAssetEvent | WarehouseShipmentEvent;

export type WarehouseEventsFilterType = {
  serialNumber: StringComparatorInput;
};

export enum WarehouseGoodsOrderStatus {
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  Failed = 'Failed',
  Packed = 'Packed',
  Preparing = 'Preparing',
  Registered = 'Registered',
  Shipped = 'Shipped',
}

export type WarehouseShipmentEvent = IWarehouseEvent & {
  __typename?: 'WarehouseShipmentEvent';
  createdAt: Scalars['DateTimeISO'];
  eventType: Scalars['String'];
  /** In case of a shipped goods_order_item */
  recipientAddress?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  skuVariantCode: Scalars['String'];
  /** Usually representing goods_order_item status (preparing, packed, shipped) */
  subType: Scalars['String'];
  uid: Scalars['ID'];
  warehouseCode: WMSWarehouseCode;
};

export type WidgetProperties = {
  countdownId?: InputMaybe<Scalars['String']>;
  excludedCategories?: InputMaybe<Array<Scalars['String']>>;
};

export enum WidgetType {
  COUNTDOWN = 'COUNTDOWN',
  DEAL_PRODUCTS = 'DEAL_PRODUCTS',
  HANDPICKED_PRODUCT = 'HANDPICKED_PRODUCT',
  NEW_PRODUCTS = 'NEW_PRODUCTS',
  RANDOM_CATEGORY_TOP_PRODUCTS = 'RANDOM_CATEGORY_TOP_PRODUCTS',
  TOP_ORDERED_PRODUCTS = 'TOP_ORDERED_PRODUCTS',
  TOP_PRODUCTS = 'TOP_PRODUCTS',
}

/** Union of Work assets */
export type WorkAsset = GroverAsset;

export type WorkAssetEdge = {
  __typename?: 'WorkAssetEdge';
  cursor: Scalars['String'];
  node: WorkAsset;
};

export type WorkAssetSortInput = {
  activatedAt?: InputMaybe<SortOrder>;
  assetAssignee?: InputMaybe<SortOrder>;
  assetStatus?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priceInCents?: InputMaybe<SortOrder>;
  returnAvailableAfter?: InputMaybe<SortOrder>;
  serialNumber?: InputMaybe<SortOrder>;
  terminatedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
};

export enum WorkAssetStatus {
  awaiting_return = 'awaiting_return',
  no_status = 'no_status',
  on_rent = 'on_rent',
  preparing_asset = 'preparing_asset',
  preparing_to_ship = 'preparing_to_ship',
  processing_return = 'processing_return',
  purchased = 'purchased',
  shipping = 'shipping',
  shipping_return = 'shipping_return',
}

export type WorkAssetsConnection = {
  __typename?: 'WorkAssetsConnection';
  edges?: Maybe<Array<WorkAssetEdge>>;
  matchingCount?: Maybe<Scalars['Float']>;
  nodes?: Maybe<Array<WorkAsset>>;
  pageInfo?: Maybe<WorkPageInfo>;
  totalCount?: Maybe<Scalars['Float']>;
};

export type WorkAssetsFilterInput = {
  joinType?: InputMaybe<JoinType>;
  predicates: Array<WorkAssetsFilterPredicateJointInput>;
};

export enum WorkAssetsFilterPredicateAttributeType {
  assetStatus = 'assetStatus',
  assignedTo = 'assignedTo',
  brandName = 'brandName',
  categoryId = 'categoryId',
  contractId = 'contractId',
  createdAt = 'createdAt',
  discountAvailable = 'discountAvailable',
  name = 'name',
  orderNumber = 'orderNumber',
  priceInCents = 'priceInCents',
  returnAvailableAfter = 'returnAvailableAfter',
  serialNumber = 'serialNumber',
  subCategoryId = 'subCategoryId',
}

export type WorkAssetsFilterPredicateInput = {
  attribute: WorkAssetsFilterPredicateAttributeType;
  comparison: ComparisonType;
  type: PredicateType;
  value: Scalars['String'];
};

export type WorkAssetsFilterPredicateJointInput = {
  joinType?: InputMaybe<JoinType>;
  predicates: Array<WorkAssetsFilterPredicateInput>;
};

export type WorkCreateAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  billingAddress?: Scalars['Boolean'];
  city: Scalars['String'];
  countryIso: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  shippingAddress?: Scalars['Boolean'];
  stateAbbr?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

/** Union of Work employees */
export type WorkEmployee = GroverEmployee;

export type WorkEmployeeConnection = {
  __typename?: 'WorkEmployeeConnection';
  edges?: Maybe<Array<WorkEmployeeEdge>>;
  matchingCount?: Maybe<Scalars['Float']>;
  nodes?: Maybe<Array<WorkEmployee>>;
  pageInfo?: Maybe<WorkPageInfo>;
  totalCount?: Maybe<Scalars['Float']>;
};

export type WorkEmployeeEdge = {
  __typename?: 'WorkEmployeeEdge';
  cursor: Scalars['String'];
  node: WorkEmployee;
};

export type WorkEmployeeSortInput = {
  createdAt?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
};

export type WorkEmployeesFilterInput = {
  joinType?: InputMaybe<JoinType>;
  predicates: Array<WorkEmployeesFilterPredicateJointInput>;
};

export enum WorkEmployeesFilterPredicateAttributeType {
  city = 'city',
  companyId = 'companyId',
  country = 'country',
  email = 'email',
  externalId = 'externalId',
  name = 'name',
}

export type WorkEmployeesFilterPredicateInput = {
  attribute: WorkEmployeesFilterPredicateAttributeType;
  comparison: ComparisonType;
  type: PredicateType;
  value: Scalars['String'];
};

export type WorkEmployeesFilterPredicateJointInput = {
  joinType?: InputMaybe<JoinType>;
  predicates: Array<WorkEmployeesFilterPredicateInput>;
};

export type WorkPageInfo = {
  __typename?: 'WorkPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type WorkPaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  /** Is optional, if provided following validation is applied: min 1 and max 1000 */
  first?: InputMaybe<Scalars['Int']>;
  /** Is optional, if provided following validation is applied: min 1 and max 1000 */
  last?: InputMaybe<Scalars['Int']>;
};

export type WorkS3Presignedurl = {
  __typename?: 'WorkS3Presignedurl';
  /** Algorithm used for the signature, ignore this field, only needed for information purposes. */
  algorithm: Scalars['String'];
  /** AWS generated field for the upload. */
  credential: Scalars['String'];
  /** Key name for the file, this is the ID or name used to upload & download the file. */
  key: Scalars['String'];
  /** AWS generated field for the upload. Add this field to the upload for it to work. */
  policy: Scalars['String'];
  /** Raw fields gotten from AWS */
  rawFields: Scalars['String'];
  /** AWS generated field for the upload. */
  securityToken: Scalars['String'];
  /** AWS Signature for the upload. Add this field to the upload for it to work. */
  signature: Scalars['String'];
  /** Url for uploading the file. */
  url: Scalars['String'];
};

export type WorkUpdateAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  billingAddress?: Scalars['Boolean'];
  city: Scalars['String'];
  countryIso: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  shippingAddress?: Scalars['Boolean'];
  stateAbbr?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type Xs2a = {
  __typename?: 'Xs2a';
  tracingId: Scalars['String'];
  xs2aSessionKey: Scalars['String'];
  xs2aTransactionId: Scalars['String'];
};

export type AddPaymentMethodMutationVariables = Exact<{
  input: AddPaymentMethodInput;
}>;

export type AddPaymentMethodMutation = {
  __typename?: 'Mutation';
  addPaymentMethod: {
    __typename?: 'AddPaymentMethodResult';
    id: string;
    redirectUrl?: string | null;
  };
};

export type SetPaymentMethodMutationVariables = Exact<{
  input: SetPaymentMethodInput;
}>;

export type SetPaymentMethodMutation = {
  __typename?: 'Mutation';
  setPaymentMethod: {
    __typename?: 'SetPaymentMethodResult';
    operation?: { __typename?: 'SetPaymentMethodOperation'; id: string } | null;
  };
};

export type ReplacePaymentMethodMutationVariables = Exact<{
  input: ReplacePaymentMethodInput;
}>;

export type ReplacePaymentMethodMutation = {
  __typename?: 'Mutation';
  replacePaymentMethod: {
    __typename?: 'ReplacePaymentMethodResult';
    operation?: { __typename?: 'ReplacePaymentMethodOperation'; id: string } | null;
  };
};

export type DeletePaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePaymentMethodMutation = {
  __typename?: 'Mutation';
  deletePaymentMethod: {
    __typename?: 'DeletePaymentMethodResult';
    operation?: { __typename?: 'DeletePaymentMethodOperation'; id: string } | null;
  };
};

export type PaymentMethodQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PaymentMethodQuery = {
  __typename?: 'Query';
  paymentMethod?:
    | {
        __typename: 'ByInvoicePaymentMethod';
        id: string;
        groverApiId?: number | null;
        status: PaymentMethodStatus;
      }
    | {
        __typename: 'CardPaymentMethod';
        id: string;
        groverApiId?: number | null;
        status: PaymentMethodStatus;
      }
    | {
        __typename: 'PaypalPaymentMethod';
        id: string;
        groverApiId?: number | null;
        status: PaymentMethodStatus;
      }
    | {
        __typename: 'SepaPaymentMethod';
        id: string;
        groverApiId?: number | null;
        status: PaymentMethodStatus;
      }
    | null;
};

type LinkedContractsFragment_Flex_Fragment = {
  __typename?: 'Flex';
  id: string;
  billingTerms: {
    __typename?: 'BillingTerms';
    nextPeriod?: {
      __typename?: 'BillingPeriod';
      starts: any;
      amountDue: { __typename?: 'Price'; inCents: number; currency: string };
    } | null;
  };
  goods: Array<{
    __typename?: 'FlexDevice';
    product: {
      __typename?: 'Product';
      name: string;
      imageUrl: string;
      category: {
        __typename?: 'Category';
        images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
      };
    };
  }>;
};

type LinkedContractsFragment_SubscriptionDetails_Fragment = {
  __typename?: 'SubscriptionDetails';
  id: string;
  note?:
    | { __typename?: 'AttentionNote' }
    | { __typename?: 'EndedNote' }
    | { __typename?: 'NextPaymentNote'; date?: any | null }
    | { __typename?: 'PausedNote' }
    | { __typename?: 'ProcessingNote' }
    | null;
  product?: {
    __typename?: 'Product';
    name: string;
    imageUrl: string;
    category: {
      __typename?: 'Category';
      images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
    };
  } | null;
  price: { __typename?: 'Price'; inCents: number; currency: string };
};

export type LinkedContractsFragmentFragment =
  | LinkedContractsFragment_Flex_Fragment
  | LinkedContractsFragment_SubscriptionDetails_Fragment;

type PaymentMethodFragment_ByInvoicePaymentMethod_Fragment = {
  __typename?: 'ByInvoicePaymentMethod';
  id: string;
  billingAccountId?: string | null;
  status: PaymentMethodStatus;
  viban?: string | null;
  linkedContracts: Array<
    | {
        __typename?: 'Flex';
        id: string;
        billingTerms: {
          __typename?: 'BillingTerms';
          nextPeriod?: {
            __typename?: 'BillingPeriod';
            starts: any;
            amountDue: { __typename?: 'Price'; inCents: number; currency: string };
          } | null;
        };
        goods: Array<{
          __typename?: 'FlexDevice';
          product: {
            __typename?: 'Product';
            name: string;
            imageUrl: string;
            category: {
              __typename?: 'Category';
              images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
            };
          };
        }>;
      }
    | {
        __typename?: 'SubscriptionDetails';
        id: string;
        note?:
          | { __typename?: 'AttentionNote' }
          | { __typename?: 'EndedNote' }
          | { __typename?: 'NextPaymentNote'; date?: any | null }
          | { __typename?: 'PausedNote' }
          | { __typename?: 'ProcessingNote' }
          | null;
        product?: {
          __typename?: 'Product';
          name: string;
          imageUrl: string;
          category: {
            __typename?: 'Category';
            images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
          };
        } | null;
        price: { __typename?: 'Price'; inCents: number; currency: string };
      }
  >;
};

type PaymentMethodFragment_CardPaymentMethod_Fragment = {
  __typename?: 'CardPaymentMethod';
  id: string;
  billingAccountId?: string | null;
  status: PaymentMethodStatus;
  brand?: string | null;
  lastFourDigits?: string | null;
  holderName?: string | null;
  expiryYear?: string | null;
  expiryMonth?: string | null;
  type?: string | null;
  linkedContracts: Array<
    | {
        __typename?: 'Flex';
        id: string;
        billingTerms: {
          __typename?: 'BillingTerms';
          nextPeriod?: {
            __typename?: 'BillingPeriod';
            starts: any;
            amountDue: { __typename?: 'Price'; inCents: number; currency: string };
          } | null;
        };
        goods: Array<{
          __typename?: 'FlexDevice';
          product: {
            __typename?: 'Product';
            name: string;
            imageUrl: string;
            category: {
              __typename?: 'Category';
              images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
            };
          };
        }>;
      }
    | {
        __typename?: 'SubscriptionDetails';
        id: string;
        note?:
          | { __typename?: 'AttentionNote' }
          | { __typename?: 'EndedNote' }
          | { __typename?: 'NextPaymentNote'; date?: any | null }
          | { __typename?: 'PausedNote' }
          | { __typename?: 'ProcessingNote' }
          | null;
        product?: {
          __typename?: 'Product';
          name: string;
          imageUrl: string;
          category: {
            __typename?: 'Category';
            images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
          };
        } | null;
        price: { __typename?: 'Price'; inCents: number; currency: string };
      }
  >;
};

type PaymentMethodFragment_PaypalPaymentMethod_Fragment = {
  __typename?: 'PaypalPaymentMethod';
  id: string;
  billingAccountId?: string | null;
  status: PaymentMethodStatus;
  accountName?: string | null;
  linkedContracts: Array<
    | {
        __typename?: 'Flex';
        id: string;
        billingTerms: {
          __typename?: 'BillingTerms';
          nextPeriod?: {
            __typename?: 'BillingPeriod';
            starts: any;
            amountDue: { __typename?: 'Price'; inCents: number; currency: string };
          } | null;
        };
        goods: Array<{
          __typename?: 'FlexDevice';
          product: {
            __typename?: 'Product';
            name: string;
            imageUrl: string;
            category: {
              __typename?: 'Category';
              images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
            };
          };
        }>;
      }
    | {
        __typename?: 'SubscriptionDetails';
        id: string;
        note?:
          | { __typename?: 'AttentionNote' }
          | { __typename?: 'EndedNote' }
          | { __typename?: 'NextPaymentNote'; date?: any | null }
          | { __typename?: 'PausedNote' }
          | { __typename?: 'ProcessingNote' }
          | null;
        product?: {
          __typename?: 'Product';
          name: string;
          imageUrl: string;
          category: {
            __typename?: 'Category';
            images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
          };
        } | null;
        price: { __typename?: 'Price'; inCents: number; currency: string };
      }
  >;
};

type PaymentMethodFragment_SepaPaymentMethod_Fragment = {
  __typename?: 'SepaPaymentMethod';
  id: string;
  billingAccountId?: string | null;
  status: PaymentMethodStatus;
  createdAt?: any | null;
  linkedContracts: Array<
    | {
        __typename?: 'Flex';
        id: string;
        billingTerms: {
          __typename?: 'BillingTerms';
          nextPeriod?: {
            __typename?: 'BillingPeriod';
            starts: any;
            amountDue: { __typename?: 'Price'; inCents: number; currency: string };
          } | null;
        };
        goods: Array<{
          __typename?: 'FlexDevice';
          product: {
            __typename?: 'Product';
            name: string;
            imageUrl: string;
            category: {
              __typename?: 'Category';
              images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
            };
          };
        }>;
      }
    | {
        __typename?: 'SubscriptionDetails';
        id: string;
        note?:
          | { __typename?: 'AttentionNote' }
          | { __typename?: 'EndedNote' }
          | { __typename?: 'NextPaymentNote'; date?: any | null }
          | { __typename?: 'PausedNote' }
          | { __typename?: 'ProcessingNote' }
          | null;
        product?: {
          __typename?: 'Product';
          name: string;
          imageUrl: string;
          category: {
            __typename?: 'Category';
            images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
          };
        } | null;
        price: { __typename?: 'Price'; inCents: number; currency: string };
      }
  >;
};

export type PaymentMethodFragmentFragment =
  | PaymentMethodFragment_ByInvoicePaymentMethod_Fragment
  | PaymentMethodFragment_CardPaymentMethod_Fragment
  | PaymentMethodFragment_PaypalPaymentMethod_Fragment
  | PaymentMethodFragment_SepaPaymentMethod_Fragment;

export type PaymentMethodsWithContractsQueryVariables = Exact<{
  filter?: InputMaybe<PaymentMethodsFilterType>;
}>;

export type PaymentMethodsWithContractsQuery = {
  __typename?: 'Query';
  paymentMethods: Array<
    | {
        __typename?: 'ByInvoicePaymentMethod';
        id: string;
        billingAccountId?: string | null;
        status: PaymentMethodStatus;
        viban?: string | null;
        linkedContracts: Array<
          | {
              __typename?: 'Flex';
              id: string;
              billingTerms: {
                __typename?: 'BillingTerms';
                nextPeriod?: {
                  __typename?: 'BillingPeriod';
                  starts: any;
                  amountDue: { __typename?: 'Price'; inCents: number; currency: string };
                } | null;
              };
              goods: Array<{
                __typename?: 'FlexDevice';
                product: {
                  __typename?: 'Product';
                  name: string;
                  imageUrl: string;
                  category: {
                    __typename?: 'Category';
                    images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                  };
                };
              }>;
            }
          | {
              __typename?: 'SubscriptionDetails';
              id: string;
              note?:
                | { __typename?: 'AttentionNote' }
                | { __typename?: 'EndedNote' }
                | { __typename?: 'NextPaymentNote'; date?: any | null }
                | { __typename?: 'PausedNote' }
                | { __typename?: 'ProcessingNote' }
                | null;
              product?: {
                __typename?: 'Product';
                name: string;
                imageUrl: string;
                category: {
                  __typename?: 'Category';
                  images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                };
              } | null;
              price: { __typename?: 'Price'; inCents: number; currency: string };
            }
        >;
      }
    | {
        __typename?: 'CardPaymentMethod';
        id: string;
        billingAccountId?: string | null;
        status: PaymentMethodStatus;
        brand?: string | null;
        lastFourDigits?: string | null;
        holderName?: string | null;
        expiryYear?: string | null;
        expiryMonth?: string | null;
        type?: string | null;
        linkedContracts: Array<
          | {
              __typename?: 'Flex';
              id: string;
              billingTerms: {
                __typename?: 'BillingTerms';
                nextPeriod?: {
                  __typename?: 'BillingPeriod';
                  starts: any;
                  amountDue: { __typename?: 'Price'; inCents: number; currency: string };
                } | null;
              };
              goods: Array<{
                __typename?: 'FlexDevice';
                product: {
                  __typename?: 'Product';
                  name: string;
                  imageUrl: string;
                  category: {
                    __typename?: 'Category';
                    images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                  };
                };
              }>;
            }
          | {
              __typename?: 'SubscriptionDetails';
              id: string;
              note?:
                | { __typename?: 'AttentionNote' }
                | { __typename?: 'EndedNote' }
                | { __typename?: 'NextPaymentNote'; date?: any | null }
                | { __typename?: 'PausedNote' }
                | { __typename?: 'ProcessingNote' }
                | null;
              product?: {
                __typename?: 'Product';
                name: string;
                imageUrl: string;
                category: {
                  __typename?: 'Category';
                  images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                };
              } | null;
              price: { __typename?: 'Price'; inCents: number; currency: string };
            }
        >;
      }
    | {
        __typename?: 'PaypalPaymentMethod';
        id: string;
        billingAccountId?: string | null;
        status: PaymentMethodStatus;
        accountName?: string | null;
        linkedContracts: Array<
          | {
              __typename?: 'Flex';
              id: string;
              billingTerms: {
                __typename?: 'BillingTerms';
                nextPeriod?: {
                  __typename?: 'BillingPeriod';
                  starts: any;
                  amountDue: { __typename?: 'Price'; inCents: number; currency: string };
                } | null;
              };
              goods: Array<{
                __typename?: 'FlexDevice';
                product: {
                  __typename?: 'Product';
                  name: string;
                  imageUrl: string;
                  category: {
                    __typename?: 'Category';
                    images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                  };
                };
              }>;
            }
          | {
              __typename?: 'SubscriptionDetails';
              id: string;
              note?:
                | { __typename?: 'AttentionNote' }
                | { __typename?: 'EndedNote' }
                | { __typename?: 'NextPaymentNote'; date?: any | null }
                | { __typename?: 'PausedNote' }
                | { __typename?: 'ProcessingNote' }
                | null;
              product?: {
                __typename?: 'Product';
                name: string;
                imageUrl: string;
                category: {
                  __typename?: 'Category';
                  images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                };
              } | null;
              price: { __typename?: 'Price'; inCents: number; currency: string };
            }
        >;
      }
    | {
        __typename?: 'SepaPaymentMethod';
        id: string;
        billingAccountId?: string | null;
        status: PaymentMethodStatus;
        createdAt?: any | null;
        linkedContracts: Array<
          | {
              __typename?: 'Flex';
              id: string;
              billingTerms: {
                __typename?: 'BillingTerms';
                nextPeriod?: {
                  __typename?: 'BillingPeriod';
                  starts: any;
                  amountDue: { __typename?: 'Price'; inCents: number; currency: string };
                } | null;
              };
              goods: Array<{
                __typename?: 'FlexDevice';
                product: {
                  __typename?: 'Product';
                  name: string;
                  imageUrl: string;
                  category: {
                    __typename?: 'Category';
                    images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                  };
                };
              }>;
            }
          | {
              __typename?: 'SubscriptionDetails';
              id: string;
              note?:
                | { __typename?: 'AttentionNote' }
                | { __typename?: 'EndedNote' }
                | { __typename?: 'NextPaymentNote'; date?: any | null }
                | { __typename?: 'PausedNote' }
                | { __typename?: 'ProcessingNote' }
                | null;
              product?: {
                __typename?: 'Product';
                name: string;
                imageUrl: string;
                category: {
                  __typename?: 'Category';
                  images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                };
              } | null;
              price: { __typename?: 'Price'; inCents: number; currency: string };
            }
        >;
      }
  >;
};

export type PaymentMethodWithContractsQueryVariables = Exact<{
  paymentMethodId: Scalars['ID'];
}>;

export type PaymentMethodWithContractsQuery = {
  __typename?: 'Query';
  paymentMethod?:
    | {
        __typename?: 'ByInvoicePaymentMethod';
        id: string;
        billingAccountId?: string | null;
        status: PaymentMethodStatus;
        viban?: string | null;
        deletionLock?: {
          __typename?: 'PaymentMethodDeletionLock';
          reason: PaymentMethodDeletionLockReason;
        } | null;
        linkedContracts: Array<
          | {
              __typename?: 'Flex';
              id: string;
              billingTerms: {
                __typename?: 'BillingTerms';
                nextPeriod?: {
                  __typename?: 'BillingPeriod';
                  starts: any;
                  amountDue: { __typename?: 'Price'; inCents: number; currency: string };
                } | null;
              };
              goods: Array<{
                __typename?: 'FlexDevice';
                product: {
                  __typename?: 'Product';
                  name: string;
                  imageUrl: string;
                  category: {
                    __typename?: 'Category';
                    images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                  };
                };
              }>;
            }
          | {
              __typename?: 'SubscriptionDetails';
              id: string;
              note?:
                | { __typename?: 'AttentionNote' }
                | { __typename?: 'EndedNote' }
                | { __typename?: 'NextPaymentNote'; date?: any | null }
                | { __typename?: 'PausedNote' }
                | { __typename?: 'ProcessingNote' }
                | null;
              product?: {
                __typename?: 'Product';
                name: string;
                imageUrl: string;
                category: {
                  __typename?: 'Category';
                  images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                };
              } | null;
              price: { __typename?: 'Price'; inCents: number; currency: string };
            }
        >;
      }
    | {
        __typename?: 'CardPaymentMethod';
        id: string;
        billingAccountId?: string | null;
        status: PaymentMethodStatus;
        brand?: string | null;
        lastFourDigits?: string | null;
        holderName?: string | null;
        expiryYear?: string | null;
        expiryMonth?: string | null;
        type?: string | null;
        deletionLock?: {
          __typename?: 'PaymentMethodDeletionLock';
          reason: PaymentMethodDeletionLockReason;
        } | null;
        linkedContracts: Array<
          | {
              __typename?: 'Flex';
              id: string;
              billingTerms: {
                __typename?: 'BillingTerms';
                nextPeriod?: {
                  __typename?: 'BillingPeriod';
                  starts: any;
                  amountDue: { __typename?: 'Price'; inCents: number; currency: string };
                } | null;
              };
              goods: Array<{
                __typename?: 'FlexDevice';
                product: {
                  __typename?: 'Product';
                  name: string;
                  imageUrl: string;
                  category: {
                    __typename?: 'Category';
                    images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                  };
                };
              }>;
            }
          | {
              __typename?: 'SubscriptionDetails';
              id: string;
              note?:
                | { __typename?: 'AttentionNote' }
                | { __typename?: 'EndedNote' }
                | { __typename?: 'NextPaymentNote'; date?: any | null }
                | { __typename?: 'PausedNote' }
                | { __typename?: 'ProcessingNote' }
                | null;
              product?: {
                __typename?: 'Product';
                name: string;
                imageUrl: string;
                category: {
                  __typename?: 'Category';
                  images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                };
              } | null;
              price: { __typename?: 'Price'; inCents: number; currency: string };
            }
        >;
      }
    | {
        __typename?: 'PaypalPaymentMethod';
        id: string;
        billingAccountId?: string | null;
        status: PaymentMethodStatus;
        accountName?: string | null;
        deletionLock?: {
          __typename?: 'PaymentMethodDeletionLock';
          reason: PaymentMethodDeletionLockReason;
        } | null;
        linkedContracts: Array<
          | {
              __typename?: 'Flex';
              id: string;
              billingTerms: {
                __typename?: 'BillingTerms';
                nextPeriod?: {
                  __typename?: 'BillingPeriod';
                  starts: any;
                  amountDue: { __typename?: 'Price'; inCents: number; currency: string };
                } | null;
              };
              goods: Array<{
                __typename?: 'FlexDevice';
                product: {
                  __typename?: 'Product';
                  name: string;
                  imageUrl: string;
                  category: {
                    __typename?: 'Category';
                    images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                  };
                };
              }>;
            }
          | {
              __typename?: 'SubscriptionDetails';
              id: string;
              note?:
                | { __typename?: 'AttentionNote' }
                | { __typename?: 'EndedNote' }
                | { __typename?: 'NextPaymentNote'; date?: any | null }
                | { __typename?: 'PausedNote' }
                | { __typename?: 'ProcessingNote' }
                | null;
              product?: {
                __typename?: 'Product';
                name: string;
                imageUrl: string;
                category: {
                  __typename?: 'Category';
                  images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                };
              } | null;
              price: { __typename?: 'Price'; inCents: number; currency: string };
            }
        >;
      }
    | {
        __typename?: 'SepaPaymentMethod';
        id: string;
        billingAccountId?: string | null;
        status: PaymentMethodStatus;
        createdAt?: any | null;
        deletionLock?: {
          __typename?: 'PaymentMethodDeletionLock';
          reason: PaymentMethodDeletionLockReason;
        } | null;
        linkedContracts: Array<
          | {
              __typename?: 'Flex';
              id: string;
              billingTerms: {
                __typename?: 'BillingTerms';
                nextPeriod?: {
                  __typename?: 'BillingPeriod';
                  starts: any;
                  amountDue: { __typename?: 'Price'; inCents: number; currency: string };
                } | null;
              };
              goods: Array<{
                __typename?: 'FlexDevice';
                product: {
                  __typename?: 'Product';
                  name: string;
                  imageUrl: string;
                  category: {
                    __typename?: 'Category';
                    images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                  };
                };
              }>;
            }
          | {
              __typename?: 'SubscriptionDetails';
              id: string;
              note?:
                | { __typename?: 'AttentionNote' }
                | { __typename?: 'EndedNote' }
                | { __typename?: 'NextPaymentNote'; date?: any | null }
                | { __typename?: 'PausedNote' }
                | { __typename?: 'ProcessingNote' }
                | null;
              product?: {
                __typename?: 'Product';
                name: string;
                imageUrl: string;
                category: {
                  __typename?: 'Category';
                  images: { __typename?: 'CategoryImages'; iconSvgPath?: string | null };
                };
              } | null;
              price: { __typename?: 'Price'; inCents: number; currency: string };
            }
        >;
      }
    | null;
};

export type PaymentMethodStatusQueryVariables = Exact<{
  paymentMethodId: Scalars['ID'];
}>;

export type PaymentMethodStatusQuery = {
  __typename?: 'Query';
  paymentMethod?:
    | {
        __typename?: 'ByInvoicePaymentMethod';
        status: PaymentMethodStatus;
        registrationErrorCode?: TransactionErrorCode | null;
      }
    | {
        __typename?: 'CardPaymentMethod';
        status: PaymentMethodStatus;
        registrationErrorCode?: TransactionErrorCode | null;
      }
    | {
        __typename?: 'PaypalPaymentMethod';
        status: PaymentMethodStatus;
        registrationErrorCode?: TransactionErrorCode | null;
      }
    | {
        __typename?: 'SepaPaymentMethod';
        status: PaymentMethodStatus;
        registrationErrorCode?: TransactionErrorCode | null;
      }
    | null;
};

export type PaymentMethodOperationStatusQueryVariables = Exact<{
  paymentMethodOperationId: Scalars['ID'];
}>;

export type PaymentMethodOperationStatusQuery = {
  __typename?: 'Query';
  paymentMethodOperation?:
    | {
        __typename?: 'DeletePaymentMethodOperation';
        id: string;
        status: PaymentMethodOperationStatus;
      }
    | {
        __typename?: 'ReplacePaymentMethodOperation';
        id: string;
        status: PaymentMethodOperationStatus;
      }
    | { __typename?: 'SetPaymentMethodOperation'; id: string; status: PaymentMethodOperationStatus }
    | null;
};

export type PaymentOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentOptionsQuery = {
  __typename?: 'Query';
  paymentOptions: Array<{
    __typename?: 'PaymentOption';
    id: string;
    type: PaymentOptionType;
    ixopay: { __typename?: 'PaymentOptionIxopay'; publicKey?: string | null };
  }>;
};

export type PaymentsFragmentFragment = {
  __typename?: 'PaymentsUnionConnection';
  totalCount: number;
  pageInfo: {
    __typename?: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
  };
  nodes?: Array<
    | {
        __typename?: 'GroupPayment';
        id: string;
        state: PaymentState;
        invoiceUrl?: string | null;
        paidDate?: any | null;
        contracts?: { __typename?: 'ContractConnection'; totalCount: number } | null;
        dueDate:
          | { __typename?: 'Date'; iso8601: any }
          | {
              __typename?: 'TimeEstimate';
              atLeast: { __typename?: 'Duration'; unit: TimeUnit; value: number };
              atMost?: { __typename?: 'Duration'; unit: TimeUnit; value: number } | null;
            };
        price: { __typename?: 'Price'; currency: string; inCents: number };
        basePrice: { __typename?: 'Price'; currency: string; inCents: number };
        refundedAmount: { __typename?: 'Price'; currency: string; inCents: number };
      }
    | {
        __typename?: 'OrderPayment';
        id: string;
        state: PaymentState;
        byInvoice: boolean;
        invoiceNumber?: string | null;
        invoiceUrl?: string | null;
        paidDate?: any | null;
        price: { __typename?: 'Price'; currency: string; inCents: number };
        basePrice: { __typename?: 'Price'; currency: string; inCents: number };
        refundedAmount: { __typename?: 'Price'; currency: string; inCents: number };
        dueDate:
          | { __typename?: 'Date'; iso8601: any }
          | {
              __typename?: 'TimeEstimate';
              atLeast: { __typename?: 'Duration'; unit: TimeUnit; value: number };
              atMost?: { __typename?: 'Duration'; unit: TimeUnit; value: number } | null;
            };
        order:
          | {
              __typename?: 'MixCartOrder';
              orderMode: OrderMode;
              orderNumber: string;
              storeCode?: string | null;
              itemTotal: { __typename?: 'Price'; currency: string; inCents: number };
              itemTotalDiscount?: {
                __typename?: 'Price';
                currency: string;
                inCents: number;
              } | null;
              mixLineItems: Array<{
                __typename?: 'MixLineItem';
                quantity?: number | null;
                meta:
                  | {
                      __typename?: 'FlexCartLineItemMeta';
                      price: { __typename?: 'Price'; currency: string; inCents: number };
                      product?: { __typename?: 'Product'; name: string } | null;
                    }
                  | {
                      __typename?: 'LineItemCatalogMeta';
                      product?: { __typename?: 'Product'; name: string } | null;
                    }
                  | {
                      __typename?: 'ReplacedLineItemMeta';
                      product?: { __typename?: 'Product'; name: string } | null;
                    };
              } | null>;
              shippingCost?: { __typename?: 'Price'; currency: string; inCents: number } | null;
              total: { __typename?: 'Price'; currency: string; inCents: number };
            }
          | {
              __typename?: 'Order';
              number: string;
              completedAt?: any | null;
              discountAmount: { __typename?: 'Price'; currency: string; inCents: number };
              shippingPrice: { __typename?: 'Price'; currency: string; inCents: number };
              price: { __typename?: 'Price'; currency: string; inCents: number };
              lineItems: Array<{
                __typename?: 'LineItem';
                name: string;
                quantity: number;
                price: { __typename?: 'Price'; inCents: number; currency: string };
              }>;
            };
      }
    | {
        __typename?: 'PurchasePayment';
        id: string;
        state: PaymentState;
        datePaid?: any | null;
        invoiceUrl?: string | null;
        paidDate?: any | null;
        dueDate:
          | { __typename?: 'Date'; iso8601: any }
          | {
              __typename?: 'TimeEstimate';
              atLeast: { __typename?: 'Duration'; unit: TimeUnit; value: number };
              atMost?: { __typename?: 'Duration'; unit: TimeUnit; value: number } | null;
            };
        price: { __typename?: 'Price'; currency: string; inCents: number };
        basePrice: { __typename?: 'Price'; currency: string; inCents: number };
        refundedAmount: { __typename?: 'Price'; currency: string; inCents: number };
        contract?:
          | {
              __typename?: 'Flex';
              id: string;
              name: string;
              goods: Array<{
                __typename?: 'FlexDevice';
                id: string;
                product: {
                  __typename?: 'Product';
                  id: string;
                  category: { __typename?: 'Category'; iconSvgPath?: string | null };
                };
              }>;
            }
          | { __typename?: 'Mix'; id: string }
          | null;
        subscriptionDetails?: {
          __typename?: 'SubscriptionDetails';
          id: string;
          name?: string | null;
          product?: {
            __typename?: 'Product';
            id: string;
            category: { __typename?: 'Category'; iconSvgPath?: string | null };
          } | null;
        } | null;
      }
    | {
        __typename?: 'SubscriptionPayment';
        id: string;
        state: PaymentState;
        invoiceUrl?: string | null;
        paidDate?: any | null;
        price: { __typename?: 'Price'; currency: string; inCents: number };
        basePrice: { __typename?: 'Price'; currency: string; inCents: number };
        refundedAmount: { __typename?: 'Price'; currency: string; inCents: number };
        dueDate:
          | { __typename?: 'Date'; iso8601: any }
          | {
              __typename?: 'TimeEstimate';
              atLeast: { __typename?: 'Duration'; unit: TimeUnit; value: number };
              atMost?: { __typename?: 'Duration'; unit: TimeUnit; value: number } | null;
            };
        contract?:
          | {
              __typename?: 'Flex';
              id: string;
              name: string;
              paymentMethod?:
                | { __typename?: 'ByInvoice'; id: string }
                | { __typename?: 'Card'; id: string }
                | { __typename?: 'Paypal'; id: string }
                | { __typename?: 'Sepa'; id: string }
                | null;
              goods: Array<{
                __typename?: 'FlexDevice';
                id: string;
                product: {
                  __typename?: 'Product';
                  id: string;
                  category: { __typename?: 'Category'; iconSvgPath?: string | null };
                };
              }>;
            }
          | {
              __typename?: 'Mix';
              id: string;
              paymentMethod?:
                | { __typename?: 'ByInvoice'; id: string }
                | { __typename?: 'Card'; id: string }
                | { __typename?: 'Paypal'; id: string }
                | { __typename?: 'Sepa'; id: string }
                | null;
            }
          | null;
        subscriptionDetails?: {
          __typename?: 'SubscriptionDetails';
          id: string;
          name?: string | null;
          product?: {
            __typename?: 'Product';
            id: string;
            category: { __typename?: 'Category'; iconSvgPath?: string | null };
          } | null;
        } | null;
      }
    | null
  > | null;
};

export type PaymentsQueryVariables = Exact<{
  firstPayments: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
  where?: InputMaybe<PaymentsWhereInput>;
}>;

export type PaymentsQuery = {
  __typename?: 'Query';
  payments?: {
    __typename?: 'PaymentsUnionConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
    nodes?: Array<
      | {
          __typename?: 'GroupPayment';
          id: string;
          state: PaymentState;
          invoiceUrl?: string | null;
          paidDate?: any | null;
          contracts?: { __typename?: 'ContractConnection'; totalCount: number } | null;
          dueDate:
            | { __typename?: 'Date'; iso8601: any }
            | {
                __typename?: 'TimeEstimate';
                atLeast: { __typename?: 'Duration'; unit: TimeUnit; value: number };
                atMost?: { __typename?: 'Duration'; unit: TimeUnit; value: number } | null;
              };
          price: { __typename?: 'Price'; currency: string; inCents: number };
          basePrice: { __typename?: 'Price'; currency: string; inCents: number };
          refundedAmount: { __typename?: 'Price'; currency: string; inCents: number };
        }
      | {
          __typename?: 'OrderPayment';
          id: string;
          state: PaymentState;
          byInvoice: boolean;
          invoiceNumber?: string | null;
          invoiceUrl?: string | null;
          paidDate?: any | null;
          price: { __typename?: 'Price'; currency: string; inCents: number };
          basePrice: { __typename?: 'Price'; currency: string; inCents: number };
          refundedAmount: { __typename?: 'Price'; currency: string; inCents: number };
          dueDate:
            | { __typename?: 'Date'; iso8601: any }
            | {
                __typename?: 'TimeEstimate';
                atLeast: { __typename?: 'Duration'; unit: TimeUnit; value: number };
                atMost?: { __typename?: 'Duration'; unit: TimeUnit; value: number } | null;
              };
          order:
            | {
                __typename?: 'MixCartOrder';
                orderMode: OrderMode;
                orderNumber: string;
                storeCode?: string | null;
                itemTotal: { __typename?: 'Price'; currency: string; inCents: number };
                itemTotalDiscount?: {
                  __typename?: 'Price';
                  currency: string;
                  inCents: number;
                } | null;
                mixLineItems: Array<{
                  __typename?: 'MixLineItem';
                  quantity?: number | null;
                  meta:
                    | {
                        __typename?: 'FlexCartLineItemMeta';
                        price: { __typename?: 'Price'; currency: string; inCents: number };
                        product?: { __typename?: 'Product'; name: string } | null;
                      }
                    | {
                        __typename?: 'LineItemCatalogMeta';
                        product?: { __typename?: 'Product'; name: string } | null;
                      }
                    | {
                        __typename?: 'ReplacedLineItemMeta';
                        product?: { __typename?: 'Product'; name: string } | null;
                      };
                } | null>;
                shippingCost?: { __typename?: 'Price'; currency: string; inCents: number } | null;
                total: { __typename?: 'Price'; currency: string; inCents: number };
              }
            | {
                __typename?: 'Order';
                number: string;
                completedAt?: any | null;
                discountAmount: { __typename?: 'Price'; currency: string; inCents: number };
                shippingPrice: { __typename?: 'Price'; currency: string; inCents: number };
                price: { __typename?: 'Price'; currency: string; inCents: number };
                lineItems: Array<{
                  __typename?: 'LineItem';
                  name: string;
                  quantity: number;
                  price: { __typename?: 'Price'; inCents: number; currency: string };
                }>;
              };
        }
      | {
          __typename?: 'PurchasePayment';
          id: string;
          state: PaymentState;
          datePaid?: any | null;
          invoiceUrl?: string | null;
          paidDate?: any | null;
          dueDate:
            | { __typename?: 'Date'; iso8601: any }
            | {
                __typename?: 'TimeEstimate';
                atLeast: { __typename?: 'Duration'; unit: TimeUnit; value: number };
                atMost?: { __typename?: 'Duration'; unit: TimeUnit; value: number } | null;
              };
          price: { __typename?: 'Price'; currency: string; inCents: number };
          basePrice: { __typename?: 'Price'; currency: string; inCents: number };
          refundedAmount: { __typename?: 'Price'; currency: string; inCents: number };
          contract?:
            | {
                __typename?: 'Flex';
                id: string;
                name: string;
                goods: Array<{
                  __typename?: 'FlexDevice';
                  id: string;
                  product: {
                    __typename?: 'Product';
                    id: string;
                    category: { __typename?: 'Category'; iconSvgPath?: string | null };
                  };
                }>;
              }
            | { __typename?: 'Mix'; id: string }
            | null;
          subscriptionDetails?: {
            __typename?: 'SubscriptionDetails';
            id: string;
            name?: string | null;
            product?: {
              __typename?: 'Product';
              id: string;
              category: { __typename?: 'Category'; iconSvgPath?: string | null };
            } | null;
          } | null;
        }
      | {
          __typename?: 'SubscriptionPayment';
          id: string;
          state: PaymentState;
          invoiceUrl?: string | null;
          paidDate?: any | null;
          price: { __typename?: 'Price'; currency: string; inCents: number };
          basePrice: { __typename?: 'Price'; currency: string; inCents: number };
          refundedAmount: { __typename?: 'Price'; currency: string; inCents: number };
          dueDate:
            | { __typename?: 'Date'; iso8601: any }
            | {
                __typename?: 'TimeEstimate';
                atLeast: { __typename?: 'Duration'; unit: TimeUnit; value: number };
                atMost?: { __typename?: 'Duration'; unit: TimeUnit; value: number } | null;
              };
          contract?:
            | {
                __typename?: 'Flex';
                id: string;
                name: string;
                paymentMethod?:
                  | { __typename?: 'ByInvoice'; id: string }
                  | { __typename?: 'Card'; id: string }
                  | { __typename?: 'Paypal'; id: string }
                  | { __typename?: 'Sepa'; id: string }
                  | null;
                goods: Array<{
                  __typename?: 'FlexDevice';
                  id: string;
                  product: {
                    __typename?: 'Product';
                    id: string;
                    category: { __typename?: 'Category'; iconSvgPath?: string | null };
                  };
                }>;
              }
            | {
                __typename?: 'Mix';
                id: string;
                paymentMethod?:
                  | { __typename?: 'ByInvoice'; id: string }
                  | { __typename?: 'Card'; id: string }
                  | { __typename?: 'Paypal'; id: string }
                  | { __typename?: 'Sepa'; id: string }
                  | null;
              }
            | null;
          subscriptionDetails?: {
            __typename?: 'SubscriptionDetails';
            id: string;
            name?: string | null;
            product?: {
              __typename?: 'Product';
              id: string;
              category: { __typename?: 'Category'; iconSvgPath?: string | null };
            } | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type PaymentsDetailsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type PaymentsDetailsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    activeSubscriptionValue: { __typename?: 'Price'; currency: string; inCents: number };
    monthlyTotal: { __typename?: 'Price'; currency: string; inCents: number };
  } | null;
};

export type SubscriptionPaymentsDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscriptionPaymentsDetailsQuery = {
  __typename?: 'Query';
  subscriptionDetails?:
    | {
        __typename?: 'Flex';
        id: string;
        contractName: string;
        contractState: ContractState;
        note?:
          | { __typename?: 'AttentionNote'; text?: string | null }
          | { __typename?: 'EndedNote'; text?: string | null }
          | {
              __typename?: 'NextPaymentNote';
              date?: any | null;
              paymentMethod?:
                | { __typename: 'ByInvoice' }
                | { __typename: 'Card' }
                | { __typename: 'Paypal' }
                | { __typename: 'Sepa' }
                | null;
            }
          | { __typename?: 'PausedNote'; text?: string | null }
          | { __typename?: 'ProcessingNote'; text?: string | null }
          | null;
        goods: Array<{
          __typename?: 'FlexDevice';
          id: string;
          variant: {
            __typename?: 'Variant';
            id: string;
            colorCodes?: Array<string> | null;
            colorTitle?: string | null;
            imageUrl: string;
          };
        }>;
        billingTerms: {
          __typename?: 'BillingTerms';
          price: { __typename?: 'Price'; currency: string; inCents: number };
        };
      }
    | {
        __typename?: 'SubscriptionDetails';
        id: string;
        name?: string | null;
        state: SubscriptionState;
        note?:
          | { __typename?: 'AttentionNote'; text?: string | null }
          | { __typename?: 'EndedNote'; text?: string | null }
          | {
              __typename?: 'NextPaymentNote';
              date?: any | null;
              paymentMethod?:
                | { __typename: 'ByInvoice' }
                | { __typename: 'Card' }
                | { __typename: 'Paypal' }
                | { __typename: 'Sepa' }
                | null;
            }
          | { __typename?: 'PausedNote'; text?: string | null }
          | { __typename?: 'ProcessingNote'; text?: string | null }
          | null;
        variant?: {
          __typename?: 'Variant';
          id: string;
          colorCodes?: Array<string> | null;
          colorTitle?: string | null;
          imageUrl: string;
        } | null;
        price: { __typename?: 'Price'; currency: string; inCents: number };
      }
    | null;
};

export type SubscriptionCollectionQueryVariables = Exact<{
  filter: SubscriptionListFilterInput;
  first?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;

export type SubscriptionCollectionQuery = {
  __typename?: 'Query';
  subscriptionCollection?: {
    __typename?: 'SubscriptionListEntryConnection';
    totalCount: number;
    nodes?: Array<
      | {
          __typename?: 'SubscriptionCard';
          id: string;
          name?: string | null;
          state: SubscriptionState;
          product: { __typename?: 'Product'; createdAt: any };
        }
      | { __typename?: 'SubscriptionStack' }
      | null
    > | null;
  } | null;
};

export type YourProfileQueryQueryVariables = Exact<{ [key: string]: never }>;

export type YourProfileQueryQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    verificationState: UserVerificationState;
    type: UserType;
    defaultStore: {
      __typename?: 'Store';
      code: string;
      defaultCurrency: string;
      defaultLocale: string;
      name: string;
    };
    company?: { __typename?: 'Company'; groverWorkEnabled?: boolean | null } | null;
  } | null;
};

export const LinkedContractsFragmentFragmentDoc = gql`
  fragment LinkedContractsFragment on FlexDetails {
    ... on Flex {
      id
      billingTerms {
        nextPeriod {
          amountDue {
            inCents
            currency
          }
          starts
        }
      }
      goods {
        ... on FlexDevice {
          product {
            name
            imageUrl
            category {
              images {
                iconSvgPath
              }
            }
          }
        }
      }
    }
    ... on SubscriptionDetails {
      id
      note {
        ... on NextPaymentNote {
          date
        }
      }
      product {
        name
        imageUrl
        category {
          images {
            iconSvgPath
          }
        }
      }
      price {
        inCents
        currency
      }
    }
  }
`;
export const PaymentMethodFragmentFragmentDoc = gql`
  fragment PaymentMethodFragment on PaymentMethod2 {
    status
    ... on CardPaymentMethod {
      id
      billingAccountId
      status
      brand
      lastFourDigits
      holderName
      expiryYear
      expiryMonth
      type
      linkedContracts {
        ...LinkedContractsFragment
      }
    }
    ... on PaypalPaymentMethod {
      id
      billingAccountId
      status
      accountName
      linkedContracts {
        ...LinkedContractsFragment
      }
    }
    ... on SepaPaymentMethod {
      id
      billingAccountId
      status
      createdAt
      linkedContracts {
        ...LinkedContractsFragment
      }
    }
    ... on ByInvoicePaymentMethod {
      id
      billingAccountId
      status
      viban
      linkedContracts {
        ...LinkedContractsFragment
      }
    }
  }
  ${LinkedContractsFragmentFragmentDoc}
`;
export const PaymentsFragmentFragmentDoc = gql`
  fragment PaymentsFragment on PaymentsUnionConnection {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      ... on OrderPayment {
        id
        state
        byInvoice
        invoiceNumber
        invoiceUrl
        price {
          currency
          inCents
        }
        basePrice {
          currency
          inCents
        }
        refundedAmount {
          currency
          inCents
        }
        dueDate {
          ... on Date {
            iso8601
          }
          ... on TimeEstimate {
            atLeast {
              unit
              value
            }
            atMost {
              unit
              value
            }
          }
        }
        paidDate
        order {
          ... on Order {
            number
            completedAt
            discountAmount {
              currency
              inCents
            }
            shippingPrice {
              currency
              inCents
            }
            price {
              currency
              inCents
            }
            lineItems {
              name
              price {
                inCents
                currency
              }
              quantity
            }
          }
          ... on MixCartOrder {
            itemTotal {
              currency
              inCents
            }
            itemTotalDiscount {
              currency
              inCents
            }
            mixLineItems: lineItems {
              quantity
              meta {
                product {
                  name
                }
                ... on FlexCartLineItemMeta {
                  price {
                    currency
                    inCents
                  }
                }
              }
            }
            orderMode
            orderNumber
            shippingCost {
              currency
              inCents
            }
            storeCode
            total {
              currency
              inCents
            }
          }
        }
      }
      ... on SubscriptionPayment {
        id
        state
        invoiceUrl
        price {
          currency
          inCents
        }
        basePrice {
          currency
          inCents
        }
        refundedAmount {
          currency
          inCents
        }
        dueDate {
          ... on Date {
            iso8601
          }
          ... on TimeEstimate {
            atLeast {
              unit
              value
            }
            atMost {
              unit
              value
            }
          }
        }
        paidDate
        contract {
          ... on Flex {
            id
            name
            paymentMethod {
              ... on ByInvoice {
                id
              }
              ... on Card {
                id
              }
              ... on Paypal {
                id
              }
              ... on Sepa {
                id
              }
            }
            goods {
              id
              product {
                id
                category {
                  iconSvgPath
                }
              }
            }
          }
          ... on Mix {
            id
            paymentMethod {
              ... on ByInvoice {
                id
              }
              ... on Card {
                id
              }
              ... on Paypal {
                id
              }
              ... on Sepa {
                id
              }
            }
          }
        }
        subscriptionDetails {
          ... on SubscriptionDetails {
            id
            name
            product {
              id
              category {
                iconSvgPath
              }
            }
          }
        }
      }
      ... on GroupPayment {
        id
        state
        invoiceUrl
        contracts {
          totalCount
        }
        dueDate {
          ... on Date {
            iso8601
          }
          ... on TimeEstimate {
            atLeast {
              unit
              value
            }
            atMost {
              unit
              value
            }
          }
        }
        paidDate
        price {
          currency
          inCents
        }
        basePrice {
          currency
          inCents
        }
        refundedAmount {
          currency
          inCents
        }
      }
      ... on PurchasePayment {
        id
        state
        datePaid
        invoiceUrl
        dueDate {
          ... on Date {
            iso8601
          }
          ... on TimeEstimate {
            atLeast {
              unit
              value
            }
            atMost {
              unit
              value
            }
          }
        }
        paidDate
        price {
          currency
          inCents
        }
        basePrice {
          currency
          inCents
        }
        refundedAmount {
          currency
          inCents
        }
        contract {
          ... on Flex {
            id
            name
            goods {
              id
              product {
                id
                category {
                  iconSvgPath
                }
              }
            }
          }
          ... on Mix {
            id
          }
        }
        subscriptionDetails {
          ... on SubscriptionDetails {
            id
            name
            product {
              id
              category {
                iconSvgPath
              }
            }
          }
        }
      }
    }
  }
`;
export const AddPaymentMethodDocument = gql`
  mutation AddPaymentMethod($input: AddPaymentMethodInput!) {
    addPaymentMethod(input: $input) {
      id
      redirectUrl
    }
  }
`;
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<
  AddPaymentMethodMutation,
  AddPaymentMethodMutationVariables
>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(
    AddPaymentMethodDocument,
    options
  );
}
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  AddPaymentMethodMutation,
  AddPaymentMethodMutationVariables
>;
export const SetPaymentMethodDocument = gql`
  mutation SetPaymentMethod($input: SetPaymentMethodInput!) {
    setPaymentMethod(input: $input) {
      operation {
        id
      }
    }
  }
`;
export type SetPaymentMethodMutationFn = Apollo.MutationFunction<
  SetPaymentMethodMutation,
  SetPaymentMethodMutationVariables
>;

/**
 * __useSetPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPaymentMethodMutation, { data, loading, error }] = useSetPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPaymentMethodMutation,
    SetPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetPaymentMethodMutation, SetPaymentMethodMutationVariables>(
    SetPaymentMethodDocument,
    options
  );
}
export type SetPaymentMethodMutationHookResult = ReturnType<typeof useSetPaymentMethodMutation>;
export type SetPaymentMethodMutationResult = Apollo.MutationResult<SetPaymentMethodMutation>;
export type SetPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  SetPaymentMethodMutation,
  SetPaymentMethodMutationVariables
>;
export const ReplacePaymentMethodDocument = gql`
  mutation ReplacePaymentMethod($input: ReplacePaymentMethodInput!) {
    replacePaymentMethod(input: $input) {
      operation {
        id
      }
    }
  }
`;
export type ReplacePaymentMethodMutationFn = Apollo.MutationFunction<
  ReplacePaymentMethodMutation,
  ReplacePaymentMethodMutationVariables
>;

/**
 * __useReplacePaymentMethodMutation__
 *
 * To run a mutation, you first call `useReplacePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplacePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replacePaymentMethodMutation, { data, loading, error }] = useReplacePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplacePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplacePaymentMethodMutation,
    ReplacePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReplacePaymentMethodMutation, ReplacePaymentMethodMutationVariables>(
    ReplacePaymentMethodDocument,
    options
  );
}
export type ReplacePaymentMethodMutationHookResult = ReturnType<
  typeof useReplacePaymentMethodMutation
>;
export type ReplacePaymentMethodMutationResult =
  Apollo.MutationResult<ReplacePaymentMethodMutation>;
export type ReplacePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  ReplacePaymentMethodMutation,
  ReplacePaymentMethodMutationVariables
>;
export const DeletePaymentMethodDocument = gql`
  mutation DeletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id) {
      operation {
        id
      }
    }
  }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(
    DeletePaymentMethodDocument,
    options
  );
}
export type DeletePaymentMethodMutationHookResult = ReturnType<
  typeof useDeletePaymentMethodMutation
>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;
export const PaymentMethodDocument = gql`
  query PaymentMethod($id: ID!) {
    paymentMethod(id: $id) {
      id
      groverApiId
      status
      __typename
    }
  }
`;

/**
 * __usePaymentMethodQuery__
 *
 * To run a query within a React component, call `usePaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentMethodQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentMethodQuery, PaymentMethodQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodQuery, PaymentMethodQueryVariables>(
    PaymentMethodDocument,
    options
  );
}
export function usePaymentMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodQuery, PaymentMethodQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentMethodQuery, PaymentMethodQueryVariables>(
    PaymentMethodDocument,
    options
  );
}
export type PaymentMethodQueryHookResult = ReturnType<typeof usePaymentMethodQuery>;
export type PaymentMethodLazyQueryHookResult = ReturnType<typeof usePaymentMethodLazyQuery>;
export type PaymentMethodQueryResult = Apollo.QueryResult<
  PaymentMethodQuery,
  PaymentMethodQueryVariables
>;
export const PaymentMethodsWithContractsDocument = gql`
  query PaymentMethodsWithContracts($filter: PaymentMethodsFilterType) {
    paymentMethods(filter: $filter) {
      ...PaymentMethodFragment
    }
  }
  ${PaymentMethodFragmentFragmentDoc}
`;

/**
 * __usePaymentMethodsWithContractsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsWithContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsWithContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsWithContractsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaymentMethodsWithContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaymentMethodsWithContractsQuery,
    PaymentMethodsWithContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaymentMethodsWithContractsQuery,
    PaymentMethodsWithContractsQueryVariables
  >(PaymentMethodsWithContractsDocument, options);
}
export function usePaymentMethodsWithContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentMethodsWithContractsQuery,
    PaymentMethodsWithContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaymentMethodsWithContractsQuery,
    PaymentMethodsWithContractsQueryVariables
  >(PaymentMethodsWithContractsDocument, options);
}
export type PaymentMethodsWithContractsQueryHookResult = ReturnType<
  typeof usePaymentMethodsWithContractsQuery
>;
export type PaymentMethodsWithContractsLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodsWithContractsLazyQuery
>;
export type PaymentMethodsWithContractsQueryResult = Apollo.QueryResult<
  PaymentMethodsWithContractsQuery,
  PaymentMethodsWithContractsQueryVariables
>;
export const PaymentMethodWithContractsDocument = gql`
  query PaymentMethodWithContracts($paymentMethodId: ID!) {
    paymentMethod(id: $paymentMethodId) {
      deletionLock {
        reason
      }
      ...PaymentMethodFragment
    }
  }
  ${PaymentMethodFragmentFragmentDoc}
`;

/**
 * __usePaymentMethodWithContractsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodWithContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodWithContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodWithContractsQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function usePaymentMethodWithContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentMethodWithContractsQuery,
    PaymentMethodWithContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodWithContractsQuery, PaymentMethodWithContractsQueryVariables>(
    PaymentMethodWithContractsDocument,
    options
  );
}
export function usePaymentMethodWithContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentMethodWithContractsQuery,
    PaymentMethodWithContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaymentMethodWithContractsQuery,
    PaymentMethodWithContractsQueryVariables
  >(PaymentMethodWithContractsDocument, options);
}
export type PaymentMethodWithContractsQueryHookResult = ReturnType<
  typeof usePaymentMethodWithContractsQuery
>;
export type PaymentMethodWithContractsLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodWithContractsLazyQuery
>;
export type PaymentMethodWithContractsQueryResult = Apollo.QueryResult<
  PaymentMethodWithContractsQuery,
  PaymentMethodWithContractsQueryVariables
>;
export const PaymentMethodStatusDocument = gql`
  query PaymentMethodStatus($paymentMethodId: ID!) {
    paymentMethod(id: $paymentMethodId) {
      status
      registrationErrorCode
    }
  }
`;

/**
 * __usePaymentMethodStatusQuery__
 *
 * To run a query within a React component, call `usePaymentMethodStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodStatusQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function usePaymentMethodStatusQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentMethodStatusQuery, PaymentMethodStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodStatusQuery, PaymentMethodStatusQueryVariables>(
    PaymentMethodStatusDocument,
    options
  );
}
export function usePaymentMethodStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentMethodStatusQuery,
    PaymentMethodStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentMethodStatusQuery, PaymentMethodStatusQueryVariables>(
    PaymentMethodStatusDocument,
    options
  );
}
export type PaymentMethodStatusQueryHookResult = ReturnType<typeof usePaymentMethodStatusQuery>;
export type PaymentMethodStatusLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodStatusLazyQuery
>;
export type PaymentMethodStatusQueryResult = Apollo.QueryResult<
  PaymentMethodStatusQuery,
  PaymentMethodStatusQueryVariables
>;
export const PaymentMethodOperationStatusDocument = gql`
  query PaymentMethodOperationStatus($paymentMethodOperationId: ID!) {
    paymentMethodOperation(id: $paymentMethodOperationId) {
      id
      status
    }
  }
`;

/**
 * __usePaymentMethodOperationStatusQuery__
 *
 * To run a query within a React component, call `usePaymentMethodOperationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodOperationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodOperationStatusQuery({
 *   variables: {
 *      paymentMethodOperationId: // value for 'paymentMethodOperationId'
 *   },
 * });
 */
export function usePaymentMethodOperationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentMethodOperationStatusQuery,
    PaymentMethodOperationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaymentMethodOperationStatusQuery,
    PaymentMethodOperationStatusQueryVariables
  >(PaymentMethodOperationStatusDocument, options);
}
export function usePaymentMethodOperationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentMethodOperationStatusQuery,
    PaymentMethodOperationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaymentMethodOperationStatusQuery,
    PaymentMethodOperationStatusQueryVariables
  >(PaymentMethodOperationStatusDocument, options);
}
export type PaymentMethodOperationStatusQueryHookResult = ReturnType<
  typeof usePaymentMethodOperationStatusQuery
>;
export type PaymentMethodOperationStatusLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodOperationStatusLazyQuery
>;
export type PaymentMethodOperationStatusQueryResult = Apollo.QueryResult<
  PaymentMethodOperationStatusQuery,
  PaymentMethodOperationStatusQueryVariables
>;
export const PaymentOptionsDocument = gql`
  query PaymentOptions {
    paymentOptions {
      id
      type
      ixopay {
        publicKey
      }
    }
  }
`;

/**
 * __usePaymentOptionsQuery__
 *
 * To run a query within a React component, call `usePaymentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentOptionsQuery, PaymentOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentOptionsQuery, PaymentOptionsQueryVariables>(
    PaymentOptionsDocument,
    options
  );
}
export function usePaymentOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentOptionsQuery, PaymentOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentOptionsQuery, PaymentOptionsQueryVariables>(
    PaymentOptionsDocument,
    options
  );
}
export type PaymentOptionsQueryHookResult = ReturnType<typeof usePaymentOptionsQuery>;
export type PaymentOptionsLazyQueryHookResult = ReturnType<typeof usePaymentOptionsLazyQuery>;
export type PaymentOptionsQueryResult = Apollo.QueryResult<
  PaymentOptionsQuery,
  PaymentOptionsQueryVariables
>;
export const PaymentsDocument = gql`
  query Payments(
    $firstPayments: Int!
    $cursor: String
    $subscriptionId: ID
    $where: PaymentsWhereInput
  ) {
    payments(
      first: $firstPayments
      after: $cursor
      subscriptionId: $subscriptionId
      where: $where
    ) {
      ...PaymentsFragment
    }
  }
  ${PaymentsFragmentFragmentDoc}
`;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *      firstPayments: // value for 'firstPayments'
 *      cursor: // value for 'cursor'
 *      subscriptionId: // value for 'subscriptionId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentsQuery, PaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
}
export function usePaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
}
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsQueryResult = Apollo.QueryResult<PaymentsQuery, PaymentsQueryVariables>;
export const PaymentsDetailsDocument = gql`
  query PaymentsDetails($userId: ID!) {
    user(id: $userId) {
      id
      activeSubscriptionValue {
        currency
        inCents
      }
      monthlyTotal {
        currency
        inCents
      }
      activeSubscriptionValue {
        currency
        inCents
      }
    }
  }
`;

/**
 * __usePaymentsDetailsQuery__
 *
 * To run a query within a React component, call `usePaymentsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePaymentsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentsDetailsQuery, PaymentsDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentsDetailsQuery, PaymentsDetailsQueryVariables>(
    PaymentsDetailsDocument,
    options
  );
}
export function usePaymentsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentsDetailsQuery, PaymentsDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentsDetailsQuery, PaymentsDetailsQueryVariables>(
    PaymentsDetailsDocument,
    options
  );
}
export type PaymentsDetailsQueryHookResult = ReturnType<typeof usePaymentsDetailsQuery>;
export type PaymentsDetailsLazyQueryHookResult = ReturnType<typeof usePaymentsDetailsLazyQuery>;
export type PaymentsDetailsQueryResult = Apollo.QueryResult<
  PaymentsDetailsQuery,
  PaymentsDetailsQueryVariables
>;
export const SubscriptionPaymentsDetailsDocument = gql`
  query SubscriptionPaymentsDetails($id: ID!) {
    subscriptionDetails(id: $id) {
      ... on SubscriptionDetails {
        id
        name
        state
        note {
          ... on NextPaymentNote {
            date
            paymentMethod {
              __typename
            }
          }
          ... on TextNoteInterface {
            text
          }
        }
        variant {
          id
          colorCodes
          colorTitle
          imageUrl
        }
        price {
          currency
          inCents
        }
      }
      ... on Flex {
        id
        contractName: name
        contractState: state
        note {
          ... on NextPaymentNote {
            date
            paymentMethod {
              __typename
            }
          }
          ... on TextNoteInterface {
            text
          }
        }
        goods {
          id
          variant {
            id
            colorCodes
            colorTitle
            imageUrl
          }
        }
        billingTerms {
          price {
            currency
            inCents
          }
        }
      }
    }
  }
`;

/**
 * __useSubscriptionPaymentsDetailsQuery__
 *
 * To run a query within a React component, call `useSubscriptionPaymentsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPaymentsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPaymentsDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscriptionPaymentsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionPaymentsDetailsQuery,
    SubscriptionPaymentsDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SubscriptionPaymentsDetailsQuery,
    SubscriptionPaymentsDetailsQueryVariables
  >(SubscriptionPaymentsDetailsDocument, options);
}
export function useSubscriptionPaymentsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionPaymentsDetailsQuery,
    SubscriptionPaymentsDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubscriptionPaymentsDetailsQuery,
    SubscriptionPaymentsDetailsQueryVariables
  >(SubscriptionPaymentsDetailsDocument, options);
}
export type SubscriptionPaymentsDetailsQueryHookResult = ReturnType<
  typeof useSubscriptionPaymentsDetailsQuery
>;
export type SubscriptionPaymentsDetailsLazyQueryHookResult = ReturnType<
  typeof useSubscriptionPaymentsDetailsLazyQuery
>;
export type SubscriptionPaymentsDetailsQueryResult = Apollo.QueryResult<
  SubscriptionPaymentsDetailsQuery,
  SubscriptionPaymentsDetailsQueryVariables
>;
export const SubscriptionCollectionDocument = gql`
  query SubscriptionCollection(
    $filter: SubscriptionListFilterInput!
    $first: Int
    $searchTerm: String
  ) {
    subscriptionCollection(filter: $filter, first: $first, searchTerm: $searchTerm) {
      totalCount
      nodes {
        ... on SubscriptionCard {
          id
          name
          state
          product {
            createdAt
          }
        }
      }
    }
  }
`;

/**
 * __useSubscriptionCollectionQuery__
 *
 * To run a query within a React component, call `useSubscriptionCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionCollectionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSubscriptionCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionCollectionQuery,
    SubscriptionCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionCollectionQuery, SubscriptionCollectionQueryVariables>(
    SubscriptionCollectionDocument,
    options
  );
}
export function useSubscriptionCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionCollectionQuery,
    SubscriptionCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionCollectionQuery, SubscriptionCollectionQueryVariables>(
    SubscriptionCollectionDocument,
    options
  );
}
export type SubscriptionCollectionQueryHookResult = ReturnType<
  typeof useSubscriptionCollectionQuery
>;
export type SubscriptionCollectionLazyQueryHookResult = ReturnType<
  typeof useSubscriptionCollectionLazyQuery
>;
export type SubscriptionCollectionQueryResult = Apollo.QueryResult<
  SubscriptionCollectionQuery,
  SubscriptionCollectionQueryVariables
>;
export const YourProfileQueryDocument = gql`
  query YourProfileQuery {
    user {
      id
      email
      firstName
      lastName
      verificationState
      type
      defaultStore {
        code
        defaultCurrency
        defaultLocale
        name
      }
      company {
        groverWorkEnabled
      }
    }
  }
`;

/**
 * __useYourProfileQueryQuery__
 *
 * To run a query within a React component, call `useYourProfileQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useYourProfileQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYourProfileQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useYourProfileQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<YourProfileQueryQuery, YourProfileQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<YourProfileQueryQuery, YourProfileQueryQueryVariables>(
    YourProfileQueryDocument,
    options
  );
}
export function useYourProfileQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<YourProfileQueryQuery, YourProfileQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<YourProfileQueryQuery, YourProfileQueryQueryVariables>(
    YourProfileQueryDocument,
    options
  );
}
export type YourProfileQueryQueryHookResult = ReturnType<typeof useYourProfileQueryQuery>;
export type YourProfileQueryLazyQueryHookResult = ReturnType<typeof useYourProfileQueryLazyQuery>;
export type YourProfileQueryQueryResult = Apollo.QueryResult<
  YourProfileQueryQuery,
  YourProfileQueryQueryVariables
>;
